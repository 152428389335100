import React,{useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import Select from 'react-select';
import { useFormik } from "formik"
import * as Yup from "yup";
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import { getMasterData } from "../../../api/mentorApi";
import { getProfileDetails } from "../../../api/adminApi";
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function AdminProfilePage(){
// const[mentorDetails,setMentorDetails] = useState({});
const [fetchedCountryOptions, setFetchedCountryOptions] = useState([]);
// const [fetchedSkillsOptions, setFetchedSkillsOptions] = useState([]);
// const [fetchedLangOptions, setFetchedLangOptions] = useState([]);


const initialValues = {
  first_name: '',
  middle_name: '',
  last_name: '',
  email_address: '',
  mobile_number: '',
  addresses:{
    address1: '',
    address2: '',
    address3: '',
    state:'',
    country: '',
    postal_code:''
  },
     
}

const  validationAddressSchema = Yup.object({
  address1: Yup.string()
  .required("Address1 is required."),
  address3: Yup.string()
  .required("City is required."),
  country: Yup.string()
  .required("Country is required."),
  postal_code: Yup.string()
    .required("Postal code is required."),
}); 

const  validationSchema = Yup.object({
  first_name: Yup.string()
  .required("First name is required."),
  // middle_name: Yup.string()
  // .required("Middle name is required."),
  last_name: Yup.string()
  .required("Last name is required."),

  email_address: Yup.string()
  .required("Email address is required."),
  mobile_number: Yup.string()
  .required("Phone number is required."),
  addresses: validationAddressSchema,

}); 

const handleSubmit =(values) => {
  console.log('Mahesh Submit- '+ JSON.stringify(values));
}

const formik = useFormik({
  initialValues,
  validationSchema,
  onSubmit: handleSubmit,
});



useEffect(()=>{

  getMasterData("CON").then(response => {
    const transformedOptions = response.map(option => ({
      label: option.label,
      value: option.value
    }));
    setFetchedCountryOptions(transformedOptions);
  });

  getProfileDetails().then(response=>{
  
  formik.setValues({
    first_name: response.first_name,
    middle_name: response.middle_name,
    last_name: response.last_name,
    email_address: response.email_address,
    mobile_number: response.mobile_number,
    
    addresses:{
      address1: response.address1,
      address2: response.address2,
      address3: response.address3, // City
      //state: response.addresses.state, // State
      country: response.country, // Country
      postal_code:response.postal_code,
    },

  })
})
  
},[]);


const handleCountryChange = (selectedOption) => {
  formik.setFieldValue('addresses.country', selectedOption.label);
};

    return(
       
            <div className="featured-section-wrap featured-section-wrap-row">
            <div className='mainPageContainer'>        
                <div className='dashboardcontainerflex'>
                  <div className='dashboardcard'>
                  <Card style={{ width: '62rem', height: '53rem' }}>
                      <Card.Body>
                        <CustomTooltip text="You can update your personal information, such as your name, skills, language, contact details, pricing, and profile picture, to keep your profile current and accurate." />

                      <Form onSubmit={formik.handleSubmit}>
                        <Card.Title style={{ display: 'inline-block' }}>My Profile</Card.Title>
                        <ListGroup variant="flush">
                          <ListGroup.Item> 
                          <Card.Subtitle>Personal Details</Card.Subtitle>
                            <div className="row">
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formFirstName">
                                      <Form.Label>First Name</Form.Label>
                                      <Form.Control required type="text" placeholder="Enter first name" 
                                      name="first_name" 
                                      //value={mentorDetails.first_name}
                                      value={formik.values.first_name}
                                      onChange={formik.handleChange} 
                                      onBlur={formik.handleBlur}
                                      />
                                      <FormErrorMessage
                                          fieldName="first_name"
                                          formik={formik}
                                      ></FormErrorMessage>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formMiddleName">
                                      <Form.Label>Middle Name</Form.Label>
                                      <Form.Control type="text" placeholder="Middle name" 
                                      name="middle_name" 
                                      value={formik.values.middle_name}
                                      onChange={formik.handleChange} 
                                      onBlur={formik.handleBlur}
                                      />
                                      <FormErrorMessage
                                          fieldName="middle_name"
                                          formik={formik}
                                      ></FormErrorMessage>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formLastName">
                                      <Form.Label>Last Name</Form.Label>
                                      <Form.Control required type="text" placeholder="Last name" 
                                      name="last_name" 
                                      value={formik.values.last_name}
                                      onChange={formik.handleChange} 
                                      onBlur={formik.handleBlur}
                                      />
                                        <FormErrorMessage
                                          fieldName="last_name"
                                          formik={formik}
                                      ></FormErrorMessage>
                                    </Form.Group>
                                  </div>
                            </div>
                            <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control required type="email" placeholder="Enter email" 
                                 name="email_address" 
                                 value={formik.values.email_address}
                                 onChange={formik.handleChange} 
                                 onBlur={formik.handleBlur}   
                                />
                                 <FormErrorMessage
                                          fieldName="email_address"
                                          formik={formik}
                                      ></FormErrorMessage>
                                <Form.Text className="text-muted">
                                  We will never share your email with anyone else.
                                </Form.Text>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formMobileNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control required type="text" placeholder="Phone number"  
                                  name="mobile_number" 
                                  value={formik.values.mobile_number}
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur}   
                                />
                                  <FormErrorMessage
                                          fieldName="mobile_number"
                                          formik={formik}
                                      ></FormErrorMessage>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formAddrss1">
                                <Form.Label>Address</Form.Label>
                                <Form.Control required as="textarea" rows={2} placeholder="Enter address"
                                  name="addresses.address1"
                                  value={formik.values.addresses?.address1 }
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur}   
                                 />
                                 <div
                                    className="invalid-feedback"
                                    style={{
                                        display:
                                        formik.touched.addresses?.address1 && formik.errors.addresses?.address1
                                        ? "block"
                                        : "none",
                                    }}
                                >
                                    {formik.errors.addresses?.address1 ? formik.errors.addresses?.address1 : null}
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Address2 (Optional)</Form.Label>
                                <Form.Control as="textarea" rows={2} placeholder="Enter address"
                                 name="addresses.address2"
                                 value={formik.values.addresses?.address2 }
                                 onChange={formik.handleChange} 
                                 onBlur={formik.handleBlur} 
                                
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                          <div className="col">
                              <Form.Group className="mb-3" controlId="formcity">
                                <Form.Label>City</Form.Label>
                                <Form.Control required type="text" placeholder="City" 
                                   name="addresses.address3"
                                   value={formik.values.addresses?.address3 }
                                   onChange={formik.handleChange} 
                                   onBlur={formik.handleBlur}                                  
                                />
                                <div
                                    className="invalid-feedback"
                                    style={{
                                        display:
                                        formik.touched.addresses?.address3 && formik.errors.addresses?.address3
                                        ? "block"
                                        : "none",
                                    }}
                                >
                                    {formik.errors.addresses?.address3 ? formik.errors.addresses?.address3 : null}
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Country</Form.Label>
                                <Select required
                                      options={fetchedCountryOptions}
                                      value={fetchedCountryOptions.find((option) => option.label === formik.values.addresses?.country)}
                                      onChange={handleCountryChange}
                                      />
                                      {formik.errors.addresses?.country && (
                                          <div>{formik.errors.addresses?.country}</div>
                                      )}
                              </Form.Group>
                            </div>
                           
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control required type="text" placeholder="Postal Code" 
                                   name="addresses.postal_code"
                                   value={formik.values.addresses?.postal_code }
                                   onChange={formik.handleChange} 
                                   onBlur={formik.handleBlur}                                  
                                />
                                <div
                                    className="invalid-feedback"
                                    style={{
                                        display:
                                        formik.touched.addresses?.postal_code && formik.errors.addresses?.postal_code
                                        ? "block"
                                        : "none",
                                    }}
                                >
                                    {formik.errors.addresses?.postal_code ? formik.errors.addresses?.postal_code : null}
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          </ListGroup.Item>                             
                          </ListGroup>
                          
                        <Button variant="primary" type="submit" className="button">
                          Submit
                          </Button>
                      </Form>
                      </Card.Body>
                  </Card>
                  </div>
                </div>
            </div>
            </div>
        
      
              
            )

}
export default AdminProfilePage;