import React,{useState, useRef} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
// import Select from 'react-select';
import { useFormik } from "formik"
import * as Yup from "yup";
// import {useQuery, useQueryClient } from "react-query";
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import { Link } from "react-router-dom";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import PropTypes from "prop-types";
import { bulkEmployeeRegistration } from "../../../api/corporateApi";
import DateTimePicker from 'react-datetime-picker';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

const SingleRgistration = ({ formik }) => {
    return (
        <>
        <div className="row">
            <div className="col-sm-12 col-md-6">
                <Form.Group className="mb-3" controlId="empName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control  
                    type="text"
                    name="empName"  
                    value={formik.values.empName}
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}                  
                    ></Form.Control>
                    <FormErrorMessage
                        fieldName="empName"
                        formik={formik}
                    ></FormErrorMessage>
                    <div 
                    className="invalid-feedback"
                    style={{
                        display:
                        formik.touched.empName &&
                        formik.touched.empName
                        ? "block"
                        : "none",
                    }}
                    >
                        { 
                        formik.touched.empName
                        ? formik.touched.empName : null}
                    </div>
                </Form.Group>
            </div>
            <div className="col-sm-12 col-md-6">
                <Form.Group className="mb-3" controlId="empEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control  
                    type="text"
                    name="empEmail"  
                    value={formik.values.empEmail}
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}                  
                    ></Form.Control>
                    <FormErrorMessage
                        fieldName="empEmail"
                        formik={formik}
                    ></FormErrorMessage>
                    <div 
                    className="invalid-feedback"
                    style={{
                        display:
                        formik.touched.empEmail &&
                        formik.touched.empEmail
                        ? "block"
                        : "none",
                    }}
                    >
                        { 
                        formik.touched.empEmail
                        ? formik.touched.empEmail : null}
                    </div>
                </Form.Group>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12 col-md-6">
            <Form.Group className="mb-3" controlId="empDOB">
                    <Form.Label>DOB</Form.Label>
                <DateTimePicker
                id="empDOB"
                name="empDOB"
                value={formik.values.empDOB}
                onChange={(date) => formik.setFieldValue('empDOB', date)}
                maxDate={new Date()}
                format="yyyy-MM-dd"
                /> 
                    <FormErrorMessage
                        fieldName="empDOB"
                        formik={formik}
                    ></FormErrorMessage>
                    
                </Form.Group>

            </div>
            <div className="col-sm-12 col-md-6">
            <Form.Group className="mb-3" controlId="isnewjoinee">
                    {/* <Form.Label>Is New Joinee</Form.Label> */}
               
                    <Form.Check type="checkbox" 
                    label="Is New Joinee"
                    name="isnewjoinee"
                    checked={formik.values.isnewjoinee}
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}
                    />
                    <FormErrorMessage
                    fieldName="isnewjoinee"
                    formik={formik}
                    ></FormErrorMessage>
                    
                </Form.Group>

            </div>
        </div>
        <div className="col-sm-12 col-md-6">
            <Form.Group className="mb-3" controlId="empComments">
                <Form.Label>Comments</Form.Label>
                <Form.Control  
                type="text"
                name="comments"  
                value={formik.values.comments}
                onChange={formik.handleChange} 
                onBlur={formik.handleBlur}                  
                ></Form.Control>
                  <FormErrorMessage
                    fieldName="comments"
                    formik={formik}
                ></FormErrorMessage>
                <div 
                className="invalid-feedback"
                style={{
                    display:
                    formik.touched.comments &&
                    formik.touched.comments
                    ? "block"
                    : "none",
                }}
                >
                    { 
                    formik.touched.comments
                    ? formik.touched.comments : null}
                </div>
            </Form.Group>
        </div>
        </>
    )
}

const BulkRgistration = ({ formik }) => {
    const fileInputRef = useRef(null);

    const handleBannerImageChange = event => { 
        console.log(event);
        const file = fileInputRef.current.files[0];
        console.log(file);
        formik.setFieldValue('uploadFile', file);
    }
    return (
        <>
        <div className="col-sm-12 col-md-6">
            <Form.Group className="mb-3" controlId="formBannerImage">
            <Form.Label>Excel Upload (Bulk)</Form.Label>
            <Form.Control type="file"  
            accept=".xlsx"
            onChange={handleBannerImageChange} 
            name="uploadFile" 
            ref={fileInputRef}
            />
             {/* <FormErrorMessage
                    fieldName="uploadFile"
                    formik={formik}
                ></FormErrorMessage> */}
                 <div
                    className="invalid-feedback"
                    style={{
                        display:
                        formik.touched.uploadFile && formik.errors.uploadFile
                        ? "block"
                        : "none",
                    }}
                >
                    {formik.errors.uploadFile ? formik.errors.uploadFile : null}
                </div>
            <Form.Text className="text-muted">
                Please download the sample template   
                <Link to="/files/fileUploadSheet.xlsx" target="_blank"> download</Link>
            </Form.Text>
            </Form.Group>
        </div>                 
        </>
    )
}


const CorporateEmpSetupPage = () => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [statusArray, setStatusArray] = useState([]);
    const [clsName, setclsName] = useState('');
    
    const [selectedOption, setSelectedOption] = useState("0");

    const validationSchema = Yup.object().shape({
        isBulkUpload: Yup.string().required('Please select an option'),
        empName: Yup.string().test('empName', 'Employee name is required', function(value) {
            const { isBulkUpload } = this.parent;
            if (isBulkUpload === '0') {
              return !!value;
            }
            return true;
          }),
        // empEmail: Yup.string().test('empEmail', 'Employee email address is required', function(value) {
        //   const { isBulkUpload } = this.parent;
        //   if (isBulkUpload === '0') {
        //     return !!value;
        //   }
        //   return true;
        // }),
        empEmail: Yup.string()
        .test('empEmail', 'Employee email address is required', function(value) {
            const { isBulkUpload } = this.parent;
            if (isBulkUpload === '0') {
            return !!value;
            }
            return true;
        })
        .email("Invalid email address."),
        empDOB: Yup.date().test('empDOB', 'Employee DOB address is required', function(value) {
            const { isBulkUpload } = this.parent;
            if (isBulkUpload === '0') {
              return !!value;
            }
            return true;
          }),
        // empDOB: Yup.date()
        // .when('isBulkUpload', {
        //   is: (isBulkUpload) => isBulkUpload === '0',
        //   then: Yup.date().required('Employee DOB is required'),
        // }),
        // uploadFile: Yup.mixed()
        //   //.required('Please upload a file')
        //   .test('uploadFile', 'Only Excel files are allowed', function(value) {
        //     const { isBulkUpload } = this.parent;

        //     if (isBulkUpload !== '0') {
        //         return !!value;
        //       }
        //     if (value) {
        //       return value.type === 'application/vnd.ms-excel' || value.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        //     }
        //     return true;
        //   }),
        uploadFile: Yup.mixed().test('uploadFile', 'Only Excel files are allowed', function(value) {
            const { isBulkUpload } = this.parent;
        
            if (isBulkUpload === '1') {
              return Yup.mixed().required('Please upload a file').nullable().isValidSync(value);
            }
        
            return true;
          }),
        
      });

    const formik = useFormik({
        initialValues: {
          isBulkUpload: "0",
          uploadFile: '',
          empName: "",
          empEmail: "",
          empDOB: new Date(),
          isnewjoinee: false,
          comments:"",
        },
        validationSchema,
        onSubmit: (values) => {

          const utcDate = new Date(Date.UTC(
            values.empDOB.getFullYear(),
            values.empDOB.getMonth(),
            values.empDOB.getDate(),
            values.empDOB.getHours(),
            values.empDOB.getMinutes(),
            values.empDOB.getSeconds(),
          ));
          

          const formDataToSend = new FormData();
          formDataToSend.append("file",values.uploadFile);  
          formDataToSend.append("isBulkUpload",values.isBulkUpload); 
          formDataToSend.append("empName",values.empName); 
          formDataToSend.append("empEmail",values.empEmail); 
          formDataToSend.append("empDOB",utcDate.toISOString().split("T")[0]);
          formDataToSend.append("isnewjoinee",values.isnewjoinee); 
          formDataToSend.append("comments",values.comments); 

          bulkEmployeeRegistration(formDataToSend).then((response)=>{
            console.log(response);
            if(!response.ok){
                setclsName('alert error');
                setSnackbarMessage('Error in response');
                setShowSnackbar(true);
            } else{
                return response.json();
            }
            }).then(response=>{
             // console.log("resp - " + JSON.stringify(response));
              setStatusArray(response);
                
                // setSnackbarMessage('Successfully registrated employees data');
                // setShowSnackbar(true);
                formik.resetForm();
                //fileInputRef.current.value = null;
            });

        },
      });
      
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
      }
    const handleRadioChange = (event) => {
       
        setSelectedOption(event.target.value);
        formik.setFieldValue('isBulkUpload', event.target.value);
        //fileInputRef.current.value = null;
        setStatusArray([]); 
        //formik.resetForm(); 
         
    };


    return(
        <div className="featured-section-wrap featured-section-wrap-row">
            <div className='mainPageContainer'>        
                <div className='dashboardcontainerflex'>
                    <div className='dashboardcard'>
                        <Card style={{ width: '62rem', height: '53rem' }}>
                        <Card.Body>
                        <CustomTooltip text="Easily upload multiple employee emails and bulk upload employee information for efficient onboarding. Employees will get an email notification to download the Mobile App. <br> <br> Utilize this function to seamlessly add new employees to the learning and development platform, including introduction videos, links, and acceptance processes. " />
                        {/* <Card.Title style={{ display: 'inline-block' }}>Employee Setup</Card.Title> */}
                        <ListGroup variant="flush">
                          <ListGroup.Item> 
                          <Card.Subtitle></Card.Subtitle>
                          <Form onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col">
                                        Do you want to setup Employee account? 
                                        <p>
                                        If you would like to setup multiple email accounts, select bulk upload
                                        </p>
                                        <hr/>
                                        <div className="mb-3" role="group">
                                            <Form.Check
                                            inline
                                            label="Bulk Upload"
                                            name="isBulkUpload"
                                            type="radio"
                                            id="radio-has-user"
                                            onChange={handleRadioChange}
                                            //onChange={formik.handleChange}
                                            //checked={formik.values.isBulkUpload == 1}
                                            value="1"
                                            >
                                            </Form.Check>
                                            <Form.Check
                                            inline
                                            label="Employee email"
                                            name="isBulkUpload"
                                            type="radio"
                                            id="radio-has-user"
                                            onChange={handleRadioChange}
                                            //onChange={formik.handleChange}
                                            defaultChecked
                                            //checked={formik.values.isBulkUpload == 0}
                                            value="0"
                                            >
                                            </Form.Check>
                                        </div>
                                        <hr/>
                                        {selectedOption == 1 && <BulkRgistration formik={formik} />}
                                        {selectedOption == 0 && <SingleRgistration formik={formik} />}

                                        

                                        <Button variant="primary" type="submit">
                                            Register
                                        </Button>
                                        {statusArray && statusArray.length > 0 ? (
                                        statusArray.map((item) => (
                                            <div key={item.email}>
                                            {item.success ? (
                                                <p className="text-sm" style={{ margin: 0 }}>
                                                Added {item.email} - We have sent an email with instructions for the user to download the app.
                                                </p>
                                            ) : (
                                                <p className="text-sm text-danger" style={{ margin: 0 }}>
                                                Error adding {item.email}: {item.message}
                                                </p>
                                            )}
                                            </div>
                                        ))
                                        ) : (
                                        <p></p>
                                        )}

                                </div>
                            </div> 
                          </Form>
                                     
                          </ListGroup.Item>
                          </ListGroup>
                         </Card.Body>
                         </Card>   
                    </div>
                </div>
                <div>
                    <SnackbarProvider 
                    show={showSnackbar}
                    message={snackbarMessage}
                    onClose={handleCloseSnackbar}
                    message1={clsName}
                    />
                </div>
            </div>
        </div>
    )
}

BulkRgistration.propTypes ={
    formik: PropTypes.object.isRequired,   
  }; 
SingleRgistration.propTypes ={
    formik: PropTypes.object.isRequired,   
  }; 
export default CorporateEmpSetupPage;