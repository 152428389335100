import React from "react";
// import './../../../../Stylesheet/leftbar.css'
// import '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min'
// import '../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css'
import  {Link}  from 'react-router-dom' //useLocation
//import ProfilePic from '../../../../Images/avatar.png';
import ProfileCard from '../profileCards/Profilecard';
import { useState } from 'react'
//import ProfileCard from '../../shared/profileCards/ProfileCard';

const AdminbarNew = ()=>{
   // const location = useLocation();
    
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [isCorporateSubmenuOpen, setIsCorporateSubmenuOpen] = useState(false);
    const [isInstituteSubmenuOpen, setIsInstituteSubmenuOpen] = useState(false);
    const [isConfigSubmenuOpen, setIsConfigSubmenuOpen] = useState(false);
    const [isReportsSubmenuOpen, setIsReportsSubmenuOpen] = useState(false);
     
    const toggleSubmenu = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
    }
    const toggleCorporateSubmenu = () => {
        setIsCorporateSubmenuOpen(!isCorporateSubmenuOpen);
    }
    const toggleInstituteSubmenu = () => {
        setIsInstituteSubmenuOpen(!isInstituteSubmenuOpen);
    }
    const toggleConfigSubmenu = () => {
        setIsConfigSubmenuOpen(!isConfigSubmenuOpen);
    }
    const toggleReportsSubmenu = () => {
        setIsReportsSubmenuOpen(!isReportsSubmenuOpen);
    }

    const handleLogout =()=>{    
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      }
    return(
        <aside className="left-sidebar" data-sidebarbg="skin6">
           
        <div className="scroll-sidebar">
        <ProfileCard></ProfileCard>
            <nav className="sidebar-nav">
                <ul id="sidebarnav">
                   
                    <li className="sidebar-item pt-2">
                        <Link to={"/dashboard-admin"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/dashboard-admin' ? 'active' : ''}`}>
                            <i className="far fa-clock"></i> <span className="hide-menu">Dashboard</span>
                        </Link>
                    </li>
                    <li className="sidebar-item">                             
                        <Link to={"/profile-admin"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/profile-admin' ? 'active' : ''}`}>
                            <i className="fa fa-user"></i> <span className="hide-menu">My Profile</span>
                        </Link>
                    </li>
                    <li className="sidebar-item">
                                <a className='nav-link align-middle px-0' style={{cursor:'pointer', marginLeft: '27px'}} onClick={toggleSubmenu}>
                                <i className="fs-4 bi-gear"></i> <span className="ms-1 d-none d-sm-inline"
                                >Mentor Account</span>
                                </a>
                                {isSubmenuOpen && (
                                    <ul className='submenu' style={{ marginLeft: '45px' }}>
                                         <li className='nav-link'>
                                            <Link to={"/new-leads/mentor"} >
                                            <i className="bi bi-caret-right me-1" ></i> New Mentors
                                            </Link>

                                        </li>
                                        <li className='nav-link'>
                                            <Link to={"/mentor-list"} >
                                            <i className="bi bi-caret-right me-1" ></i> Mentor List
                                            </Link>
                                        </li>
                                        <li className='nav-link'> 
                                        <Link to={"/admin-payment-list"} className="nav-link align-middle px-0">
                                            <i className="bi bi-caret-right me-1" ></i> Payments
                                            </Link>                                            
                                        </li>
                                    </ul>
                                )}
                               
                                
                            </li>
                            <li className="sidebar-item">
                                <a className='nav-link align-middle px-0' style={{cursor:'pointer',  marginLeft: '27px'}} onClick={toggleCorporateSubmenu}>
                                <i className="fs-4 bi-building"></i> <span className="ms-1 d-none d-sm-inline"
                                >Corporate Account</span>
                                </a>
                                {isCorporateSubmenuOpen && (
                                    <ul className='submenu' style={{ marginLeft: '45px' }}>
                                        <li className='nav-link'>
                                            <Link to={"/new-leads/corporate"} >
                                            <i className="bi bi-caret-right me-1" ></i> New Corporates
                                            </Link>

                                        </li>
                                        <li className='nav-link'>
                                            <Link to={"/corporate-list"} >
                                            <i className="bi bi-caret-right me-1" ></i> Corporate List
                                            </Link>

                                        </li>
                                       
                                    </ul>
                                )}
                            </li>

                            <li>
                                <a className='nav-link align-middle px-0' style={{cursor:'pointer',  marginLeft: '27px'}} onClick={toggleInstituteSubmenu}>
                                <i className="bi bi-book fs-4"></i> <span className="ms-1 d-none d-sm-inline"
                                >Educational Account</span>
                                </a>
                                {isInstituteSubmenuOpen && (
                                    <ul className='submenu' style={{ marginLeft: '45px' }}>
                                        <li className='nav-link'>
                                            <Link to={"/new-leads/institute"} >
                                            <i className="bi bi-caret-right me-1" ></i> New Institutes
                                            </Link>

                                        </li>
                                        <li className='nav-link'>
                                            <Link to={"/institute-list"} >
                                            <i className="bi bi-caret-right me-1" ></i> Institute List
                                            </Link>

                                        </li>
                                       
                                    </ul>
                                )}
                            </li>

                    {/* <li className="sidebar-item">                            
                        <Link to={"/under-construction"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/under-construction' ? 'active' : ''}`}>
                            <i className="bi bi-person-plus"></i> <span className="hide-menu">Educational Account </span>
                        </Link> 
                    </li> */}
                                       
                    <li className="sidebar-item">                            
                        <Link to={"/admin-notifications"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/admin-notifications' ? 'active' : ''}`}>
                            <i className="fa fa-bell"></i> <span className="hide-menu">Alerts & Messages</span>
                        </Link>
                    </li>
                    <li>
                        <a className='nav-link align-middle px-0' style={{cursor:'pointer',  marginLeft: '27px'}} onClick={toggleReportsSubmenu}>
                        <i className="bi bi-file-earmark-bar-graph me-1"></i> <span className="ms-1 d-none d-sm-inline"
                        >Reports</span>
                        </a>
                        {isReportsSubmenuOpen && (
                            <ul className='submenu' style={{ marginLeft: '45px' }}>
                                <li className='nav-link'>
                                    <Link to={"/report-emotionExpresso"} >
                                    <i className="bi bi-caret-right me-1" ></i> Emotion Expresso
                                    </Link>

                                </li>
                            </ul>
                        )}
                    </li>
                    <li>
                        <a className='nav-link align-middle px-0' style={{cursor:'pointer',  marginLeft: '27px'}} onClick={toggleConfigSubmenu}> 
                               <i className="fs-4 bi-tools"></i> <span className="ms-1 d-none d-sm-inline"
                        >Configurations</span>
                        </a>
                        {isConfigSubmenuOpen && (
                                <ul className='submenu' style={{ marginLeft: '20px' }}>
                                        <li className='nav-link' >
                                            <Link to={"/config-skills"} >
                                            <i className="bi bi-caret-right me-1" ></i> Skills Setup
                                            </Link>
                                        </li>
                                        <li className='nav-link' href='#'>
                                            <Link to={"/config-category"} >
                                                <i className="bi bi-caret-right me-1" ></i> Category Setup
                                            </Link>
                                        </li>
                                        <li className='nav-link' href='#'>
                                            <Link to={"/config-language"} >
                                                <i className="bi bi-caret-right me-1" ></i> Language Setup
                                            </Link>
                                        </li>
                                        <li className='nav-link' href='#'>
                                            <Link to={"/config-rcategory"} >
                                                <i className="bi bi-caret-right me-1" ></i> Resource Category
                                            </Link>
                                        </li>
                                        <li className='nav-link'>
                                            <Link to={"/config-constants"} >
                                            <i className="bi bi-caret-right me-1" ></i> Constants
                                            </Link>
                                        </li>
                                        <li className='nav-link'> 
                                            <Link to={"/config-etemplates"} >
                                            <i className="bi bi-caret-right me-1" ></i> Email Templates
                                            </Link>                                            
                                        </li>
                                        <li className='nav-link'> 
                                            <Link to={"/config-ads/view"} >
                                            <i className="bi bi-caret-right me-1" ></i> Advertisements
                                            </Link>                                            
                                        </li>
                                        <li className='nav-link'>
                                            <Link to={"/config-subscriptionplan/view"} >
                                                <i className="bi bi-caret-right me-1" ></i> Subscription Setup
                                            </Link>    
                                            
                                        </li>
                                       
                                        <li className='nav-link' >
<Link to={"/under-construction"} className="nav-link align-middle px-0">
                                        <i className="bi bi-caret-right me-1" ></i> Coupon Setup
</Link>
                                        </li>
                                       
                                        
                                    </ul>
                                     )}
                            </li> 

                            <li className="text-center p-20 upgrade-btn">
                            <a href="/login" onClick={handleLogout}
                                className="btn d-grid btn-menu text-white">
                                SignOut</a>
                            </li>
                </ul>

            </nav>
           
        </div>
       
    </aside> 
    );
   
}
export default AdminbarNew;