// import React, { useState } from 'react';
// import VideoPopup from './VideoPopup';

// const AddCoursePage = () => {
//   const [showPopup, setShowPopup] = useState(false);
//   const [selectedVideo, setSelectedVideo] = useState(null);

//   const videos = [
//     {
//       videoLibraryId: 1,
//       guid: 'abcd-1234',
//       title: 'Introduction to React',
//       dateUploaded: '2022-01-15T10:00:00.000Z',
//       views: 100,
//       isPublic: true,
//       length: 180,
//       status: 2,
//       framerate: 30,
//       rotation: null,
//       width: 1280,
//       height: 720,
//       availableResolutions: '360p,480p,720p',
//       thumbnailCount: 50,
//       encodeProgress: 100,
//       storageSize: 150000000,
//       hasMP4Fallback: true,
//       collectionId: 'abcd-5678',
//       thumbnailFileName: 'thumbnail.jpg',
//       averageWatchTime: 120,
//       totalWatchTime: 600,
//       category: 'education',
//       chapters: [],
//       moments: [],
//       metaTags: ['React', 'Frontend'],
//       transcodingMessages: [],
//     },
//     {
//       videoLibraryId: 2,
//       guid: 'efgh-5678',
//       title: 'Node.js Basics',
//       dateUploaded: '2022-02-20T14:30:00.000Z',
//       views: 50,
//       isPublic: true,
//       length: 240,
//       status: 2,
//       framerate: 30,
//       rotation: null,
//       width: 1280,
//       height: 720,
//       availableResolutions: '360p,480p,720p',
//       thumbnailCount: 60,
//       encodeProgress: 100,
//       storageSize: 200000000,
//       hasMP4Fallback: true,
//       collectionId: 'efgh-1234',
//       thumbnailFileName: 'thumbnail.jpg',
//       averageWatchTime: 180,
//       totalWatchTime: 900,
//       category: 'technology',
//       chapters: [],
//       moments: [],
//       metaTags: ['Node.js', 'Backend'],
//       transcodingMessages: [],
//     },
//     // Add more video data entries as needed
//   ];
  

//   const handleOpenPopup = () => {
//     setShowPopup(true);
//   };

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   const handleSelectVideo = (video) => {
//     setSelectedVideo(video);
//     setShowPopup(false);
//   };

//   return (
//     <div>
//       <h1>Add New Course</h1>
//       <button onClick={handleOpenPopup}>Search/Map Videos</button>

//       {/* Render the selected video */}
//       {selectedVideo && (
//         <div>
//           <h2>Selected Video</h2>
//           <p>{selectedVideo.title}</p>
//         </div>
//       )}

//       {/* Render the video popup */}
//       {showPopup && (
//         <VideoPopup
//           videos={videos}
//           onSelectVideo={handleSelectVideo}
//           onClose={handleClosePopup}
//         />
//       )}
//     </div>
//   );
// };

// export default AddCoursePage;

import React, { useState } from 'react';
import VideoPopup from './VideoPopup'; // Assuming the path to VideoPopup is correct

const AddCoursePage = () => {
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);

    const videos = [
    {
      videoLibraryId: 1,
      guid: 'abcd-1234',
      title: 'Introduction to React',
      dateUploaded: '2022-01-15T10:00:00.000Z',
      views: 100,
      isPublic: true,
      length: 180,
      status: 2,
      framerate: 30,
      rotation: null,
      width: 1280,
      height: 720,
      availableResolutions: '360p,480p,720p',
      thumbnailCount: 50,
      encodeProgress: 100,
      storageSize: 150000000,
      hasMP4Fallback: true,
      collectionId: 'abcd-5678',
      thumbnailFileName: 'thumbnail.jpg',
      averageWatchTime: 120,
      totalWatchTime: 600,
      category: 'education',
      chapters: [],
      moments: [],
      metaTags: ['React', 'Frontend'],
      transcodingMessages: [],
    },
    {
      videoLibraryId: 2,
      guid: 'efgh-5678',
      title: 'Node.js Basics',
      dateUploaded: '2022-02-20T14:30:00.000Z',
      views: 50,
      isPublic: true,
      length: 240,
      status: 2,
      framerate: 30,
      rotation: null,
      width: 1280,
      height: 720,
      availableResolutions: '360p,480p,720p',
      thumbnailCount: 60,
      encodeProgress: 100,
      storageSize: 200000000,
      hasMP4Fallback: true,
      collectionId: 'efgh-1234',
      thumbnailFileName: 'thumbnail.jpg',
      averageWatchTime: 180,
      totalWatchTime: 900,
      category: 'technology',
      chapters: [],
      moments: [],
      metaTags: ['Node.js', 'Backend'],
      transcodingMessages: [],
    },
    // Add more video data entries as needed
  ];

  const handleOpenVideoPopup = () => {
    setShowVideoPopup(true);
  };

  const handleCloseVideoPopup = () => {
    setShowVideoPopup(false);
  };

  const handleSelectVideos = (videos) => {
  
    setSelectedVideos(videos);
    setShowVideoPopup(false);
  };

  return (
    <div>
      {/* ... your other content ... */}
      <button onClick={handleOpenVideoPopup} className="button">Select Videos</button>

      {showVideoPopup && (
        <VideoPopup
          videos={videos}
          onClose={handleCloseVideoPopup}
          onSelectVideos={handleSelectVideos}
        />
      )}

         {/* Render the selected videos */}
    {selectedVideos.length > 0 && (
      <div>
        <h2>Selected Videos</h2>
        <ul>
          {selectedVideos.map((video) => (
            <li key={video.videoLibraryId}>{video.title}</li>
          ))}
        </ul>
      </div>
    )}
    </div>
  );
};

export default AddCoursePage;

