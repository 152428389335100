import React,{useEffect , useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import { Card} from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';
import { addWorkshopsRequest } from "../../../api/corporateApi";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import Select from 'react-select';
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";

import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';
import { getCurrentUser } from '../../..//api/userAuthApi';

function WorkshopsRequestPage(){
  const [clsName, setclsName] = useState(''); 
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isOtherSkillsSelected, setIsOtherSkillsSelected] = useState(false);

 
  const initialValues = {
    name: '',
    emailAddress: '',
    description: '', 
    requestDate: new Date(),
    skills: [],
    others: '',
    mode: 'Online', 
    type: 'C', 
    requestType: 'W',
    durations: [],
    durationsText:'', 
  }

  const  validationSchema = Yup.object({
    
    description: Yup.string()
    .required("Description is required."),  
    
  }); 

 
  const handleSubmit = (values) => {

    try {
      values.type = 'C';
      values.requestType = 'W';
      addWorkshopsRequest(values).then((response)=>{
        console.log(response);
        if(!response.ok){
          setclsName('alert error');
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
            //return response;
            setclsName('alert success');
            setSnackbarMessage('Request has been submitted Successfully.');
            setShowSnackbar(true);
            formik.resetForm(); 
        });
    }
    catch (error){
      console.log(error);
    }
  }
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(()=>{
    const user = getCurrentUser();         
    if (user) {
      
        // setProfileCompletionPercentage(user.profile_completion);
        // setUserName(user.firstname + ' ' + user.lastname);

        formik.setValues({
          name: user.firstname,
          emailAddress: user.user_logon,
          requestDate: new Date(),
          type: 'C', 
          requestType: 'W', 
          mode: 'Online', 
        })
    }
    
  },[]);
   
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
  const fetchedSkillsOptions = [
    { value: '1', label: 'Communication' },
    { value: '2', label: 'Emotional Intelligence' },
    { value: '3', label: 'Leadership' },
    { value: '4', label: 'Diversity and Inclusion' },
    { value: '5', label: 'Team Management' },
    { value: '6', label: 'Workplace Wellness' },
    { value: '7', label: 'Employee Mentalhealth' },
    { value: '8', label: 'Any other Skills' },
  ];

  const fetchedDurationsOptions = [
    { value: '1', label: '2 hours' },
    { value: '2', label: 'Half-Day' },
    { value: '3', label: 'Full-Day' },
    { value: '4', label: 'Not Sure' },
  ];

  const handleSkillsChange = (selectedOptions) => {
    formik.setFieldValue('skills', selectedOptions);

    // Check if "Any other Skills" is selected
    const otherSkillsSelected = selectedOptions.some(option => option.value === '8');
    setIsOtherSkillsSelected(otherSkillsSelected);

    // Clear the "others" field if "Any other Skills" is deselected
    if (!otherSkillsSelected) {
      formik.setFieldValue('others', '');
    }
  };

  const handleDurationsChange = (selectedOptions) => {
    formik.setFieldValue('durations', selectedOptions);
    formik.setFieldValue('durationsText', selectedOptions.label);
    
  };

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card>
                  <Card.Body>
                  <div>
                  All inquiries submitted inquiries on this page will be shared with our registered Mentors and Motivational Speakers. Also, our internal team will circulate with new mentors and motivational spakers if their skills match the requirements. Please provide as much details as possibl for consideration.
                  </div>
                  <CustomTooltip text="If you need assistance or have any questions, our support team is available to help you with any issues or concerns you may have while using the platform." />
                    <br></br>
                    <Form onSubmit={formik.handleSubmit}>                        
                          <div className="row">
                             <div className="col">
                               <Form.Group className="mb-3" controlId="formdescription">
                                 <Form.Label>Name</Form.Label>
                                 <Form.Control  type="text"
                                  name="name"
                                  value={formik.values.name }
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur}   
                                 />
                                  <FormErrorMessage
                                       fieldName="name"
                                       formik={formik}
                                   ></FormErrorMessage>
                               </Form.Group>
                             </div>
                           </div> 
                           <div className="row">
                             <div className="col">
                               <Form.Group className="mb-3" controlId="formdescription">
                                 <Form.Label>Email Address</Form.Label>
                                 <Form.Control  type="text"
                                  name="emailAddress"
                                  value={formik.values.emailAddress }
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur}   
                                 />
                                  <FormErrorMessage
                                       fieldName="emailAddress"
                                       formik={formik}
                                   ></FormErrorMessage>
                               </Form.Group>
                             </div>
                           </div> 
                             <div className="row">
                             <div className="col">
                               <Form.Group className="mb-3" controlId="formdescription">
                                 <Form.Label>Description</Form.Label>
                                 <Form.Control required as="textarea" rows={4} placeholder="Enter description"
                                  name="description"
                                  value={formik.values.description }
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur}   
                                 />
                                  <FormErrorMessage
                                       fieldName="description"
                                       formik={formik}
                                   ></FormErrorMessage>
                               </Form.Group>
                             </div>
                           </div> 
                           <div className="row">
                            <div className="col">
                                <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Workshop Date</Form.Label><br/>
                                <DateTimePicker
                                    id="requestDate"
                                    name="requestDate"
                                    value={formik.values.requestDate}
                                    onChange={(date) => formik.setFieldValue('requestDate', date)}
                                    minDate={new Date()}
                                    //format="yyyy-MM-dd HH:mm:ss"
                                    />
                                <br/>
                                    <FormErrorMessage
                                        fieldName="requestDate"
                                        formik={formik}
                                    ></FormErrorMessage>
                                     
                                </Form.Group>
                            </div>                  
                        </div>

                        <div className="row">
                            <div className="col">
                            <Form.Group className="mb-3" controlId="formdescription">
                                 <Form.Label>Specific Skills</Form.Label>
                                <Select 
                                required
                                options={fetchedSkillsOptions}
                                isMulti
                                isSearchable={false}
                                value={formik.values.skills}
                                onChange={handleSkillsChange}
                              />
                              <FormErrorMessage
                                fieldName="skills"
                                formik={formik}
                              ></FormErrorMessage>

         
                          {isOtherSkillsSelected && (
                            <Form.Control 
                              type="text"
                              name="others"
                              placeholder="Please specify other skills"
                              value={formik.values.others}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}   
                            />
                          )}
                               </Form.Group>
                            </div>                  
                        </div>   
                        <div className="row">
                             <div className="col">
                             <Form.Group className="mb-3" controlId="formdescription">
                             <Form.Label>Duration of the Workshop / Session:</Form.Label>
                                <Select 
                                    required
                                    options={fetchedDurationsOptions}
                                    isSearchable={false}
                                    value={formik.values.durations}
                                    onChange={handleDurationsChange}
                                  />
                                  <FormErrorMessage
                                    fieldName="durations"
                                    formik={formik}
                                  ></FormErrorMessage>
                             </Form.Group>
                             </div>
                            </div>  
                           <div className="row">
                             <div className="col">
                               <Form.Group className="mb-3" controlId="formdescription">
                                 <Form.Label>How do you wish to conduct the Workshop/Session?</Form.Label>
                                  <br></br>
                                 <Form.Check
                                      inline
                                      label="Online"
                                      name="mode"
                                      type="radio"
                                      id="radio-has-user"
                                      onChange={formik.handleChange}
                                      checked={formik.values.mode === "Online"}
                                      value="Online"
                                      >
                                    </Form.Check>
                                    <Form.Check
                                    inline
                                    label="Offline"
                                    name="mode"
                                    type="radio"
                                    id="radio-has-user"
                                    onChange={formik.handleChange}
                                    checked={formik.values.mode === "Offline"}
                                    value="Offline"
                                    >
                                    </Form.Check>
                                 
                               </Form.Group>
                             </div>
                           </div>
                           
                          <Button variant="primary" className="button" type="submit">
                          Submit
                          </Button>                 
                    </Form>

                 

                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
            </div>

        </div>
        </div>
          );

}
export default WorkshopsRequestPage;