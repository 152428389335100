import React,{useEffect , useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { Link, useParams, useNavigate } from "react-router-dom";
import { updateShorts,getShortsDetails,deleteFilesPhysically } from "../../../api/mentorApi";
import Modal from 'react-bootstrap/Modal';

function MentorShortsEditPage(){
  const [clsName, setclsName] = useState('');
  const navigate = useNavigate();
  const { guid } = useParams();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const documentfileInputRef = useRef(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rowDataToDelete, setRowDataToDelete] = useState(null);

  const initialValues = {
    guid: guid,
    title: '',
    description: '',
    downloadFile: null,
    fileitems:[]
  }

  const  validationSchema = Yup.object({
    title: Yup.string()
    .required("Title is required."),  
    description: Yup.string()
    .required("Description is required."),  
    downloadFile: Yup.mixed()
    // .required('Shorts image is required.')
    .test('fileSize', 'File size must be less than 10MB', (value) => {
      if (!value) return true; // No file uploaded, so validation passes
      const fileSizeLimit = 10 * 1024 * 1024; // 10MB in bytes
      return value.size <= fileSizeLimit;
    })
    .test('fileType', 'Only JPEG, PNG, and GIF images are allowed.', (value) => {
      if (!value) return true;  

      const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/mpeg', 'video/quicktime', 'video/webm', 'video/x-msvideo', 'video/x-flv'];
      return allowedImageTypes.includes(value.type);
    }),
  }); 

 
  const handleSubmit = (values) => {
     
    try {
     
      const formDataToSend = new FormData();
      formDataToSend.append("guid",values.guid);
      formDataToSend.append("title",values.title);
      formDataToSend.append("description",values.description);
      const files = documentfileInputRef.current.files;
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formDataToSend.append("documentfile",file);
      }
       
      updateShorts(formDataToSend).then((response)=>{
        if(!response.ok){
          setclsName('alert error');
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
          setclsName('alert success');
            setSnackbarMessage('Successfully updated shorts data.');
            setShowSnackbar(true);
            //formik.resetForm();
            setTimeout(() => {
              navigate("/shortsView");
            }, 2000);
            documentfileInputRef.current.value = null;
            fetchShortsData();
        });

    }
    catch (error){
      console.log(error);
    }
  }
   

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(()=>{
    
    fetchShortsData();

  },[guid]);

  const fetchShortsData =() => {
    getShortsDetails(guid).then(response=>{    
      formik.setValues({
        guid:guid,
        title: response.title,
        description: response.description,
        fileitems: response.fileitems,
      });
    })
  }

  const handleDocumentUploadChange  = event => {
    console.log(event);
    const file = documentfileInputRef.current.files[0];  
    formik.setFieldValue('downloadFile', file);
     
  }

 
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
  
  const handleDeleteFile = (index) => {
    setRowDataToDelete(index);
    handleDeleteClick();
  };

  const handleDeleteClick = () => {
    //setRowDataToDelete(row);
    setShowConfirmation(true);
};

const handleConfirmDelete = () => {
    
  const updatedFiles = [...formik.values.fileitems];
  //const deletedFile = updatedFiles[rowDataToDelete];
  updatedFiles.splice(rowDataToDelete, 1); // Remove the file at the specified index
  formik.setFieldValue('fileitems', updatedFiles);

  const fileId = formik.values.fileitems[rowDataToDelete].fileid;
 
  const formDataToSend = {
    id: null,
    guid: guid,
    fileid: fileId,
    type: 'SHORTS',
    };
  deleteFilesPhysically(formDataToSend).then((response)=>{
    if(!response.ok){
        setclsName('alert error');
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        //return response.json();
    }
    }).then(response=>{
      console.log(response);
        setclsName('alert success');
        setSnackbarMessage('Successfully deleted file.');
        setShowSnackbar(true);
        
    });

    setShowConfirmation(false);
    setRowDataToDelete(null);
};

const handleCancelDelete = () => {
   setRowDataToDelete(null);
    setShowConfirmation(false);
}; 

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card>
                  <Card.Body>
                 
                    {/* <Card.Title style={{ display: 'inline-block' }}>Update Shorts</Card.Title> */}
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="row">
                                <div className="col" style={{float:"right"}}>
                                  <Link to="/shortsView">
                                  <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                                      Back
                                  </Button>
                                  </Link>  
                                </div>
                        </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control required type="text" placeholder="Enter title" 
                                name="title"  
                                value={formik.values.title}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="title"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control required type="text" placeholder="Enter description" 
                                name="description"  
                                value={formik.values.description}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="description"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>                          
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDownloadFile">
                                <Form.Label>Upload document</Form.Label>
                                <Form.Control type="file" multiple 
                                onChange={handleDocumentUploadChange} 
                                name="downloadFile" 
                                ref={documentfileInputRef} />
                                 <FormErrorMessage
                                      fieldName="downloadFile"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                          </div>  
                          <div className="row">
                                <div className="col">
                                 
                                {formik.values.fileitems && formik.values.fileitems.map((fileItem, index) => (
                                  <div key={index}>
                                    <a href={fileItem.path} target="_blank" rel="noopener noreferrer">
                                      {fileItem.title}
                                    </a>
                                    <Button variant="primary" style={{marginBottom:'5px',marginTop:'5px',marginLeft:'5px'}} size="sm" onClick={() => handleDeleteFile(index)}>
                                    <span className="bi bi-trash"></span>  
                                  </Button>
                                  </div>
                                ))}

                              
                                </div>
                              </div> 
                          <Button variant="primary" type="submit" className="button">
                          Update
                          </Button>                 
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
            
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
               <Modal show={showConfirmation} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                    Cancel
                </Button>
                <Button variant="danger" className="button" onClick={handleConfirmDelete}>
                    Delete
                </Button>
                </Modal.Footer>
            </Modal>
            </div>

        </div>
        </div>
          );

}
export default MentorShortsEditPage;