import React, {useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
// import { Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
//import Select from 'react-select';
//accountDeActivate,accountActivate,
import { updateSettlePercentage } from "../../../api/adminApi";
//import moment from "moment/moment";
import * as Yup from "yup";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { PropTypes } from "prop-types";

function SettlementUpdatePage(props){
 // const restrictedWordsRegex = /\b(call|phone|whatsapp|number|mobile)\b/i;

  const { id } = props;
  
  //const { guid } = useParams(); 
  //const [conversationsList, setConversationsList] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  const validationSchema = Yup.object({
    pervalue: Yup.string()
        .required('Percentage value is required.')
        .matches(/^[0-9]+$/, 'Percentage value must contain only numbers'),
});

const handleSubmit = async (values) => {  
  
    updateSettlePercentage(values).then((response)=>{    
    if(!response.ok){
        // const err = new Error("Error in response");
        // throw err;
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        //return response.json();
    }
    }).then(response=>{
      console.log(response);
        setSnackbarMessage('Settlement percentage updated successful.');
        setShowSnackbar(true);
        formik.resetForm();         
    });


}

const formik = useFormik({
  initialValues: {
      guid: id,
      pervalue: ""
  },
  validationSchema ,
  onSubmit: handleSubmit,
}); 

   
  
  useEffect(()=>{
    //fetchConversationsData();

  },[id]);
  

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    }
   

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              Settlement % Update
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
            
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3" controlId="formShortDescription">
                      <Form.Label>Percentage Value</Form.Label>
                      <Form.Control required type="text" placeholder="Enter value" 
                      name="pervalue"  
                      value={formik.values.pervalue}
                      onChange={formik.handleChange} 
                      //onChange={handleOnChange}
                      onBlur={formik.handleBlur} 
                      />
                        <FormErrorMessage
                            fieldName="pervalue"
                            formik={formik}
                        ></FormErrorMessage>
                    </Form.Group>
                  </div>                            
                </div>
                          <Button variant="primary" type="submit">
                          Submit
                          </Button>  
            </Form> 
           
         <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
          </Modal.Body>
        </Modal>

        
    );
}

SettlementUpdatePage.propTypes ={
  id: PropTypes.string.isRequired,  
  onPaymentSettlementSubmit: PropTypes.object.isRequired, 
};
export default SettlementUpdatePage;