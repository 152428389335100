import React, { useEffect, useState } from "react";
import { Card } from 'react-bootstrap';

//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
import { getEmployees } from "../../../api/corporateApi";
//import { getConversationsByLogon } from "../../../api/adminApi";
import { getNotifications } from "../../../api/mentorApi";
import moment from "moment/moment";
import '../../../Stylesheet/dashboard.css';
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

const CorporateDashboardPage =()=>{
  const classes = useStyles();
  const [employeeList, setEmployeeList] = useState([]);
  //const [conversationsList, setConversationsList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  
 // const [showWelcomeContent, setShowWelcomeContent] = useState(true);

//   const [filterType, setFilterType] = useState("RECEIVED");
//   const [filterStatus, setFilterStatus] = useState("");
  
  // const dateFormatter = (cell) => { //, row
  //   const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
  //   if(formattedDate === 'Invalid date'){return <span></span>;}
  //   return <span>{formattedDate}</span>;
  //   }

  const columns =[
    {field: 'firstname', headerName: 'Name', minWidth: 150},
    {field: 'emailaddress', headerName: 'Email Address', minWidth: 150},
    {field: 'mobilenumber', headerName: 'Mobile Number', minWidth: 150},
    {field: 'lastlogon', headerName: 'Download Status', minWidth: 150},

        // // {dataField: 'id', text: '#'},
        // {dataField: 'firstname', text: 'Name'},
        // {dataField: 'emailaddress', text: 'Email Address'},
        // {dataField: 'mobilenumber', text: 'Mobile Number'},
        // // {dataField: 'divisionname', text: 'Division'},
        // // {dataField: 'createdby', text: 'Created By'},
        // {dataField: 'lastlogon', text: 'Download Status'},
        // // {dataField: 'lastlogon', text: 'Last Logon',formatter: dateFormatter},
         
    ];
    // const ActionColumnFormatter = (cell) => {
    //     if (cell === 'mentor1@gmail.com') {
    //       return (
    //         <div  
    //                 style={{
    //                   width: '30px',
    //                   height: '10px',
    //                   backgroundColor: 'red',
    //                   marginRight: '10px',
    //                   marginLeft:'10px'
                      
    //                 }}
    //               ></div>
    //       );
    //     }
    //     else
    //     {
    //       return (
    //         <div 
    //         style={{
    //           width: '30px',
    //           height: '10px',
    //           backgroundColor: 'blue',
    //           marginRight: '10px',
    //           marginLeft:'10px'
    //         }}
    //       ></div>
    //       );
    //     }
    //   }
    // const columnsConversation = [
       
    //     {
    //       dataField: 'message',
    //       text: 'Message',
    //     },
    //     {
    //         dataField: 'sendat',
    //         text: 'Send At',
    //         formatter: dateFormatter,  
    //     },
    //      {
    //             dataField: 'sender',
    //             text: '',
    //             formatter: ActionColumnFormatter,
    //           },
        
        
    //   ];

    useEffect(()=>{
        fetchEmployeesData();
        //fetchConversationsData();
        fetchNotificationsData();
          },[]);
      
      const fetchEmployeesData =() => {
        getEmployees().then(response=>{                    
          setEmployeeList(response); 
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }
    //   const fetchConversationsData =() => {
    //     getConversationsByLogon('').then(response=>{                    
    //       setConversationsList(response); 
    //     })
    //     .catch(error => {
    //       // Handle error
    //       console.log(error);
    //     });
    //   }

      const fetchNotificationsData =() => {
        getNotifications().then(response=>{
          const data = response;                  
          setNotificationList(data);        
        });
      }
// const toggleWelcomeContent = () => {
//     setShowWelcomeContent(!showWelcomeContent);
//   }
//   const handleAccordionToggle = () => {
//     setShowWelcomeContent(!showWelcomeContent);
//   }
//   const columnsReceived = [
//     // { dataField: "receiverlogon", text: "Receiver" },
//     { dataField: "senderlogon", text: "Name" },
//     { dataField: "notificationtype", text: "Type" },
//     { dataField: "message", text: "Message" },
//     { dataField: "status", text: "Status" },
//     { dataField: "createddate", text: "Created Date", formatter: dateFormatter },
//     { dataField: "ref_id", text: "Ref. Id" },
//     {
//       dataField: "refid",
//       text: "Actions",
//       formatter: ActionColumnFormatter,
//     },
//   ];

  const columnsSent = [
    {field: 'receiverlogon', headerName: 'Name', minWidth: 150},
    {field: 'notificationtype', headerName: 'Type', minWidth: 150},
    {field: 'message', headerName: 'Message', minWidth: 150},
    {field: 'status', headerName: 'Status', minWidth: 150},
    {field: 'createddate', headerName: 'Created Date', minWidth: 150, 
    valueGetter: (params) => moment(params.row.createddate).format("YYYY-MM-DD HH:mm")
    },

    // { dataField: "receiverlogon", text: "Name" },
    // // { dataField: "senderlogon", text: "Sender" },
    // { dataField: "notificationtype", text: "Type" },
    // { dataField: "message", text: "Message" },
    // { dataField: "status", text: "Status" },
    // { dataField: "createddate", text: "Created Date", formatter: dateFormatter },
    // // { dataField: "ref_id", text: "Ref. Id" },
    // // {
    // //   dataField: "refid",
    // //   text: "Actions",
    // //   formatter: ActionColumnFormatter,
    // // },
  ];

//   const filteredNotifications = notificationList.filter((notification) => {
//     if (filterType === "RECEIVED" && notification.receiverlogon !== "Somotus Admin") {
//       return false;
//     }
//     if (filterType === "SENT" && notification.senderlogon !== "Somotus Admin") {
//       return false;
//     }
//     if (filterStatus && notification.status !== filterStatus) {
//       return false;
//     }
//     return true;
//   });

const [isInitialRender, setIsInitialRender] = useState(true);
const [isAlertVisible, setAlertVisible] = useState(true);

useEffect(()=>{
  const isDismissed = localStorage.getItem('alertDismissed');     
  setAlertVisible(!isDismissed);
  setIsInitialRender(false);
});

  const handleCloseClick = () => {
    setAlertVisible(false);
    localStorage.setItem('alertDismissed', 'true');
  };

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>    
        {!isInitialRender && isAlertVisible && (
        <div className="dashboard-alert">
          <span className="dashboard-closebtn" onClick={handleCloseClick}>&times;</span>
          Welcome <br></br>
                You are the VIPs of our technological world! Without you, our products would be like a phone without a charger - useless! Your feedback and suggestions are like the secret sauce that makes our technology the best in the world. We rely on your brilliant minds to help us improve, innovate, and create the next big thing. So please, dont hold back! Share your thoughts, ideas, and even your wildest dreams with us. Together, we can conquer any technological challenge and build a future that is truly extraordinary. 
                <br></br><br></br>
                Ah, product development, the never-ending journey of adding features and making improvements. Our tech team are Superheroes, working tirelessly every day to add some awesome features to our product. And guess what? You wont have to wait long to see the magic happen! Updates will be rolling out every week, so get ready to be wowed. Stay tuned, my friend!
                Thank you for being a part of our incredible journey!<br></br><br></br>
                Yours sincerely,<br></br>
                Team at SOMOTUS
        </div>
      )}
            <Card>
            <Card.Body>
            {/* <Button
            className="welcome-section"
              variant="outline-primary"
              style={{
                color:'white',
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: '10px'
              }}
              onClick={() => setShowWelcomeContent(!showWelcomeContent)}
            >
              {/* Welcome Content */}
              {/* <span className={`bi ${showWelcomeContent ? 'bi-chevron-up' : 'bi-chevron-down'}`} />
            </Button>
            
            {showWelcomeContent && (
              <>
                Welcome <br></br>
                You are the VIPs of our technological world! Without you, our products would be like a phone without a charger - useless! Your feedback and suggestions are like the secret sauce that makes our technology the best in the world. We rely on your brilliant minds to help us improve, innovate, and create the next big thing. So please, dont hold back! Share your thoughts, ideas, and even your wildest dreams with us. Together, we can conquer any technological challenge and build a future that is truly extraordinary. 
                <br></br><br></br>
                Ah, product development, the never-ending journey of adding features and making improvements. Our tech team are Superheroes, working tirelessly every day to add some awesome features to our product. And guess what? You wont have to wait long to see the magic happen! Updates will be rolling out every week, so get ready to be wowed. Stay tuned, my friend!
                Thank you for being a part of our incredible journey!<br></br><br></br>
                Yours sincerely,<br></br>
                Team at SOMOTUS
              </>
            )} */}  
{/* <div
  className="welcome-section"
  style={{
    color: 'white',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '10px',
    border: '1px solid #007bff', // Optional: Add border for separation
    borderRadius: '5px', // Optional: Add border-radius for a rounded appearance
    backgroundColor: '#007bff', // Optional: Set background color
  }}
  onClick={() => setShowWelcomeContent(!showWelcomeContent)}
>
  <div className="welcome-section" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <span></span>
    <span className={`bi ${showWelcomeContent ? 'bi-chevron-up' : 'bi-chevron-down'}`} />
  </div>
  {showWelcomeContent && (
    <>
      <div>
        Welcome <br />
        You are the VIPs of our technological world! Without you, our products would be like a phone without a charger - useless! Your feedback and suggestions are like the secret sauce that makes our technology the best in the world. We rely on your brilliant minds to help us improve, innovate, and create the next big thing. So please, don't hold back! Share your thoughts, ideas, and even your wildest dreams with us. Together, we can conquer any technological challenge and build a future that is truly extraordinary.
      </div>
      <div>
        Ah, product development, the never-ending journey of adding features and making improvements. Our tech team are Superheroes, working tirelessly every day to add some awesome features to our product. And guess what? You won't have to wait long to see the magic happen! Updates will be rolling out every week, so get ready to be wowed. Stay tuned, my friend!
        Thank you for being a part of our incredible journey!
        <br />
        Yours sincerely, Team at SOMOTUS
      </div>
    </>
  )}
</div> */}

           
<div className="container-fluid">
  <h3>Employees</h3>
  <div className="row">
    <div className="col">
      {/* <BootstrapTable 
        keyField="guid"
        data={employeeList}
        columns={columns}
        bootstrap4
        striped
        hover
        condensed
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-bordered table-hover"
      /> */}
        <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={employeeList}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

    </div>
  </div>
</div>

<div className="container-fluid">
  <h3>Alerts and Notifications</h3>
  <div className="row">
    <div className="col">
    
      {/* <BootstrapTable 
         keyField="id"
         data={notificationList}
         columns={columnsSent}
        bootstrap4
        striped
        hover
        condensed
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-bordered table-hover"
      /> */}
       <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={notificationList}
                                columns={columnsSent}
                                getRowId={(row) => row.id}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

    </div>
  </div>
</div>
            {/* <BootstrapTable 
                    keyField="id"
                    data={conversationsList}
                    columns={columnsConversation}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                   //pagination={paginationFactory(options)}
                    
                    /> */}
            
           
            </Card.Body>
            </Card>  
            
        </div>
          
        </div>
      
    )
}
export default CorporateDashboardPage;