import React,{useState, useEffect} from "react";

import Button  from "react-bootstrap/Button";
import Col  from "react-bootstrap/Col";
import Alert from 'react-bootstrap/Alert';
import { Card, Form } from 'react-bootstrap';
// import Select from 'react-select';
import { Link } from 'react-router-dom';
import * as Yup from "yup";
// import {useQueryClient } from "react-query";
import { registerWithUserDetails } from "../../../api/userAuthApi";
import Row  from "react-bootstrap/Row";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import topbarLogo from '../../../../src/Images/lifeskylls-logo.png';
function RegisterPage(){
    
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isError, setIsError] = useState(null);

    const [clsName, setclsName] = useState('');
   
    
   
    const  validationSchema = Yup.object({
        company_name: Yup.string()
        .required("Company Name is required."),
        first_name: Yup.string()
        .required("First Name is required."),
            // last_name: Yup.string()
            // .required("Last Name is required."),
        email_address: Yup.string()
        .email("Invalid email address.")
        .required("Email is required."),
        mobile_number: Yup.string()
        .required("Mobile number is required."),
        password: Yup.string()
        .min(6, "Password must be at least 6 characters long.")
        .max(50, "Must be 50 characters or less.")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
        )
        .required("Password is required."),

        profile_URL: Yup.string().url('Invalid LinkedIn URL')
        .required("LinkedIn URL is required.")
        .matches(
            /^(https?:\/\/)?(www\.)?linkedin\.com\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]+$/,
            'Invalid LinkedIn URL format'
        ),
            // short_description: Yup.string()
            // .required("Short description is required."),
            // confirmPassword: Yup.string()
            // .oneOf([Yup.ref('password'),null], 'Password must match')
            // .max(50,"Must be 50 characters or less.")
            // .required("Confirm Password is required."),
            // role: Yup.string()
            // .required("Please select the role."),

            // is_terms_condition: Yup.bool().oneOf([true], 'Terms and Condition is required.'),
      }); 


      const handleSubmit = async (values) => {  
        values.short_description = values.company_name;
        
        await registerWithUserDetails(values)        
        .then(response=>{
           
            //if(Array.isArray(response) && response.length > 0){
            if(response.message.responsemessage === 'IN-USE'){
                //const errors = response.map(item => item.code);
                //alert("Errors - " + errors);
                setSnackbarMessage('Email address already in used.');
                setIsError(true);
               
                setclsName('alert error');
                // setShowSnackbar(true);
            }
            else{
                setSnackbarMessage('Registration Successful, your profile will be reviewed by our team. You will receive the confirmation email after verification.');
               
                setIsError(false);
                formik.resetForm();
                setclsName('alert success');
                //setShowSnackbar(true);
            }
            
           
            
          
          });
      }



    const formik = useFormik({
        initialValues: {
            company_name: "",
            first_name: "",
            last_name: "",
            email_address: "",
            mobile_number: "",
            profile_URL: '',
            short_description: '',
            is_terms_condition: true,
            password: "",
            confirmPassword: "",
            role: '3',
          
        },
        validationSchema ,
        onSubmit: handleSubmit,
    }); 
   
    useEffect(()=>{
        // getMasterData("ROL").then(response => {
        //   const transformedOptions = response.map(option => ({
        //     label: option.label,
        //     value: option.value
        //   }));
        //   alert(response)
        //   setFetchedRoleOptions(transformedOptions);
        // });
        //console.log('isError - '+ isError);
         
        
      },[isError]);

    // const handleRoleChange = (selectedOption) => {
    //     formik.setFieldValue('role', selectedOption.value);
    //   };
    const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    }
    
    return(
        <div>
             <nav className="navbar navbar-expand-lg navbar-light header-color">
    <div className="container-fluid">
    <img src={topbarLogo} className="topBarLogo" />
      <a className="navbar-brand" href="#"></a>
     
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
      </div>
    </div>
      </nav>
      
        <div className="row justify-content-md-center" style={{width:"100%",  alignContent:"center"}}>
        <div className='col-sm-12 col-md-6'>
          <Card className='my-5'>
            <Card.Body>
              <Card.Title>Sign Up</Card.Title>
                        <Form onSubmit={formik.handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col}  controlId="formcontrolCompName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                required
                                type='text'
                                placeholder="Company Name"
                                name="company_name" 
                                value={formik.values.company_name}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage
                                    fieldName="company_name"
                                    formik={formik}
                                ></FormErrorMessage>
                            </Form.Group>
                            
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}  controlId="formcontrolFirstName">
                                <Form.Label>Your Name</Form.Label>
                                <Form.Control
                                required
                                type='text'
                                placeholder="First Name"
                                name="first_name" 
                                value={formik.values.first_name}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage
                                    fieldName="first_name"
                                    formik={formik}
                                ></FormErrorMessage>
                                <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please provide First Name</Form.Control.Feedback>
                            </Form.Group>
                            
                        </Row>
                                

                                <Row className="mb-3">
                                <Form.Group as={Col}  controlId="formcontrolEmail">
                                <Form.Label>Email</Form.Label>
                                    <Form.Control
                                required
                                type='email'
                                placeholder="Email"
                                name="email_address" 
                                value={formik.values.email_address}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage
                                    fieldName="email_address"
                                    formik={formik}
                                ></FormErrorMessage>
                                <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please provide valid email</Form.Control.Feedback>
                                </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                <Form.Group as={Col}  controlId="formcontrolMobileNumber">
                                <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                required
                                type='text'
                                placeholder="Mobile Number"
                                name="mobile_number" 
                                value={formik.values.mobile_number}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage
                                    fieldName="mobile_number"
                                    formik={formik}
                                ></FormErrorMessage>
                                <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please provide Last Name</Form.Control.Feedback>
                                </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                <Form.Group as={Col}  controlId="formcontrolProfileURL">
                                <Form.Label>LinkedIn Profile URL</Form.Label>
                                    <Form.Control
                                    required
                                    type='text'
                                    placeholder="Profile URL"
                                    name="profile_URL" 
                                    value={formik.values.profile_URL}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    />
                                    <FormErrorMessage
                                        fieldName="profile_URL"
                                        formik={formik}
                                    ></FormErrorMessage>
                              
                                </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                <Form.Group as={Col}  controlId="formcontrolPassword">
                                <Form.Label>Password</Form.Label>
                                    <Form.Control
                                required
                                type='password'
                                placeholder="Paswword"
                                name="password" 
                                value={formik.values.password}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage
                                    fieldName="password"
                                    formik={formik}
                                ></FormErrorMessage>
                                <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please provide valid email</Form.Control.Feedback>
                                </Form.Group>
                                </Row>
                                {isError !== null && (
                                <div className={isError ? 'alert error' : 'alert success'}>
                                    {snackbarMessage}
                                </div>
                                )}


                               
                                 
                                <Button 
                                //variant="primary" 
                                type="submit" 
                                className="button"
                                //disabled={mutation.isLoading}
                                //onClick={handleSubmit}
                                >
                                Submit{" "}
                                
                                </Button>
                                <Alert key='primary' className="alertbox" variant='primary'>
                                    <Link to="/Login" className="aLink">Already has an account? Login here</Link>
                                </Alert>
                    </Form>
              </Card.Body>
              </Card>
              </div>
                <SnackbarProvider 
                show={showSnackbar}
                message={snackbarMessage}
                onClose={handleCloseSnackbar}
                clsname={clsName}
                
                />
              </div>
        </div>
    )
}

export default RegisterPage;