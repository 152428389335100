import React,{useEffect, useState, useRef} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import Select from 'react-select';
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import { getCorporateDetails, getMasterData,updateProfile,updateInduction,updateInductionDocuments,deleteFilesPhysically } from "../../../api/corporateApi";
import * as Yup from "yup";
// import CustomSelect from "../../common/shared/controls/CustomSelect";
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import Modal from 'react-bootstrap/Modal';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function CorporateProfilePage(){

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rowDataToDelete, setRowDataToDelete] = useState(null);
  
  const [options, setOptions] = useState([{title: '', link: '' }]);
  //const [optionErrors, setOptionErrors] = useState([]);
  const [optionErrors, setOptionErrors] = useState(Array(options.length).fill({ title: '', link: '' }));
  //const [optionErrors, setOptionErrors] = useState(Array((options || []).length).fill({ title: '', link: '' }));
  const [isAtLeastOneOptionAdded, setIsAtLeastOneOptionAdded] = useState(false);

// const[mentorDetails,setMentorDetails] = useState({});
const [clsName, setclsName] = useState('');
const [fetchedCountryOptions, setFetchedCountryOptions] = useState([]);
// const [fetchedSkillsOptions, setFetchedSkillsOptions] = useState([]);
// const [fetchedLangOptions, setFetchedLangOptions] = useState([]);
const [showSnackbar, setShowSnackbar] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const fileInputRef = useRef(null);
const [selectedImage, setSelectedImage] = useState(null);

const [introVideoList, setIntroVideoList] = useState([]);

const [documentList, setDocumentList] = useState([]);
const [deleteType, setDeleteType] = useState(null);

const initialValues = {
  first_name: '',
  middle_name: '',
  last_name: '',
  email_address: '',
  mobile_number: '',
  addresses:{
    address1: '',
    address2: '',
    address3: '',
    state:'',
    country: '',
    postal_code:''
  },
  short_description: '',
  company_name: '',
  company_website: '',
  companylogopath: '',
  logoFile: null,
}
 
 
const initialValuesDocuments = {
  videoLinks: [],
  documentspath: '',
  documentsFile: null,
}
const  validationSchemaDocuments = Yup.object({
  // introsummary: Yup.string()
  // .required("Summary is required."),
}); 

const documentfileInputRef = useRef(null);

const handleSubmitDocuments =(values) => {
   

  const hasOptionErrors = optionErrors.some((error) => error.link);
    
  if (hasOptionErrors) {
    setclsName('alert error');
    setSnackbarMessage('Please fix the video link errors before submitting.');
    setShowSnackbar(true);
  }
  else{
    const formDataToSend = new FormData();
    const validOptions = options.filter((option) => option.title.trim() !== '' && option.link.trim() !== '');
    
    if (validOptions.length === 0 && !documentfileInputRef.current.files.length) {
      // No valid non-empty rows and no uploaded document files
      setclsName('alert error');
      setSnackbarMessage('Please upload at least one item (Video List or Document).');
      setShowSnackbar(true);
      return; // Prevent further processing
    }

    if (validOptions.length === 0) {
      // No valid rows, set VideoLink to null
      formikDocuments.setFieldValue('videoLinks', null);
      formDataToSend.append("videoLinks",null);
    } else {
      formikDocuments.setFieldValue('videoLinks',options);
      formDataToSend.append("videoLinks",JSON.stringify(values.videoLinks));
    }
        
    
    const files = documentfileInputRef.current.files;
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formDataToSend.append("documentfile",file);
    }
  
    updateInductionDocuments(formDataToSend).then((response)=>{
      if(!response.ok){
        setclsName('alert error');
          setSnackbarMessage('Error in response');
          setShowSnackbar(true);
      } else{
        return response.json();
      }
      }).then(response=>{
        console.log(response);
        setclsName('alert success');
        setSnackbarMessage('Induction documents updated successfully.');
        setShowSnackbar(true);
        fetchProfileData();
        documentfileInputRef.current.value = null;
      });

    
  }


  


}

const formikDocuments = useFormik({
  initialValues: initialValuesDocuments,
  validationSchema: validationSchemaDocuments,
  onSubmit: handleSubmitDocuments,
});


const introfileInputRef = useRef(null);
const initialValuesInduction = {
  introsummary: '',
  introfilepath: '',
  introFile: null,
}
const  validationSchemaInduction = Yup.object({
  introsummary: Yup.string()
  .required("Summary is required."),
}); 

const handleSubmitInduction =(values) => {
  
  const formDataToSend = new FormData();
  formDataToSend.append("file",values.introFile); 
  formDataToSend.append("introsummary",values.introsummary); 
   
  updateInduction(formDataToSend).then((response)=>{
    if(!response.ok){
      setclsName('alert error');
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
      return response.json();
    }
    }).then(response=>{
      console.log(response);
      setclsName('alert success');
      setSnackbarMessage('Induction updated successfully.');
      setShowSnackbar(true);
      fetchProfileData();
      introfileInputRef.current.value = null;
    });

}

const formikInduction = useFormik({
  initialValues: initialValuesInduction,
  validationSchema: validationSchemaInduction,
  onSubmit: handleSubmitInduction,
});


const  validationAddressSchema = Yup.object({
  address1: Yup.string()
  .required("Address1 is required."),
  address3: Yup.string()
  .required("City is required."),
  country: Yup.string()
  .required("Country is required."),
  postal_code: Yup.string()
    .required("Postal code is required."),
}); 

const  validationSchema = Yup.object({
  first_name: Yup.string()
  .required("First name is required."),
  // middle_name: Yup.string()
  // .required("Middle name is required."),
  last_name: Yup.string()
  .required("Last name is required."),

  email_address: Yup.string()
  .email('Invalid email address format')
  .required('Email address is required'),
  mobile_number: Yup.string()
  .matches(/^(?:\+91\s?)?\d{10}$/, 'Invalid mobile number')
  .required('Mobile number is required'),
  addresses: validationAddressSchema,
  short_description: Yup.string()
  .required("Short description is required."),
  company_name: Yup.string()
  .required("Company name is required."),
  company_website: Yup.string()
  .required("Company website is required."),

 
}); 

const handleSubmit =(values) => {
   
  const formDataToSend = new FormData();
  formDataToSend.append("file",values.logoFile); 

  formDataToSend.append("first_name",values.first_name); 
  formDataToSend.append("middle_name",values.middle_name); 
  formDataToSend.append("last_name",values.last_name); 
  formDataToSend.append("email_address",values.email_address); 
  formDataToSend.append("mobile_number",values.mobile_number); 

  formDataToSend.append("address1",values.addresses.address1); 
  formDataToSend.append("address2",values.addresses.address2); 
  formDataToSend.append("address3",values.addresses.address3); 
  formDataToSend.append("state",values.addresses.state); 
  formDataToSend.append("country",values.addresses.country); 
  formDataToSend.append("postal_code",values.addresses.postal_code); 

  formDataToSend.append("short_description",values.short_description); 
  formDataToSend.append("company_name",values.company_name); 
  formDataToSend.append("company_website",values.company_website); 

   
  updateProfile(formDataToSend).then((response)=>{
    if(!response.ok){
      setclsName('alert error');
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
      return response.json();
    }
    }).then(response=>{
      //console.log(response);

      // Retrieve the user object from localStorage
      const userString = localStorage.getItem('user');
      const user = JSON.parse(userString);
      user.profile_completion = response.profilecompletionCount; 
      const modifiedUserString = JSON.stringify(user);
      localStorage.setItem('user', modifiedUserString);
      setclsName('alert success');
      setSnackbarMessage('Profile updated successfully. Now! You can start with employee setup');
      setShowSnackbar(true);
      fetchProfileData();
        //formik.resetForm();
    });

}

const formik = useFormik({
  initialValues,
  validationSchema,
  onSubmit: handleSubmit,
});



useEffect(()=>{
  getMasterData("CON").then(response => {
 
    const transformedOptions = response.map(option => ({
      label: option.label,
      value: option.value
    }));
    setFetchedCountryOptions(transformedOptions);
  });
  fetchProfileData();
// getCorporateDetails().then(response=>{
//   // const transformedSkillsOptions = response.skills.map(option => ({
//   //   label: option.name,
//   //   value: option.id
//   // }));
//   // const transformedLangOptions = response.languages.map(option => ({
//   //   label: option.name,
//   //   value: option.id
//   // }));
  
//   formik.setValues({
//     first_name: response.first_name,
//     middle_name: response.middle_name,
//     last_name: response.last_name,
//     email_address: response.email_address,
//     mobile_number: response.mobile_number,
//     short_description: response.short_description,
//     company_name: response.company_name,
//     company_website: response.company_website,
//     companylogopath: response.companylogoPath,
     
//     addresses:{
//       address1: response.addresses.address1,
//       address2: response.addresses.address2,
//       address3: response.addresses.address3, // City
//       //state: response.addresses.state, // State
//       country: response.addresses.country, // Country
//       postal_code:response.addresses.postal_code,
//     },

//   })
// })
  
},[]);


const fetchProfileData =() => {
  getCorporateDetails().then(response=>{
   
    formik.setValues({
      first_name: response.first_name,
      middle_name: response.middle_name,
      last_name: response.last_name,
      email_address: response.email_address,
      mobile_number: response.mobile_number,
      short_description: response.short_description,
      company_name: response.company_name,
      company_website: response.company_website,
      companylogopath: response.companylogoPath,
       
      addresses:{
        address1: response.addresses.address1,
        address2: response.addresses.address2,
        address3: response.addresses.address3, // City
        //state: response.addresses.state, // State
        country: response.addresses.country, // Country
        postal_code:response.addresses.postal_code,
      },
  
    });

    formikInduction.setValues({
      introsummary : response.introsummary,
    });
    setIntroVideoList(response.introvideo);
    setDocumentList(response.documentfiles);
    
    if(response.documentlinks != null){
      setOptions(response.documentlinks);
    }
  

     
    // alert(JSON.stringify(response.introvideo));
    // const introVideoArray = JSON.parse(response.introvideo);
    // setIntroVideoList(introVideoArray || []);
    // alert(JSON.stringify(introVideoList));
  })
}

 
const handleCloseSnackbar = () => {
  setShowSnackbar(false);
}

const handleCountryChange = (selectedOption) => {
  formik.setFieldValue('addresses.country', selectedOption.label);
};

const handleLogoFileChange = event => {
  console.log(event);
    const file = fileInputRef.current.files[0];
   formik.setFieldValue('logoFile', file);
   
}
const handleVideoFileChange = event => {
  console.log(event);
    const file = introfileInputRef.current.files[0];
    formikInduction.setFieldValue('introFile', file);
}
const handleDocumentUploadChange = event => {
  console.log(event);
  const file = documentfileInputRef.current.files[0];  
  formikDocuments.setFieldValue('documentsfile', file);
}

const openBannerModal = () => {
  setSelectedImage(formik.values.companylogopath);
};

const handleRemoveOption = (index) => {
  const updatedOptions = [...options];
  updatedOptions.splice(index, 1);
  setOptions(updatedOptions);
  const hasOptionsLeft = updatedOptions.length > 0;
  setIsAtLeastOneOptionAdded(hasOptionsLeft);
  console.log(isAtLeastOneOptionAdded);
};

const handleAddOption = () => {
  setOptions([...options, {title: '', link: ''  }]);
};
const isYouTubeUrl = (url) => {
  const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/)/;
  return youtubePattern.test(url);
};
const isValidUrl = (url) => {
  return !isYouTubeUrl(url);
};

const handleOptionTextChange = (e, index) => {
  const updatedOptions = [...options];
  updatedOptions[index].link = e.target.value;
  setOptions(updatedOptions);
  formikDocuments.setFieldValue('videoLinks', options);
  if (!isValidUrl(e.target.value)) {
    const newErrors = [...optionErrors];
    newErrors[index] = { ...newErrors[index], link: 'YouTube URL is not allowed.' };
    setOptionErrors(newErrors);
  } else {
    const newErrors = [...optionErrors];
    newErrors[index] = { ...newErrors[index], link: '' };
    setOptionErrors(newErrors);
    //setIsAtLeastOneOptionAdded(true);
  }
  const hasProperData = updatedOptions.some(option => option.link.trim() !== '');
  setIsAtLeastOneOptionAdded(hasProperData)

};

const handleOptionTitleTextChange = (e, index) => {
  const updatedOptions = [...options];
  updatedOptions[index].title = e.target.value;
  setOptions(updatedOptions);
  formikDocuments.setFieldValue('videoLinks',options);
};

const handleDeleteFile = (index) => {
  setRowDataToDelete(index);
  setDeleteType('INDUCTION');
  handleDeleteClick();
};
const handleDeleteFileDoc = (index) => {
  setRowDataToDelete(index);
  setDeleteType('INDUCTIONDOC');
  handleDeleteClick();
};

const handleDeleteClick = () => {
  //setRowDataToDelete(row);
  setShowConfirmation(true);
};

const handleConfirmDelete = () => {
  const deletedFile = deleteType === 'INDUCTION' ? introVideoList[rowDataToDelete] : documentList[rowDataToDelete];
  const fileId = deletedFile.value;
 
  // deleteFilesPhysically
  const formDataToSend = {
    id: null,
    guid:null,
    fileid: fileId,
    type: deleteType,
  };
  deleteFilesPhysically(formDataToSend).then((response)=>{
  if(!response.ok){
      setclsName('alert error');
      setSnackbarMessage('Error in response');
      setShowSnackbar(true);
  } else{
      //return response.json();
  }
  }).then(response=>{
    console.log(response);
      setclsName('alert success');
      setSnackbarMessage('Successfully deleted file.');
      setShowSnackbar(true);
      fetchProfileData();
  });

  setShowConfirmation(false);
  setRowDataToDelete(null);
};

// const handledeleteFileItem = (filepath) => {
// const formDataToSend = {
//   filepath:filepath
//   };
//  deleteFile(formDataToSend).then((response)=>{
//   console.log(response);
//   }).then(response=>{
//     console.log(response);
//   });
// }

const handleCancelDelete = () => {
 setRowDataToDelete(null);
  setShowConfirmation(false);
}; 


    return(
       
            <div className="featured-section-wrap featured-section-wrap-row">
            <div className='mainPageContainer'>        
                <div className='dashboardcontainerflex'>
                  <div className='dashboardcard'>
                  <Card>
                      <Card.Body>
                      <CustomTooltip text="You can update your personal information, such as your name, skills, language, contact details, pricing, and profile picture, to keep your profile current and accurate." />

                        {/* <Card.Title style={{ display: 'inline-block' }}>My Profile</Card.Title> */}
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                          <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true">Profile</button>
                          </li>
                          <li className="nav-item" role="presentation">
                          <button className="nav-link" id="induction-tab" data-bs-toggle="tab" data-bs-target="#induction" type="button" role="tab" aria-controls="subscription" aria-selected="false">Induction Details</button>
                          </li>
                          <li className="nav-item" role="presentation">
                          <button className="nav-link" id="documents-tab" data-bs-toggle="tab" data-bs-target="#documents" type="button" role="tab" aria-controls="subscription" aria-selected="false">Documents Upload</button>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <Form onSubmit={formik.handleSubmit}>
                            <ListGroup variant="flush">
                            <ListGroup.Item> 
                            <Card.Subtitle>Personal Details</Card.Subtitle>
                              <div className="row">
                                    <div className="col">
                                      <Form.Group className="mb-3" controlId="formFirstName">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control required type="text" placeholder="Enter first name" 
                                        name="first_name" 
                                        //value={mentorDetails.first_name}
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}
                                        />
                                        <FormErrorMessage
                                            fieldName="first_name"
                                            formik={formik}
                                        ></FormErrorMessage>
                                      </Form.Group>
                                    </div>
                                    <div className="col">
                                      <Form.Group className="mb-3" controlId="formMiddleName">
                                        <Form.Label>Middle Name</Form.Label>
                                        <Form.Control type="text" placeholder="Middle name" 
                                        name="middle_name" 
                                        value={formik.values.middle_name}
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}
                                        />
                                        <FormErrorMessage
                                            fieldName="middle_name"
                                            formik={formik}
                                        ></FormErrorMessage>
                                      </Form.Group>
                                    </div>
                                    <div className="col">
                                      <Form.Group className="mb-3" controlId="formLastName">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control required type="text" placeholder="Last name" 
                                        name="last_name" 
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}
                                        />
                                          <FormErrorMessage
                                            fieldName="last_name"
                                            formik={formik}
                                        ></FormErrorMessage>
                                      </Form.Group>
                                    </div>
                              </div>
                              <div className="row">
                              <div className="col">
                                <Form.Group className="mb-3" controlId="formEmail">
                                  <Form.Label>Email address</Form.Label>
                                  <Form.Control required type="email" placeholder="Enter email" 
                                  name="email_address" 
                                  value={formik.values.email_address}
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur}   
                                  />
                                  <FormErrorMessage
                                            fieldName="email_address"
                                            formik={formik}
                                        ></FormErrorMessage>
                                  <Form.Text className="text-muted">
                                    We will never share your email with anyone else.
                                  </Form.Text>
                                </Form.Group>
                              </div>
                              <div className="col">
                                <Form.Group className="mb-3" controlId="formMobileNumber">
                                  <Form.Label>Phone Number</Form.Label>
                                  <Form.Control required type="text" placeholder="Phone number"  
                                    name="mobile_number" 
                                    value={formik.values.mobile_number}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}   
                                  />
                                    <FormErrorMessage
                                            fieldName="mobile_number"
                                            formik={formik}
                                        ></FormErrorMessage>
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <Form.Group className="mb-3" controlId="formAddrss1">
                                  <Form.Label>Address</Form.Label>
                                  <Form.Control required as="textarea" rows={2} placeholder="Enter address"
                                    name="addresses.address1"
                                    value={formik.values.addresses?.address1 }
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}   
                                  />
                                  <div
                                      className="invalid-feedback"
                                      style={{
                                          display:
                                          formik.touched.addresses?.address1 && formik.errors.addresses?.address1
                                          ? "block"
                                          : "none",
                                      }}
                                  >
                                      {formik.errors.addresses?.address1 ? formik.errors.addresses?.address1 : null}
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="col">
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Address2 (Optional)</Form.Label>
                                  <Form.Control as="textarea" rows={2} placeholder="Enter address"
                                  name="addresses.address2"
                                  value={formik.values.addresses?.address2 }
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur} 
                                  
                                  />
                                </Form.Group>
                              </div>
                            </div>
                            <div className="row">
                            <div className="col">
                                <Form.Group className="mb-3" controlId="formcity">
                                  <Form.Label>City</Form.Label>
                                  <Form.Control required type="text" placeholder="City" 
                                    name="addresses.address3"
                                    value={formik.values.addresses?.address3 }
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}                                  
                                  />
                                  <div
                                      className="invalid-feedback"
                                      style={{
                                          display:
                                          formik.touched.addresses?.address3 && formik.errors.addresses?.address3
                                          ? "block"
                                          : "none",
                                      }}
                                  >
                                      {formik.errors.addresses?.address3 ? formik.errors.addresses?.address3 : null}
                                  </div>
                                </Form.Group>
                              </div>
                              <div className="col">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                  <Form.Label>Country</Form.Label>
                                  <Select required
                                        options={fetchedCountryOptions}
                                        value={fetchedCountryOptions.find((option) => option.label === formik.values.addresses?.country)}
                                        onChange={handleCountryChange}
                                        />
                                        {formik.errors.addresses?.country && (
                                            <div>{formik.errors.addresses?.country}</div>
                                        )}
                                </Form.Group>
                              </div>
                            
                              <div className="col">
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                  <Form.Label>Postal Code</Form.Label>
                                  <Form.Control required type="text" placeholder="Postal Code" 
                                    name="addresses.postal_code"
                                    value={formik.values.addresses?.postal_code }
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}                                  
                                  />
                                  <div
                                      className="invalid-feedback"
                                      style={{
                                          display:
                                          formik.touched.addresses?.postal_code && formik.errors.addresses?.postal_code
                                          ? "block"
                                          : "none",
                                      }}
                                  >
                                      {formik.errors.addresses?.postal_code ? formik.errors.addresses?.postal_code : null}
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                            </ListGroup.Item>
                            <ListGroup.Item> 
                            <Card.Subtitle>Company Details</Card.Subtitle>
                              <div className="row">
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formSkills">
                                      <Form.Label>Name</Form.Label>                                
                                      <Form.Control required type="text" placeholder="Company name" 
                                        name="company_name"
                                        value={formik.values.company_name }
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur} 
                                      />
                                      <FormErrorMessage
                                            fieldName="company_name"
                                            formik={formik}
                                        ></FormErrorMessage>
                                    </Form.Group>                                  
                                  </div>
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formLang">
                                      <Form.Label>Website</Form.Label>
                                      <Form.Control required type="text" placeholder="Website URL" 
                                        name="company_website"
                                        value={formik.values.company_website }
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur} 
                                      />
                                      <FormErrorMessage
                                            fieldName="company_website"
                                            formik={formik}
                                        ></FormErrorMessage>
                                    </Form.Group>
                                  </div>                           
                                </div> 
                              <div className="row">
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formShotdescription">
                                      <Form.Label>Short Description</Form.Label>
                                      <Form.Control required as="textarea" rows={4} placeholder="Enter Short description" 
                                      name="short_description"
                                      value={formik.values.short_description }
                                      onChange={formik.handleChange} 
                                      onBlur={formik.handleBlur}   
                                      />
                                      <FormErrorMessage
                                            fieldName="short_description"
                                            formik={formik}
                                        ></FormErrorMessage>
                                    </Form.Group>
                                  </div> 
                                </div>
                              
                                <div className="row">                            
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formlogo">
                                      <Form.Label>Logo</Form.Label>
                                      <Form.Control type="file"  
                                      accept=".jpeg, .png, .gif, .jpg"
                                      onChange={handleLogoFileChange} 
                                      name="logoFile" 
                                      ref={fileInputRef}
                                      />
                                {formik.values.companylogopath && (
                                  <div>
                                    <i className="bi bi-eye-fill" title='' onClick={() => openBannerModal()} />
                                    {/* <i className="bi bi-trash-fill" title="Delete" onClick={deleteBannerImage} /> */}
                                  </div>
                                )}
                                    </Form.Group>
                                  </div>
                                  
                                </div> 
                              
                            </ListGroup.Item>         
                          </ListGroup>
                            
                          <Button variant="primary" type="submit" className="button">
                            Submit
                          </Button>
                            </Form>

                          </div>
                          <div className="tab-pane fade" id="induction" role="tabpanel" aria-labelledby="induction-tab">
                          <ListGroup variant="flush">
                            <ListGroup.Item> 
                            <Form onSubmit={formikInduction.handleSubmit}>
                            {/* <Card.Subtitle>Introduction</Card.Subtitle> */}
                            <div className="row">                            
                          <div className="col">
                          <Form.Group className="mb-3" controlId="formintrofile">
                            <Form.Label>Self Introduction Video</Form.Label>
                            <Form.Control type="file"  
                            accept=".mp4, .avi"
                            onChange={handleVideoFileChange} 
                            name="introFile" 
                            ref={introfileInputRef}
                            />
                            <Form.Text className="text-muted">
                              Maximum limit is 5 MB.
                            </Form.Text>
                          {introVideoList && introVideoList.map((fileItem, index) => (
                                  <div key={index}>
                                    
                                    <a href={fileItem.label} target="_blank" rel="noopener noreferrer">
                                      {fileItem.title}
                                    </a>
                                    <Button variant="primary" style={{marginBottom:'5px',marginTop:'5px',marginLeft:'5px'}} size="sm" 
                                     onClick={() => handleDeleteFile(index)}
                                    >
                                    <span className="bi bi-trash"></span>  
                                  </Button>
                                  </div>
                                ))}
                          </Form.Group>
                          </div>

                            </div> 
                            <div className="row">
                                    <div className="col">
                                    <Form.Group className="mb-3" controlId="formAddrss1">
                                    <Form.Label>Intro Summary</Form.Label>
                                    <Form.Control required as="textarea" rows={10} placeholder="Enter Summary"
                                      name="introsummary"
                                      value={formikInduction.values.introsummary }
                                      onChange={formikInduction.handleChange} 
                                      onBlur={formikInduction.handleBlur}   
                                    />
                                    <div
                                        className="invalid-feedback"
                                        style={{
                                            display:
                                            formikInduction.touched.introsummary && formikInduction.errors.introsummary
                                            ? "block"
                                            : "none",
                                        }}
                                    >
                                        {formikInduction.errors.introsummary ? formikInduction.errors.introsummary : null}
                                    </div>
                                  </Form.Group>
                                    </div>
                            </div>    
                              
                          <Button variant="primary" type="submit" className="button">
                            Update
                          </Button>  
                          </Form>
                            </ListGroup.Item>
                          </ListGroup>

                          </div>
                          <div className="tab-pane fade" id="documents" role="tabpanel" aria-labelledby="documents-tab">
                          <ListGroup variant="flush">
                            <ListGroup.Item> 
                            {/* <Card.Subtitle>Introduction</Card.Subtitle> */}
                            <Form onSubmit={formikDocuments.handleSubmit}>
                            <div className="row">                            
                          <div className="col">
                          <Form.Group className="mb-3" controlId="formlogo">
                            <Form.Label>Upload Documents</Form.Label>
                            <Form.Control type="file" multiple 
                            accept=".doc, .docx, .pdf"
                            onChange={handleDocumentUploadChange} 
                            name="documentsFile" 
                            ref={documentfileInputRef}
                            />
                       
                          
                          {documentList && documentList.map((fileItem, index) => (
                                  <div key={index}>
                                    
                                    <a href={fileItem.label} target="_blank" rel="noopener noreferrer">
                                      {fileItem.title}
                                    </a>
                                    <Button variant="primary" style={{marginBottom:'5px',marginTop:'5px',marginLeft:'5px'}} size="sm" 
                                     onClick={() => handleDeleteFileDoc(index)}
                                    >
                                    <span className="bi bi-trash"></span>  
                                  </Button>
                                  </div>
                                ))}
                          </Form.Group>
                          </div>

                          </div>   

                          <div className="row">
                       
                       <div className="col">
                      <span style={{fontWeight:"bold"}}> Introduction file links</span>
                         
                         {options && options.map((option, index) => (
                         <div className="row" key={index}>
                           <div className="col">
                             <Form.Group className="mb-3" controlId={`formLinkTitle_${index}`}>
                               <Form.Label>Title</Form.Label>
                               <Form.Control
                                 type="text"
                                 placeholder="Enter title"
                                 name={`videoLinks[${index}].title`}
                                 autoComplete="off"
                                 value={option.title}
                                 onChange={(e) => handleOptionTitleTextChange(e, index)}
                                 onBlur={(e) => handleOptionTitleTextChange(e, index)}
                                 onSelect={(e) => handleOptionTitleTextChange(e, index)}
                               />
                               
                             </Form.Group>
                           </div>
                           <div className="col">
                             <Form.Group className="mb-3" controlId={`formRedirectLink_${index}`}>
                               <Form.Label>Link</Form.Label>
                               <Form.Control
                                 type="text"
                                 placeholder="Enter URL"
                                 name={`videoLinks[${index}].link`}
                                 autoComplete="off"
                                 value={option.link}
                                 onChange={(e) => handleOptionTextChange(e, index)}
                                 onBlur={(e) => handleOptionTextChange(e, index)}
                                 onSelect={(e) => handleOptionTextChange(e, index)}
                               />
                              
                             </Form.Group>
                           </div>
                           <div className="col">
                           {index !== 0 && (   
                             <Button className="button"
                               variant="primary"
                               onClick={() => handleRemoveOption(index)}
                             >
                               Delete
                             </Button>
                           )}
                           </div>
                         </div>
                       ))}
                       {optionErrors && optionErrors.map((optionError, index) => (
                         <div key={index}>
                           {/* {optionError.title && <div className="error">{optionError.title}</div>} */}
                           {optionError.link && <div className="error">{optionError.link}</div>}
                         </div>
                       ))}
                         <div className="row">
                           <div className="col">
                             <Button variant="primary"  className="button" 
                             onClick={handleAddOption}
                             >
                                 + (more Links)
                             </Button>
                             <br></br>
                             <br></br>
                           </div>
                         </div>
                       </div>
                    

                          </div>  


                          <Button variant="primary" type="submit" className="button">
                            Update
                          </Button>  
                          </Form>
                            </ListGroup.Item>
                          </ListGroup>
                          </div>
                        </div>
                        
                      </Card.Body>
                  </Card>

                  {selectedImage && (
                        <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
                          <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Image Preview</h5>
                                <button type="button" className="close" onClick={() => setSelectedImage(null)}>
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                 <img src={selectedImage} className='card-img-top' alt="Card image cap"
                              style={{ width: '100%', height: '100%', borderRadius: '50%'}} />

                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                  <SnackbarProvider 
                    show={showSnackbar}
                    message={snackbarMessage}
                    onClose={handleCloseSnackbar}
                    message1={clsName}
                    />

<Modal show={showConfirmation} onHide={handleCancelDelete}>
                                <Modal.Header closeButton>
                                <Modal.Title>Confirm Delete</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                                    Cancel
                                </Button>
                                <Button variant="secondary" className="button" onClick={handleConfirmDelete}>
                                    Delete
                                </Button>
                                </Modal.Footer>
            </Modal>
                  </div>
                </div>
            </div>
            </div>
        
      
              
            )

}
export default CorporateProfilePage;