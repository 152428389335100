import React, { useEffect } from "react";
import { Toast } from "react-bootstrap"; 
import { PropTypes } from "prop-types";

const SnackbarProvider =({show, message, onClose, message1 }) =>{
   
    useEffect(() => {
        let timer;
        if(show){
            timer = setTimeout(onClose, 5000)
        }
        return () => {
            clearTimeout(timer);
        };
    }, [show, onClose]);

    return(
        <div 
        style={{
            position:'fixed',
            top: '20px',
            right: '20px',
            zIndex: '9999',
        }}
        >
        <Toast show={show} onClose={onClose}
        className={message1}
        style={{ minWidth: '200px'}}
        >
            <Toast.Header closeButton={true} className={message1}>
                <strong className="me-auto">Notification</strong>

            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
        </div>
       
    );
};

SnackbarProvider.propTypes = {    
    show: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.any.isRequired,
    message1: PropTypes.string.isRequired,
};

export default SnackbarProvider;