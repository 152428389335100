import React, {useState, useEffect} from "react";
import Form from 'react-bootstrap/Form';
import { Card } from 'react-bootstrap';
import 'react-day-picker/dist/style.css';
import 'react-calendar/dist/Calendar.css';
import clockimg from '../../../Images/clock.svg';
import WorkshopCreatePage from "../mentor-workshop-page/WorkshopCreatePage";
import MentorWorkshopViewPage from "../mentor-workshop-page/MentorWorkshopViewPage";
import MentorWorkshopEditPage from  '../mentor-workshop-page/MentorWorkshopEditPage';
import  {getWorkShops}  from "../../../api/mentorApi";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";

function MentorWorkshopPage(){  
  const [clsName, setclsName] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [showTabWorkShop, setShowTabWorkShop] = useState(true);
  const [showViewPage, setShowViewPage] = useState(false);
  const date = new Date();
   
  const [editmodalShow, setEditModalShow] = useState(false);
  const[isEditWorkShopDisplay,setIsEditWorkShopDisplay]=useState(false);
  const[editWorkShop,setEditWorkShop] = useState({});

  const [events, setEvents] = useState([]);
  
   const handleBackClick = () => {
    setShowTabWorkShop(true);
    setShowViewPage(false);
   };

   useEffect(()=>{     
    getWorkShops().then(response=>{
      setEvents(response);
       });
},[])
//const [reloadEvents, setReloadEvents] = useState(false);

// Event handler function to handle the reload trigger
const handleReloadEvents = () => {
  //setReloadEvents(true);
  getWorkShops().then(response=>{
    setEvents(response);
    setIsEditWorkShopDisplay(false);
    setEditModalShow(false);
     });
};

const handleReloadUpdateEvents = (obj) => {   
  //setReloadEvents(true);
  setclsName('alert success');
  setSnackbarMessage(JSON.stringify(obj));
  setShowSnackbar(true);
  getWorkShops().then(response=>{
    setEvents(response);
    setIsEditWorkShopDisplay(false);
    setEditModalShow(false);
     });
};
const handleCloseSnackbar = () => {
  setShowSnackbar(false);
}   
const handleEventClick = (eventobj)=>{ 
  setEditWorkShop(eventobj);
  setIsEditWorkShopDisplay(true);
  setEditModalShow(true);
}
   
function getStingToDate(dateString) {
  const date = new Date(dateString);
  return date.toDateString();
}
return (
  <div className="featured-section-wrap featured-section-wrap-row">
    <div className='mainPageContainer'>
      <div className='dashboardcontainerflex'>
        <div className='dashboardcard'>
          {showTabWorkShop && (<></>)}
          <Card>
            <Card.Body>
              <Card.Text>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5" style={{ paddingTop: "7px" }}>
                    <Card.Title style={{ display: 'inline-block' }}>My Workshops</Card.Title>
                    
                    <Form.Group controlId="formBasicEmail">
                      <div className="eventsContainer">
                      {events.length > 0 ? (
  events.map((item) => (
    <div key={item.id} onClick={() => { handleEventClick(item) }}>
      <div className="card mt-3 flex w-full cursor-pointer flex-col rounded-lg border border-slate-300 p-2 transition-all hover:animate-pulse relative">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-sm" style={{ margin: 0 }}>{item.title}</p>
            <p className="text-xs" style={{ margin: 0 }}>{getStingToDate(item.date)}</p>
          </div>
          <div className="flex items-center gap-x-2 aLink" style={{ color: "#44D5E7" }}>
            <img src={clockimg} alt="Clock Icon" />
            {item.estart} - {item.eend}
            <span>
              <i className="bi bi-pencil mx-2" ></i>
            </span>
          </div>
        </div>
        {/* Additional items for each row */}
        <div className="top-right-item absolute top-0 right-0">
          <p> {item.pricemode}</p>
        </div>
        <div className="bottom-right-item absolute bottom-0 right-0">
          <p>{item.streammode}</p>
        </div>
      </div>
    </div>
  ))
) : (
  <p>No WorkShops</p>
)}



                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-7">
                    <div className="row">
                      <div className="col">
                        <label>{(date != null && date != undefined) ? date.toDateString() : ''}</label>
                      </div>
                      <div className="col">
                      </div>
                    </div>
                    <div className="row">
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <div style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // height: "100%"
                        }}>
                          <div style={{
                            boxShadow: "0 1px 2px 0 rgba(0,0,0,.1)",
                            borderTop: "4px solid #654DA4",
                            background: "#fff",
                           // height: "100%",
                           // width: "620px",
                            marginTop: 36,
                            paddingTop: 5
                          }}>
                            <WorkshopCreatePage onWorkshopSubmit={handleReloadEvents} />
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
          {showViewPage && (
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5" style={{ paddingTop: "7px" }}>
                <MentorWorkshopViewPage onBackClick={handleBackClick} />
              </div>
            </div>
          )}
          {isEditWorkShopDisplay && editWorkShop &&
            <MentorWorkshopEditPage editWorkShop={editWorkShop} onWorkshopSubmit={handleReloadUpdateEvents} show={editmodalShow} onHide={() => { setEditModalShow(false); setIsEditWorkShopDisplay(false); }}></MentorWorkshopEditPage>
          }
          <SnackbarProvider
            show={showSnackbar}
            message={snackbarMessage}
            onClose={handleCloseSnackbar}
            message1={clsName}
          />
        </div>
      </div>
    </div>
  </div>
);

}
export default MentorWorkshopPage;