import React from "react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//import Header from '../../common/shared/header/Header';
import HeaderNew from '../../common/shared/header/HeaderNew';
//import SubHeader from '../../common/shared/header/SubHeader';
import Footer from '../../common/shared/footer/Footer';
//import Leftbar from '../../common/shared/leftbar/Leftbar';
import LeftbarNew from '../../common/shared/leftbar/LeftbarNew';
import PropTypes from "prop-types";
//import CheckApplicationBlocking from "../../common/shared/blocking/CheckApplicationBlocking";
// import  {Link}  from 'react-router-dom'
// import { useEffect } from "react";
import { getappAccess } from '../../../api/mentorApi';

import './../../../css/style.min.css';

const MentorLayout = ({ children}) => {
   
    const navigate = useNavigate();
    const location = useLocation();
    let formHeaderName = "";
    if(location.pathname === '/dashboard')
    {
        formHeaderName = 'Dashboard';
    }
    else if(location.pathname === '/profile')
    {
        formHeaderName = 'My Profile';
    }
    else if(location.pathname === '/mentor-sessions')
    {
        formHeaderName = 'Mentoring & Workshops';
    }
    else if(location.pathname === '/courses')
    {
        formHeaderName = 'Byte-Sized Courses';
    }
    else if(location.pathname.split('/')[1] === 'courseEdit')
    {
        formHeaderName = 'Byte-Sized Courses - Update';
    }
    else if(location.pathname.split('/')[1] === 'courseAdd')
    {
        formHeaderName = 'Byte-Sized Courses - Add';
    }
    else if(location.pathname === '/resourcesView')
    {
        formHeaderName = 'My Resources';
    }
    else if(location.pathname.split('/')[1] === 'resourceEdit')
    {
        formHeaderName = 'My Resources - Update';
    }
    else if(location.pathname.split('/')[1] === 'resourceAdd')
    {
        formHeaderName = 'My Resources - Add';
    }
    else if(location.pathname === '/shortsView')
    {
        formHeaderName = 'My Shorts';
    }
    else if(location.pathname.split('/')[1] === 'shortsEdit')
    {
        formHeaderName = 'My Shorts - Update';
    }
    else if(location.pathname.split('/')[1] === 'shortsAdd')
    {
        formHeaderName = 'My Shorts - Add';
    }
    else if(location.pathname === '/mentor-notifications')
    {
        formHeaderName = 'My Alerts & Messages';
    }
    else if(location.pathname === '/mentor-payment-list')
    {
        formHeaderName = 'My Payment Details';
    }
    else if(location.pathname === '/mentor-enroll-list')
    {
        formHeaderName = 'My Enrollment Details';
    }
    else if(location.pathname === '/assessments')
    {
        formHeaderName = 'My Assessments';
    }
    else if(location.pathname === '/assessment/create')
    {
        formHeaderName = 'My Assessments';
    }
    else if(location.pathname.split('/')[1] === 'assessment')
    {
        formHeaderName = 'My Assessments';
    }
    else if(location.pathname.split('/')[1] === 'employee-invites')
    {
        formHeaderName = 'My Assessments';
    }
    else if(location.pathname.split('/')[1] === 'employee-results')
    {
        formHeaderName = 'My Assessments';
    }
    
    else if(location.pathname === '/survey/create')
    {
        formHeaderName = 'My Survey';
    }
    else if(location.pathname.split('/')[1] === 'survey')
    {
        formHeaderName = 'My Survey';
    }
    else if(location.pathname.split('/')[1] === 'employee-survey-results')
    {
        formHeaderName = 'My Survey';
    }
    else if(location.pathname.split('/')[1] === 'employee-survey-invites')
    {
        formHeaderName = 'My Survey';
    }
    else if(location.pathname === '/subscriptions')
    {
        formHeaderName = 'My Subscriptions';
    }
    else if(location.pathname === '/support')
    {
        formHeaderName = 'Supportings';
    }
    else if(location.pathname === '/privacy')
    {
        formHeaderName = 'Privacy Policy';
    }
    else if(location.pathname === '/terms')
    {
        formHeaderName = 'Terms and Condition';
    }
    else if(location.pathname === '/refund')
    {
        formHeaderName = 'Refunds & Cancellations';
    }
    useEffect(() => {   
         
        getappAccess(location.pathname.split('/')[1]).then(response => {
            //const isApplicationAccess = response.returnflag;
            const type = response.returntype;
          
            if(!response.returnflag){
               
                if(type == 'PNC')
                {
                    const personalDetailsScore = response.pdetailsscore;
                    const mentorShipDetailsScore = response.mdetailsscore;
                    const profilePictureScore = response.ppicturescore;
                    navigate(`/profile-incomplete?param1=${personalDetailsScore}&param2=${mentorShipDetailsScore}&param3=${profilePictureScore}`);
                }
                else if(type == 'SUE')
                {
                    navigate('/subscription-expired');
                }
                else if(type == 'BLO')
                {
                    navigate('/block');
                }
                else
                {
                    navigate('/block');
                }
                
            }
            
          });
        
    },[navigate]);  //navigate,location.pathname

    return(

        
        
        <div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
        data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
              <HeaderNew /> 
                <LeftbarNew ></LeftbarNew>

                <div className="page-wrapper page-wrapper-height">
                <div className="page-breadcrumb bg-white">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                        <h4 className="page-title">{formHeaderName}</h4>
                    </div>
                    {/*<div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                        <div class="d-md-flex">
                            <ol class="breadcrumb ms-auto">
                                <li><a href="#" class="fw-normal">{formHeaderName}</a></li>
                            </ol>
                             <a href="" target="_blank"
                                class="btn btn-danger  d-none d-md-block pull-right ms-3 hidden-xs hidden-sm waves-effect waves-light text-white">Upgrade
                                to Pro</a> 
                        </div>
                    </div>*/}
                </div>
                
                </div>

                <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="white-box" style={{ minHeight: '45.4rem', height: 'auto' }}>
                      {children}
                        </div>
                    </div>
                </div>
              
                </div>
               <Footer></Footer>
               
                 </div>
        </div>
              
                 
          
     
    );
}

MentorLayout.propTypes ={
    children: PropTypes.array.isRequired,   
}; 

export default MentorLayout;


// import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import Header from '../../common/shared/header/Header';
// import SubHeader from '../../common/shared/header/SubHeader';
// import Footer from '../../common/shared/footer/Footer';
// import Leftbar from '../../common/shared/leftbar/Leftbar';
// import PropTypes from "prop-types";
// import { getappAccess } from '../../../api/mentorApi';

// const MentorLayout = ({ children }) => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [isSidebarOpen, setIsSidebarOpen] = useState(true);

//     useEffect(() => {
//         getappAccess(location.pathname.split('/')[1]).then(response => {
//             const type = response.returntype;
//             if (!response.returnflag) {
//                 if (type === 'PNC') {
//                     const personalDetailsScore = response.pdetailsscore;
//                     const mentorShipDetailsScore = response.mdetailsscore;
//                     const profilePictureScore = response.ppicturescore;
//                     navigate(`/profile-incomplete?param1=${personalDetailsScore}&param2=${mentorShipDetailsScore}&param3=${profilePictureScore}`);
//                 } else if (type === 'SUE') {
//                     navigate('/subscription-expired');
//                 } else if (type === 'BLO') {
//                     navigate('/block');
//                 } else {
//                     navigate('/block');
//                 }
//             }
//         });
//     }, [navigate]);

//     const toggleSidebar = () => {
//         setIsSidebarOpen(!isSidebarOpen);
//     };

//     return (
//         <div className={`wrapper flex-grow-1 ${isSidebarOpen ? 'sidebar-open' : ''}`}>
//             <div className="App">
//                 <Header />
//                 <SubHeader />
//                 <div className='layout-container-flex'>
//                     <button className='toggle-button' onClick={toggleSidebar}>
//                         Toggle Sidebar
//                     </button>
//                     <div className='layout-flex-item1'>
//                         {isSidebarOpen && <Leftbar />}
//                     </div>
//                     <div className='layout-flex-item2'>
//                         {children}
//                     </div>
//                 </div>
//                 <div className='layout-flex-item2'>
//                     <Footer />
//                 </div>
//             </div>
//         </div>
//     );
// }

// MentorLayout.propTypes = {
//     children: PropTypes.array.isRequired,
// };

// export default MentorLayout;

