import React,{useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
// import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
import Select from 'react-select';
import { addAdvertisements } from "../../../api/adminApi";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import { getMasterData } from "../../../api/mentorApi";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { Link } from "react-router-dom";
import AdsSetupPage from "./AdsSetupPage";
import DateTimePicker from 'react-datetime-picker';
import moment from "moment/moment";

function CreateAdsPage(){
  // const [categoryOptions,setCategoryOptions] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showViewPageModal, setShowViewPageModal] = useState(false);
  // const [curriculum, SetCurriculum] = useState('');
  const fileInputRef = useRef(null);
  const fetchedPageOptions = [ { value: "1", label: "HOME PAGE" },
  { value: "2", label: "SUB PAGE" }]

  const initialValues = {
    title: '', 
    pagename: '',
    bannerImage: null,
    startdate: new Date(),
    enddate: new Date(),
    targeturl: '',
  }

  const  validationSchema = Yup.object({
    title: Yup.string()
    .required("Title is required."),
    startdate: Yup.string()
    .required("Start date is required."),
    enddate: Yup.string()
    .required("End date is required."),
  }); 

 
  const handleSubmit = (values) => {
     
    //values.preventDefault();
    try {
     
      const TStartDate = moment(values.startdate).format('YYYY-MM-DD HH:mm:ss');
      const TEndDate = moment(values.enddate).format('YYYY-MM-DD HH:mm:ss');

      const formDataToSend = new FormData();
      formDataToSend.append("title",values.title);
      formDataToSend.append("pagename",values.pagename);
      formDataToSend.append("file",values.bannerImage);
      formDataToSend.append("startdate",TStartDate.toLocaleString());
      formDataToSend.append("enddate",TEndDate.toLocaleString());
      formDataToSend.append("targeturl",values.targeturl);    
       
      addAdvertisements(formDataToSend).then((response)=>{
        if(!response.ok){
            // const err = new Error("Error in response");
            // throw err;
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
            //return response;
            setSnackbarMessage('Successfully added Advertisement data');
            setShowSnackbar(true);
            formik.resetForm();
        });

    }
    catch (error){
      console.log(error);
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  

  const handleBannerImageChange = event => {
    console.log(event);
      const file = fileInputRef.current.files[0];
     formik.setFieldValue('bannerImage', file);
     
  }
 
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
 
  const closeViewPageModal = () => {
    setShowViewPageModal(false);
  }
  const handlePageNameChange = (selectedOption) => {
    formik.setFieldValue('pagename', selectedOption.label);
  };

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card style={{ width: '62rem', height: '53rem' }}>
                  <Card.Body>
                    
                  {/* <Button variant="primary" type="submit" style={{float:"right"}} onClick={openViewPageModal} >
                      View Courses
                  </Button> */}
                   <Link to="/config-ads/view">
                  <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                       Back
                  </Button>
                  </Link>
                    <Card.Title style={{ display: 'inline-block' }}>Add Advertisement</Card.Title>
                    <Form onSubmit={formik.handleSubmit}>
                      
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control required type="text" placeholder="Enter title" 
                                name="title"  
                                value={formik.values.title}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="title"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                                                     
                          </div>
                          
                          <div className="row">
                          <div className="col">
                              <Form.Group className="mb-3" controlId="formBannerImage">
                                <Form.Label>Display Page</Form.Label>


                          <Select required 
                                    options={fetchedPageOptions}
                                    value= {fetchedPageOptions.find((option) => option.label === formik.values.pagename)}
                                    onChange={handlePageNameChange}
                                    />
                                    {formik.errors.pagename && (
                                        <div className='invalid-feedback'>{formik.errors.pagename}</div>
                                    )}
                                </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBannerImage">
                                <Form.Label>Banner Image</Form.Label>
                                <Form.Control type="file"  
                                onChange={handleBannerImageChange} 
                                name="BannerImage" 
                                ref={fileInputRef}
                                />
                              </Form.Group>
                            </div>
                             
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDuration">
                                <Form.Label>Start Date</Form.Label>
                                <DateTimePicker
                                    id="startdate"
                                    name="startdate"
                                    value={formik.values.startdate}
                                    onChange={(date) => formik.setFieldValue('startdate', date)}
                                    //format="yyyy-MM-dd HH:mm:ss"
                                  />
                                 <FormErrorMessage
                                      fieldName="startdate"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDisplayPrice">
                                <Form.Label>End Date</Form.Label>
                                <DateTimePicker
                                  id="enddate"
                                  name="enddate"
                                  value={formik.values.enddate}
                                  onChange={(date) => formik.setFieldValue('enddate', date)}
                                  //format="yyyy-MM-dd HH:mm:ss"
                                />
                                  <FormErrorMessage
                                      fieldName="enddate"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                          
                          </div>   
                          <div className="row">
                            <div className="col">
                            <Form.Group className="mb-3" controlId="formtargeturl">
                                <Form.Label>Redirect URL</Form.Label>
                                <Form.Control type="text" placeholder="Enter redirect URL" 
                                  name="targeturl"  
                                  value={formik.values.targeturl}
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur} 
                                 />
                                   <FormErrorMessage
                                       fieldName="targeturl"
                                       formik={formik}
                                   ></FormErrorMessage>
                              </Form.Group>
                            </div>
                          </div>
                          <Button variant="primary" className="button" type="submit">
                          Submit
                          </Button>                 
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
            </div>

            {showViewPageModal && (
            <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Advertisement List</h5>
                    <button type="button" className="close" onClick={closeViewPageModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <AdsSetupPage/>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        </div>
          );

}
export default CreateAdsPage;