import React, {useEffect, useState } from "react";
// import Form from 'react-bootstrap/Form';
// import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
// import Select from 'react-select';
// import { useFormik, Formik } from "formik"
// import * as Yup from "yup";
// import {useQuery, useQueryClient } from "react-query";
// import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
// import { Link } from "react-router-dom";
// import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import FilterFactoryProps, {textFilter} from "react-bootstrap-table2-filter";
//import filterFactory from "react-bootstrap-table2-filter";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import moment from "moment/moment";
import { getNotificationsById } from "../../../api/adminApi";
import { useParams } from "react-router-dom";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function NotificationDetails(){
  const classes = useStyles();
  const { id } = useParams(); 
  const [notificationList, setNotificationList] = useState([]);

      
  // const dateFormatter = (cell) => { //, row
  //   const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
  //   return <span>{formattedDate}</span>;
  // }   

  const columns = [
    {field: 'receiverlogon', headerName: 'Receiver', minWidth: 150},
    {field: 'senderlogon', headerName: 'Sender', minWidth: 150},
    {field: 'notificationtype', headerName: 'Notification Type', minWidth: 150},
    {field: 'message', headerName: 'Message', minWidth: 150},
    {field: 'status', headerName: 'Status', minWidth: 150},
    {field: 'refid', headerName: 'Ref Id', minWidth: 150},
    {field: 'Action', headerName: 'Action', minWidth: 150},

    // {
    //   dataField: 'receiverlogon',
    //   text: 'Receiver',
    // },
    // {
    //   dataField: 'senderlogon',
    //   text: 'Sender',
    // },
    // {
    //   dataField: 'notificationtype',
    //   text: 'Notification Type',
    // },
    // {
    //   dataField: 'message',
    //   text: 'Message',
    // },
    // {
    //   dataField: 'status',
    //   text: 'Status',
    // },
    // // {
    // //     dataField: 'approver',
    // //     text: 'Approver',
    // // },
    // // {
    // //     dataField: 'approvedDate',
    // //     text: 'Approved Date',
    // // },
    // {
    //     dataField: 'refid',
    //     text: 'Ref Id',
    // },
    // {
    //     dataField: 'action',
    //     text: 'Action',
    // },
    
    // // {
    // //   dataField: 'isRead',
    // //   text: 'Is Read',
    // //   formatter: (cell, row) => (cell ? 'Yes' : 'No'),
    // // },
    // // {
    // //   dataField: 'conversation',
    // //   text: 'Conversation',
    // //   formatter: nestedTableFormatter,
    // // },
  ];
  
  useEffect(()=>{
    fetchNotificationsData();

  },[]);
  
  const fetchNotificationsData =() => {
    getNotificationsById(id).then(response=>{                    
        setNotificationList(response); 
    });
  }
    
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <ListGroup variant="flush">
                    <ListGroup.Item> 
                    
                    <div className="row">
                        <div className="col">
                        <Card.Subtitle>Notification Details</Card.Subtitle>
                        <div style={{float:"right"}}> &nbsp;</div>  
                            {/* <BootstrapTable 
                            keyField="id"
                            data={notificationList}
                            columns={columns}
                            // cellEdit={cellEditFactory(cellEditOptions)}
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                            /> */}
                             <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={notificationList}
                                columns={columns}
                                getRowId={(row) => row.id}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
                        </div>
                    </div>
                    </ListGroup.Item>
                </ListGroup>
               
                </div>
            </div>
        </div>
        </div>        

    )

}
export default NotificationDetails