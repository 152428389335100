import React, {useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
// import { Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import Select from 'react-select';
import { getConversationsByGUID,addConversations } from "../../../api/mentorApi";
import moment from "moment/moment";
import * as Yup from "yup";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { PropTypes } from "prop-types";

import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function ConversationPage(props){
 // const restrictedWordsRegex = /\b(call|phone|whatsapp|number|mobile)\b/i;

  const { id } = props;
  
  //const { guid } = useParams(); 
  const [conversationsList, setConversationsList] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const classes = useStyles();

  const validationSchema = Yup.object({
    action: Yup.string().required('Action is required.'),
    message: Yup.string()
      .test('restricted-words', 'Restricted words found.', function (value) {
        const restrictedWordsRegex = /\b(call|phone|whatsapp|whats|number|num|landline|mobile|email|emailaddress|me|cell|cellphone)\b/i;
        return !restrictedWordsRegex.test(value);
      })
      .test('no-numbers', 'Numbers are not allowed.', function (value) {
        const numbersRegex = /\d/;
        return !numbersRegex.test(value);
      })
      .test('no-special-characters', 'Special characters are not allowed.', function (value) {
        const specialCharactersRegex = /[@!#$%^&*()_+[\]{}|\\:;"'<>,?/~`]/;
        return !specialCharactersRegex.test(value);
      }),
  });

const handleSubmit = async (values) => {  
  addConversations(values).then((response)=>{
    
    if(!response.ok){
        // const err = new Error("Error in response");
        // throw err;
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        //return response.json();
    }
    }).then(response=>{
      console.log(response);
       
        fetchConversationsData();
        setSnackbarMessage('Successfully sent message');
        setShowSnackbar(true);
        formik.resetForm();
    });
}

const formik = useFormik({
  initialValues: {
      guid: id,
      action: "",
      subject: "",
      message: "",
  },
  validationSchema ,
  onSubmit: handleSubmit,
}); 

  // const ActionColumnFormatter = (cell) => {
  //   if (cell === 'mentor1@gmail.com') {
  //     return (
  //       <div  
  //               style={{
  //                 width: '30px',
  //                 height: '10px',
  //                 backgroundColor: 'red',
  //                 marginRight: '10px',
  //                 marginLeft:'10px'
                  
  //               }}
  //             ></div>
  //     );
  //   }
  //   else
  //   {
  //     return (
  //       <div 
  //       style={{
  //         width: '30px',
  //         height: '10px',
  //         backgroundColor: 'blue',
  //         marginRight: '10px',
  //         marginLeft:'10px'
  //       }}
  //     ></div>
  //     );
  //   }
  // }

  // const dateFormatter = (cell) => { //, row
  //   const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
  //   return <span>{formattedDate}</span>;
  // }   

  const columns = [
    {
      field: 'message',
      headerName: 'Message',
    },
    {
      field: 'sendat',
      headerName: 'Send At',
      valueGetter: (params) => moment(params.row.sendat).format("YYYY-MM-DD HH:mm"), 
    },
     {
      field: 'sender',
      headerName: '',
      //formatter: ActionColumnFormatter,
          },
    
    
  ];
  
  useEffect(()=>{
    fetchConversationsData();

  },[id]);
  
  const fetchConversationsData =() => {
    getConversationsByGUID(id).then(response=>{                    
      setConversationsList(response); 
    })
    .catch(error => {
      // Handle error
      console.log(error);
    });
  }

  const fetchedActionOptions = [ 
    { value: "4", label: "Approved" },
    { value: "9", label: "Need Clarifications" },
    { value: "10", label: "Clarified" },
  ];

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    }
  const handleActionChange = (selectedOption) => {
      formik.setFieldValue('action', selectedOption.label);
  };
  // const options = {
  //   sizePerPageList: [
  //     { text: '5', value: 5 }, // Customize the available page size options
  //     { text: '10', value: 10 },
  //     { text: '20', value: 20 },
  //     { text: 'All', value: conversationsList.length },
  //   ],
  //   sizePerPage: 5, // Set the default page size to 5
  // };  


    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              Conversation
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
            <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBasicCategory">
                                <Form.Label>Action</Form.Label>
                                <Select required
                                  options={fetchedActionOptions}
                                  value={fetchedActionOptions.find((option) => option.label === formik.values.action)}
                                  onChange={handleActionChange}
                                  />
                                 {formik.errors.action && (
                                          <div className='invalid-feedback'>{formik.errors.action}</div>
                                      )}
                              
                              </Form.Group>
                            </div>                           
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formShortDescription">
                                <Form.Label>Comments</Form.Label>
                                <Form.Control required as="textarea" rows={4} placeholder="Enter comments" 
                                name="message"  
                                value={formik.values.message}
                                onChange={formik.handleChange} 
                                //onChange={handleOnChange}
                                onBlur={formik.handleBlur} 
                                />
                                 <FormErrorMessage
                                      fieldName="message"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>                            
                          </div>
                          <Button variant="primary" type="submit">
                          Submit
                          </Button>  
            </Form> 
           <br></br>
            <div className="row" style={{
              // position: "absolute",
            right: "31px",
            top: "10px",
            bottom: "0",
            display: "flex",
            alignItems: "center"}}>
              <div  
                style={{
                  width: '30px',
                  height: '10px',
                  backgroundColor: 'red',
                  marginRight: '10px',
                  marginLeft:'10px'
                  
                }}
              ></div>Self
              <div 
                style={{
                  width: '30px',
                  height: '10px',
                  backgroundColor: 'blue',
                  marginRight: '10px',
                  marginLeft:'10px'
                }}
              ></div>Admin
            </div>             
              <div className={`${classes.dataGridContainer}`}>
                <DataGrid
                  rows={conversationsList}
                  columns={columns}
                  //getRowId={(row) => row.guid}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  disableSelectionOnClick
                  //hideFooterPagination
                  autoHeight
                  checkboxSelection={false}
                  classes={{
                    header: classes.header,
                    toolbarContainer: classes.toolbarContainer,
                    columnsContainer: classes.columnsContainer,
                    columnHeaderTitle: classes.columnHeaderTitle,
                    cell: classes.cell,
                    paginationItem: classes.paginationItem,
                    paginationItemSelected: classes.paginationItemSelected,
                    paginationItemHover: classes.paginationItemHover,
                  }}
                />

          </div>
          
         <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
          </Modal.Body>
        </Modal>

        
    );
}

ConversationPage.propTypes ={
  id: PropTypes.string.isRequired,   
};
export default ConversationPage;