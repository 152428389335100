import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Card } from "react-bootstrap";
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment/moment";
import ConversationPage from "./ConversationPage";
import { getNotifications } from "../../../api/mentorApi";

import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function AdminNotificationPage() {
  const [notificationList, setNotificationList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isModelDisplay, setIsModelDisplay] = useState(false);
  const [guid, setGuid] = useState(null);
  const [filterType, setFilterType] = useState("RECEIVED");
  const [filterStatus, setFilterStatus] = useState("");

  const classes = useStyles();

     

  const columnsReceived = [
    // { field: "receiverlogon", headerName: "Receiver" },
    { field: "senderlogon", headerName: "Name", minWidth: 150 },
    { field: "notificationtype", headerName: "Type", minWidth: 150 },
    { field: "message", headerName: "Message", minWidth: 200 },
    { field: "status", headerName: "Status", minWidth: 150 },
    { field: "createddate", headerName: "Created Date", minWidth: 200
    ,valueGetter: (params) => moment(params.row.createddate).format("YYYY-MM-DD HH:mm"), 
    },
    {
      field: "refid",
      headerName: "Actions",
      minWidth: 100,
      renderCell: (params) => (
        params.row.refid ? (
       // params.row.refid === null || params.row.refid === undefined ? (
          <i
            className="bi bi-chat"
            onClick={() => handleViewClick(params.row.guid)}
            style={{ paddingRight: "10px" }}
          />
        ) : null
      ),
    },
  ];

  const columnsSent = [
    { field: "receiverlogon", headerName: "Name" },
    // { field: "senderlogon", headerName: "Name" },
    { field: "notificationtype", headerName: "Type" },
    { field: "message", headerName: "Message" },
    { field: "status", headerName: "Status" },
    { field: "createddate", headerName: "Created Date"
    ,valueGetter: (params) => moment(params.row.createddate).format("YYYY-MM-DD HH:mm"), 
    },
    {
      field: "refid",
      headerName: "Actions",
      minWidth: 100,
      renderCell: (params) => (
        params.row.refid ? (
       // params.row.refid === null || params.row.refid === undefined ? (
          <i
            className="bi bi-chat"
            onClick={() => handleViewClick(params.row.guid)}
            style={{ paddingRight: "10px" }}
          />
        ) : null
      ),
    },
  ];


  useEffect(() => {
    fetchNotificationsData();
  }, []);

  

  const fetchNotificationsData =() => {
    getNotifications().then(response=>{
      const data = response;                  
      setNotificationList(data);        
    });
  }
  const handleViewClick = (guid) => {
    setGuid(guid);
    setIsModelDisplay(true);
    setModalShow(true);
  };

  const filteredNotifications = notificationList.filter((notification) => {
    if (filterType === "RECEIVED" && notification.receiverlogon !== "Somotus Admin") {
      return false;
    }
    if (filterType === "SENT" && notification.senderlogon !== "Somotus Admin") {
      return false;
    }
    if (filterStatus && notification.status !== filterStatus) {
      return false;
    }
    return true;
  });

  return (
    <div className="featured-section-wrap featured-section-wrap-row">
      <div className="mainPageContainer">
        <div className="dashboardcontainerflex">
          <div className="dashboardcard">
            <Card style={{ width: "62rem", height: "53rem" }}>
              <Card.Body>
              <CustomTooltip text="This page provides information and notifications about important updates, messages, or changes. It is a central hub for all the communications between admin, or users." />
                <Card.Title style={{ display: "inline-block" }}>
                  Alerts & Messages
                </Card.Title>
                <br />
                <Form.Check
                  inline
                  label="RECEIVED"
                  type="radio"
                  name="filterType"
                  checked={filterType === "RECEIVED"}
                  onChange={() => setFilterType("RECEIVED")}
                />
                <Form.Check
                  inline
                  label="SENT"
                  type="radio"
                  name="filterType"
                  checked={filterType === "SENT"}
                  onChange={() => setFilterType("SENT")}
                />
                <Form.Select
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                >
                   <option value="">Filter by Status</option>
                  <option value="Pending">Pending</option>
                  <option value="Approved">Approved</option>
                  <option value="Need Clarifications"> Need Clarifications</option>
                  <option value="Not Applicable">Not Applicable</option>
                </Form.Select>
                <div className={`${classes.dataGridContainer}`}>
                      <DataGrid
                        rows={filteredNotifications}
                        columns={filterType === "RECEIVED" ? columnsReceived : columnsSent}
                        //getRowId={(row) => row.guid}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        //hideFooterPagination
                        autoHeight
                        checkboxSelection={false}
                        classes={{
                          header: classes.header,
                          toolbarContainer: classes.toolbarContainer,
                          columnsContainer: classes.columnsContainer,
                          columnHeaderTitle: classes.columnHeaderTitle,
                          cell: classes.cell,
                          paginationItem: classes.paginationItem,
                          paginationItemSelected: classes.paginationItemSelected,
                          paginationItemHover: classes.paginationItemHover,
                        }}
                      />

                </div>

              </Card.Body>
            </Card>
            {isModelDisplay && (
              <ConversationPage
                id={guid}
                show={modalShow}
                onHide={() => {
                  setModalShow(false);
                  setIsModelDisplay(false);
                }}
              ></ConversationPage>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminNotificationPage;
