import React, {useState,useEffect  } from "react";
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { deleteMasterdata } from "../../../api/adminApi";
import CreateRCategoryPage from './CreateRCategoryPage';
import EditRCategoryPage from './EditRCategoryPage';
import Modal from 'react-bootstrap/Modal';
import { getMasterData } from "../../../api/mentorApi";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function RCategorySetupPage(){
  const classes = useStyles();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [dataList, setDataList] = useState([]);   
    const [modalShow, setModalShow] = useState(false);
    const [isEditModelDisplay,setIsEditModelDisplay]=useState(false);
    const [isCreateModelDisplay,setIsCreateModelDisplay]=useState(false);
    const [id, setId] = useState(null);
    const [label, setLabel] = useState(null);
    const [rowDataToDelete, setRowDataToDelete] = useState(null);

    // const ActionColumnFormatter = (cell, row) => {
        
    //     if (cell) {
    //       return (
    //         <>
    //         <i
    //           className="bi bi-pencil-fill"
    //           onClick={() => handleEditClick(row)}
    //           style={{ paddingRight: "10px" }}
    //         />
    //         <i className="bi bi-trash" 
    //         onClick={() => handleDeleteClick(row)}
    //           style={{ paddingRight: "10px" }}
    //           />
    //         </>
            
    //       );
    //     }
    //   }

    const handleDeleteClick = (row) => {
        setRowDataToDelete(row);
        setShowConfirmation(true);
    };

    const handleConfirmDelete = () => {
        const updatedDataToDelete = {
            ...rowDataToDelete,
            type: 'RCAT',
          };

        deleteMasterdata(updatedDataToDelete).then((response)=>{    
            if(!response.ok){
                // const err = new Error("Error in response");
                // throw err;
                setSnackbarMessage('Error in response');
                setShowSnackbar(true);
            } else{
                //return response.json();
            }
            }).then(response=>{
              console.log(response);
               
                //return response;
                setSnackbarMessage('Successfully deleted category data.');
                setShowSnackbar(true);
                fetchMasterData();
            });

        setShowConfirmation(false);
    };

    const handleCancelDelete = () => {
        setShowConfirmation(false);
    }; 

    const handleEditClick = (row)=>{   
        setId(row.value); 
        setLabel(row.label); 
        setIsEditModelDisplay(true);
        setModalShow(true);
      }

    const handleCreateMasterdata = ()=>{
    setModalShow(true);
    setIsCreateModelDisplay(true);
    }
     
    const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    }  

    const columns =[
      // {field: 'value', headerName: '#', minWidth: 150},
      {field: 'label', headerName: 'Name', minWidth: 300},
      {
        field: 'value',
        headerName: 'Actions',
        minWidth: 200,
        renderCell: (params) => (
          <>
             <i
              className="bi bi-pencil-fill"
              onClick={() => handleEditClick(params.row)}
              style={{ paddingRight: "10px" }}
            />
            <i className="bi bi-trash" 
            onClick={() => handleDeleteClick(params.row)}
              style={{ paddingRight: "10px" }}
              />
          </>
        ),
      },

        // {dataField: 'value', text: '#'},
        // {dataField: 'label', text: 'Name'},
        // {
        //     dataField: 'value',
        //     text: 'Actions',
        //     formatter: ActionColumnFormatter,
        //   },
    ];
    
    useEffect(()=>{
        fetchMasterData();
  
      },[]);
      
    const fetchMasterData =() => {
    
    getMasterData("RCAT").then(response => {
        const transformedOptions = response.map(option => ({
            label: option.label,
            value: option.value
        }));
        setDataList(transformedOptions);
        });
    }

      const handleReloadUpdateEvents = () => {       

        // setIsEditModelDisplay(false);
        // setModalShow(false);
        // setSnackbarMessage('Successfully updated Skills data.');
        // setShowSnackbar(true);
        fetchMasterData();
      };

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
              <Card style={{ width: '62rem', height: '53rem' }}>
              <Card.Body>
               
                  <Button variant="primary" className="button" type="submit" style={{float:"right"}}  onClick={handleCreateMasterdata}>
                       Add Catgory
                  </Button>               
                  <div className="row">
                        <div className="col">
                            &nbsp; 
                        </div>
                   </div>
                    <div className="row">
                        <div className="col">
                        <Card.Subtitle>Resource Category Setup</Card.Subtitle>
                        <div style={{float:"right"}}> &nbsp;</div>  
                            {/* <BootstrapTable 
                            keyField="id"
                            data={dataList}
                            columns={columns}
                            // cellEdit={cellEditFactory(cellEditOptions)}
                            pagination={paginationFactory()}
                            // filter={filterFactory()}
                            /> */}
                            <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={dataList}
                                columns={columns}
                                getRowId={(row) => row.value}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
                        </div>
                    </div>
                    
                     

                    {isCreateModelDisplay && 
                        <CreateRCategoryPage onMasterSubmit={handleReloadUpdateEvents} show={modalShow} onHide={() => {setModalShow(false); setIsCreateModelDisplay(false);}}  ></CreateRCategoryPage>
                    }
                    {isEditModelDisplay && 
                        <EditRCategoryPage onMasterSubmit={handleReloadUpdateEvents} id={id} label={label} show={modalShow} onHide={() => {setModalShow(false); setIsEditModelDisplay(false);}}  ></EditRCategoryPage>
                    }
 

                 </Card.Body>
                  </Card>
                </div>
            </div>
            <Modal show={showConfirmation} onHide={handleCancelDelete}>
            <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                Cancel
            </Button>
            <Button variant="danger" className="button" onClick={handleConfirmDelete}>
                Delete
            </Button>
            </Modal.Footer>
            </Modal>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
            </div>
        </div>
        </div>        

    )

}

export default RCategorySetupPage