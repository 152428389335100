// import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
// import * as ReactBootStrap from "react-bootstrap";
import { getAdvertisements, deleteAdvertisements } from "../../../api/adminApi";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import 'bootstrap/dist/css/bootstrap.min.css';
// import cellEditFactory from 'react-bootstrap-table2-editor';
// import OverlayFactory from "react-bootstrap-table2-overlay";
import { Link } from "react-router-dom";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { Card } from 'react-bootstrap';
import Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function AdsSetupPage() {
  const classes = useStyles();
    const [items, setItems] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    //const [modalShow, setModalShow] = useState(false);
    const [rowDataToDelete, setRowDataToDelete] = useState(null);

    //const [pageCount, setpageCount] = useState(0);  
    //let limit = 2;

    // const handlePageClick = async (data) => {
    // console.log(data.selected);

    // let currentPage = data.selected + 1;

    // };

    const columns =[
      {field: 'title', headerName: 'Title', minWidth: 150},
      {field: 'pagename', headerName: 'Display Page', minWidth: 150},
      {field: 'startdate', headerName: 'Start Date', minWidth: 150},
      {field: 'enddate', headerName: 'End Date', minWidth: 150},
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        renderCell: (params) => (
          <>
          <Link to={`/config-ads/edit/${params.row.guid}`}><i className="bi bi-pencil-fill" style={{paddingRight:"10px"}}/></Link>
           
           <i className="bi bi-trash" title={params.cell} onClick={() => handleDeleteClick(params.row)} />
          </>
        ),
      },

      // {dataField: 'title', text: 'Title'},
      // {dataField: 'pagename', text: 'Display Page'},
      // {dataField: 'startdate', text: 'Start Date'},
      // {dataField: 'enddate', text: 'End Date'},
            
      
      // {
      //   dataField: 'actions',
      //   text: 'Actions',
      //   formatter: (cell, row) => (
      //     <>
      //     <Link to={`/config-ads/edit/${row.guid}`}><i className="bi bi-pencil-fill" style={{paddingRight:"10px"}}/></Link>
           
      //     <i className="bi bi-trash" title={cell} onClick={() => handleDeleteClick(row)} />
      //     </>
      //   ),
      // },
    ];

    // const handleDelete =(guid) => {      
      
    //   const updatedData = items.filter((row) => row.guid !== guid);
    //   //const updatedData = items.filter((row) => row.id === id);
    //   setItems(updatedData);
    //   //console.log(JSON.stringify(items.filter((row) => row.id === id)));
    //   const deleteCourseData = items.filter((row) => row.guid === guid);
      
    //   deleteAdvertisements(guid).then((response)=>{
    //     if(!response.ok){
    //         // const err = new Error("Error in response");
    //         // throw err;
    //         setSnackbarMessage('Error in response');
    //         setShowSnackbar(true);
    //     } else{
    //         //return response.json();
    //     }
    //     }).then(response=>{
    //       console.log(response);
    //         //return response;
            
    //         setSnackbarMessage('Successfully deleted Advertisement data.');
    //         setShowSnackbar(true);
    //         //formik.resetForm();
    //     });

    // }
   
    const handleDeleteClick = (row) => {
      setRowDataToDelete(row);
      setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
      const updatedDataToDelete = {
          ...rowDataToDelete,
        };

        deleteAdvertisements(updatedDataToDelete).then((response)=>{    
          if(!response.ok){
              // const err = new Error("Error in response");
              // throw err;
              setSnackbarMessage('Error in response');
              setShowSnackbar(true);
          } else{
              //return response.json();
          }
          }).then(response=>{
            console.log(response);
             
              //return response;
              setSnackbarMessage('Successfully deleted Advertisement data.');
              setShowSnackbar(true);
              fetchAdvertisementsData();
          });

      setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
      setShowConfirmation(false);
  }; 


    useEffect(()=>{
      fetchAdvertisementsData();

    },[]);
    
    const fetchAdvertisementsData =() => {
      getAdvertisements().then(response=>{
        const data = response;
        //const total = 10;//response.headers.get("x-total-count");
        //setpageCount(Math.ceil(total / limit));              
        setItems(data);
        // console.log(response);
        // console.log(JSON.stringify(response));
      });
    }
    // const cellEditOptions ={
    //   mode: 'click',
    //   blurToSave: true,
    //   autoSelectText: true,
    // }

    // const closeModel = () => {
    //   setSelectedImage(null);
    // }

    const handleCloseSnackbar = () => {
      setShowSnackbar(false);
    }
    
    return (
      <div className="featured-section-wrap featured-section-wrap-row">
      <div className='mainPageContainer'>        
          <div className='dashboardcontainerflex'>
            <div className='dashboardcard'>
            <Card style={{ width: '62rem', height: '53rem' }}>
              <Card.Body>
                <Link to="/config-ads/create">
                  <Button variant="primary" type="submit" className="button" style={{float:"right"}} >
                      + Add Advertisement
                  </Button>
                  </Link>
                  <Card.Title style={{ display: 'inline-block' }}>Advertisements</Card.Title>
                  <div className="row">
                  <div className="col">
&nbsp;
                    </div>
                    </div>
                  <div className="row">
                            <div className="col">
                    {/* <BootstrapTable 
                    keyField="guid"
                    data={items}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory()}
                    /> */}
     <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={items}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
     
                    {selectedImage && (
                        <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
                          <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Image Preview</h5>
                                <button type="button" className="close" onClick={() => setSelectedImage(null)}>
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <img src={`data:image/png;base64, ${selectedImage}`} 
                                alt="Selected" 
                                className="img-fluid" 
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
        

                    <div>
                      <SnackbarProvider 
                      show={showSnackbar}
                      message={snackbarMessage}
                      onClose={handleCloseSnackbar}
                      />
                    <Modal show={showConfirmation} onHide={handleCancelDelete}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="danger" className="button" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                    </Modal.Footer>
                    </Modal>

                    </div>
                    </div>
                    </div>
              </Card.Body>
            </Card>
          </div>
          </div>
          </div>
      </div>
    );

}

export default AdsSetupPage;