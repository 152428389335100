import React, {useEffect, useState } from "react";
// import Form from 'react-bootstrap/Form';
// import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
// import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
// import Select from 'react-select';
// import { useFormik, Formik } from "formik"
// import * as Yup from "yup";
// import {useQuery, useQueryClient } from "react-query";
// import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import { Link } from "react-router-dom";
// import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import {textFilter} from "react-bootstrap-table2-filter";
// import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment/moment";
import { getMentors } from "../../../api/adminApi";
import SearchInputs from "../../common/shared/search/SearchInputs";

import DeactivatePage from "./DeactivatePage"; 
import ConversationPage from "../corporate-emp-page/ConversationPage";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function MentorListPage(){
  const classes = useStyles();
    const [mentorsList, setMentorsList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [isModelDisplay, setIsModelDisplay] = useState(false);

    const [modalShowChat, setModalShowChat] = useState(false);
    const [isModelDisplayChat, setIsModelDisplayChat] = useState(false);

    const [guid, setGuid] = useState(null);
    const [emailaddress, setEmailaddress] = useState(null);

    // const dateFormatter = (cell) => { //, row
    //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
    //     return <span>{formattedDate}</span>;
    // }
     
    const columns =[
      {field: 'userlogon', headerName: 'User Logon', minWidth: 150},
      {field: 'firstname', headerName: 'First Name', minWidth: 150},
      {field: 'lastname', headerName: 'Last Name', minWidth: 150},
      {field: 'mobilenumber', headerName: 'Mobile Nuber', minWidth: 150},
      {field: 'createddate', headerName: 'Created date', minWidth: 150, 
      valueGetter: (params) => moment(params.row.createddate).format("YYYY-MM-DD HH:mm")
      },
      {
        field: 'action',
        headerName: 'Action',
        minWidth: 200,
        renderCell: (params) => (
          <>
           <i
                className="bi bi-chat"
                onClick={() => handleChatClick(params.row.emailaddress)}
                style={{ paddingRight: "10px" }}
                />

              <Link to={`/mentor-details/${params.row.guid}`}><i className="bi bi-activity" style={{paddingRight:"10px", fontSize:'20px'}}/></Link>
              
              <i className="bi bi-pencil" title={params.row.guid} onClick={() => handleViewClick(params.row.guid)} />
          </>
        ),
      },

        // // {dataField: 'guid', text: '#'},
        // {
        //     dataField: 'userlogon', 
        //     text: 'User Logon', 
        //     // headerFormatter: () => <span>HHH</span>,
        //     // filter: textFilter({ 
        //     //     placeholder: 'Filter by logon', 
        //     //     getFilter: (filter)=> filter
                
        //     // })
        // },
        // {dataField: 'firstname', text: 'First Name'},
        // {dataField: 'lastname', text: 'Last Name'},
        // {dataField: 'mobilenumber', text: 'Mobile Nuber'},
        // // {dataField: 'planName', text: 'Plan Name'},
        // // {dataField: 'description', text: 'Description'},
        // // {dataField: 'duration', text: 'Duration'},
        // // {dataField: 'subscriptionStartDate', text: 'Subs. Start Date'},
        // // {dataField: 'subscriptionEndDate', text: 'Subs. End Date'},
        // // {dataField: 'status', text: 'Status'},
        // {dataField: 'createddate', text: 'Created date', formatter: dateFormatter},  
        
        // {
        //     dataField: 'action',
        //     text: 'Action',
        //     formatter: (cell, row) => (
        //       <>
        //        <i
        //         className="bi bi-chat"
        //         onClick={() => handleChatClick(row.emailaddress)}
        //         style={{ paddingRight: "10px" }}
        //         />

        //       <Link to={`/mentor-details/${row.guid}`}><i className="bi bi-activity" style={{paddingRight:"10px", fontSize:'20px'}}/></Link>
              
        //       <i className="bi bi-pencil" title={row.guid} onClick={() => handleViewClick(row.guid)} />
        //       </>
        //     ),
        // },
    ];

    const handleChatClick = (emailaddress) => {      
        setEmailaddress(emailaddress);
        setIsModelDisplayChat(true);
        setModalShowChat(true);
      };

    const handleViewClick = (guid) => {      
        setGuid(guid);
        setIsModelDisplay(true);
        setModalShow(true);
      };

    useEffect(()=>{
        fetchMentorsData();
  
      },[]);
      
      const fetchMentorsData =() => {
        getMentors().then(response=>{                    
            setMentorsList(response); 
           
        });
      }
      useEffect(()=>{
        const filteredResults = mentorsList.filter((item) => {
            const emailMatch = item.userlogon.toLowerCase().includes(emailSearch.toLowerCase());

            return emailMatch;
        });
        setFilteredData(filteredResults);
      },[mentorsList,emailSearch]);

      const handleEmailChange = (e) => {
        setEmailSearch(e.target.value);
      }
    //columns[1].filter = undefined;
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
               


                    
                <Card style={{ width: '62rem', height: '53rem' }}>
                    <Card.Body>
                    <Card.Title style={{ display: 'inline-block' }}>Mentor List</Card.Title>
                    <SearchInputs
                    showEmailSearch={true}
                    emailSearch={emailSearch}
                    onEmailChange={handleEmailChange}
                    ></SearchInputs>

                    {/* <BootstrapTable 
                    keyField="user_id"
                    data={filteredData}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory()}
                    // filter={filterFactory()}
                    /> */}
                     <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.orgguid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

{isModelDisplay && (
              <DeactivatePage
                id={guid}
                show={modalShow}
                //onPaymentSettlementSubmit={handleReloadUpdateEvents}
                onHide={() => {
                  setModalShow(false);
                  setIsModelDisplay(false);
                }}
              ></DeactivatePage>
            )}

            {isModelDisplayChat && (
            <ConversationPage id={emailaddress} show={modalShowChat} onHide={() => {setModalShowChat(false); setIsModelDisplayChat(false);}}></ConversationPage>
            )}

                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default MentorListPage