import * as Sentry from "@sentry/react";
import React from "react";
// import logo from './logo.svg';
// import './App.css';
// import './Stylesheet/somotus.css';

// import './css/style.min.css';

//import './Stylesheet/chartist.min.css';
//import './Stylesheet/chartist-plugin-tooltip.css';
//import './Stylesheet/style.css';
//import { Helmet } from 'react-helmet';

import {Routes,Route,Navigate  } from "react-router-dom";
import LoginPage from "./components/pages/login-page/LoginPage";
import RegisterPage from "./components/pages/register-page/RegisterPage";
import CRegisterPage from "./components/pages/register-page/CRegisterPage";
import IRegisterPage from "./components/pages/register-page/IRegisterPage";

import CRegisterPage1 from "./components/pages/register-page/CRegisterPage1";
import RazorpayPayment from "./components/pages/register-page/RazorpayPayment";



import StaticProfilePage from "./components/pages/profile-pages/StaticProfilePage";

import DashboardPage from "./components/pages/home-page/DashboardPage";
import AdminDashboardPage from "./components/pages/home-page/AdminDashboardPage";
import CorporateDashboardPage from "./components/pages/home-page/CorporateDashboardPage";

import InstituteDashboardPage from "./components/pages/home-page/InstituteDashboardPage";
 
import MentorProfilePage from "./components/pages/profile-pages/MentorProfilePage";
import AdminProfilePage from "./components/pages/profile-pages/AdminProfilePage";
import CorporateProfilePage from "./components/pages/profile-pages/CorporateProfilePage";
import InstituteProfilePage from "./components/pages/profile-pages/InstituteProfilePage";

import ResetPasswordPage from "./components/pages/profile-pages/ResetPasswordPage";

import CorporateEmpSetupPage from "./components/pages/corporate-setup-page/CorporateEmpSetupPage";
import InstituteStudentSetupPage from "./components/pages/institute-setup-page/InstituteStudentSetupPage";

import CorporateEmpPage from "./components/pages/corporate-emp-page/CorporateEmpPage";
import AssignmentManagement from "./components/pages/corporate-emp-page/AssignmentManagement";

import LNDLibraryManagement from "./components/pages/corporate-emp-page/LNDLibraryManagement";
import SAssignmentManagement from "./components/pages/institute-setup-page/SAssignmentManagement";

import InstituteStudentPage from "./components/pages/institute-student-pages/InstituteStudentPage";

import SLNDLibraryManagement from "./components/pages/institute-student-pages/LNDLibraryManagement";


// import logoHead from './Images/somotus-logo-white.png';
// import LeftBarNav from './LeftBarNav';
// import MentorDashboard from './components/common/shared/header/SubHeader';

import { QueryClient, QueryClientProvider} from "react-query";
// import LoggedoutContainer from './components/common/layout/LoggedoutContainer/LoggedoutContainer';
// import LoggedinContainer from './components/common/layout/LoggedinContainer/LoggedinContainer';
import {  Component } from 'react';
import { AuthProvider } from './components/authContext/authContext';
// import  AppRoutes  from './AppRoutes';


import MentorResourcesPage from "./components/pages/mentor-resources-page/MentorResourcesPage";
import MentorResourcesViewPage from "./components/pages/mentor-resources-page/MentorResourcesViewPage";
import MentorResourcesEditPage from "./components/pages/mentor-resources-page/MentorResourcesEditPage";

import MentorShortsPage from "./components/pages/mentor-shorts-page/MentorShortsPage";
import MentorShortsViewPage from "./components/pages/mentor-shorts-page/MentorShortsViewPage";
import MentorShortsEditPage from "./components/pages/mentor-shorts-page/MentorShortsEditPage";


import MentorCoursesViewPage from "./components/pages/mentor-courses-page/MentorCoursesViewPage";
import MentorCoursesEditPage from "./components/pages/mentor-courses-page/MentorCoursesEditPage";
import MentorPaymentPage from "./components/pages/payment-pages/MentorPaymentPage";
import AdminPaymentPage from "./components/pages/payment-pages/AdminPaymentPage";
import EnrollmentsPage from "./components/pages/enrollments-page/EnrollmentsPage";
import MentorNotificationPage from "./components/pages/notifications-page/MentorNotificationPage";
import AdminNotificationPage from "./components/pages/notifications-page/AdminNotificationPage";
import CorporateNotificationPage from "./components/pages/notifications-page/CorporateNotificationPage";

import InstituteNotificationPage from "./components/pages/notifications-page/InstituteNotificationPage";

import AddCoursePage from "./components/pages/mentor-courses-page/AddCoursePage";

// import ProfileDashboardCard from './components/common/shared/profileCards/ProfileDashboardCard'


import MentorWorkshopPage from "./components/pages/mentor-workshop-page/MentorWorkshopPage";
import MentorWorkshopViewPage from "./components/pages/mentor-workshop-page/MentorWorkshopViewPage";
import MentorWorkshopEditPage from "./components/pages/mentor-workshop-page/MentorWorkshopEditPage";

import WorkshopsRequestPage from "./components/pages/leads-pages/WorkshopsRequestPage";
import WorkshopsRequestViewPage from "./components/pages/leads-pages/WorkshopsRequestViewPage";


import MentorCalendarPage from "./components/pages/mentor-calendar-page/MentorCalendarPage";
import MentorCoursesPage from "./components/pages/mentor-courses-page/MentorCoursesPage";
// import Header from './components/common/shared/header/Header';
// import SubHeader from './components/common/shared/header/SubHeader';
// import Leftbar from './components/common/shared/leftbar/Leftbar';
// import { getCurrentUser,logout } from './api/userAuthApi';
// import EventBus from "./api/EventBus";

// import Loader from "./components/common/shared/loader/Loder";
import PrivateRoutes  from "./components/authContext/PrivateRoutes";

import Forbidden from "./components/pages/Forbidden";
import NotFound from "./components/pages/NotFound";
import UnderConstructions from "./components/pages/UnderConstructions";

import BlockedPage from "./components/pages/block-pages/BlockedPage";
import ProfileNotCompletePage from "./components/pages/block-pages/ProfileNotCompletePage";
import SubsciptionExpiredPage from "./components/pages/block-pages/SubsciptionExpiredPage";

import MentorListPage from "./components/pages/admin-mentor-pages/MentorListPage";
import MentorDetailsPage from "./components/pages/admin-mentor-pages/MentorDetailsPage";
import EmpDetailsPage from "./components/pages/corporate-emp-page/EmpDetailsPage";
import StudentDetailsPage from "./components/pages/institute-student-pages/StudentDetailsPage";

import CorporateListPage from "./components/pages/admin-corporate-pages/CorporateListPage";

import InstituteListPage from "./components/pages/admin-institute-pages/InstituteListPage";

import SessionDetailsPage from "./components/pages/mentor-sessions-page/SessionDetailsPage";

import AssessmentPage from "./components/pages/assessment-page/AssessmentPage";
import ViewAssessmentsPage from "./components/pages/assessment-page/ViewAssessmentsPage";
import ViewResultsPage from "./components/pages/assessment-page/ViewResultsPage";
import SendInvitesPage from "./components/pages/assessment-page/SendInvitesPage";

import SurveyPage from "./components/pages/survey-pages/SurveyPage";
import ViewSurveyPage from "./components/pages/survey-pages/ViewSurveyPage";
import ViewSurveyResultsPage from "./components/pages/survey-pages/ViewResultsPage";
import SendSurveyInvitesPage from "./components/pages/survey-pages/SendInvitesPage";

import MentorPaymentConfigPage from "./components/pages/account-management-page/MentorPaymentConfigPage";
import CorporateLeadsPage from "./components/pages/subscriber-page/CorporateLeadsPage";
import InstituteLeadsPage from "./components/pages/subscriber-page/InstituteLeadsPage";

import MentorLeadsPage from "./components/pages/subscriber-page/MentorLeadsPage";
import CorporateActivityPage from "./components/pages/subscriber-page/CorporateActivityPage";
import InstituteActivityPage from "./components/pages/subscriber-page/InstituteActivityPage";

import MentorActivityPage from "./components/pages/subscriber-page/MentorActivityPage";

import SubscriptionDetailsPage from "./components/pages/subscription-pages/SubscriptionDetails";

import SkillsSetupPage from "./components/pages/admin-configuration-pages/SkillsSetupPage";
import CategorySetupPage from "./components/pages/admin-configuration-pages/CategorySetupPage";
import LanguageSetupPage from "./components/pages/admin-configuration-pages/LanguageSetupPage";
import RCategorySetupPage from "./components/pages/admin-configuration-pages/RCategorySetupPage";
import ConstantsSetupPage from "./components/pages/admin-configuration-pages/ConstantsSetupPage";

import UserSetupPage from "./components/pages/corporate-account-pages/UserSetupPage";
import RoleSetupPage from "./components/pages/corporate-account-pages/RoleSetupPage";
import DivisionSetupPage from "./components/pages/corporate-account-pages/DivisionSetupPage";

import AdsSetupPage from "./components/pages/admin-configuration-pages/AdsSetupPage";
import CreateAdsPage from "./components/pages/admin-configuration-pages/CreateAdsPage";
import EditAdsPage from "./components/pages/admin-configuration-pages/EditAdsPage";
import EmailTemplateSetupPage from "./components/pages/admin-configuration-pages/EmailTemplateSetupPage";

import SubscriptionSetupPage from "./components/pages/admin-configuration-pages/SubscriptionSetupPage";
import CreateSubscriptionPage from "./components/pages/admin-configuration-pages/CreateSubscriptionPage";
import EditSubscriptionPage from "./components/pages/admin-configuration-pages/EditSubscriptionPage";

import LandingPage from "./components/pages/landing-page/LandingPage";

import SupportPage from "./components/pages/support-pages/SupportPage";

import PrivacyPage from "./components/pages/static-pages/PrivacyPage";
import TermsPage from "./components/pages/static-pages/TermsPage";
import RefundPage from "./components/pages/static-pages/RefundPage";
import ContactUsPage from "./components/pages/landing-page/ContactUsPage";

import EmotionExpressoPage from "./components/pages/admin-report-pages/EmotionExpressoPage";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      suspense: true,
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    // this.logOut = this.logOut.bind(this);

    // this.state = {
    //   showMentorBoard: false,
    //   showAdminBoard: false,
    //   showCorporateBoard: false,
    //   currentUser: undefined,
    // };
  }
  componentDidMount() {
    // const user = getCurrentUser();
     
    // if (user) {
    //   this.setState({
    //     currentUser: user,
    //     showMentorBoard: user.role.includes("Mentor"), //ROLE_MODERATOR
    //     showAdminBoard: user.role.includes("Admin"), //ROLE_ADMIN
    //     showCorporateBoard: user.role.includes("Corporate"), //ROLE_MODERATOR
    //   });
    // }
    
    // EventBus.on("logout", () => {
    //   alert('eventBusLogout');
    //   this.logOut();
    // });
  }
  componentWillUnmount() {
    // EventBus.remove("logout");
  }
  // logOut() {  
  //   alert('logout');   
  //   logout();
  //   this.setState({
  //     showModeratorBoard: false,
  //     showAdminBoard: false,
  //     showCorporateBoard: false,
  //     currentUser: undefined,
  //   });
  // }

  render() {
    // <Helmet>
    //     <script src="./plugins/bower_components/jquery/dist/query.min.js"></script>
    //     <script src="./bootstrap/dist/js/bootstrap.bundle.min.js"></script>
    //     <script src="./js/app-style-switcher.js"></script>
    //     <script src="./js/waves.js"></script>
    //     <script src="./js/sidebarmenu.js"></script>
    //     <script src="./js/custom.js"></script>
    //     <script src="./plugins/bower_components/chartist/dist/chartist.min.js"></script>
    //     <script src="./plugins/bower_components/chartist-plugin-tooltips/dist/chartist-plugin-tooltip.min.js"></script>
    //     <script src="./js/pages/dashboards/dashboard1.js"></script>
    
    //   </Helmet>
  //  const { currentUser, showMentorBoard, showAdminBoard,showCorporateBoard } = this.state;
      return (
         
        //  <React.Suspense fallback={<Loader />}>
          <Sentry.ErrorBoundary fallback={"An error has occurred"}>
            <QueryClientProvider client={queryClient}>
              {/* <SettingsProvider>
                <QueryWrapper>
                  <SnackbarProvider> */}
                    <AuthProvider>
                    <Routes >
                      <Route path="/" element={<LandingPage />} />
                      <Route path="/login" element={<LoginPage />} />
                      <Route path="/register" element={<RegisterPage />} />
                      <Route path="/register/corporate" element={<CRegisterPage />} />
                      <Route path="/register/institute" element={<IRegisterPage />} />

                      <Route path="/register/corporate1" element={<CRegisterPage1 />} />
                      <Route path="/register/razorpay" element={<RazorpayPayment />} />
                      <Route path="/contactus" element={<ContactUsPage />} />
                      

                      <Route path="/profile/:guid" element={<StaticProfilePage />} />
                      <Route  element={<PrivateRoutes />} >
                        <Route path="/dashboard" element={<DashboardPage />} exact />
                        <Route path="/dashboard-admin" element={<AdminDashboardPage />} exact />
                        <Route path="/dashboard-corporate" element={<CorporateDashboardPage />} exact />
                        <Route path="/dashboard-institute" element={<InstituteDashboardPage />} exact />
                        <Route path="/profile" element={<MentorProfilePage />} />
                        <Route path="/profile-admin" element={<AdminProfilePage />} />
                        <Route path="/profile-corporate" element={<CorporateProfilePage />} />
                        <Route path="/profile-institute" element={<InstituteProfilePage />} />
                        <Route path="/corporate-emp-setup" element={<CorporateEmpSetupPage />} />
                        <Route path="/institute-student-setup" element={<InstituteStudentSetupPage />} />
                        <Route path="/corporate-emp-list" element={<CorporateEmpPage />} />
                        <Route path="/corporate-assign" element={<AssignmentManagement />} />
                        
                        <Route path="/resetPassword" element={<ResetPasswordPage />} />

                        <Route path="/corporate-library" element={<LNDLibraryManagement />} />
                        <Route path="/students-library" element={<SLNDLibraryManagement />} />
                        <Route path="/institute-assign" element={<SAssignmentManagement />} />
                        <Route path="/institute-student-list" element={<InstituteStudentPage />} />

                        <Route path="/mentor-payment-list" element={<MentorPaymentPage />} />
                        <Route path="/admin-payment-list" element={<AdminPaymentPage />} />
                        <Route path="/mentor-enroll-list" element={<EnrollmentsPage />} />
                        <Route path="/mentor-notifications" element={<MentorNotificationPage />} />
                        <Route path="/admin-notifications" element={<AdminNotificationPage />} />
                        <Route path="/corporate-notifications" element={<CorporateNotificationPage />} />
                        
                        <Route path="/institute-notifications" element={<InstituteNotificationPage />} />
                        
                        <Route path="/mentor-workshop" element={<MentorWorkshopPage />} />
                        <Route path="/workshopsView" element={<MentorWorkshopViewPage />} />                        
                        <Route path="/workshopEdit/:id" element={<MentorWorkshopEditPage />} />

                        <Route path="/workshops-request" element={<WorkshopsRequestPage />} />
                        <Route path="/workshops-request-view" element={<WorkshopsRequestViewPage />} />
                        

                        <Route path="/mentor-resources" element={<MentorResourcesPage />} />
                        <Route path="/resourcesView" element={<MentorResourcesViewPage />} />
                        <Route path="/resourceAdd" element={<MentorResourcesPage />} />
                        <Route path="/resourceEdit/:id" element={<MentorResourcesEditPage />} />

                        <Route path="/mentor-shorts" element={<MentorShortsPage />} />
                        <Route path="/shortsView" element={<MentorShortsViewPage />} />
                        <Route path="/shortsAdd" element={<MentorShortsPage />} />
                        <Route path="/shortsEdit/:guid" element={<MentorShortsEditPage />} />

                        

                        <Route path="/calendar" element={<MentorCalendarPage />} />
                        <Route path="/courseAdd" element={<MentorCoursesPage />} />
                        <Route path="/courses" element={<MentorCoursesViewPage />} />
                        
                        <Route path="/courseView" element={<MentorCoursesViewPage />} />
                        <Route path="/courseEdit/:id" element={<MentorCoursesEditPage />} />

                        <Route path="/coursestest" element={<AddCoursePage />} />
                        
                       
                        <Route path="/under-construction" element={<UnderConstructions />} />
                        <Route path="/profile-incomplete" element={<ProfileNotCompletePage />} />
                        <Route path="/subscription-expired" element={<SubsciptionExpiredPage />} />
                        <Route path="/block" element={<BlockedPage />} />
                      
                        <Route path="/corporate-list" element={<CorporateListPage />} />
                        <Route path="/institute-list" element={<InstituteListPage />} />
                        <Route path="/mentor-list" element={<MentorListPage />} />
                        <Route path="/mentor-details/:id/" element={<MentorDetailsPage />} />
                        <Route path="/emp-details/:id/" element={<EmpDetailsPage />} />

                        <Route path="/student-details/:id/" element={<StudentDetailsPage />} />

                        <Route path="/mentor-payment-config" element={<MentorPaymentConfigPage />} />
                        <Route path="/new-leads/corporate" element={<CorporateLeadsPage />} />
                        <Route path="/new-leads/institute" element={<InstituteLeadsPage />} />
                        <Route path="/new-leads/mentor" element={<MentorLeadsPage />} />
                        {/* <Route path="/new-subscriberActivity/:id/:role/:email/" element={<ActivityPage />} /> */}
                        <Route path="/new-lead/cdetails/:guid/" element={<CorporateActivityPage />} />
                        <Route path="/new-lead/idetails/:guid/" element={<InstituteActivityPage />} />
                        <Route path="/new-lead/mdetails/:guid/" element={<MentorActivityPage />} />

                        <Route path="/subscriptions" element={<SubscriptionDetailsPage />} />

                        <Route path="/mentor-sessions" element={<SessionDetailsPage />} />
                        <Route path="/assessment/create" element={<AssessmentPage />} />
                        <Route path="/assessment/:guid/edit" element={<AssessmentPage />} />
                        <Route path="/assessments" element={<ViewAssessmentsPage />} />
                        <Route path="/employee-results/:guid/" element={<ViewResultsPage />} />
                        <Route path="/employee-invites/:guid/" element={<SendInvitesPage />} />

                        <Route path="/survey/create" element={<SurveyPage />} />
                        <Route path="/survey/:guid/edit" element={<SurveyPage />} />
                        <Route path="/survey" element={<ViewSurveyPage />} />
                        <Route path="/employee-survey-results/:guid/" element={<ViewSurveyResultsPage />} />
                        <Route path="/employee-survey-invites/:guid/" element={<SendSurveyInvitesPage />} />
                        
                        <Route path="/config-skills" element={<SkillsSetupPage />} />
                        <Route path="/config-category" element={<CategorySetupPage />} />
                        <Route path="/config-rcategory" element={<RCategorySetupPage />} />
                        <Route path="/config-language" element={<LanguageSetupPage />} />
                        <Route path="/config-constants" element={<ConstantsSetupPage />} />

                        <Route path="/config-users" element={<UserSetupPage />} />
                        <Route path="/config-divisions" element={<DivisionSetupPage />} />
                        <Route path="/config-roles" element={<RoleSetupPage />} />

                        <Route path="/config-ads/view" element={<AdsSetupPage />} />
                        <Route path="/config-ads/create" element={<CreateAdsPage />} />
                        <Route path="/config-ads/edit/:guid/" element={<EditAdsPage />} />
                        <Route path="/config-etemplates" element={<EmailTemplateSetupPage />} />

                        <Route path="/config-subscriptionplan/view" element={<SubscriptionSetupPage />} />
                        <Route path="/config-subscriptionplan/create" element={<CreateSubscriptionPage />} />
                        <Route path="/config-subscriptionplan/edit/:guid/" element={<EditSubscriptionPage />} />
                        <Route path="/report-emotionExpresso" element={<EmotionExpressoPage />} />
                        

                        <Route path="/support" element={<SupportPage />} />
                        
                        <Route path="/privacy" element={<PrivacyPage />} />
                        <Route path="/terms" element={<TermsPage />} />
                        <Route path="/refund" element={<RefundPage />} />

                      </Route>
                      <Route path="/under-construction" element={<UnderConstructions />} />
                      <Route path="/403" element={<Forbidden />} />
                      <Route path="/404" element={<NotFound />} />
                      <Route
                        path="*"
                        element={<Navigate to={`/${process.env.PUBLIC_URL}/404`} replace />}
                      />
                    </Routes>
                    </AuthProvider>
                  {/* </SnackbarProvider>
                </QueryWrapper>
              </SettingsProvider> 
              <ReactQueryDevtools initialIsOpen />*/}
            </QueryClientProvider>
          </Sentry.ErrorBoundary>
        //  </React.Suspense>
      );
  }

  

}

export default App;
