import React,{useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import Select from 'react-select';
import { useFormik } from "formik"
import * as Yup from "yup";
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import { getMentorDetails, getMasterData, updatePriceChange, updateProfile,getMentorDefaultPrice } from "../../../api/mentorApi";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton,InstapaperShareButton,InstapaperIcon, FacebookIcon, LinkedinIcon, TwitterIcon} from 'react-share';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function MentorProfilePage(){
// const[mentorDetails,setMentorDetails] = useState({});
const [clsName, setclsName] = useState('');
const [fetchedCountryOptions, setFetchedCountryOptions] = useState([]);
const [fetchedSkillsOptions, setFetchedSkillsOptions] = useState([]);
const [fetchedLangOptions, setFetchedLangOptions] = useState([]);
const [showSnackbar, setShowSnackbar] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [isDisabledPrice, setIsDisabledPrice] = useState(false);

const [profileURL, setProfileURL] = useState('');

const  validationAddressSchema = Yup.object({
  address1: Yup.string()
  .required("Address1 is required."),
  address3: Yup.string()
  .required("City is required."),
  country: Yup.string()
  .required("Country is required."),
  postal_code: Yup.string()
    .required("Postal code is required."),
}); 

const  validationSchema = Yup.object({
  first_name: Yup.string()
  .required("First name is required."),
  // middle_name: Yup.string()
  // .required("Middle name is required."),
  last_name: Yup.string()
  .required("Last name is required."),

  email_address: Yup.string()
    .email('Invalid email address format')
    .required('Email address is required'),
    mobile_number: Yup.string()
    .matches(/^(?:\+91\s?)?\d{10}$/, 'Invalid mobile number')
    .required('Mobile number is required'),
  
  addresses: validationAddressSchema,
  short_biography: Yup.string()
  .required('Short biography is required.')
  .matches(/^[^0-9]*([0-9][^0-9]*){0,6}$/, 'Numbers are not allowed(Max 6).')
  
  .test('no-restricted-words', 'Restricted words found.', (value) => {
    const restrictedWords = ['call', 'phone', 'whatsapp', 'whats', 'landline', 'mobile', 'email', 'emailaddress', 'cellphone'];
    for (const word of restrictedWords) {
      if (value.toLowerCase().includes(word)) {
        return false; // Found a restricted word
      }
    }
    return true; // No restricted words found
  })
  // .test('no-more-than-two-digits', 'Numbers are not allowed.', (value) => {
  //   // Use a regular expression to check for more than two consecutive digits
  //   const consecutiveDigitsPattern = /\d{3,}/;
  //   return !consecutiveDigitsPattern.test(value);
  // })
  .test('no-special-characters', 'Special characters are not allowed.', (value) => {
    const specialCharactersRegex = /[@#$%^_[\]{}|\\:"<>/~`]/; //   /[@!#$%^&*()_[\]{}|\\:;"'<>?/~`]/;
    return !specialCharactersRegex.test(value);
  })
  // .test('two-digit-limit', 'Only two-digit numbers are allowed.', (value) => {
  //   // Use a regular expression to check for only two-digit numbers with optional non-digit characters in between
  //   const twoDigitNumberPattern = /^((?:\D*\d\D*){2}|^\D*)$/;
  //   return twoDigitNumberPattern.test(value);
  // })
  .test('no-number-wording', 'Number wordings are not allowed.', (value) => {
    // Use a regular expression to check for number wordings
    const numberWordingsPattern = /\bthree\b|\bfour\b|\bfive\b|\bsix\b|\bseven\b|\beight\b|\bnine\b|\bten\b|\beleven\b|\btwelve\b|\bthirteen\b|\bfourteen\b|\bfifteen\b|\bsixteen\b|\bseventeen\b|\beighteen\b|\bnineteen\b|\btwenty\b|\bthirty\b|\bforty\b|\bfifty\b|\bsixty\b|\bseventy\b|\beighty\b|\bninety\b|\bhundred\b|\bthousand\b|\bmillion\b|\bbillion\b|\btrillion\b/;
    return !numberWordingsPattern.test(value.toLowerCase());
  }),


    
  price_range: Yup.string()
  .required("Price range is required."),
  total_exp: Yup.string()
  .required("Total experiance is required."),

  skills: Yup.array().min(1, 'Please select at lease one option'),
  languages: Yup.array().min(1, 'Please select at lease one option'),
}); 



const handleSubmit =(values) => {   
  updateProfile(values).then((response)=>{
    if(!response.ok){
        setclsName('alert error');
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        return response.json();
    }
    }).then(response=>{
      //console.log(response);
    

      // Retrieve the user object from localStorage
      const userString = localStorage.getItem('user');
      const user = JSON.parse(userString);
      user.profile_completion = response.profilecompletionCount; 
      const modifiedUserString = JSON.stringify(user);
      localStorage.setItem('user', modifiedUserString);
      setclsName('alert success');
      setSnackbarMessage('Profile updated successfully. Now! You can start posting your learning contents, setup mentoring sessions and setup workshops.');
      setShowSnackbar(true);
        //formik.resetForm();
    });

  
}

const formik = useFormik({
  initialValues:{
    first_name: '',
    middle_name: '',
    last_name: '',
    email_address: '',
    mobile_number: '',
    addresses:{
      address1: '',
      address2: '',
      address3: '',
      state:'',
      country: '',
      postal_code:''
    },
    short_biography: '',
    price_range: '',
    total_exp: '',
    skills: [],
    languages: [],
    price_set_type: "PER SESSION", 
    iscertissue: false,
  },
  validationSchema,
  onSubmit: handleSubmit,
});

const handleCloseSnackbar = () => {
  setShowSnackbar(false);
}

const handlePriceChangeButton = () => {
  
  formik.setTouched({
    price_range: true,
    price_set_type: true
  })
  const validationSchemaPriceChange = Yup.object({    
    price_range: Yup.string()
    .required("Price is required."),
     
  }); 

  formik.validateForm(validationSchemaPriceChange)
  .then(() => {
    
    if(formik.isValid){
      const payload = {
        price_range: formik.values.price_range,
        price_set_type: formik.values.price_set_type
      };
       
      updatePriceChange(payload).then((response)=>{
        if(!response.ok){
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
            setSnackbarMessage('Price change request has been initiated.');
            setShowSnackbar(true);
            setIsDisabledPrice(true);
        });
    }
     
  })
  .catch((errors) => {
    console.log("Error" + errors);
  })
  
}

// const handlePriceChangeButton = () => {
//   const validationSchemaPriceChange = Yup.object({
//     price_range: Yup.string().required("Price is required1."),
//   });

//   // Validate the form with the provided validation schema
//   formik
//     .validateForm(validationSchemaPriceChange)
//     .then(() => {
//       // Check if the form is valid
//       if (formik.isValid) {
//         const payload = {
//           price_range: formik.values.price_range,
//           price_set_type: formik.values.price_set_type,
//         };

//         updatePriceChange(payload)
//           .then((response) => {
//             if (!response.ok) {
//               setSnackbarMessage("Error in response");
//               setShowSnackbar(true);
//             } else {
//               //return response.json();
//             }
//           })
//           .then((response) => {
//             console.log(response);
//             setSnackbarMessage("Successfully triggered Price change request.");
//             setShowSnackbar(true);
//             setIsDisabledPrice(true);
//           });
//       }
//     })
//     .catch((errors) => {
//       console.log("Error", errors);
//     });
// };


useEffect(()=>{
  getMasterData("SKI").then(response => {
    const transformedOptions = response.map(option => ({
      label: option.label,
      value: option.value
    }));
    setFetchedSkillsOptions(transformedOptions);
  });
  
  getMasterData("LAN").then(response => {
    const transformedOptions = response.map(option => ({
      label: option.label,
      value: option.value
    }));
    setFetchedLangOptions(transformedOptions);
  });
 
  getMasterData("CON").then(response => {    
    const transformedOptions = response.map(option => ({
      label: option.label,
      value: option.value
    }));
    setFetchedCountryOptions(transformedOptions);
  });

  getMentorDetails().then(response=>{
    const transformedSkillsOptions = response.skills.map(option => ({
      label: option.label,
      value: option.value
    }));
    const transformedLangOptions = response.languages.map(option => ({
      label: option.label,
      value: option.value
    }));
    // const transformedCountryOptions = response.country.map(option => ({
    //   label: option.name,
    //   value: option.id
    // }));

    //setMentorDetails(response);  
    const currentURL = window.location.href;    
    setProfileURL(`${currentURL}/${response.guid}`);
    setIsDisabledPrice(response.isPriceChangePending);
    //formik.setValues(response);
    formik.setValues({
      first_name: response.first_name,
      middle_name: response.middle_name,
      last_name: response.last_name,
      email_address: response.email_address,
      mobile_number: response.mobile_number,
      short_biography: response.short_biography,
      price_range: response.price_range,
      total_exp: response.total_exp,
      skills: transformedSkillsOptions,
      languages: transformedLangOptions,
      addresses:{
        address1: response.addresses.address1,
        address2: response.addresses.address2,
        address3: response.addresses.address3, // City
        //state: response.addresses.state, // State
        country: response.addresses.country, // Country
        postal_code:response.addresses.postal_code,
      },
      price_set_type: response.price_set_type == null ? formik.values.price_set_type : response.price_set_type,// response.price_set_type,
      iscertissue: response.iscertificateissue == null ? formik.values.iscertissue : response.iscertificateissue,
    })
  });
    
},[setclsName]);


// const handleChangeSkills = (selected) => {
//   setSelectedSkillsOptions(selected);
// }
// const handleChangeLang = (selected) => {
//   setSelectedLanguageOptions(selected);
// }

const handleCountryChange = (selectedOption) => {
  formik.setFieldValue('addresses.country', selectedOption.label);
};

const handlePriceTypeChange = (event) => {
 
  //setSelectedOption(event.target.value);
  formik.setFieldValue('price_set_type', event.target.value);
  
  getMentorDefaultPrice(event.target.value).then(response => {    
    formik.setFieldValue('price_range', response[0].rprice);
  });
   
};

    return(
       
            <div className="featured-section-wrap featured-section-wrap-row">
            <div className='mainPageContainer'>        
                <div className='dashboardcontainerflex'>
                  <div className='dashboardcard'>
                  {/* style={{ width: '62rem', height: '70rem' }} */}
                  <Card >
                      <Card.Body>
                      <CustomTooltip text="You can update your personal information, such as your name, skills, language, contact details, pricing, and profile picture, to keep your profile current and accurate." />

                      <Form onSubmit={formik.handleSubmit}>
                        {/* <Card.Title style={{ display: 'inline-block' }}>My Profile </Card.Title> */}
                        <ListGroup variant="flush">
                          <ListGroup.Item> 
                          <Card.Subtitle>Personal Details</Card.Subtitle>
                            <div className="row">
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formFirstName">
                                      <Form.Label>First Name</Form.Label>
                                      <Form.Control required type="text" placeholder="Enter first name" 
                                      name="first_name" 
                                      //value={mentorDetails.first_name}
                                      value={formik.values.first_name}
                                      onChange={formik.handleChange} 
                                      onBlur={formik.handleBlur}
                                      />
                                      <FormErrorMessage
                                          fieldName="first_name"
                                          formik={formik}
                                      ></FormErrorMessage>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formMiddleName">
                                      <Form.Label>Middle Name</Form.Label>
                                      <Form.Control type="text" placeholder="Middle name" 
                                      name="middle_name" 
                                      value={formik.values.middle_name}
                                      onChange={formik.handleChange} 
                                      onBlur={formik.handleBlur}
                                      />
                                      <FormErrorMessage
                                          fieldName="middle_name"
                                          formik={formik}
                                      ></FormErrorMessage>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formLastName">
                                      <Form.Label>Last Name</Form.Label>
                                      <Form.Control required type="text" placeholder="Last name" 
                                      name="last_name" 
                                      value={formik.values.last_name}
                                      onChange={formik.handleChange} 
                                      onBlur={formik.handleBlur}
                                      />
                                        <FormErrorMessage
                                          fieldName="last_name"
                                          formik={formik}
                                      ></FormErrorMessage>
                                    </Form.Group>
                                  </div>
                            </div>
                            <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control required disabled type="email" placeholder="Enter email" 
                                 name="email_address" 
                                 value={formik.values.email_address}
                                 onChange={formik.handleChange} 
                                 onBlur={formik.handleBlur}   
                                />
                                 <FormErrorMessage
                                          fieldName="email_address"
                                          formik={formik}
                                      ></FormErrorMessage>
                                <Form.Text className="text-muted">
                                  We will never share your email with anyone else.
                                </Form.Text>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formMobileNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control required type="text" placeholder="Phone number"  
                                  name="mobile_number" 
                                  value={formik.values.mobile_number}
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur}   
                                />
                                  <FormErrorMessage
                                          fieldName="mobile_number"
                                          formik={formik}
                                      ></FormErrorMessage>
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formAddrss1">
                                <Form.Label>Address</Form.Label>
                                <Form.Control required as="textarea" rows={2} placeholder="Enter address"
                                  name="addresses.address1"
                                  value={formik.values.addresses?.address1 }
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur}   
                                 />
                                 <div
                                    className="invalid-feedback"
                                    style={{
                                        display:
                                        formik.touched.addresses?.address1 && formik.errors.addresses?.address1
                                        ? "block"
                                        : "none",
                                    }}
                                >
                                    {formik.errors.addresses?.address1 ? formik.errors.addresses?.address1 : null}
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Address2 (Optional)</Form.Label>
                                <Form.Control as="textarea" rows={2} placeholder="Enter address"
                                 name="addresses.address2"
                                 value={formik.values.addresses?.address2 }
                                 onChange={formik.handleChange} 
                                 onBlur={formik.handleBlur} 
                                
                                />
                              </Form.Group>
                            </div>
                          </div>
                          <div className="row">
                          <div className="col">
                              <Form.Group className="mb-3" controlId="formcity">
                                <Form.Label>City</Form.Label>
                                <Form.Control required type="text" placeholder="City" 
                                   name="addresses.address3"
                                   value={formik.values.addresses?.address3 }
                                   onChange={formik.handleChange} 
                                   onBlur={formik.handleBlur}                                  
                                />
                                <div
                                    className="invalid-feedback"
                                    style={{
                                        display:
                                        formik.touched.addresses?.address3 && formik.errors.addresses?.address3
                                        ? "block"
                                        : "none",
                                    }}
                                >
                                    {formik.errors.addresses?.address3 ? formik.errors.addresses?.address3 : null}
                                </div>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Country</Form.Label>
                                <Select required
                                      options={fetchedCountryOptions}
                                      value={fetchedCountryOptions.find((option) => option.label === formik.values.addresses?.country)}
                                      onChange={handleCountryChange}
                                      />
                                      {formik.errors.addresses?.country && (
                                          <div>{formik.errors.addresses?.country}</div>
                                      )}
                              </Form.Group>
                            </div>
                           
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Postal Code</Form.Label>
                                <Form.Control required type="text" placeholder="Postal Code" 
                                   name="addresses.postal_code"
                                   value={formik.values.addresses?.postal_code }
                                   onChange={formik.handleChange} 
                                   onBlur={formik.handleBlur}                                  
                                />
                                <div
                                    className="invalid-feedback"
                                    style={{
                                        display:
                                        formik.touched.addresses?.postal_code && formik.errors.addresses?.postal_code
                                        ? "block"
                                        : "none",
                                    }}
                                >
                                    {formik.errors.addresses?.postal_code ? formik.errors.addresses?.postal_code : null}
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                          </ListGroup.Item>
                          <ListGroup.Item> 
                          <Card.Subtitle>Mentorship Details</Card.Subtitle>
                      
                            <div className="row">
                                <div className="col">
                                  <Form.Group className="mb-3" controlId="formShotBiography">
                                    <Form.Label>Short Biography</Form.Label>
                                    <Form.Control required as="textarea" rows={4} placeholder="Enter Short biography" 
                                    name="short_biography"
                                    value={formik.values.short_biography }
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}   
                                    />
                                     <FormErrorMessage
                                          fieldName="short_biography"
                                          formik={formik}
                                      ></FormErrorMessage>
                                  </Form.Group>
                                </div> 
                              </div>
                              <div className="row">
                                <div className="col">
                                  <Form.Group className="mb-3" controlId="formSkills">
                                    <Form.Label>Skills</Form.Label>                                
                                    {/* <Select  isMulti options={skillsOptions} 
                                    value={selectedSkillsOptions} 
                                    onChange={handleChangeSkills} /> */}
                                      <Select  required
                                      options={fetchedSkillsOptions}
                                      isMulti
                                      isSearchable={false}
                                      value={formik.values.skills}
                                      onChange={(selectedOptions) =>
                                          formik.setFieldValue('skills',selectedOptions)
                                      }
                                      />
                                       <FormErrorMessage
                                          fieldName="skills"
                                          formik={formik}
                                      ></FormErrorMessage>
                                  </Form.Group>                                  
                                </div>
                                <div className="col">
                                  <Form.Group className="mb-3" controlId="formLang">
                                    <Form.Label>Language</Form.Label>
                                    <Select required isMulti 
                                    isSearchable={false}
                                    options={fetchedLangOptions} 
                                    value={formik.values.languages}
                                    onChange={(selectedOptions) =>
                                        formik.setFieldValue('languages',selectedOptions)
                                    }
                                    />
                                     <FormErrorMessage
                                          fieldName="languages"
                                          formik={formik}
                                      ></FormErrorMessage>
                                  </Form.Group>
                                </div>                           
                              </div> 
                              <div className="row"> 
                              <div className="col">
                                  <Form.Group className="mb-3" controlId="formTotalExp">
                                    <Form.Label>Total exp.</Form.Label>
                                    <Form.Control required type="text" placeholder="Total exp" 
                                      name="total_exp"
                                      value={formik.values.total_exp }
                                      onChange={formik.handleChange} 
                                      onBlur={formik.handleBlur} 
                                    />
                                     <FormErrorMessage
                                          fieldName="total_exp"
                                          formik={formik}
                                      ></FormErrorMessage>
                                  </Form.Group>
                                </div>
                                <div className="col">
                                  <Form.Group className="mb-3" controlId="formTotalExp">
                                    <Form.Label> </Form.Label>
                                    <Form.Check type="checkbox" 
                                    label="Would you provide Certificate?"
                                    name="iscertissue"
                                    checked={formik.values.iscertissue}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    />
                                  </Form.Group>
                                </div>
                              

                              </div>
                              <div className="row">                            
                                <div className="col">
                                  <Form.Group className="mb-3" controlId="formPriceRang">
                                    <Form.Label>Price</Form.Label>
                                    <table>
                                      <tr>
                                        <td>
                                          <Form.Control required type="text" placeholder="Price" 
                                          disabled={isDisabledPrice}
                                          name="price_range"
                                          value={formik.values.price_range }
                                          onChange={formik.handleChange} 
                                          onBlur={formik.handleBlur}                                      
                                          />
                                            <FormErrorMessage
                                                fieldName="price_range"
                                                formik={formik}
                                            ></FormErrorMessage>
                                        </td>
                                        <td>
                                          {!isDisabledPrice && (
                                            <button type="button" className="button" onClick={() => handlePriceChangeButton() }><span className="bi bi-send"></span> Request</button>
                                          )}
                                           {isDisabledPrice && (
                                            <span className="bi bi-send" style={{color:'red'}}>Under review by Admin</span>
                                            // <button type="button" class="btn btn-primary" onClick={() => handlePriceChangeButton() }><span class="bi bi-send"></span> Request</button>
                                          )}
                                        
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}>
                                        <div className="mb-3" role="group">
                                        <Form.Check
                                              inline
                                              label="Per session"
                                              name="price_set_type"
                                              type="radio"
                                              id="radio-has-user"
                                             // onChange={formik.handleChange}
                                              onChange={handlePriceTypeChange}
                                              checked={formik.values.price_set_type === "PER SESSION"}
                                              value="PER SESSION"
                                              >
                                          </Form.Check>
                                          <Form.Check
                                          inline
                                          label="Per hour"
                                          name="price_set_type"
                                          type="radio"
                                          id="radio-has-user"
                                         // onChange={formik.handleChange}
                                          onChange={handlePriceTypeChange}
                                          checked={formik.values.price_set_type === "PER HOUR"}
                                          value="PER HOUR"
                                          >
                                          </Form.Check>
                                          <br></br>
                                          <Form.Text className="text-muted">
                                                  [Please type your price indication, this will be reviewed by admin team. Once admin reviewed, it will be ready only and cannot be editable further.]
                                          </Form.Text>
                                        </div>

                                        
                                         
                                        </td>
                                      </tr>
                                    
                                    </table>
                                   
                                  </Form.Group>
                                </div>
                               
                              
                              </div> 
                          </ListGroup.Item>         
                          </ListGroup>
                          
                        <Button variant="primary" type="submit" className="button">
                          Submit
                          </Button>
                        
                      </Form>
                      <FacebookShareButton
                      url={profileURL}
                      //quote="Please share this post"
                      hashtag="#Somotus #emotionalintelligence #EmpathySkills #LifeSkills #MentalHealth #SocialEmotionalLearning #criticalThinking #students"
                      >
                        <FacebookIcon className="btn"></FacebookIcon>
                      </FacebookShareButton>

                      <LinkedinShareButton
                      url={profileURL}
                      //quote="Please share this post"
                      hashtag="#Somotus #emotionalintelligence #EmpathySkills #LifeSkills #MentalHealth #SocialEmotionalLearning #criticalThinking #students"
                      >
                        <LinkedinIcon className="btn"></LinkedinIcon>
                      </LinkedinShareButton>

                      <TwitterShareButton
                      url={profileURL}
                      //quote="Please share this post"
                      hashtag="#Somotus #emotionalintelligence #EmpathySkills #LifeSkills #MentalHealth #SocialEmotionalLearning #criticalThinking #students"
                      >
                        <TwitterIcon className="btn"></TwitterIcon>
                      </TwitterShareButton>
                      <InstapaperShareButton
                      url={profileURL}
                      //quote="Please share this post"
                      hashtag="#Somotus #emotionalintelligence #EmpathySkills #LifeSkills #MentalHealth #SocialEmotionalLearning #criticalThinking #students"
                      >
                        <InstapaperIcon className="btn"></InstapaperIcon>
                      </InstapaperShareButton>                          
                      
                      Share your Profile -<a target="_blank" rel="noreferrer" href={profileURL}>{profileURL}</a> 
                      </Card.Body>
                  </Card>
                  </div>
                </div>
                <SnackbarProvider 
                    show={showSnackbar}
                    message={snackbarMessage}
                    onClose={handleCloseSnackbar}
                    message1={clsName}
                    />
            </div>
            </div>
        
      
              
            )

}
export default MentorProfilePage;