import React, { useEffect, useState } from 'react';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import { getEmployees,sendFireBaseNotifications } from '../../../api/corporateApi';
import SearchInputs from '../../common/shared/search/SearchInputs';
import { PropTypes } from "prop-types";
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { addRecommendations } from '../../../api/corporateApi';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function EmployeesListPageLND(props) { //{ onSelectEmployees, initialSelectedEmployees  }
  const classes = useStyles();
  const [employeeList, setEmployeeList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState([]); //initialSelectedEmployees
  
  const [clsName, setclsName] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  //const [showConfirmation, setShowConfirmation] = useState(false);
  //const [rowDataToDelete, setRowDataToDelete] = useState([]);


  const columns = [  
    {
      field: 'selectField',
      headerName: 'Select',
      minWidth: 200,
      renderCell: (params) => (
        <>
         <input
          type="checkbox"
          value={params.row.emailaddress}
         // checked={selectedEmployees.some((e) => e.emailaddress === row.emailaddress)} // Check if the row's id is in selectedEmployees
          onChange={(event) => handleCheckboxChange(event, params.row)}
        />
        </>
      ),
    },
    {field: 'firstname', headerName: 'First Name', minWidth: 150},
      {field: 'emailaddress', headerName: 'Email Address', minWidth: 150},
     

    // {
    //   dataField: 'emailaddress',
    //   text: 'Select',
    //   formatter: (cell, row) => (
    //     <input
    //       type="checkbox"
    //       value={row.emailaddress}
    //      // checked={selectedEmployees.some((e) => e.emailaddress === row.emailaddress)} // Check if the row's id is in selectedEmployees
    //       onChange={(event) => handleCheckboxChange(event, row)}
    //     />
    //   ),
    // },
    // {dataField: 'firstname', text: 'First Name'},
    // // {dataField: 'lastname', text: 'Last Name'},
    // {dataField: 'emailaddress', text: 'Email Address'},
    // // Other columns...
  ];

  useEffect(() => {
    fetchEmployeesData();
  }, []);

  useEffect(() => {
    const filteredResults = employeeList.filter((item) => {
      const emailMatch = item.emailaddress.toLowerCase().includes(emailSearch.toLowerCase());
      return emailMatch;
    });
    setFilteredData(filteredResults);
  }, [employeeList, emailSearch]);

  

  const fetchEmployeesData = () => {
    getEmployees()
      .then((response) => {
        setEmployeeList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckboxChange = (event, employee) => {
    const isChecked = event.target.checked;
  
    console.log('Checkbox Change Event:', isChecked, employee);
  
    if (isChecked) {
      setSelectedEmployees((prevSelectedEmployees) => [...prevSelectedEmployees, employee]);
    } else {
      setSelectedEmployees((prevSelectedEmployees) =>
        prevSelectedEmployees.filter(
          (selectedEmployee) => selectedEmployee.emailaddress !== employee.emailaddress
        )
      );
    }
  };
   
   

  const handleEmailChange = (e) => {
    setEmailSearch(e.target.value);
  };

  const onSubmitClick = () => {
    
    

    let data = [];
    selectedEmployees.forEach((receiver) => {
      const postData = {
        receiverguid: receiver.guid,
        contenttype: props.contentType,
        contentguid: props.contentGUID,
        title: props.contentTitle,
        description: '',                   
      };
      data.push(postData);

    });
    

    data.forEach((dataItem) => {
      addRecommendations(dataItem).then((response)=>{    
      if(!response.ok){
          setclsName('alert error');
          setSnackbarMessage('Error in response');
          setShowSnackbar(true);
      } else{
          //return response.json();
      }
      }).then(response=>{
        console.log(response);
          
        let contentType = dataItem.contenttype;
        let title ='';
        let body ='';
        let postData = null;

        switch (contentType) {
          case "MENTOR":
            postData = {
              type: 'MENTOR',
              guid: dataItem.contentguid,                                                   
            };
            
            title = "Personalized Mentor Recommendations";
            body = "Explore personalized mentor recommendations tailored just for you. Connect with mentors who can help you achieve your goals.";
            break;  
          case "RESOURCE":
            postData = {
              type: 'RESOURCE',
              guid: dataItem.contentguid,                                                   
            };
            title = "Recommendation about Resource";
            body = "Explore curated recommendations for resource. Take your skills to the next level with personalized learning suggestions.";
            break;  
          case "COURSE":
            postData = {
              type: 'COURSE',
              guid: dataItem.contentguid,                                                   
            };
            title = "Recommendation about Courses";
            body = "Explore curated recommendations for courses. Take your skills to the next level with personalized learning suggestions.";
            break;  
          case "WORKSHOPS":
            postData = {
              type: 'WORKSHOPS',
              guid: dataItem.contentguid,                                                   
            };
            title = "Recommendation about Workshops";
            body = "Explore curated recommendations for workshops. Take your skills to the next level with personalized learning suggestions.";
            break; 
          case "SESSIONS":
            postData = {
              type: 'SESSIONS',
              guid: dataItem.contentguid,                                                   
            };
            title = "Recommendation about Mentor Sessions";
            body = "Explore curated recommendations for one-on-one sessions. Take your skills to the next level with personalized learning suggestions.";
            break;
          default:
            title = "New Notification";
            body = "You have received a new notification. Check it out!";
            break;
        }
         
        const postNotifications = {
          guid: dataItem.receiverguid,
          title: title,
          body: body,
          data: postData,  
        };
      
        sendFireBaseNotifications(postNotifications).then((response)=>{    
          if(!response.ok){
              setclsName('alert error');
              setSnackbarMessage('Error in response');
              setShowSnackbar(true);
          } else{
              //return response.json();
          }
          }).then(response=>{
            console.log(response);
           
          }); 
                setclsName('alert success');
                setSnackbarMessage('Successfully assigned.');
                setShowSnackbar(true);
                 
            });

          setclsName('alert success');
          setSnackbarMessage('Successfully assigned.');
          setShowSnackbar(true);
           
      });
  //});

  };
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
  return (
    <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
      <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Employees
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>

    <div>
      <SearchInputs showEmailSearch={true} emailSearch={emailSearch} onEmailChange={handleEmailChange} />
      {/* <BootstrapTable
        keyField="guid"
        data={filteredData}
        columns={columns}
        pagination={paginationFactory()}
        bootstrap4
        striped
        hover
        condensed
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-bordered table-hover"
      /> */}
<div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
    <Button type="button" onClick={onSubmitClick}>
      Assign
    </Button>

    <SnackbarProvider 
                      show={showSnackbar}
                      message={snackbarMessage}
                      onClose={handleCloseSnackbar}
                      message1={clsName}
                      />
    </div>
    </Modal.Body>
        </Modal>
  );
}

EmployeesListPageLND.propTypes ={
  contentType: PropTypes.object.isRequired,  
  contentGUID: PropTypes.object.isRequired, 
  contentTitle: PropTypes.object.isRequired,  
};
export default EmployeesListPageLND;
