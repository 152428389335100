import React from "react";
// import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
// import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
// import Select from 'react-select';
// import { useFormik, Formik } from "formik"
// import * as Yup from "yup";
// import {useQuery, useQueryClient } from "react-query";
// import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import { Link } from "react-router-dom";
// import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import {textFilter} from "react-bootstrap-table2-filter";
// import filterFactory from "react-bootstrap-table2-filter";
 
import ProfileDetails from './ProfileDetails';
import SubscriptionDetails from './SubscriptionDetails';
import PaymentDetails from './PaymentDetails';
import NotificationDetails from './NotificationDetails';
import SessionsListPage from './SessionsListPage';
import WorkshopsListPage from './WorkshopsListPage';
import CoursesListPage from './CoursesListPage';
import ResourcesListPage from './ResourcesListPage';


function MentorDetailsPage(){

     
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
               


                    
                <Card style={{ width: '62rem', height: '65rem' }}>
                    <Card.Body>
                    <Link to="/mentor-list">
                  <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                       Back
                  </Button>
                  </Link>  
                    <Card.Title style={{ display: 'inline-block' }}>Mentor Details</Card.Title>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true">Profile</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="subscription-tab" data-bs-toggle="tab" data-bs-target="#subscription" type="button" role="tab" aria-controls="subscription" aria-selected="false">Subscription</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment" type="button" role="tab" aria-controls="payment" aria-selected="false">Payments</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="notification-tab" data-bs-toggle="tab" data-bs-target="#notification" type="button" role="tab" aria-controls="notification" aria-selected="false">Notifications</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="sessions-tab" data-bs-toggle="tab" data-bs-target="#sessions" type="button" role="tab" aria-controls="sessions" aria-selected="false">Sessions</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="workshops-tab" data-bs-toggle="tab" data-bs-target="#workshops" type="button" role="tab" aria-controls="workshops" aria-selected="false">Workshops</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="courses-tab" data-bs-toggle="tab" data-bs-target="#courses" type="button" role="tab" aria-controls="courses" aria-selected="false">Courses</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="resources-tab" data-bs-toggle="tab" data-bs-target="#resources" type="button" role="tab" aria-controls="resources" aria-selected="false">Resources</button>
                    </li>
                   
                    </ul>
                <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <ProfileDetails />
                </div>
                <div className="tab-pane fade" id="subscription" role="tabpanel" aria-labelledby="subscription-tab">
                    <SubscriptionDetails />
                </div>
                <div className="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                    <PaymentDetails />
                </div>
                <div className="tab-pane fade" id="notification" role="tabpanel" aria-labelledby="notification-tab">
                   <NotificationDetails />
                </div>
                <div className="tab-pane fade" id="sessions" role="tabpanel" aria-labelledby="sessions-tab">
                   <SessionsListPage />
                </div>
                <div className="tab-pane fade" id="workshops" role="tabpanel" aria-labelledby="workshops-tab">
                   <WorkshopsListPage />
                </div>
                <div className="tab-pane fade" id="courses" role="tabpanel" aria-labelledby="courses-tab">
                   <CoursesListPage />
                </div>
                <div className="tab-pane fade" id="resources" role="tabpanel" aria-labelledby="resources-tab">
                   <ResourcesListPage />
                </div>
                
                </div>

                   
                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default MentorDetailsPage