import React, {useEffect, useState } from "react";
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import { getSurveyInvites } from "../../../api/corporateApi";
import { Link,useParams } from "react-router-dom";
import moment from "moment/moment";
import SearchInputs from "../../common/shared/search/SearchInputs";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function SendInvitesPage(){
    const classes = useStyles();

    const { guid } = useParams();  
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const isAnyCheckboxSelected = selectedEmployees.length > 0;
    const [headerData, setHeaderData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');

  //   const dateFormatter = (cell) => {  
  //     if (!cell) {
  //       return <span>{'N/A'}</span>;  
  //     }

  //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
  //     return <span>{formattedDate}</span>;
  // }

    const columns =[
        {
          field: 'selectField',  
          headerName: 'Select',
          minWidth: 150,
          renderCell: (params) => (
            <>
               <input
                 type="checkbox"
                 value={params.row.emailaddress}  
                 onChange={handleCheckboxChange}
                 disabled={!!params.row.sentdate}
               />
            </>
            
          ),
            
          },
        {field: 'firstname', headerName: 'First Name',minWidth: 150},
        // {dataField: 'lastname', text: 'Last Name'},
        {field: 'emailaddress', headerName: 'Email Address',minWidth: 150},
        {field: 'sentdate', headerName: 'Sent Date', minWidth: 150, 
        valueGetter: (params) => moment(params.row.sentdate).format("YYYY-MM-DD HH:mm")
        },
         
    ];

    const handleCheckboxChange = (event) => {
        const employeeId = event.target.value;
        const isChecked = event.target.checked;
      
        if (isChecked) {
          setSelectedEmployees((prevSelectedEmployees) => [...prevSelectedEmployees, employeeId]);
        } else {
          setSelectedEmployees((prevSelectedEmployees) =>
            prevSelectedEmployees.filter((id) => id !== employeeId)
          );
        }
      
       // setIsAnyCheckboxSelected(selectedEmployees.length > 0);
      };
      

      
    useEffect(()=>{
        fetchSurveyInvitesData();
          },[]);
      
      const fetchSurveyInvitesData =() => {
        getSurveyInvites(guid).then(response=>{                    
          setEmployeeList(response); 
          if (response.length > 0) {
            setHeaderData(response[0].title); 
          }
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }

      const handleSendInvite = () => {
        // Call your API here with the selected employee IDs (selectedEmployees)
        // ...
        alert(selectedEmployees);
      };

    useEffect(()=>{
      const filteredResults = employeeList.filter((item) => {
          const emailMatch = item.emailaddress.toLowerCase().includes(emailSearch.toLowerCase());
  
          return emailMatch;
      });
      setFilteredData(filteredResults);
      },[employeeList,emailSearch]);
      
      const handleEmailChange = (e) => {
      setEmailSearch(e.target.value);
      }
        
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                {/* style={{ width: '62rem', height: '53rem' }} */}
                <Card >
                    <Card.Body>
                    <Link to="/survey">
                    <Button variant="primary" type="submit" style={{float:"right"}} >
                        Back
                    </Button>
                    </Link>  
                    <Card.Title style={{ display: 'inline-block' }}>Invitation - {headerData} </Card.Title>
                    <br/> <br/>
                    <SearchInputs
                    showEmailSearch={true}
                    emailSearch={emailSearch}
                    onEmailChange={handleEmailChange}
                    ></SearchInputs>
                    {/* <BootstrapTable 
                    keyField="invitationid"
                    data={filteredData}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory()}
                    bootstrap4
                    striped
                    hover
                    condensed
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-bordered table-hover"
                    /> */}
                    <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.uguid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
               <Button variant="primary" type="submit" onClick={handleSendInvite} disabled={!isAnyCheckboxSelected}>
                Send Invite
                </Button>
                      
                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default SendInvitesPage