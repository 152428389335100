import React, { createContext,useContext, useState} from "react";
import { PropTypes } from "prop-types";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const login =() => {
        setIsAuthenticated(true);
    };
    const logout =() => {
        setIsAuthenticated(false);
    };
    const value ={
        isAuthenticated,
        login,
        logout,
    };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth =() => useContext(AuthContext);
// {
//     const context = React.useContext(AuthContext);
//     if(context === undefined){
//         throw new Error('useAuth must b used within an AuthProvider');
//     }
//     return context;
// };

AuthProvider.propTypes = {    
    children: PropTypes.object.isRequired,
};