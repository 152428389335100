import { apiMthods } from "./apiUtils";
import { getToken } from "./userAuthApi";

//const baseUrl = "http://localhost/somutusAPI/v1/mentors";
//const baseUrl = "https://somotusapi.azurewebsites.net/v1/mentors"; 
//const baseUrl = "http://localhost:3001/v1/mentors"; 
//const baseUrl = process.env.NODE_ENV === 'production' ? "api/v1/mentors" : "http://localhost:3001/v1/mentors"; 

const baseUrl = process.env.NODE_ENV === 'production' ? "http://13.232.221.26/api/v1/mentors" : "http://localhost:3001/v1/mentors"; 

//const baseUrl = "http://13.232.221.26/api/v1/mentors"; 


export async function getMentorDetails(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentordetailsbylogon", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getMentorDetailsbyGUID(payload){
    //const token = await getToken();
    return fetch(baseUrl + "/retrieveMentordetails/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            //Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{       
        console.log(response);
        return response;
        
    });
}

export async function getEvents(){
    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentorSessions", {      
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
           
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );
            return response.json();
        }
     });
    //.then(response=>{
    //     debugger;
    //     return response;
    //     // console.log(response);
    // });
}

export async function addEvent(payload){
     
    const token = await getToken();
    return fetch(baseUrl + "/addMentorSessions", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateEventApi(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateMentorSessions", {
        method: apiMthods.put,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    });
    // .then((response) => {
    //     if(!response.ok){
    //         const err = new Error("Error in response");
    //         throw err;
    //     } else{
    //         return response;
    //     }
    // });
}

export async function deleteEventApi(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteEvent", {
        method: apiMthods.delete,
        headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getConstantsData(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveconstants/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization:  token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}

export async function getMasterData(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMasterData/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}

export async function addCourse(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addMentorCourses",{
    method:apiMthods.post,
    headers:{
        //"content-type": "application/json",
        Authorization: token,
    },
    body: payload,
})
// .then((response)=>{
//     if(!response.ok){
//         const err = new Error("Error in response");
//         throw err;
//     } else{
//         return response.json();
//     }
// }).then(response=>{
//     return response;
// });
}

export async function updateCourse(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateMentorCourses",{
        method:apiMthods.put,
        headers:{
            //"content-type": "application/json",
            Authorization: token,
        },
        body: payload,
    })

}

export async function deleteCourse(payload){
    const token = await getToken();
return fetch(baseUrl + "/deleteMentorCourses/"+payload,{
    method:apiMthods.delete,
    headers:{
        //"content-type": "application/json",
        Authorization: token,
    },
    //body: payload,
})

}

export async function getCourses(){    
    const token = await getToken();
   // handleTokenExpiration();
    return fetch(baseUrl + "/retrieveMentorCourses", {      
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization:  token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getBunnyVideos(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveBunnyVideos", {      
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization:  token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getCoursesDetails(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentorCourses/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization:  token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getResources(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentorResources", {      
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getResourceDetails(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentorResources/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function addResource(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addMentorResources",{
        method:apiMthods.post,
        headers:{
            //"content-type": "application/json",
            Authorization: token,
        },
        body: payload,
    })
}

export async function updateResource(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateMentorResources",{
        method:apiMthods.put,
        headers:{
            //"content-type": "application/json",
            Authorization: token,
        },
        body: payload,
    })

}

export async function deleteResource(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteMentorResources/"+payload,{
        method:apiMthods.delete,
        headers:{
            //"content-type": "application/json",
            Authorization:  token,
        },
        // body: payload,
    })
    
}

export async function addSupportings(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addSupportings",{
        method:apiMthods.post,
        headers:{
            //"content-type": "application/json",
            Authorization: token,
        },
        body: payload,
    })
}

export async function getShorts(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentorShorts", {      
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getShortsDetails(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentorShorts/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function addShorts(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addMentorShorts",{
        method:apiMthods.post,
        headers:{
            //"content-type": "application/json",
            Authorization: token,
        },
        body: payload,
    })
}

export async function updateShorts(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateMentorShorts",{
        method:apiMthods.put,
        headers:{
            //"content-type": "application/json",
            Authorization: token,
        },
        body: payload,
    })

}

export async function deleteShorts(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteMentorShorts",{
        method:apiMthods.delete,
        headers:{
            "content-type": "application/json",
            Authorization:  token,
        },
         body: JSON.stringify(payload),
    })
    
}

export async function getWorkShops(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentorWorkshops", {      
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
           
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );
            return response.json();
        }
     });
    //.then(response=>{
    //     debugger;
    //     return response;
    //     // console.log(response);
    // });
}

export async function getWorkShopsDetails(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentorWorkshops/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}


export async function addWorkShop(payload){     
    const token = await getToken();
    return fetch(baseUrl + "/addMentorWorkshops", {
        method: apiMthods.post,
        headers: {
            //"content-type": "application/json",
            Authorization: token,
        },
        body: payload, //JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateWorkShop(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateMentorWorkshops", {
        method: apiMthods.put,
        headers: {
            //"content-type": "application/json",
            Authorization: token,
        },
        body: payload, //JSON.stringify(payload),
    });
    // .then((response) => {
    //     if(!response.ok){
    //         const err = new Error("Error in response");
    //         throw err;
    //     } else{
    //         return response;
    //     }
    // });
}


export async function cancelWorkshop(payload){   
    const token = await getToken();
    return fetch(baseUrl + "/cancelMentorWorkshops", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function cancelSession(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/cancelMentorSessions", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }) 
    .then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function deleteWorkShop(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteWorkShop", {
        method: apiMthods.delete,
        headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function uploadProfileImage(payload){
    const token = await getToken();
    return fetch(baseUrl + "/uploadProfileImage", {
        method: apiMthods.post,
        headers: {
            // "content-type": "application/json",
            Authorization: token,
        },
        body: payload,
    });
    // .then((response) => {
    //     if(!response.ok){
    //         const err = new Error("Error in response");
    //         throw err;
    //     } else{
    //         return response;
    //     }
    // });
}

export async function updatePriceChange(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/updateMentorPrice", {
        method: apiMthods.put,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateProfile(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updatementorprofile", {
        method: apiMthods.put,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // return response.json();
            return response;
        }
    });
}
 
export async function getNotifications(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveNotificationsByLogon", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getDashboardData(){
    const token = await getToken();
    return fetch(baseUrl + "/myDashboard", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getappAccess(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveAppAccess/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}

export async function getPayments(){
    const token = await getToken();
    return fetch(baseUrl + "/retrievePaymentsByLogon", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getOrgsDashboard(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveOrgsdashboard", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getDashboardStatistics(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveDashboardStatistics", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getConversationsByGUID(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveConversations/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function addConversations(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addConversations", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getEnrollments(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveEnrollmentsByLogon", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getSettlePercentage(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSettlePercentage", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getcustomers(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveCustomers", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getSubscriptions(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSubscriptions", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}


export async function getMentorDefaultPrice(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentorDefaultPrice/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
        return response;
    });
}



export async function deleteFilesPhysically(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteFilesPhysically", {
        method: apiMthods.delete,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}
export async function deleteFile(payload){
    const token = await getToken();
    return fetch(baseUrl + "/1b2203ff-73e4-42f5-aa4f-34034b52e9e8", {
        method: apiMthods.delete,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function sendFireBaseNotifications(payload){
    const token = await getToken();
    return fetch(baseUrl + "/SendFireBaseNotifications", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updatePassword(payload){
    const token = await getToken();
    return fetch(baseUrl + "/resetpassword", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}