import React, { useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
import { Tabs, Tab, Button } from 'react-bootstrap';
import CreateAssessmentPage from './CreateAssessmentPage';
import CreateQuestionPage from './CreateQuestionPage';
import PreviewPage from './PreviewPage';

import { updateQuestion,addEditAssessment,publishAssessment } from "../../../api/corporateApi";
import { useParams,useNavigate,Link } from "react-router-dom";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";

function AssessmentPage() {
  const [clsName, setclsName] = useState('');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('assessments');
  const [assessments, setAssessments] = useState([]);
  //const [questions, setQuestions] = useState([]);
  //const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  const [assessmentGuid, setAssessmentGuid] = useState(null);//40cbcabf-1a91-415a-b742-c838bee13c8d
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  // const [questionCount, setQuestionCount] = useState(null);
  const { guid } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [isModelDisplay,setIsModelDisplay]=useState(false);

  const [isPublishEnabled, setIsPublishEnabled] = useState(false);

  useEffect(()=>{
    if(guid !== undefined){
      setAssessmentGuid(guid);
    }
  },[guid]);
  
  const handleChildQuestionCountChange = (value) => {
     
   if(value >= 5){
    setIsPublishEnabled(true);
    //setIsContinueEnabled(true);
   }
   else{
    setIsPublishEnabled(false);
   }
    
    //setQuestionCount(value);
  }; 

  const handleAssessmentSave = (assessment) => {
    setAssessments((prevAssessments) => [...prevAssessments, assessment]);
    //setIsContinueEnabled(true);
     
     
    addEditAssessment(assessment).then((response)=>{
      if(!response.ok){
        setclsName('alert error');
          setSnackbarMessage('Error in response');
          setShowSnackbar(true);
      } else{
          return response.json();
      }
      }).then(response=>{
          
        setAssessmentGuid(response[0].responseguid);
        setclsName('alert success');
        setSnackbarMessage('Successfully updated assessment data.');
        setShowSnackbar(true);
          
        //history.push(`assessment/${ response[0].responseguid}/edit`);
          // props.onWorkshopSubmit();
      });

  };

  const handleQuestionSave = (question) => {
    
    //setQuestions((prevQuestions) => [...prevQuestions, question]);
    updateQuestion(question).then((response)=>{
      if(!response.ok){
        setclsName('alert error');
          setSnackbarMessage('Error in response');
          setShowSnackbar(true);
      } else{
          //return response.json();
      }
      }).then(response=>{
        console.log(response);
       
        setclsName('alert success');
          setSnackbarMessage('Successfully updated data.');
          setShowSnackbar(true);
          
          // props.onWorkshopSubmit();
      });

    
  };

  // const handleContinue = () => {
  //   setActiveTab('questionConfigurations');
  // };
  const handlePreviewClick = () => {
    
    setIsModelDisplay(true);
    setModalShow(true);
  }
  // const handleGoBack = () => {
  //   setActiveTab('assessments');
  // };
  const handlePublish = () => {    
    publishAssessment(assessmentGuid).then((response)=>{
      if(!response.ok){
        setclsName('alert error');
          setSnackbarMessage('Error in response');
          setShowSnackbar(true);
      } else{
          //return response.json();
      }
      }).then(response=>{
        console.log(response);
        setclsName('alert success');
          setSnackbarMessage('Successfully published.');
          setShowSnackbar(true);

          setTimeout(() => {
            navigate("/assessments");
          }, 5000);
      });
  };
  
  const handleTabSelect = (key) => {
    if (key === 'questionConfigurations' && assessments.length === 0) {
      return;
    }
    setActiveTab(key);
  };
 

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
  return (
    <div className="featured-section-wrap featured-section-wrap-row">
      <div className="mainPageContainer">
        <div className="dashboardcontainerflex">
          <div className="dashboardcard">
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="mb-0">Create Test</h2>
                  <div>
                  {/* className="me-2" */}
                  <Link to="/assessments">
                    <Button variant="primary" type="submit" style={{marginRight:'2px'}} >
                        Back
                    </Button>
                    </Link>  
                    <Button variant="primary" className="button" style={{marginRight:'2px'}} onClick={handlePreviewClick}>
                      Preview
                    </Button>
                    {/*  onClick={handleContinue} disabled={!isContinueEnabled}  */}
                    <Button variant="primary" className="button" onClick={handlePublish}  disabled={!isPublishEnabled}>
                      Publish 
                    </Button>
                  </div>
                </div>
                <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
                  <Tab eventKey="assessments" title="BASIC DETAILS">
                    <CreateAssessmentPage assessmentguid={assessmentGuid} onSave={handleAssessmentSave} setActiveTab={setActiveTab} />
                   
                    {/* {questions.length && questionCount(
                      <></>
                    )} */}
                    
                  </Tab>
                  <Tab eventKey="questionConfigurations" title="QUESTIONS" disabled={assessments.length === 0}>
                      <CreateQuestionPage questionCount={handleChildQuestionCountChange} assessmentguid={assessmentGuid} onSave={handleQuestionSave}  /> 
                      {/* setIsContinueEnabled={setIsContinueEnabled} */}
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>

            {isModelDisplay && 
                <PreviewPage assessmentguid={assessmentGuid} show={modalShow} onHide={() => {setModalShow(false); setIsModelDisplay(false);}}></PreviewPage>
            }

          </div>
        </div>
      </div>
      <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
    </div>
  );
}

export default AssessmentPage;
