import React from "react";
// import { Link as RouterLink } from "react-router-dom";
import { Card } from 'react-bootstrap';
// import Container from 'react-bootstrap/Container';

const SubscriptionExpiredPage = () => {
  return (
    <div className="featured-section-wrap featured-section-wrap-row">
    <div className='mainPageContainer'>        
        <div className='dashboardcontainerflex'>
        <div className='dashboardcard'>
                <Card style={{ width: '62rem', height: '53rem' }}>
                <Card.Body>
                        Subscription has been expired. Please contact Administrator for renew your account.
                </Card.Body>
                </Card>
            </div>
          
        </div>
        
    </div>
    </div>
  );
  };
  
  export default SubscriptionExpiredPage;