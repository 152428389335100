import React, {useState,useEffect  } from "react";
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import { getEmailTemplates } from "../../../api/adminApi"; // deleteMasterdata,
import CreateEmailTemplatePage from './CreateEmailTemplatePage';
import EditEmailTemplatePage from './EditEmailTemplatePage';
import Modal from 'react-bootstrap/Modal';
// import { getMasterData } from "../../../api/mentorApi";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function EmailTemplateSetupPage(){
  const classes = useStyles();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [dataList, setDataList] = useState([]);   
    const [modalShow, setModalShow] = useState(false);
    const [isEditModelDisplay,setIsEditModelDisplay]=useState(false);
    const [isCreateModelDisplay,setIsCreateModelDisplay]=useState(false);

    const [guid, setGuid] = useState(null);
    const [description, setDescription] = useState(null);
    const [template, setTemplate] = useState(null);

    // const [rowDataToDelete, setRowDataToDelete] = useState(null);

    // const ActionColumnFormatter = (cell, row) => {
        
    //     if (cell) {
    //       return (
    //         <>
    //         <i
    //           className="bi bi-pencil-fill"
    //           onClick={() => handleEditClick(row)}
    //           style={{ paddingRight: "10px" }}
    //         />
    //         <i className="bi bi-trash" 
    //         onClick={() => handleDeleteClick(row)}
    //           style={{ paddingRight: "10px" }}
    //           />
    //         </>
            
    //       );
    //     }
    //   }

    const handleDeleteClick = (row) => {
      console.log(row);
       // setRowDataToDelete(row);
        setShowConfirmation(true);
    };

    const handleConfirmDelete = () => {
        // const updatedDataToDelete = {
        //     ...rowDataToDelete,
        //     type: 'CAT',
        //   };

        // deleteMasterdata(updatedDataToDelete).then((response)=>{    
        //     if(!response.ok){
        //         // const err = new Error("Error in response");
        //         // throw err;
        //         setSnackbarMessage('Error in response');
        //         setShowSnackbar(true);
        //     } else{
        //         //return response.json();
        //     }
        //     }).then(response=>{
        //       console.log(response);
               
        //         //return response;
        //         setSnackbarMessage('Successfully deleted category data.');
        //         setShowSnackbar(true);
        //         fetchMasterData();
        //     });
        setSnackbarMessage('Successfully deleted category data.');
        setShowSnackbar(true);
        setShowConfirmation(false);
    };

    const handleCancelDelete = () => {
        setShowConfirmation(false);
    }; 

    const handleEditClick = (row)=>{   
        setGuid(row.guid); 
        setDescription(row.description); 
        setTemplate(row.template); 
        setIsEditModelDisplay(true);
        setModalShow(true);
      }

    // const handleCreateMasterdata = ()=>{
    // setModalShow(true);
    // setIsCreateModelDisplay(true);
    // }
     
    const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    }  

    const columns =[
      {field: 'templateid', headerName: '#', minWidth: 150},
      {field: 'description', headerName: 'Description', minWidth: 150},
      // {field: 'template', headerName: 'Template', minWidth: 150},
      {
        field: 'template',
        headerName: 'Template',
        minWidth: 200,
        renderCell: (params) => (
          <div
            className="text-truncate"
            style={{ maxWidth: '200px' }}
            title={params.value}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: 'guid',
        headerName: 'Actions',
        minWidth: 200,
        renderCell: (params) => (
          <>
        <i
              className="bi bi-pencil-fill"
              onClick={() => handleEditClick(params.row)}
              style={{ paddingRight: "10px" }}
            />
            <i className="bi bi-trash" 
            onClick={() => handleDeleteClick(params.row)}
              style={{ paddingRight: "10px" }}
              />
          </>
        ),
      },

      // {dataField: 'templateid', text: '#'},
      // {dataField: 'description', text: 'Description'},
      // {
      //   dataField: 'template', 
      //   text: 'Template',
      //   formatter: (cellContent) => (     //, row      
      //     <div className="text-truncate" style={{maxWidth: '200px'}} title={cellContent}>
      //       {cellContent}
      //     </div>
      //   ),
      //   // editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
      //   //   <input type="text" {...editorProps} defaultValue={value} />
      //   // ),
      // },
      // {
      //       dataField: 'guid',
      //       text: 'Actions',
      //       formatter: ActionColumnFormatter,
      // },
    ];
    
    useEffect(()=>{
        fetchEmailTemplatesData();
  
      },[]);
      
    const fetchEmailTemplatesData =() => {
      getEmailTemplates().then(response => {
        setDataList(response);
        });
    }

      const handleReloadUpdateEvents = () => {       

        // setIsEditModelDisplay(false);
        // setModalShow(false);
        // setSnackbarMessage('Successfully updated Skills data.');
        // setShowSnackbar(true);
        fetchEmailTemplatesData();
      };

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
              <Card style={{ width: '62rem', height: '53rem' }}>
              <Card.Body>
                  
                    <div className="row">
                        <div className="col">
                        <Card.Subtitle>Email Templates Setup</Card.Subtitle>
                        <div style={{float:"right"}}> &nbsp;</div>  
                            {/* <BootstrapTable 
                            keyField="id"
                            data={dataList}
                            columns={columns}
                            // cellEdit={cellEditFactory(cellEditOptions)}
                            pagination={paginationFactory()}
                            // filter={filterFactory()}
                            /> */}
                            <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={dataList}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
                        </div>
                    </div>
                    
                     

                    {isCreateModelDisplay && 
                        <CreateEmailTemplatePage onMasterSubmit={handleReloadUpdateEvents} show={modalShow} onHide={() => {setModalShow(false); setIsCreateModelDisplay(false);}}  ></CreateEmailTemplatePage>
                    }
                    {isEditModelDisplay && 
                        <EditEmailTemplatePage onMasterSubmit={handleReloadUpdateEvents} guid={guid} description={description} template={template} show={modalShow} onHide={() => {setModalShow(false); setIsEditModelDisplay(false);}}  ></EditEmailTemplatePage>
                    }
 

                 </Card.Body>
                  </Card>
                </div>
            </div>
            <Modal show={showConfirmation} onHide={handleCancelDelete}>
            <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                Cancel
            </Button>
            <Button variant="danger" className="button" onClick={handleConfirmDelete}>
                Delete
            </Button>
            </Modal.Footer>
            </Modal>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
            </div>
        </div>
        </div>        

    )

}

export default EmailTemplateSetupPage