import React,{useEffect , useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import Select from 'react-select';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import { getMasterData, getCoursesDetails,updateCourse,deleteFilesPhysically,deleteFile } from "../../../api/mentorApi";
import Modal from 'react-bootstrap/Modal';

function MentorCoursesEditPage(){
  const [clsName, setclsName] = useState('');
  const { id } = useParams();
  const[categoryOptions,setCategoryOptions] = useState([]);
  const [fetchedLangOptions, setFetchedLangOptions] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const fileInputRef = useRef(null);
  const documentfileInputRef = useRef(null);
  const [options, setOptions] = useState([{title: '', link: '' }]);
  const [optionErrors, setOptionErrors] = useState(Array(options.length).fill({ title: '', link: '' }));
  const [isAtLeastOneOptionAdded, setIsAtLeastOneOptionAdded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rowDataToDelete, setRowDataToDelete] = useState(null);

  const suitableForOptions = [ 
    { value: "1", label: "For Corporate" },
    { value: "2", label: "For Institutional" },    
  ];

  const initialValues = {
    id:'',
    overviews: '',
    objectives: '',
    duration: '',
    displayPrice: '',
    categoryItems: [],
    suitableForItems: [],
    videoLinks: [],
    bannerImage: null,
    documentUpload: null,
    bannerImagePath: '',
    fileitems:[],
    languageItems: [],
  }

  // const isValidUrl = (url) => {
  //   const youtubePattern = /youtube\.com/;
  //   return !youtubePattern.test(url);
  // }
  const isValidUrl = (url) => {
    const validDomain = 'vz-0970798c-105.b-cdn.net';
    const urlPattern = /^https?:\/\/vz-0970798c-105\.b-cdn\.net\//;
    return urlPattern.test(url) && url.includes(validDomain);
  };
  const  validationSchema = Yup.object({
    // videoLinks: Yup.array().of(
    //   Yup.object().shape({
    //     title: Yup.string().required('Video title is required.'),
    //     link: Yup.string()
    //       .required('Video link is required.')
    //       .url('Invalid URL')
    //       .test('youtube-link', 'YouTube links are not allowed', (value) => {
    //         return !value.includes('youtube.com');
    //       }),
    //   })
    // ),
    title: Yup.string()
    .required("Title is required."),
    categoryItems: Yup.array().min(1, 'Please select at lease one option'),
    suitableForItems: Yup.array().min(1, 'Please select at lease one option'),
    languageItems: Yup.array().min(1, 'Please select at lease one option'),
    overviews: Yup.string()
    .required("Overviews is required."),
    objectives: Yup.string()
    .required("Objectives is required."),
    duration: Yup.string()
    .required("Duration is required."),
    // displayPrice: Yup.string()
    // .required("Display price is required."),

    displayPrice: Yup.number()
    .typeError('Display price must be a number.')
    //.positive('Display price must be a positive number.')
    .required('Display price is required.'),

    bannerImage: Yup.mixed()
    //.required('Banner image is required.')
    .test('fileSize', 'File size must be less than 3MB', (value) => {
      if (!value) return true; // No file uploaded, so validation passes
      const fileSizeLimit = 3 * 1024 * 1024; // 3MB in bytes
      return value.size <= fileSizeLimit;
    })
    .test('fileType', 'Only JPEG, PNG, and GIF images are allowed.', (value) => {
      if (!value) return true;  

      const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      return allowedImageTypes.includes(value.type);
    }),
    documentUpload: Yup.mixed()
    .test('fileType', 'Only PDF, spreadsheet, and DOC files are allowed.', (value) => {
      if (!value) return true; // No file selected, so validation passes

      const allowedFileTypes = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      return allowedFileTypes.includes(value.type);
    })
    .test('fileSize', 'File size must be less than 10MB', (value) => {
      if (!value) return true; // No file uploaded, so validation passes
      const fileSizeLimit = 10 * 1024 * 1024; // 10MB in bytes
      return value.size <= fileSizeLimit;
    })
    .nullable(),
  }); 

 
  const handleSubmit = (values) => {
     // Check if at least one row item exists
    const hasAtLeastOneRow = options.some(option => option.title.trim() !== '' || option.link.trim() !== '');

    if (!hasAtLeastOneRow) {
      // Display an error message or prevent submission.
      setclsName('alert error');
      setSnackbarMessage('Please add at least one row with proper data.');
      setShowSnackbar(true);
    } else {
      // Check each option for title and link validation
      const invalidOptions = options.filter(option => option.title.trim() === '' || option.link.trim() === '');

      if (invalidOptions.length > 0) {
        // Display an error message or prevent submission.
        setclsName('alert error');
        setSnackbarMessage('Please ensure all rows have both title and link filled.');
        setShowSnackbar(true);
      } else {
        // Submit the form because at least one row exists and all rows have valid data.
        
      try {
        const hasOptionErrors = optionErrors.some((error) => error.link);
        
        if (!isAtLeastOneOptionAdded) {
          setclsName('alert error');
          setSnackbarMessage('Please add at least one video option before submitting.');
          setShowSnackbar(true);
        }
        else if (hasOptionErrors) {
          setclsName('alert error');
          setSnackbarMessage('Please fix the video link errors before submitting.');
          setShowSnackbar(true);
        } else {

        formik.setFieldValue('videoLinks',options);

        const formDataToSend = new FormData();
        formDataToSend.append("id",values.id);
        formDataToSend.append("title",values.title);
        formDataToSend.append("overviews",values.overviews);
        formDataToSend.append("objectives",values.objectives);
        formDataToSend.append("displayPrice",values.displayPrice);
        formDataToSend.append("duration",values.duration);    
        formDataToSend.append("categoryItems",JSON.stringify(values.categoryItems));
        formDataToSend.append("suitableForItems",JSON.stringify(values.suitableForItems));
        formDataToSend.append("languageItems",JSON.stringify(values.languageItems));
        formDataToSend.append("videoLinks",JSON.stringify(values.videoLinks));
      
        formDataToSend.append("file",values.bannerImage);
  
         
       
        // const fileInput = fileInputRef.current;
        // if (fileInput.files.length > 0) {
        //   alert("New file selected");
          
        //   alert(formik.values.bannerImagePath);
        // }
        // else{
        //   alert("No file selected");
        // }
        

        const files = documentfileInputRef.current.files;
    
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          formDataToSend.append("documentfile",file);
        } 

        updateCourse(formDataToSend).then((response)=>{
          if(!response.ok){
              // const err = new Error("Error in response");
              // throw err;
              setclsName('alert error');
              setSnackbarMessage('Error in response');
              setShowSnackbar(true);
          } else{
              //return response.json();
          }
          }).then(response=>{
            console.log(response);
              //return response;

              const fileInput = fileInputRef.current;
              if (fileInput.files.length > 0) {              
                handledeleteFileItem(formik.values.bannerImagePath);
              }
              // else{
                
              // }
              fetchCourseData();
              setclsName('alert success');
              setSnackbarMessage('Successfully updated course data.');
              setShowSnackbar(true);
              //formik.resetForm();
              fileInputRef.current.value = null;
              documentfileInputRef.current.value = null;
          });
        }
      }
      catch (error){
        console.log(error);
      }
    }
  }
  }
  

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
   
  useEffect(()=>{
    getMasterData("CAT").then(response => {
      const transformedOptions = response.map(option => ({
        label: option.label,
        value: option.value
      }));
      setCategoryOptions(transformedOptions);
    });
    getMasterData("LAN").then(response => {
      const transformedOptions = response.map(option => ({
        label: option.label,
        value: option.value
      }));
      setFetchedLangOptions(transformedOptions);
    });

    fetchCourseData();
    // getCoursesDetails(id).then(response=>{    
    //   formik.setValues({
    //     id:id,
    //     title: response.title,
    //     overviews: response.overview,
    //     objectives: response.objectives,
    //     categoryItems:response.categoryitems,
    //     suitableForItems:response.suitableitems,
    //     duration: response.duration,
    //     displayPrice: response.displayprice,
    //     videoLinks: response.videoitems,
    //     bannerImagePath: response.bannerimage,
    //     fileitems: response.fileitems,
    //   })
      
    //   if (response.videoitems && response.videoitems.length > 0) {
    //     setIsAtLeastOneOptionAdded(true);
    //   } else {
    //     setIsAtLeastOneOptionAdded(false);
    //   }
    //   setOptions(response.videoitems);
       
      
    // })

  },[id]);

  const fetchCourseData =() => {
    getCoursesDetails(id).then(response=>{    
      const transformedLangOptions = (response.languageitems || [])
      .filter(option => option)  
      .map(option => ({
        label: option.label,
        value: option.value
      }));

      formik.setValues({
        id:id,
        title: response.title,
        overviews: response.overview,
        objectives: response.objectives,
        categoryItems:response.categoryitems,
        suitableForItems:response.suitableitems,
        duration: response.duration,
        displayPrice: response.displayprice,
        videoLinks: response.videoitems,
        bannerImagePath: response.bannerimage,
        fileitems: response.fileitems,
        languageItems: transformedLangOptions,
      })
      
      if (response.videoitems && response.videoitems.length > 0) {
        setIsAtLeastOneOptionAdded(true);
      } else {
        setIsAtLeastOneOptionAdded(false);
      }
      setOptions(response.videoitems);
       
      
    });
  }

   const handleBannerImageChange = event => {
    console.log(event);
    const file = fileInputRef.current.files[0];
    formik.setFieldValue('bannerImage', file);     
  }

  const handleDocumentUploadChange = event => {
    console.log(event);
    const file = documentfileInputRef.current.files[0]; 
    formik.setFieldValue('documentUpload', file);
  }

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
  
  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
    const hasOptionsLeft = updatedOptions.length > 0;
    setIsAtLeastOneOptionAdded(hasOptionsLeft);
  };

  const handleAddOption = () => {
    setOptions([...options, { title: '', link: ''  }]);
    //setIsAtLeastOneOptionAdded(true);
  };

  
  const handleOptionTextChange = (e, index) => {
    const updatedOptions = [...options];
    updatedOptions[index].link = e.target.value;
    setOptions(updatedOptions);
    formik.setFieldValue('videoLinks', options);
  
    if (!isValidUrl(e.target.value)) {
      const newErrors = [...optionErrors];
      newErrors[index] = { ...newErrors[index], link: 'Only links from  Bunny.net https://vz-0970798c-105.b-cdn.net are allowed.' };
      setOptionErrors(newErrors);
    } else {
      const newErrors = [...optionErrors];
      newErrors[index] = { ...newErrors[index], link: '' };
      setOptionErrors(newErrors);
      setIsAtLeastOneOptionAdded(true);
    }
  };
  
  
  const handleOptionTitleTextChange = (e, index) => {
    const updatedOptions = [...options];
    updatedOptions[index].title = e.target.value;
    setOptions(updatedOptions);
    formik.setFieldValue('videoLinks',options);
  };

  const openBannerModal = () => {
    setSelectedImage(formik.values.bannerImagePath);
  };
  // const deleteBannerImage = () => {
  //   //formik.setFieldValue('bannerImage', null);
  //   formik.setFieldValue('bannerImagePath', ''); // Clear the image path if needed
  // };
  // const deleteFileItem = (index) => {
  //   const updatedFileItems = [...formik.values.fileitems];
  //   updatedFileItems.splice(index, 1);
  //   formik.setFieldValue('fileitems', updatedFileItems);
  // };
  const handleDeleteFile = (index) => {
    setRowDataToDelete(index);
    handleDeleteClick();
  };

  const handleDeleteClick = () => {
    //setRowDataToDelete(row);
    setShowConfirmation(true);
};

const handleConfirmDelete = () => {
    
  const updatedFiles = [...formik.values.fileitems];
  //const deletedFile = updatedFiles[rowDataToDelete];
  updatedFiles.splice(rowDataToDelete, 1); // Remove the file at the specified index
  formik.setFieldValue('fileitems', updatedFiles);

  const fileId = formik.values.fileitems[rowDataToDelete].fileid;
  //alert(fileId);
 // deleteFilesPhysically
 const formDataToSend = {
  id: id,
  guid:null,
  fileid: fileId,
  type: 'COURSES',
  };
 deleteFilesPhysically(formDataToSend).then((response)=>{
  if(!response.ok){
      setclsName('alert error');
      setSnackbarMessage('Error in response');
      setShowSnackbar(true);
  } else{
      //return response.json();
  }
  }).then(response=>{
    console.log(response);
      setclsName('alert success');
      setSnackbarMessage('Successfully deleted file.');
      setShowSnackbar(true);
       
  });

    setShowConfirmation(false);
    setRowDataToDelete(null);
};

const handledeleteFileItem = (filepath) => {
  const formDataToSend = {
    filepath:filepath
    };
   deleteFile(formDataToSend).then((response)=>{
    console.log(response);
    }).then(response=>{
      console.log(response);
    });
}

const handleCancelDelete = () => {
   setRowDataToDelete(null);
    setShowConfirmation(false);
}; 

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card>
                  <Card.Body>
                   
                    {/* <Card.Title style={{ display: 'inline-block' }}>Update Course</Card.Title> */}
                    <Form onSubmit={formik.handleSubmit}>
                    <div className="row">
                    <div className="col" style={{float:"right"}}>
                            <Link to="/courses">
                            <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                                Back
                            </Button>
                            </Link>
                            </div>
                    </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control required type="text" placeholder="Enter title" 
                                name="title"  
                                value={formik.values.title}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="title"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBasicCategory">
                                <Form.Label>Category</Form.Label>                                
                                <Select required isMulti
                                options={categoryOptions}
                                value={formik.values.categoryItems}
                                //onChange={handleCategoryChange}
                                onChange={(selectedOptions) =>
                                  formik.setFieldValue('categoryItems',selectedOptions)
                                }
                                />
                                 <FormErrorMessage
                                          fieldName="categoryItems"
                                          formik={formik}
                                      ></FormErrorMessage>                              
                              </Form.Group>
                            </div>                           
                          </div>
                          <div className="row">
                            <div className="col">
                            <Form.Group className="mb-3" controlId="formSuitableFor">
                                <Form.Label>Suitable For</Form.Label>  
                                <Select required isMulti
                                options={suitableForOptions}
                                value={formik.values.suitableForItems}
                                //onChange={handleCategoryChange}
                                onChange={(selectedOptions) =>
                                  formik.setFieldValue('suitableForItems',selectedOptions)
                                }
                                />
                                 <FormErrorMessage
                                      fieldName="suitableForItems"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                                  <Form.Group className="mb-3" controlId="formLang">
                                    <Form.Label>Course Languages</Form.Label>
                                    <Select required isMulti 
                                    isSearchable={false}
                                    options={fetchedLangOptions} 
                                    value={formik.values.languageItems}
                                    onChange={(selectedOptions) =>
                                        formik.setFieldValue('languageItems',selectedOptions)
                                    }
                                    />
                                     <FormErrorMessage
                                          fieldName="languageItems"
                                          formik={formik}
                                      ></FormErrorMessage>
                                  </Form.Group>
                                </div> 
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formOverviews">
                                <Form.Label>Overviews</Form.Label>
                                <Form.Control required as="textarea" rows={4} placeholder="Enter overviews" 
                                name="overviews"  
                                value={formik.values.overviews}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                />
                                 <FormErrorMessage
                                      fieldName="overviews"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>                            
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formobjectives">
                                <Form.Label>Objectives</Form.Label>
                                <Form.Control required as="textarea" rows={4} placeholder="Enter objectives" 
                                name="objectives"  
                                value={formik.values.objectives}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                />
                                 <FormErrorMessage
                                      fieldName="objectives"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>                            
                          </div>
                          
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDuration">
                                <Form.Label>Duration</Form.Label>
                                <Form.Control required type="text" placeholder="Enter duration" 
                                 name="duration"  
                                 value={formik.values.duration}
                                 onChange={formik.handleChange} 
                                 onBlur={formik.handleBlur} 
                                />
                                 <FormErrorMessage
                                      fieldName="duration"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDisplayPrice">
                                <Form.Label>Display Price</Form.Label>
                                <Form.Control required type="text" placeholder="Enter display price" 
                                 name="displayPrice"  
                                 value={formik.values.displayPrice}
                                 onChange={formik.handleChange} 
                                 onBlur={formik.handleBlur} 
                                />
                                  <FormErrorMessage
                                      fieldName="displayPrice"
                                      formik={formik}
                                  ></FormErrorMessage>
                                    <Form.Text className="text-muted">
                                    Enter Zero to make the course available for FREE
                                    {/* Leave blank to make the course available for FREE */}
                                </Form.Text>
                              </Form.Group>
                            </div>
                            
                          </div> 
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBannerImage">
                                <Form.Label>Banner Image</Form.Label>
                                <Form.Control type="file"  
                                 accept=".jpeg, .png, .gif, .jpg"
                                onChange={handleBannerImageChange} 
                                name="BannerImage" 
                                ref={fileInputRef}
                                // value={bannerImage} 
                                />
                                  <FormErrorMessage
                                      fieldName="bannerImage"
                                      formik={formik}
                                  ></FormErrorMessage>
                                {formik.values.bannerImagePath && (
                                <div>
                                  <i className="bi bi-eye-fill" title='' onClick={() => openBannerModal()} />
                                  {/* <i className="bi bi-trash-fill" title="Delete" onClick={deleteBannerImage} /> */}
                                </div>
                              )}
                              </Form.Group>
                            </div>
                             
                          </div>
                         
                      <hr/>
                      <div className="row">
                       
                          <div className="col">
                         <span style={{fontWeight:"bold"}}> Video Curriculum  All the media files saved at <a href="https://bunny.net/" target="_blank" rel="noreferrer">Bunny.net</a> cloud (Safe and Secure)</span>
                            {options.map((option, index) => (
                            <div className="row" key={index}>
                              <div className="col">
                                <Form.Group className="mb-3" controlId={`formLinkTitle_${index}`}>
                                  <Form.Label>Video Title</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter video title"
                                    name={`videoLinks[${index}].title`}
                                    autoComplete="off"
                                    value={option.title}
                                    onChange={(e) => handleOptionTitleTextChange(e, index)}
                                    onBlur={(e) => handleOptionTitleTextChange(e, index)}
                                    onSelect={(e) => handleOptionTitleTextChange(e, index)}
                                  />
                                 
                                  {/* <FormErrorMessage
                                    fieldName={`videoLinks[${index}].title`}
                                    formik={formik}
                                  /> */}
                                </Form.Group>
                              </div>
                              <div className="col">
                                <Form.Group className="mb-3" controlId={`formRedirectLink_${index}`}>
                                  <Form.Label>Video Link</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter video URL"
                                    name={`videoLinks[${index}].link`}
                                    autoComplete="off"
                                    value={option.link}
                                    onChange={(e) => handleOptionTextChange(e, index)}
                                    onBlur={(e) => handleOptionTextChange(e, index)}
                                    onSelect={(e) => handleOptionTextChange(e, index)}
                                  />
                                   
                                  {/* <FormErrorMessage
                                    fieldName={`videoLinks[${index}].link`}
                                    formik={formik}
                                  /> */}
                                </Form.Group>
                              </div>
                              <div className="col">
                              {index !== 0 && (  // Check if it's not the first row
                                <Button
                                className="button"
                                variant="primary"
                                  onClick={() => handleRemoveOption(index)}
                                >
                                  Delete
                                </Button>
                              )}
                              </div>
                            </div>
                          ))}

                          {optionErrors && optionErrors.map((optionError, index) => (
                            <div key={index}>
                              {optionError && (
                                <>
                                  {/* {optionError.title && <div className="error">{optionError.title}</div>} */}
                                  {optionError.link && <div className="error">{optionError.link}</div>}
                                </>
                              )}
                            </div>
                          ))}

                            <div className="row">
                              <div className="col">
                                <Button variant="primary"  className="button"
                                onClick={handleAddOption}
                                >
                                  + (more video)
                                </Button>
                              </div>
                            </div>
                          </div>
                       

                      </div>   
                      <div className="row">
                            <div className="col">
                             
                                <Form.Group className="mb-3" controlId="formDocumentUpload">
                                <Form.Label>Attach Activity files (we recommend 2 files, PDF, Speadsheet, Doc)</Form.Label>
                                {/* <Form.Control type="file"  
                                onChange={handleDocumentUploadChange} 
                                name="documentUpload" 
                                ref={documentfileInputRef}
                                /> 
                                 // ${window.location.origin}/
                                */}
                                <Form.Control type="file" multiple 
                                  accept=".doc, .docx, .pdf, .xlsx"
                                onChange={handleDocumentUploadChange} 
                                name="documentUpload" 
                                ref={documentfileInputRef} />
                                   <FormErrorMessage
                                      fieldName="documentUpload"
                                      formik={formik}
                                  ></FormErrorMessage>
                                <div className="row">
                                <div className="col">
                                  
                                {formik.values.fileitems && formik.values.fileitems.map((fileItem, index) => (
                                  <div key={index}>
                                    <a href={fileItem.path} target="_blank" rel="noopener noreferrer">
                                      {fileItem.title}
                                    </a>
                                    <Button variant="primary" style={{marginBottom:'5px',marginTop:'5px',marginLeft:'5px'}} size="sm" onClick={() => handleDeleteFile(index)}>
                                    <span className="bi bi-trash"></span>  
                                  </Button>
                                  </div>
                                ))}


                              {/* <button onClick={() => deleteFileItem(index)}>Delete</button> */}
                                </div>
                              </div>

                              </Form.Group>
                            </div>
                          </div>    
                           <hr />
                          <Button variant="primary" className="button" type="submit">
                          Submit
                          </Button>                 
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
            {selectedImage && (
                        <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
                          <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Image Preview</h5>
                                <button type="button" className="close" onClick={() => setSelectedImage(null)}>
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                {/* <img src={`data:image/png;base64, ${selectedImage}`} 
                                alt="Selected" 
                                className="img-fluid" 
                                /> */}
                                <img src={selectedImage} className='card-img-top' alt="Card image cap"
                              style={{ width: '100%', height: '100%', borderRadius: '50%'}} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />

            <Modal show={showConfirmation} onHide={handleCancelDelete}>
                                <Modal.Header closeButton>
                                <Modal.Title>Confirm Delete</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                                    Cancel
                                </Button>
                                <Button variant="primary" className="button" onClick={handleConfirmDelete}>
                                    Delete
                                </Button>
                                </Modal.Footer>
            </Modal>
            </div>

        </div>
        </div>
          );

}
export default MentorCoursesEditPage;