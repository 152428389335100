import React from "react";
import { Card } from 'react-bootstrap';

function RefundPage(){
   
    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card>
                  <Card.Body>
                  <p> Refunds & Cancellations</p>
                 
                  <p></p>Most of the services we offer fall under the services industry. As such, we do not offer any returns on any of our services. However, we do offer refunds and cancellations based on certain conditions if you request for the same within 3 Days of Payment. If you have not used our service, refund will be made.

                  <p></p>Our service includes, 1-1 mentoring, career coaching, workshops, and access to all our course contents. 

                  <p></p>Please go through our Refunds and Cancellations policy carefully. Make sure to read this page thoroughly before registering for any of our services.

                  <p></p>We do not offer any refunds for cancellations made after registration and payment have been made for the services/mentoring and course access. However, in special cases such as factual errors, duplicate payments, mistaken denominations, and other inaccurate payment deductions where the payee faces unreasonable loss, we offer refunds.
                  <p></p>We offer refunds only by facts regarding a specific case (between 3-5 days of the transaction). We reserve the right to refuse refunds for any reason whatsoever, as we deem fit.
                  <p></p>We do not offer refunds once you Login and access our courses.
                  <p></p>All refunds will only be made to the same account or card from which payments were made initially.
                  <p></p>Refunds will only be processed after a thorough verification process related to the credentials of the account holder.
                  <p></p>We do not entertain any cheque or cash refunds. We only offer online refunds.
                  <p></p>Any extra charges borne by the customer such as bank transaction fees, service fees or processing fees will not be refunded.
                  <p></p>We do not entertain multiple return requests for the same order. Customers requesting for multiple refunds will be blocked from accessing our website.
                  <p></p>We do not pay any interest on refunds.
                  <p></p>We will not be held responsible if the customer fails to receive our communication due to incomplete or incorrect contact details.
                  <p></p>Customers should produce valid documentation including a valid invoice, email proof to claim a refund.
                  <p></p>We reserve the right to update the contents of this Refunds and Cancellation policy from time to time without prior notice. Make sure to keep updated before you register for any of our services. 

                  <p></p>If you have any questions or doubts related to our Refunds & Cancellations, please feel free to get in touch with us. You can email us at hello@somotus.com 
                  

                  </Card.Body>
                </Card>
              </div>
            </div>
            

            

        </div>
        </div>
          );

}
export default RefundPage;