import React, {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import  Button  from "react-bootstrap/Button";

import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { addUser,getDivisions,getRoles } from "../../../api/corporateApi";
import { useFormik} from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import PropTypes from "prop-types";
import Select from 'react-select';

function CreateUserPage(props){
  const [clsName, setclsName] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [fetchDivisionsOptions, setFetchDivisionsOptions] = useState([]);
    const [fetchRolesOptions, setFetchRolesOptions] = useState([]);

    const initialValues = {  
        emailaddress: '',
        firstname:'',
        lastname:'',
        mobilenumber:'',
        password:'',
        divisionid:'',
        roleid:''
      }
    
      const  validationSchema = Yup.object({   
        divisionid: Yup.string()
        .required("Division is required."),
        roleid: Yup.string()
        .required("Role is required."),
        emailaddress: Yup.string()
        .required("Email address is required."),
        firstname: Yup.string()
        .required("First name is required."),
        lastname: Yup.string()
        .required("Last name is required."),
        password: Yup.string()
        .required("Password is required."),
      
      }); 
      const handleSubmit = (values) => { 
        addUser(values).then((response)=>{    
          if(!response.ok){
            setclsName('alert error');
              setSnackbarMessage('Error in response');
              setShowSnackbar(true);
          } else{
              //return response.json();
          }
          }).then(response=>{
            console.log(response);
             
              //return response;
              setclsName('alert success');
              setSnackbarMessage('Successfully added user data.');
              setShowSnackbar(true);
              formik.resetForm();
              props.onMasterSubmit();
          });
      }
      const handleCloseSnackbar = () => {
        setShowSnackbar(false);
      }
      const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
      });
    
      useEffect(()=>{
       
        getDivisions().then(response => {
          const transformedOptions = response.map(option => ({
            label: option.divisionname,
            value: option.guid
          }));
          setFetchDivisionsOptions(transformedOptions);
        });

        getRoles().then(response => {
          const transformedOptionsRoles = response.map(option => ({
            label: option.rolename,
            value: option.guid
          }));
          setFetchRolesOptions(transformedOptionsRoles);
        });
      
      
        
      },[]);

      const handleDivisionChange = (selectedOption) => {
        formik.setFieldValue('divisionid', selectedOption.value);
      };
      const handleRoleChange = (selectedOption) => {
        formik.setFieldValue('roleid', selectedOption.value);
      };

    return (
        <>
         <Modal  
             {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create Sub Admin
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3" controlId="formCategory">
                                <Form.Label>Division</Form.Label>                                
                                <Select required
                                    options={fetchDivisionsOptions}
                                    value={fetchDivisionsOptions.find((option) => option.value === formik.values.divisionid)}
                                    onChange={handleDivisionChange}
                                    />
                                    {formik.errors.divisionid && (
                                        <div>{formik.errors.divisionid}</div>
                                    )}
                              
                              </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group className="mb-3" controlId="formCategory">
                                <Form.Label>Role</Form.Label>                                
                                <Select required
                                    options={fetchRolesOptions}
                                    value={fetchRolesOptions.find((option) => option.value === formik.values.roleid)}
                                    onChange={handleRoleChange}
                                    />
                                    {formik.errors.roleid && (
                                        <div>{formik.errors.roleid}</div>
                                    )}
                              
                              </Form.Group>
                  </div>
                </div>
               
                <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formLabel">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control required type="text" placeholder="Enter email address" 
                        name="emailaddress"  
                        value={formik.values.emailaddress}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}                                
                        />
                            <FormErrorMessage
                                fieldName="emailaddress"
                                formik={formik}
                            ></FormErrorMessage>
                        </Form.Group>
                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formLabel">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control required type="text" placeholder="Enter first name" 
                        name="firstname"  
                        value={formik.values.firstname}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}                                
                        />
                            <FormErrorMessage
                                fieldName="firstname"
                                formik={formik}
                            ></FormErrorMessage>
                        </Form.Group>
                        
                        </div>
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formLabel">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control required type="text" placeholder="Enter last name" 
                        name="lastname"  
                        value={formik.values.lastname}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}                                
                        />
                            <FormErrorMessage
                                fieldName="lastname"
                                formik={formik}
                            ></FormErrorMessage>
                        </Form.Group>
                        
                        </div>
                    </div>
                    <div className="row">
                      
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formLabel">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control type="text" placeholder="Enter mobile number" 
                        name="mobilenumber"  
                        value={formik.values.mobilenumber}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}                                
                        />
                            <FormErrorMessage
                                fieldName="mobilenumber"
                                formik={formik}
                            ></FormErrorMessage>
                        </Form.Group>
                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formLabel">
                        <Form.Label>Password</Form.Label>
                        <Form.Control required type="password" placeholder="Enter password" 
                        name="password"  
                        value={formik.values.password}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}                                
                        />
                            <FormErrorMessage
                                fieldName="password"
                                formik={formik}
                            ></FormErrorMessage>
                        </Form.Group>
                        
                        </div>
                    </div>
                    <Button variant="primary" className="button" type="submit"> Submit </Button>
            </Form>
            </Modal.Body>
        </Modal>
        <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
            </div>
                   
        </>
    );
}

CreateUserPage.propTypes ={    
  onMasterSubmit: PropTypes.object.isRequired,
  }; 
export default CreateUserPage;