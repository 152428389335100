import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import  Button  from "react-bootstrap/Button";

import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { addMasterdata } from "../../../api/adminApi";
import { useFormik} from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import PropTypes from "prop-types";

function CreateRCategoryPage(props){
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const initialValues = {  
        label: '',
        type:'RCAT'
      }
    
      const  validationSchema = Yup.object({   
        label: Yup.string()
        .required("Label is required."),
      }); 
      const handleSubmit = (values) => {
        addMasterdata(values).then((response)=>{    
          if(!response.ok){
              // const err = new Error("Error in response");
              // throw err;
              setSnackbarMessage('Error in response');
              setShowSnackbar(true);
          } else{
              //return response.json();
          }
          }).then(response=>{
            console.log(response);
             
              //return response;
              setSnackbarMessage('Successfully added category data.');
              setShowSnackbar(true);
              //formik.resetForm();
              props.onMasterSubmit();
          });
      }
      const handleCloseSnackbar = () => {
        setShowSnackbar(false);
      }
      const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
      });
    

    return (
        <>
         <Modal  
             {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create Category
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
                <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formLabel">
                        <Form.Label>Category Name</Form.Label>
                        <Form.Control required type="text" placeholder="Enter Name" 
                        name="label"  
                        value={formik.values.label}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}                                
                        />
                            <FormErrorMessage
                                fieldName="label"
                                formik={formik}
                            ></FormErrorMessage>
                        </Form.Group>
                        
                        </div>
                    </div>
                    
                    <Button variant="primary" className="button" type="submit"> Submit </Button>
            </Form>
            </Modal.Body>
        </Modal>
        <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
            </div>
                   
        </>
    );
}

CreateRCategoryPage.propTypes ={    
  onMasterSubmit: PropTypes.object.isRequired,
  }; 
export default CreateRCategoryPage;