import React, { useEffect, useState } from "react";
// import { Card } from 'react-bootstrap';

import { getWorkShopLeads } from "../../../api/adminApi";
import moment from "moment/moment";
import '../../../Stylesheet/dashboard.css';
// import { DataGrid} from '@mui/x-data-grid';
// import useStyles from '../../common/shared/grid/useStyles';

const AdminDashboardPage =()=>{
  // const classes = useStyles();
  const [workShopLeadsList, setWorkShopLeadsList] = useState([]);
  
 
  // const columnsWorkshopLeads =[
  //   {field: 'createdby', headerName: 'Name', minWidth: 50},
  //   {field: 'emailaddress', headerName: 'Email Address', minWidth: 50},
  //   {field: 'mobilenumber', headerName: 'Mobile Number', minWidth: 50},
  //   {field: 'requesteddate', headerName: 'Requested Date', minWidth: 50},
  //   {field: 'summary', headerName: 'Summary', minWidth: 150},
  //   {field: 'skills', headerName: 'Skills', minWidth: 150},
  //   ];

 

    useEffect(()=>{ 
        fetchWorkshopLeadsData();
          },[]);

    const fetchWorkshopLeadsData =() => {
      getWorkShopLeads().then(response=>{                    
        setWorkShopLeadsList(response); 
      })
      .catch(error => {
        // Handle error
        console.log(error);
      });
    }

 
    return(

      <div style={{ padding: "20px", backgroundColor: "#f4f4f4" }}>
      <h2>Lead Dashboard</h2>
      <div className="row">
      {workShopLeadsList.slice(0, 5).map((lead) => (
          <div className="lead-container" key={lead.guid} style={leadContainerStyle}>
             <div className="created-date" style={createdDateStyle}>
              {moment(lead.createddate).format('MMMM D, YYYY')}
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Name:</label>
              <p>{lead.createdby}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Email Address:</label>
              <p>{lead.emailaddress}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Mobile Number:</label>
              <p>{lead.mobilenumber}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Brief Summary of Requirements:</label>
              <p>{lead.summary}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Workshop Dates:</label>
              <p>{moment(lead.requesteddate).format('MMMM D, YYYY')}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Specific Skills:</label>
              <ul className="skills-list" style={skillsListStyle}>
                {lead.skills?.map((skill, index) => (
                  <li key={index} style={skillItemStyle}>
                     {skill.name === "Any other Skills" && skill.others
                            ? skill.others
                            : skill.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
      
    );
};

// Inline styles to replicate the CSS in the original static HTML
const leadContainerStyle = {
  position: "relative", // Make this container relative so that the created date can be positioned absolutely
  backgroundColor: "#fff",
  borderRadius: "5px",
  padding: "20px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  marginBottom: "20px",
};

const leadDetailsStyle = {
  marginBottom: "10px",
};

const labelStyle = {
  fontWeight: "bold",
};

const skillsListStyle = {
  listStyle: "none",
  paddingLeft: "0",
};

const skillItemStyle = {
  marginBottom: "5px",
};
const createdDateStyle = {
  position: "absolute",
  top: "10px",
  right: "20px",
  fontSize: "12px",
  color: "#666",
  fontWeight: "bold",
};
export default AdminDashboardPage;