import React, {useState} from "react";
// import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
// import { Link } from "react-router-dom";

 
import MentorCalendarPage from '../mentor-calendar-page/MentorCalendarPage';
import MentorWorkshopPage from '../mentor-workshop-page/MentorWorkshopPage';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function SessionDetailsPage(){
    const [activeTab, setActiveTab] = useState(1);
    
    const handleTabClick = (index) => {
       
        setActiveTab(index);
    };
   
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                {/* style={{ width: '62rem', height: '65rem' }} */}
                <Card >
                    <Card.Body>
                    <CustomTooltip text="Scheduling Mentoring Session with Calendar: Use the calendar feature to schedule mentoring sessions with your mentees. You can add your availability slots, set reminders, and keep track of upcoming sessions to ensure effective and timely communication.<br><br>Scheduling Workshops online or offline: Use this feature to plan and schedule workshops, whether they are conducted online through webinars or in-person at a physical location. You can share the address, link, and Google Maps." />

                    {/* <Card.Title style={{ display: 'inline-block' }}>Mentor Details</Card.Title> */}
                    <ul className="nav nav-tabs">
                    
                        <li
                            className={`nav-item ${activeTab === 1 ? 'active' : '' }`}
                            onClick={() => handleTabClick(1)}
                        >
                            <span className={`aLink nav-link ${activeTab === 1 ? 'active' : '' }`}>One On One</span>
                        </li>
                  
                        <li
                            className={`nav-item ${activeTab === 2 ? 'active' : '' }`}
                            onClick={() => handleTabClick(2)}
                        >
                            <span className={`aLink nav-link ${activeTab === 2 ? 'active' : '' }`}>WorkShops</span>
                        </li>
                    </ul>
                    <div className="tab-content">
                        {/* <div className={`tab-pane ${activeTab === 1 ? 'active' : ''}`}>
                            <MentorCalendarPage />
                        </div>
                        <div className={`tab-pane ${activeTab === 2 ? 'active' : ''}`}>
                            <MentorWorkshopPage />
                        </div> */}
                  {activeTab === 1 && <MentorCalendarPage />}
                  {activeTab === 2 && <MentorWorkshopPage />}
                    </div>

                    {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true" >One on One</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="subscription-tab" data-bs-toggle="tab" data-bs-target="#subscription" type="button" role="tab" aria-controls="subscription" aria-selected="false" >Group (WorkShop)</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <MentorCalendarPage />
                        </div>
                        <div className="tab-pane fade" id="subscription" role="tabpanel" aria-labelledby="subscription-tab">
                            <MentorWorkshopPage />
                        </div>
                    </div> */}

                   
                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default SessionDetailsPage