import React,{useEffect , useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import Select from 'react-select';
import { addResource } from "../../../api/mentorApi";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { getMasterData } from "../../../api/mentorApi";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { Link } from "react-router-dom";

function MentorResourcesPage(){
  const [clsName, setclsName] = useState('');
  const[categoryOptions,setCategoryOptions] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showViewPageModal, setShowViewPageModal] = useState(false);

  const initialValues = {
    categoryId: '',
    title: '',
    downloadFile: null,
    resourceLink: '',   
  }

  const  validationSchema = Yup.object({
    categoryId: Yup.string()
    .required("Category is required."),
    title: Yup.string()
    .required("Title is required."), 
    downloadFile: Yup.mixed()
    .test('fileType', 'Only PDF, spreadsheet, and DOC files are allowed.', (value) => {
      if (!value) return true; // No file selected, so validation passes

      const allowedFileTypes = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      return allowedFileTypes.includes(value.type);
    })
    .test('fileSize', 'File size must be less than 10MB', (value) => {
      if (!value) return true; // No file uploaded, so validation passes
      const fileSizeLimit = 10 * 1024 * 1024; // 10MB in bytes
      return value.size <= fileSizeLimit;
    })
    .nullable(),
    // downloadFile: Yup.mixed().when("categoryId", {
    //   is: "1", // Assuming "1" represents the Video category
    //   then: Yup.mixed()
    //     .required("Video file is required.")
    //     .test(
    //       "fileType",
    //       "Only video files are allowed (e.g., MP4, AVI, MKV).",
    //       (value) => {
    //         if (!value) return true; // No file selected, so validation passes
  
    //         const allowedVideoTypes = [
    //           "video/mp4",
    //           "video/avi",
    //           "video/mkv",
    //           // Add more video MIME types as needed
    //         ];
  
    //         return allowedVideoTypes.includes(value.type);
    //       }
    //     ),
    //   otherwise: Yup.mixed()
    //     .required("Document is required.")
    //     .test(
    //       "fileType",
    //       "Only PDF, spreadsheet, and DOC files are allowed.",
    //       (value) => {
    //         if (!value) return true; // No file selected, so validation passes
  
    //         const allowedFileTypes = [
    //           "application/pdf",
    //           "application/vnd.ms-excel",
    //           "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //           // Add more document MIME types as needed
    //         ];
  
    //         return allowedFileTypes.includes(value.type);
    //       }
    //     ),
    // }),
    // resourceLink: Yup.string().when("categoryId", {
    //   is: "1", // Assuming "1" represents the Video category
    //   then: Yup.string().url("Invalid URL"), // You can add more specific URL validation if needed
    //   otherwise: Yup.string(),
    // }),

  }); 

 
  const handleSubmit = (values) => {

    try {
     
      const formDataToSend = new FormData();
      formDataToSend.append("CategoryId",values.categoryId);
      formDataToSend.append("Title",values.title);
      formDataToSend.append("file",values.downloadFile);
      formDataToSend.append("ResourceLink",values.resourceLink); 
 
      addResource(formDataToSend).then((response)=>{
        console.log(response);
        if(!response.ok){
          setclsName('alert error');
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
            //return response;
            setclsName('alert success');
            setSnackbarMessage('Successfully added resource data');
            setShowSnackbar(true);
            formik.resetForm();            
            fileInputRef.current.value = null;
        });

    }
    catch (error){
      console.log(error);
    }
  }
  const fileInputRef = useRef(null);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(()=>{

    getMasterData("RCAT").then(response => {
      const transformedOptions = response.map(option => ({
        label: option.label,
        value: option.value
      }));
      setCategoryOptions(transformedOptions);
    });
      
    
  },[]);

  const handleDownloadFileChange = event => {
    console.log(event);
      const file = fileInputRef.current.files[0];
     formik.setFieldValue('downloadFile', file);
     
  }

 
  const handleCategoryChange = (selectedOption) => {
    formik.setFieldValue('categoryId', selectedOption.value);
    // if(selectedOption.value !== 1) // Video
    // {
    //   setShowDownloadFile(true);
    //   setShowResourceLink(false);
    // }
    // else{
    //   setShowDownloadFile(false);
    //   setShowResourceLink(true);
    // }
  };

  // const handleFileChange =(e)=>{
  //   setFileSelected(e.target.files[0]);
  // }
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }

  const closeViewPageModal = () => {
    setShowViewPageModal(false);
  }
    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card >
                  <Card.Body>
                  
                    {/* <Card.Title style={{ display: 'inline-block' }}>Add Resources</Card.Title> */}
                    <Form onSubmit={formik.handleSubmit}>
                    <div className="row">
                    <div className="col" style={{float:"right"}}>
                      <Link to="/resourcesView">
                      <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                          Back
                      </Button>
                      </Link>  
                      </div>
                    </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control required type="text" placeholder="Enter title" 
                                name="title"  
                                value={formik.values.title}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="title"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formCategory">
                                <Form.Label>Category</Form.Label>                                
                                <Select required
                                options={categoryOptions}
                                //value={formik.values.category}
                                onChange={handleCategoryChange}
                                // onChange={(selectedOptions) =>
                                //   formik.setFieldValue('categoryId',selectedOptions.value)
                                // }
                                />
                                 <div
                                    className="invalid-feedback"
                                    style={{
                                        display:
                                        formik.errors.categoryId
                                        ? "block"
                                        : "none",
                                    }}
                                >
                                    {formik.errors.categoryId ? formik.errors.categoryId : null}
                                </div>
                              
                              </Form.Group>
                            </div>                           
                          </div>
                         
                            <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDownloadFile">
                                <Form.Label>Upload document</Form.Label>
                                <Form.Control type="file"  
                                accept=".pdf, .xls, .xlsx, .docx, .doc"
                                onChange={handleDownloadFileChange} 
                                name="downloadFile" 
                                ref={fileInputRef}
                                />
                                  <FormErrorMessage
                                      fieldName="downloadFile"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                             
                          </div>
                        
                          
                         
                             <div className="row">
                             <div className="col">
                               <Form.Group className="mb-3" controlId="formResourceLink">
                                 <Form.Label>Resource Link</Form.Label>
                                 <Form.Control type="text" placeholder="Enter resource link" 
                                  name="resourceLink"  
                                  value={formik.values.resourceLink}
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur} 
                                 />
                                  <FormErrorMessage
                                       fieldName="resourceLink"
                                       formik={formik}
                                   ></FormErrorMessage>
                               </Form.Group>
                             </div>
                             
                           </div> 
                         
                           
                          <Button variant="primary" className="button" type="submit">
                          Submit
                          </Button>                 
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
            </div>

            {showViewPageModal && (
            <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Courses List</h5>
                    <button type="button" className="close" onClick={closeViewPageModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {/* <MentorCoursesViewPage/> */}
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        </div>
          );

}
export default MentorResourcesPage;