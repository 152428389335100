import React from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
// import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
// import Select from 'react-select';
// import { useFormik, Formik } from "formik"
// import * as Yup from "yup";
// import {useQuery, useQueryClient } from "react-query";
// import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
// import { Link } from "react-router-dom";
// import  SnackbarProvider  from "../../authContext/SnackbarProvider";
//import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function MentorPaymentConfigPage(){
    const classes = useStyles();

    const employeeList = [
        {id: 1,userLogon:'email@gmail.com', percentage:'80%',action:'Action' },
    ];
    const columns =[
        {field: 'id', headerName: '#', minWidth: 150},
        {field: 'userLogon', headerName: 'User Logon', minWidth: 150},
        {field: 'percentage', headerName: 'percentage', minWidth: 150},
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 200,
            // renderCell: (params) => (
            //   <>
            //   </>
            // ),
          },
    ];
 
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <Card style={{ width: '62rem', height: '53rem' }}>
                    <Card.Body>
                    <Card.Title style={{ display: 'inline-block' }}>Mentor % wise payment settlement</Card.Title>
                    
                    {/* <BootstrapTable 
                    keyField="id"
                    data={employeeList}
                    columns={columns}                     
                    pagination={false}
                    /> */}
                    <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={employeeList}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

                    <div className="row">
                        <div className="col">
                            <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Enter percentage</Form.Label>
                            <Form.Control required type="text" placeholder="Enter percentage" 
                            name="percentage"  
                            // value={formik.values.title}
                            // onChange={formik.handleChange} 
                            // onBlur={formik.handleBlur}                                
                            />
                                {/* <FormErrorMessage
                                    fieldName="title"
                                    formik={formik}
                                ></FormErrorMessage> */}
                            </Form.Group>
                        </div>
                    </div>
                    <Button variant="primary" type="submit" className="button">
                          Submit
                          </Button>  
                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default MentorPaymentConfigPage