import React, {useEffect, useState } from "react";
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
// import FilterFactoryProps, {textFilter} from "react-bootstrap-table2-filter";
//import filterFactory from "react-bootstrap-table2-filter";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { getPayments,getSettlePercentage  } from "../../../api/mentorApi";
// import { useParams } from "react-router-dom";
import SearchInputs from "../../common/shared/search/SearchInputs";

import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function MentorPaymntPage(){
    //const { id } = useParams(); 
    const [paymentList, setPaymentList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');
    const [settlementPerValue, setSettlementPerValue] = useState('');

    const classes = useStyles();

    // const dateFormatter = (cell) => { //, row
    //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
    //     return <span>{formattedDate}</span>;
    // }
     
    const columns =[
        {field: 'enrollmenttype', headerName: 'Type', minWidth: 150},
        {field: 'sender', headerName: 'Sender', minWidth: 150},
        // {dataField: 'receiver', text: 'Receiver'},
        {field: 'amount', headerName: 'Amount', minWidth: 150,
       // formatter: (cell) => `₹ ${parseFloat(cell).toFixed(2)}`},  
        renderCell: (params) => (
            <span>
              ₹ {parseFloat(params.value).toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </span>
          ),
        },
        {field: 'transactiondate', headerName: 'Transaction Date', minWidth: 150, 
        valueGetter: (params) => moment(params.row.transactiondate).format("YYYY-MM-DD HH:mm")}, 
        //formatter: dateFormatter},
        {field: 'settlementstatus', headerName: 'Settlement Status', minWidth: 150},
        
    ];
    useEffect(()=>{
        fetchPaymentsData();
        fetchSettleentPercentageData();
      },[]);
      
      const fetchPaymentsData =() => {
        getPayments().then(response=>{                    
            setPaymentList(response); 
        });
      }
      const fetchSettleentPercentageData =() => {
        getSettlePercentage().then(response=>{                    
            setSettlementPerValue(response);             
        });
      }
      
    useEffect(()=>{
    const filteredResults = paymentList.filter((item) => {
        const emailMatch = item.sender.toLowerCase().includes(emailSearch.toLowerCase());

        return emailMatch;
    });
    setFilteredData(filteredResults);
    },[paymentList,emailSearch]);
    
    const handleEmailChange = (e) => {
    setEmailSearch(e.target.value);
    }
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <Card>
                    <Card.Body>
                    {/* <Card.Title style={{ display: 'inline-block' }}>Payment Details</Card.Title> */}
                    <CustomTooltip text="Coming soon." />
                    
                    <div className="row">
                        <div className="col">                         
                          <div style={{float:"right"}}>Payment settlement - {settlementPerValue.settleper} % </div> 
                           {/* <i className="bi bi-pencil"></i> */}
                           <br/>
                           <SearchInputs
                            showEmailSearch={true}
                            emailSearch={emailSearch}
                            onEmailChange={handleEmailChange}
                            ></SearchInputs>
                            

                        <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

                        </div>
                    </div>
                    <Button variant="primary" type="submit" className="button">
                          Export to Excel
                        </Button> 
                   </Card.Body>
                   </Card>
               
                </div>
            </div>
        </div>
        </div>        

    )

}
export default MentorPaymntPage