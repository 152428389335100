// import Button from "@material-ui/core/Button";
// import { useTranslation } from "react-i18next";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";
import constructions from '../../Images/constructions.svg';
import { Card } from 'react-bootstrap';
const UnderConstructions = () => {
    return (
      <div className="featured-section-wrap featured-section-wrap-row">
      <div className='mainPageContainer'>        
          <div className='dashboardcontainerflex'>
            <div className='dashboardcard'>
            <Card style={{ width: '62rem', height: '53rem' }}>
              <Card.Body>
                <img style={{paddingTop:"35px", paddingLeft:"55px"}} src={constructions} />
              </Card.Body>
            </Card>
              
            </div>
          </div>
          
      </div>
      </div>
       
    );
  };
  
  export default UnderConstructions;
  