import { apiMthods } from "./apiUtils";

//const baseUrl = "http://localhost/somutusAPI/v1/users";
//const baseUrl = "https://somotusapi.azurewebsites.net/v1/users"; 
//const baseUrl = "http://localhost:3001/v1/users"; 

//const baseUrl = process.env.NODE_ENV === 'production' ? "api/v1/users" : "http://localhost:3001/v1/users"; 
const baseUrl = process.env.NODE_ENV === 'production' ? "http://13.232.221.26/api/v1/users" : "http://localhost:3001/v1/users"; 

//const baseUrl = "http://13.232.221.26/api/v1/users"; 
 
export function authenticateUser(payload){  
    return fetch(baseUrl + "/login", {
        method: apiMthods.post,
        headers: { "content-type": "application/json"},
        body: JSON.stringify(payload),
    })
    .then((response) => {        
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{         
            return response.json(); //response.text();
        }
    });
    // .then((data) => {
    //     if (data.access_token) {
    //         localStorage.setItem("user", JSON.stringify(data));
    //       }
    //     localStorage.setItem("accessToken",data.access_token);
    //     localStorage.setItem("refreshToken",data.refresh_token);
       
    // });  
}

export function authenticateUserNew(payload){    
    return fetch(baseUrl + "/login",{
        method: apiMthods.post,
        headers: { "content-type": "application/json"},
        body: JSON.stringify(payload),
    })
}

export async function getUserDetails(){
    const token = await getToken();
    return fetch(baseUrl + "/", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: "bearer " + token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export function logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }

export function getCurrentUser() {    
    return JSON.parse(localStorage.getItem('user'));
  }

export function registerWithUserDetails(payload){  
    return fetch(baseUrl + "/registerWithUserDetails", {
        method: apiMthods.post,
        headers: { "content-type": "application/json"},
        body: JSON.stringify(payload),
    })
    .then((response) => {
       // alert(JSON.stringify(response));
        if(!response.ok){
            // alert(response.json());
            // alert(JSON.stringify(response));
            // const err = new Error("Error in response");
            //throw err;
            return response.json();
        } else{
            //return response.json(); //response.text();
            return response;
        }
    })
    .then(response=>{
        return response;
    }); 
}

export async function getMasterData(payload){
 // const token = await getToken();
  return fetch(baseUrl + "/retrieveMasterData/"+payload, {
      method: apiMthods.get,
      headers: {
          "content-type": "application/json",
          //Authorization: token,
      },
  }).then((response) => {
      if(!response.ok){
          const err = new Error("Error in response");
          throw err;
      } else{
          return response.json();
      }
  }).then(response=>{
      return response;
  });
}

export function razorpay(payload) {
    return fetch(baseUrl + "/razorpay", {
      method: apiMthods.post,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          // Log more details about the error
          console.error('Error in response:', response.status, response.statusText);
          return response.json();
        } else {
          // Log the successful response
          console.log('Success:', response.status, response.statusText);
          return response.json();
        }
      })
      .then((data) => {
        // Log the parsed data
        console.log('Parsed data:', data);
        return data;
      })
      .catch((error) => {
        // Log any unexpected errors
        console.error('Unexpected error:', error);
        throw error;
      });
  }



// export function getToken(){
//     return localStorage.getItem("accessToken");
// }


export async function getToken() {
    let token = localStorage.getItem("accessToken");
  
    // Check if the token is present
    if (!token) {
      // Token is not present, redirect to the login page
      window.location.href = '/login'; // Replace '/login' with your actual login page URL
      throw new Error('Token not present');
    }
  
    try {
      // Make an API call to your server to check if the token is expired
      const response = await fetch(baseUrl + "/isTokenExpired", {
        method: apiMthods.get,
        headers: {
          "content-type": "application/json",
          Authorization: token,
        },
      });
  
      // Check if the response status is not OK (e.g., 401 for unauthorized)
      if (!response.ok) {
        // Token validation failed or other error occurred
        window.location.href = '/login'; // Redirect to the login page
        throw new Error('Token validation failed');
      }
  
      // Parse the response JSON which contains the boolean result
      const isTokenExpired = await response.json();
  
      if (isTokenExpired) {
        // Token is expired, redirect to the login page
        window.location.href = '/login'; // Replace '/login' with your actual login page URL
        throw new Error('Token expired');
      }
  
      // Token is valid, return it
      return token;
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error(error);
      throw new Error('Token validation failed');
    }
}
  
export function isAuthenticated(){
    let jwt_token= localStorage.getItem("jwt_token");
    if(jwt_token){ return true;} else {return false;}
}