import React,{useState} from "react";
import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
// import { useState } from 'react';
import { Form, Spinner,InputGroup  } from 'react-bootstrap';
import * as Yup from "yup";
import { useMutation } from "react-query";
import { authenticateUser } from "../../../api/userAuthApi";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import ReCAPTCHA from "react-google-recaptcha";

import topbarLogo from '../../../../src/Images/lifeskylls-logo.png';

function LoginPage() {
  const navigate = useNavigate();
  const [isVarified, setisVarified] = useState(false);

  //const queryClient = useQueryClient();
  const mutation = useMutation(authenticateUser, {
    onSuccess: (data) => {    
     
      //navigate("/dashboard");
        if (data.token) {
          localStorage.setItem("user", JSON.stringify(data.users[0]));
        }
        localStorage.setItem("accessToken",data.token);
        //localStorage.setItem("refreshToken",data.token);
        localStorage.setItem("profilePic",data.users[0].photo);
 
        if(data.users[0].role === 'Mentor'){
          navigate("/dashboard");
        }
        else if(data.users[0].role === 'Super Admin'){
          navigate("/dashboard-admin");
        }
        else if(data.users[0].role === 'Corporate'){
          navigate("/dashboard-corporate");
        }
        else if(data.users[0].role === 'Educational Institute'){
          navigate("/dashboard-institute");
        }
    },
  })
 
  
  const formik = useFormik({
    initialValues: {
      user_logon: "",
      password: "",
    },
    validationSchema: Yup.object({
      user_logon: Yup.string()
      .max(50,"Must be 50 characters or less.")
      .required("User ID is required."),
      password: Yup.string()
      .max(50,"Must be 50 characters or less.")
      .required("Password is required."),
    }),
    onSubmit: (values) => {
      mutation.mutate(values);

      // try 
      // {
      //   authenticateUser(values).then(data=>{
      //     if (data.access_token) {
      //         localStorage.setItem("user", JSON.stringify(data));
      //       }
      //     localStorage.setItem("accessToken",data.access_token);
      //     localStorage.setItem("refreshToken",data.refresh_token);

      //     if(data.role === 'Mentor'){
      //       navigate("/dashboard");
      //     }
      //     else if(data.role === 'SuperAdmin'){
      //       navigate("/dashboard-admin");
      //     }
      //     else if(data.role === 'Corporate'){
      //       navigate("/dashboard-corporate");
      //     }
          
      //   });
       
      // } 
      // catch (error) {
        
      // }
    },
  });

  
  const onCaptchaChange = (value) => {
   console.log(value);
   setisVarified(true);
  }


  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-light header-color">
    <div className="container-fluid">
    <img src={topbarLogo} className="topBarLogo" />
      <a className="navbar-brand" href="#"></a>
     
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
      </div>
    </div>
      </nav>

   
    <div className="container">
     
   

    <div className="Login-header">Sign In</div>
 
		<div className="row justify-content-center">
			<div className="col-md-6">
      <Form onSubmit={formik.handleSubmit}>
					<div className="form-group">
						{/* <label for="email">Email address:</label> */}
            <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control 
                  type="email" 
                  required
                  placeholder="Enter email" 
                  name="user_logon" 
                  value={formik.values.user_logon}
                  onChange={formik.handleChange} 
                  onBlur={formik.handleBlur}
                
                  />
                  <FormErrorMessage
                    fieldName="user_logon"
                    formik={formik}
                  ></FormErrorMessage>
                  <Form.Text className="text-muted">
                    We will never share your email with anyone else.
                  </Form.Text>
                </Form.Group>
					</div>
					<div className="form-group">
						{/* <label for="pwd">Password:</label> */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
  <Form.Label>Password</Form.Label>
  <InputGroup>
    <Form.Control
      type={formik.values.showPassword ? "text" : "password"}
      required
      placeholder="Password"
      name="password"
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.password}
    />
    <InputGroup.Text onClick={() => formik.setFieldValue("showPassword", !formik.values.showPassword)}>
      {formik.values.showPassword ? (
        <i className="bi bi-eye-slash"></i>
      ) : (
        <i className="bi bi-eye"></i>
      )}
    </InputGroup.Text>
  </InputGroup>
  <FormErrorMessage fieldName="password" formik={formik}></FormErrorMessage>
</Form.Group>

                {mutation.isError && (
                  <div
                    className='invalid-feedback'
                    style={{
                      display: "block",
                    }}
                    >
                      Invalid user ID or Password
                    </div>
                )}
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Remember me" />
                </Form.Group>
					</div>
          <div className="form-group">
          <ReCAPTCHA 
                  sitekey="6Ld_-4EoAAAAAMm-ZmWKZsOzymn-R52uNzR6bSqI"
                  onChange={onCaptchaChange}
                />

                {mutation.isSuccess && <div>It was successful</div>}                 
                <Button  
                //variant="primary" 
                type="submit" 
                className='button'
                disabled={!isVarified}
               // disabled={mutation.isLoading}
                //onClick={handleSubmit}
                >
                  Submit{" "}
                  {mutation.isLoading && (
                    <Spinner
                      as="span"
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      >
                        <span className='visually-hidden'>Loading...</span>
                      </Spinner>
                  )}
                </Button>
                <Alert key='primary' variant='primary' className="alertbox">
                
                    {/* <Alert.Link href="/Register"> Don't have an account? Register here</Alert.Link> */}
                    <Link to="/Register" className="aLink">Dont have an account? Register here</Link>
                  
                  
                  </Alert>
          </div>
          </Form>
			</div>
		</div>
   
   
    
    </div>
    </div>
  );
}

export default LoginPage;