import React, {useEffect, useState } from "react";
// import Form from 'react-bootstrap/Form';
// import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
// import Select from 'react-select';
// import { useFormik, Formik } from "formik"
// import * as Yup from "yup";
// import {useQuery, useQueryClient } from "react-query";
// import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
// import { Link } from "react-router-dom";
// import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import FilterFactoryProps, {textFilter} from "react-bootstrap-table2-filter";
// import filterFactory from "react-bootstrap-table2-filter";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { getSubscriptionsById } from "../../../api/adminApi";
import { useParams } from "react-router-dom";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function SubscriptionDetails(){
    const classes = useStyles();
    const { id } = useParams(); 
    const [subscriptionList, setSubscriptionList] = useState([]);

    // const dateFormatter = (cell) => { //, row
    //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
    //     return <span>{formattedDate}</span>;
    // }

    //   const fetchedSubscriptionOptions = [ 
    //     { value: "1", label: "Free Plan", details: "Free Subscription plan | Duration: 3 months | Price: 0" },
    //     { value: "2", label: "Premium Plan" , details: "Crargeable Subscription plan | Duration: 12 months | Price: 2000"},
    //     { value: "3", label: "Basic Plan", details: "Chargeable Subscription plan | Duration: 6 months | Price: 1000" }
    //   ];

    //   const customOptionLabel = ({
    //     label, details
    //   }) => (
    //     <div>
    //       <div>{label}</div>
    //       <div style={{color:"green"}}>{details}</div>
    //     </div>
    //   );

     
    const columns =[
    {field: 'id', headerName: '#', minWidth: 150},
    {field: 'planname', headerName: 'Plan Name', minWidth: 150},
   
    {field: 'description', headerName: 'Description', minWidth: 150},
    {field: 'duration', headerName: 'Duration', minWidth: 150},
    {field: 'finalamount', headerName: 'Price', minWidth: 150},
    {field: 'subscriptionstartDate', headerName: 'Start Date', minWidth: 150, 
    valueGetter: (params) => moment(params.row.subscriptionstartDate).format("YYYY-MM-DD HH:mm")
    },
    {field: 'subscriptionendDate', headerName: 'End Date', minWidth: 150, 
    valueGetter: (params) => moment(params.row.subscriptionendDate).format("YYYY-MM-DD HH:mm")
    },
    {field: 'isexpired', headerName: 'Is Expired', minWidth: 150},
    {field: 'action', headerName: 'Action', minWidth: 150},

        // {dataField: 'id', text: '#'},
        // {dataField: 'planname', text: 'Plan Name'},
        // {dataField: 'description', text: 'Description'},
        // {dataField: 'duration', text: 'Duration'},
        // {dataField: 'finalamount', text: 'Price'},
        // {dataField: 'subscriptionstartDate', text: 'Start Date', formatter: dateFormatter},  
        // {dataField: 'subscriptionendDate', text: 'End Date', formatter: dateFormatter},  
        // {dataField: 'isexpired', text: 'Is Expired'},
        // {
        //     dataField: 'action',
        //     text: 'Action',
        //     // formatter: (cell, row) => (
        //     //   <>
        //     //   {/* <Link to={`/mentor-details/${row.id}`}><i className="bi bi-activity" style={{paddingRight:"10px", fontSize:'20px'}}/></Link> */}
               
        //     //   </>
        //     // ),
        // },
    ];
    
    useEffect(()=>{
        fetchSubscriptionData();
  
      },[]);
      
      const fetchSubscriptionData =() => {
        getSubscriptionsById(id).then(response=>{                    
            setSubscriptionList(response); 
        });
      }

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <ListGroup variant="flush">
                    <ListGroup.Item> 
                    
                    <div className="row">
                        <div className="col">
                        <Card.Subtitle>Subscription Details</Card.Subtitle>
                        <div style={{float:"right"}}> &nbsp;</div>  
                            {/* <BootstrapTable 
                            keyField="id"
                            data={subscriptionList}
                            columns={columns}
                            // cellEdit={cellEditFactory(cellEditOptions)}
                            pagination={paginationFactory()}
                            // filter={filterFactory()}
                            /> */}
                             <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={subscriptionList}
                                columns={columns}
                                getRowId={(row) => row.id}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col">
                            <Card.Subtitle>Renew Subscription</Card.Subtitle>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formBannerImage">
                            <Form.Label>Subscription</Form.Label>
                            <Select required
                            options={fetchedSubscriptionOptions}
                            //value={fetchedSubscriptionOptions.find((option) => option.label === formik.values.categoryName)}
                            formatOptionLabel={customOptionLabel}
                        //    onChange={handleSubscriptionChange}
                            />                                  
                            
                        </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formBannerImage" style={{width:'150px'}}>
                            <Form.Label>Start From </Form.Label>
                            <DatePicker required  
                            // selected={date}
                            // onChange={(date)=>{setDate(date)}}
                            // onCalendarClose={handleCalendarClose}
                            // onCalendarOpen={handleCalendarOpen}
                            name="date"
                            ></DatePicker>
                        </Form.Group>
                        </div>
                    </div>        
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>    */}
                    </ListGroup.Item>
                </ListGroup>
               
                </div>
            </div>
        </div>
        </div>        

    )

}
export default SubscriptionDetails