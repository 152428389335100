import React, { useEffect, useState } from "react";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Select from 'react-select';
import { getSurveyQuestions,deleteSurveyQuestion } from "../../../api/corporateApi";
import { PropTypes } from "prop-types";
import Modal from 'react-bootstrap/Modal';
import { Trash  } from "react-bootstrap-icons";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";

const CreateQuestionPage = ({  questionCount, onSave, surveyguid }) => {
  const [questionType, setQuestionType] = useState('single_choice');
  const [options, setOptions] = useState([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);
  const [questionsList, setQuestionsList] = useState([]);
  const [isAddQuestion, setIsAddQuestion] = useState(false);
  const [editingQuestionIndex, setEditingQuestionIndex] = useState(-1);

  const [clsName, setclsName] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rowDataToDelete, setRowDataToDelete] = useState(null);
  //const [isEdit, setIsEdit] = useState(false);

  const initialValues = {
    guid:'',
    questionType: 'single_choice',
    questionText: '',
   
    options: [{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }],
    
  };
  

  const validationSchema = Yup.object({
    questionType: Yup.string().required("Question type is required."),
    questionText: Yup.string().required("Question text is required."),
  });
  

  const handleSubmit = (values) => {
  
    let guid = values.guid;
    let addeditStatus = 'EDIT';
    if(editingQuestionIndex === -1){
        guid = surveyguid;
        addeditStatus = 'ADD';
    }
    
    const question = {
      guid,
      questionText: values.questionText,
      questionType: values.questionType,
      options: options.map((option) => ({
        optionText: option.optionText,
        correctAns: option.correctAns
      })),
      addeditStatus: addeditStatus,
      parentid:null,
    };
    onSave(question);
    formik.resetForm();
    setIsAddQuestion(false);
    fetchQuestionsData();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  
  
  useEffect(() => {
    fetchQuestionsData();    
  }, [onSave, surveyguid, questionsList.length]);
  
  useEffect(() => {
    questionCount(questionsList.length);
  }, [questionsList]);

  const fetchQuestionsData =() => {
   
    if (surveyguid !== null && surveyguid !== undefined) {
      getSurveyQuestions(surveyguid).then(response=>{                    
            setQuestionsList(response); 
            questionCount(questionsList.length);
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }
    
  }
 

  const categoryOptions = [    
    { value: "single_choice", label: "Single-choice" },
    { value: "multiple_choice", label: "Multiple-choice" },
  ];


  const handleQuestionTypeChange = (selectedOption) => {
    console.log(selectedOption.value);
    //setQuestionType(selectedOption.value);
    //setOptions([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);

    setQuestionType(selectedOption.value);
    formik.setFieldValue('questionType',selectedOption.value);
    setOptions([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);

  };

  const handleOptionTextChange = (e, index) => {
    const updatedOptions = [...options];
    updatedOptions[index].optionText = e.target.value;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, { optionText: '', correctAns: false }]);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const handleCheckboxChange = (index) => {
    const updatedOptions = [...options];

    if (questionType === 'single_choice') {
      updatedOptions.forEach((option, idx) => {
        updatedOptions[idx].correctAns = idx === index;
      });
    } else if (questionType === 'multiple_choice') {
      updatedOptions[index].correctAns = !updatedOptions[index].correctAns;
    }

    setOptions(updatedOptions);
  };


  const handleAddQuestionLink = () => {
    // setIsAddQuestion(true);
    // formik.resetForm();
    // setOptions([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);
    // setEditingQuestionIndex(-1);

    setIsAddQuestion(true);    
    formik.resetForm();
    setOptions([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);
    setEditingQuestionIndex(-1);
    //setIsEdit(true);
    
    setQuestionType('single_choice');
    formik.setFieldValue('questionType','single_choice');
    setOptions([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);

  };

 
  const handleEditQuestion = (index) => {
    const question = questionsList[index];
    setEditingQuestionIndex(index);
    formik.setValues({
        guid:question.guid,
      questionType: question.type,
      questionText: question.questiontext,
      options: question.options || [],  
      
    });
    setQuestionType(question.type);
    setOptions(question.options || []);  
    setIsAddQuestion(true);
    //setIsEdit(true);
     

  };

  const handleCancelQuestion = () => {
   
    setIsAddQuestion(false);
    setEditingQuestionIndex(-1);
    formik.resetForm();
    setOptions([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);
    //setIsEdit(false);
  };
  
  

const handleDeleteClick = (row) => {
  setRowDataToDelete(row);
  setShowConfirmation(true);
};

const handleConfirmDelete = () => {
  const updatedDataToDelete = {
      ...rowDataToDelete,
    };

    const deletedata = {
      aguid:surveyguid,
      qguid: updatedDataToDelete.guid,
    };
 
    deleteSurveyQuestion(deletedata).then((response)=>{
      if(!response.ok){
        setclsName('alert error');
          setSnackbarMessage('Error in response');
          setShowSnackbar(true);
      } else{
          return response.json();
      }
      }).then(response=>{
        console.log(response);
        setclsName('alert success');
          setSnackbarMessage('Successfully deleted data.');
          setShowSnackbar(true);
          fetchQuestionsData();
          // alert(questionsList.length);
          // questionCount(questionsList.length);
          //handleReloadUpdateData();
          // props.onWorkshopSubmit();
      });


  setShowConfirmation(false);
};

const handleCancelDelete = () => {
  setShowConfirmation(false);
}; 
const handleCloseSnackbar = () => {
  setShowSnackbar(false);
}


  return (
    <div>
      <div className="row">
        <div className="col" style={{ borderRight: '1px solid black' }}>
          <label>Selected Questions - {questionsList.length}/10 (min 5 questions are required to publish)</label><br />
          <span onClick={handleAddQuestionLink} className="mt-3 flex flex-row items-center gap-x-2 aLink" style={{ color: "#44D5E7" }}>
            + Add Questions
          </span>
          
          

{questionsList.length > 0 ? (
            questionsList.map((item, index) => (
              <div key={item.guid}>
                <article className="card mt-3 flex w-full cursor-pointer flex-col rounded-lg border border-slate-300 p-2 transition-all hover:animate-pulse">
                  <p className="text-sm" style={{ margin: 0 }}>
                    {index + 1}. {item.questiontext}
                  </p>
                  <p className="text-xs" style={{ margin: 0 }}></p>
                  <span className="mt-3 flex flex-row items-center gap-x-2 aLink" style={{ color: "#44D5E7" }} onClick={() => handleEditQuestion(index)}>
                    View Options
                  </span>
                  <Trash className="aLink primary"
                        size={24}
                        // color="red"
                        style={{ position: "absolute", top: "5px", right: "5px" }}
                        onClick={() => handleDeleteClick(item)}
                      />
                      
                </article>
              </div>
            ))
          ) : (
            <p>No Questions</p>
          )}

        </div>
        <div className="col">
          <Form onSubmit={formik.handleSubmit}>
            {isAddQuestion && (
              <>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3" controlId="formBasicCategory">
                      <Form.Label>Type of questions</Form.Label>
                      <Select
                        required
                        styles={{ width: "150px" }}
                        options={categoryOptions}
                        value={categoryOptions.find((option) => option.value === formik.values.questionType)}
                        onChange={handleQuestionTypeChange}
                        defaultValue={categoryOptions[0]} // Set the default value here
                        />
                      <FormErrorMessage fieldName="questionType" formik={formik} />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3" controlId="formTitle">
                      <Form.Label>Question Text</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Question Text"
                        name="questionText"
                        value={formik.values.questionText}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                />
                 <FormErrorMessage
                    fieldName="questionText"
                    formik={formik}
                ></FormErrorMessage>
              </Form.Group>
            </div>
          </div>
          {questionType !== 'text' && (
            <div className="row">
              <div className="col">
                Enter the choice and mark the checkbox corresponding to the correct choice.
                {options.map((option, index) => (
                  <div className="row" key={index}>
                    <div className="col" style={{ marginBottom: '5px',marginTop: '5px' }}>
                      <Form.Check
                        type={questionType === 'multiple_choice' ? 'checkbox' : 'radio'}
                        label=""
                        checked={option.correctAns}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </div>
                    <div className="col"  style={{ marginBottom: '5px', marginTop: '5px', marginLeft: '-200px' }}>
                      <Form.Control
                        required
                        type="text"
                        placeholder={`Option ${index + 1}`}
                        value={option.optionText}
                        onChange={(e) => handleOptionTextChange(e, index)}
                      />
                    </div>
                    <div className="col">
                    {index !== 0 && (   
                      <Button variant="primary" onClick={() => handleRemoveOption(index)}>Delete</Button>
                    )}
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col">
                    <Button variant="primary" type="submit" className="button" onClick={handleAddOption}>
                      Add Option
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
           
        <br></br>
          <div className="row">
            <div className="col">
              <Button variant="primary" type="submit" className="button">
                {editingQuestionIndex !== -1 ? "Update Question" : "Add Question"}
              </Button>
              <Button variant="primary" type="submit" className="button" onClick={handleCancelQuestion}>
                Cancel
              </Button>
            </div>
          </div>
            </>
          )}      
         
         <SnackbarProvider 
                      show={showSnackbar}
                      message={snackbarMessage}
                      onClose={handleCloseSnackbar}
                      message1={clsName}
                      />
         <Modal show={showConfirmation} onHide={handleCancelDelete}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="secondary" className="button" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                    </Modal.Footer>
                    </Modal>

         
         </Form>
         

        </div>
      </div>
    </div>
  );
};

CreateQuestionPage.propTypes ={
    onSave: PropTypes.object.isRequired, 
    questionCount: PropTypes.string.isRequired, 
    surveyguid: PropTypes.string.isRequired, 
    
  };
export default CreateQuestionPage;


