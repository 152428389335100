import React, {useRef,useState, useEffect} from 'react';
// import ProfilePic from '../../../../Images/avatar.png';
import { uploadProfileImage } from "../../../../api/mentorApi";
import PropTypes from "prop-types";

const ProgressCircle = ({ percent, avatarURL }) => {
    const [selectedProfilePic, setSelectedProfilePic] = useState('');    
    const strokeWidth = 10;
    const radius = 50;
    const normalizedRadius = radius - strokeWidth;
    const circumfrence = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumfrence - (percent / 100) * circumfrence;

    const textX = radius + 51;
    const textY = radius + 42;

    const fileInputRef = useRef(null);

    useEffect(() => {
        setSelectedProfilePic(avatarURL);
    },[selectedProfilePic,uploadProfileImage]);
    
    const handlePlusIconClick =() =>{
        //fileInputRef.current && fileInputRef.current.dispatchEvent(new MouseEvent('click'));
        // setTimeout(() =>{
        //     if(fileInputRef.current){
        //         alert();
        //         fileInputRef.current.value = null;
        //         fileInputRef.current.click();
        //     }
        // },10);
        
                fileInputRef.current.value = null;
                fileInputRef.current.click();
       
    }
    const handleFileSelect =(e) =>{
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file',file);

        uploadProfileImage(formData)
        .then((response)=>{
            if(!response.ok){                 
                // setSnackbarMessage('Error in response');
                // setShowSnackbar(true);
            } else{
                return response.json();
            }
            })
        .then(data=>{    
               
            // Retrieve the user object from localStorage
                const userString = localStorage.getItem('user');
                const user = JSON.parse(userString);

                // Modify the desired property
                user.profile_completion = data.profilecompletionCount;//user.profile_completion + 25;
                user.photo = data.imageBase64String;
                // Stringify the modified object
                const modifiedUserString = JSON.stringify(user);

                // Update the modified JSON string in localStorage
                localStorage.setItem('user', modifiedUserString);

                // setSnackbarMessage('Successfully added course data');
                // setShowSnackbar(true);
                //avatarURL = data.imageBase64String;
                setSelectedProfilePic(data.imageBase64String);
                //localStorage.setItem("profilePic",data.imageBase64String);
                localStorage.setItem("profilePic",data.imageBase64String);
                location.reload();
            });
    }
    return (
        <div className='progress-circle'>
            <svg height={radius * 3} width={radius * 2}>
                <circle 
                    className='progress-circle-background'
                    strokeWidth={strokeWidth}
                    fill='transparent'
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                 <circle 
                    className='progress-circle-fill'
                    strokeWidth={strokeWidth}
                    fill='transparent'
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    transform={`rotate(-190 ${radius} ${radius})`}
                    style={{
                        strokeDasharray: circumfrence,
                        strokeDashoffset : strokeDashoffset,
                    }}
                />
                <foreignObject x={strokeWidth} y={strokeWidth} width={normalizedRadius * 2} height={normalizedRadius * 2}>
                    <img src={selectedProfilePic} className='card-img-top' alt="Card image cap"
                    style={{ width: '100%', height: '100%', borderRadius: '50%'}} />

                    {/* <img src={`data:image/png;base64,${selectedProfilePic}`}                                 
                                className="img-fluid" 
                                style={{ width: '100%', height: '100%', borderRadius: '50%'}}
                                /> */}
                </foreignObject>
                
                <text
                className='progress-circle-text'
                x={textX}
                y={textY}
                textAnchor='end'
                alignmentBaseline='baseline'
                fill='#007bff'
                style={{dominantBaseline:'central'}}
                dy='.3em'
                >
                    {percent}%
                </text>
              
            </svg>
            <input 
                ref={fileInputRef}
                type='file'
                accept='image/*'
                style={{ display: 'none'}}
                onChange={handleFileSelect}
                />
                <label htmlFor='file-input'>
                <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        width='24'
                        height='24'
                        style={{
                            cursor:'pointer',
                            // marginTop:'10px',
                            // marginLeft:'5px',
                            position:'absolute',
                            top:'50%',
                            left:'80%',
                            transform: 'translate(-50%,-50%)',
                        }}  
                        onClick={handlePlusIconClick}
                    >
                        <path 
                        fill='#007bff'
                        //d='M19,11H13V5a1,1,0,0,0-2,0V6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z'
                        d='M12 4a1 1 0 0 1 1 1v5h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 0 1 0-2h5V5a1 1 0 0 1 1-1z'
                        onMouseDown={(e) => e.preventDefault()}
                        />
                    </svg>
                </label>
            {/* <img src={ProfilePic} className='card-img-top' alt="Card image cap" /> */}
        </div>
    )
}

ProgressCircle.propTypes ={
    percent: PropTypes.number.isRequired, 
    avatarURL: PropTypes.string.isRequired,   
  }; 

export default ProgressCircle;