import React from "react";
// import './../../../../Stylesheet/leftbar.css'
// import '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min'
// import '../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css'
import  {Link}  from 'react-router-dom'
//import ProfilePic from '../../../../Images/avatar.png';
import ProfileCard from '../profileCards/Profilecard';
//import ProfileCard from '../../shared/profileCards/ProfileCard';
import { useLocation } from "react-router-dom";

import './../../../../css/style.min.css';

const LeftbarNew = ()=>{
    const location = useLocation();     
    const handleLogout =()=>{    
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      }

      
    return(
        
           <aside className="left-sidebar" data-sidebarbg="skin6">
           
            <div className="scroll-sidebar">
            <ProfileCard></ProfileCard>
                <nav className="sidebar-nav">
                    <ul id="sidebarnav">
                       
                        <li className="sidebar-item pt-2">
                            <Link to={"/dashboard"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                                <i className="far fa-clock"></i> <span className="hide-menu left-menu-text">Dashboard</span>
                            </Link>
                        </li>
                        <li className="sidebar-item">                             
                            <Link to={"/profile"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/profile' ? 'active' : ''}`}>
                                <i className="fa fa-user"></i> <span className="hide-menu left-menu-text">My Profile</span>
                            </Link>
                        </li>
                        <li className="sidebar-item">                            
                            <Link to={"/mentor-sessions"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/mentor-sessions' ? 'active' : ''}`}>
                                <i className="fa fa-calendar"></i> <span className="hide-menu left-menu-text">Mentoring & Workshops </span>
                            </Link> 
                        </li>
                        <li className="sidebar-item"> 
                            <Link to={"/assessments"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/assessments' ? 'active' : ''}`}>
                                <i className="fas fa-tasks"></i> <span className="hide-menu left-menu-text">Assessments</span>
                            </Link> 
                        </li>
                        <li className="sidebar-item">                            
                            <Link to={"/courses"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/courses' ? 'active' : ''}`}>
                                <i className="fas fa-book"></i> <span className="hide-menu left-menu-text">Byte-Sized Courses</span>
                            </Link> 
                        </li>
                        <li className="sidebar-item">
                            <Link to={"/resourcesView"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/resourcesView' ? 'active' : ''}`}>
                                <i className="fa fa-database"></i> <span className="hide-menu left-menu-text">Resources</span>
                                </Link>
                        </li>
                        <li className="sidebar-item">
                           
                            <Link to={"/shortsView"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/shortsView' ? 'active' : ''}`}>
                                    <i className="far fa-image"></i> <span className="hide-menu left-menu-text">SoShorts</span>
                            </Link> 
                        </li>
                        <li className="sidebar-item">                            
                            <Link to={"/mentor-notifications"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/mentor-notifications' ? 'active' : ''}`}>
                                <i className="fa fa-bell"></i> <span className="hide-menu left-menu-text">Alerts & Messages</span>
                            </Link>
                        </li>
                        <li className="sidebar-item">                              
                            <Link to={"/mentor-payment-list"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/mentor-payment-list' ? 'active' : ''}`}>
                            <i className="fa fa-credit-card"></i> <span className="hide-menu left-menu-text">Payment Details</span>
                            </Link> 
                        </li>
                        <li className="sidebar-item"> 
                            <Link to={"/mentor-enroll-list"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/mentor-enroll-list' ? 'active' : ''}`}>
                                <i className="fas fa-registered"></i> <span className="hide-menu left-menu-text">Enrollments</span>
                            </Link>  
                        </li>
                        
                        <li className="sidebar-item"> 
                            <Link to={"/survey"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/survey' ? 'active' : ''}`}>
                                <i className="fs-4 bi-clipboard-data"></i> <span className="hide-menu left-menu-text">Survey</span>
                            </Link>  
                        </li>
                        <li className="sidebar-item"> 
                            <Link to={"/subscriptions"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/subscriptions' ? 'active' : ''}`}>
                                <i className="bi bi-credit-card"></i> <span className="hide-menu left-menu-text">Subscriptions</span>
                            </Link>  
                        </li>
                        <li className="sidebar-item"> 
                            <Link to={"/support"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/support' ? 'active' : ''}`}>
                                <i className="bi bi-question-circle"></i> <span className="hide-menu left-menu-text">Support</span>
                            </Link>  
                        </li>
                        
                        <li className="text-center p-20 upgrade-btn">
                            <a href="/login" onClick={handleLogout}
                                className="btn d-grid btn-menu text-white">
                                SignOut</a>

                                
                        </li>
                    </ul>

                </nav>
               
            </div>
           
        </aside>
         
    );
}
export default LeftbarNew;