// import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
// import * as ReactBootStrap from "react-bootstrap";
import { getEmotionExpresso } from "../../../api/adminApi";
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
import 'bootstrap/dist/css/bootstrap.min.css';
// import cellEditFactory from 'react-bootstrap-table2-editor';
// import OverlayFactory from "react-bootstrap-table2-overlay";
//import { Link } from "react-router-dom";
//import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { Card } from 'react-bootstrap';
//import Button  from "react-bootstrap/Button";
//import Modal from 'react-bootstrap/Modal';

import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function EmotionExpressoPage() {
  //const [clsName, setclsName] = useState('');
    const [items, setItems] = useState([]);
    //const [selectedImage, setSelectedImage] = useState(null);
    // const [showSnackbar, setShowSnackbar] = useState(false);
    // const [snackbarMessage, setSnackbarMessage] = useState('');
    // const [showConfirmation, setShowConfirmation] = useState(false);
    // const [rowDataToDelete, setRowDataToDelete] = useState(null);

    const classes = useStyles();

    const columns =[     
      {field: 'orgtype', headerName: 'orgtype', minWidth: 200},
      {field: 'orgname', headerName: 'orgname', minWidth: 200},    
      {field: 'title', headerName: 'Title', minWidth: 200},     
      {field: 'name', headerName: 'Name', minWidth: 250},
      {field: 'description', headerName: 'description', minWidth: 200},
      {field: 'createddate', headerName: 'createddate', minWidth: 200},
    ];
   

    useEffect(()=>{
      fetchEmotionExpressoData();

    },[]);
    
    const fetchEmotionExpressoData =() => {
      getEmotionExpresso().then(response=>{
        const data = response;
        setItems(data);
      });
    }
    
    
    return (
      <div className="featured-section-wrap featured-section-wrap-row">
      <div className='mainPageContainer'>        
          <div className='dashboardcontainerflex'>
            <div className='dashboardcard'>
            <Card >
              <Card.Body>
              
                  <Card.Title style={{ display: 'inline-block' }}>Emotion Expresso</Card.Title>
                
                  <div className="row">
                    <div className="col">
                      <div className={`${classes.dataGridContainer}`}>
                      <DataGrid
                        rows={items}
                        columns={columns}
                        getRowId={(row) => row.pguid}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        //hideFooterPagination
                        autoHeight
                        checkboxSelection={false}
                        classes={{
                          header: classes.header,
                          toolbarContainer: classes.toolbarContainer,
                          columnsContainer: classes.columnsContainer,
                          columnHeaderTitle: classes.columnHeaderTitle,
                          cell: classes.cell,
                          paginationItem: classes.paginationItem,
                          paginationItemSelected: classes.paginationItemSelected,
                          paginationItemHover: classes.paginationItemHover,
                        }}
                      />
              
                      </div>
                  
                    </div>
                    </div>
              </Card.Body>
            </Card>
          </div>
          </div>
          </div>
      </div>
    );

}

export default EmotionExpressoPage;