import React, { useState, useEffect } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { useFormik } from "formik"
 
import * as Yup from "yup";
import { getSearchDetailsByParams } from '../../../api/corporateApi';
 
import { DataGrid } from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';
import EmployeesListPageLND from "./EmployeesListPageLND";
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

const LNDLibraryManagement = () => {
    const [searchresultsList, setSearchresultsList] = useState([]);
    //const [selectedRows, setSelectedRows] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [isModelDisplay,setIsModelDisplay]=useState(false);
    const classes = useStyles();
    const [selectedContentguid, setSelectedContentguid] = useState(null);
    const [selectedContentType, setSelectedContentType] = useState(null);
    const [selectedContentTitle, setSelectedContentTitle] = useState(null);

    useEffect(() => {       
     // handleSubmit(formik.values);
    }, []);  
  

    

    const handleSubmit = async (values) => {
     
        try {
            const response = await getSearchDetailsByParams({
                ...values,
                ...formik.values // Include selected filters
            });
            
            setSearchresultsList(response);
        } catch (error) {
            console.log(error);
        }
    }

    const validationSchema = Yup.object({
      searchKeyword: Yup.string().required("Keyword is required.")
    });

    const formik = useFormik({
        initialValues: {
          searchKeyword: "",
            isMentors: true,
            isSkills: false,
            isCourses: false,
            isWorkshops: false,
            isResources: false,
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        formik.setFieldValue(name, checked);
    };

    const columns = [      
        // { field: "contenttype", headerName: "Content type", minWidth: 150 },
        { field: "title", headerName: "Title", minWidth: 170 },
        { field: "description", headerName: "Description", minWidth: 350 },
        {
          field: "image",
          headerName: "Type",
          sortable: false,
          minWidth: 50,
          renderCell: (params) => (
              <span className={`bi ${getImageForContentType(params.row.contenttype)}`} />
          ),
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        minWidth: 50,
        renderCell: (params) => (
          <>
            <span><Button onClick={() => handleViewClick(params.row)}>Assign</Button></span>
            {/* <i
              className="bi bi-chat"
              onClick={() => handleViewClick(params.row.contenttype)}
              style={{ paddingRight: "10px" }}
            /> */}
            </>
        ),
    },
    ];

    const handleViewClick = (row)=>{   
      setSelectedContentguid(row.contentguid); 
      setSelectedContentType(row.contenttype); 
      setSelectedContentTitle(row.title); 
      setIsModelDisplay(true);
      setModalShow(true);
    }

    const getImageForContentType = (contentType) => {
      switch (contentType) {
          case "MENTOR":
              return "bi bi-people"; //text-primary
          case "RESOURCE":
              return "bi bi-book"; //text-success
          case "COURSE":
              return "bi bi-person-video2"; //text-warning
          case "WORKSHOPS":
              return "bi-calendar"; //text-danger
          default:
              return "";
      }
  };

     
  //   const handleRowSelection = (selectedRowIds) => {
  //     setSelectedRows(selectedRowIds);
  // };
    return (
        <div className="featured-section-wrap featured-section-wrap-row">
            <div className="mainPageContainer">
                <div className="dashboardcontainerflex">
                    <div className="dashboardcard">
                        <Card>
                            <Card.Body style={{width:'100%'}}>
                            <CustomTooltip text="Use this feature to assign and suggest courses, assessments, and mentors to employees within your organization. It acts like a recommendation engine for your employees." />
                                <Form onSubmit={formik.handleSubmit}>
                                    <p>With our curated selection of courses, your team can develop new skills and stay ahead in their industry. Our mentors are industry experts who can provide valuable guidance and support to help your employees reach their full potential. We have many courses on &quot;emotional intelligence, empathy, critical thinking, resilience, diversity & inclusion, mindfulness, self-management, relationship building, leadership skills, and more&quot; and subject matter experts as &quot;Mentors&quot;</p>

                                    <p>COURSES, MENTORS AND RESOURCES</p>
                                      Search by
                                    <div className="row" >
                                        <div className="col-1">
                                         <Form.Check
                                                type="checkbox"
                                                name="isMentors"
                                                label='Mentors'
                                                checked={formik.values.isMentors}
                                                onChange={handleCheckboxChange}
                                            />
                                        </div>
                                          <div className="col-1">
                                            <Form.Check
                                                type="checkbox"
                                                name="isSkills"
                                                label='Skills'
                                                checked={formik.values.isSkills}
                                                onChange={handleCheckboxChange}
                                            />
                                        </div>
                                          <div className="col-1">
                                             <Form.Check
                                                type="checkbox"
                                                name="isCourses"
                                                label='Courses'
                                                checked={formik.values.isCourses}
                                                onChange={handleCheckboxChange}
                                            />
                                        </div>
                                          <div className="col-1">
                                            <Form.Check
                                                type="checkbox"
                                                name="isWorkshops"
                                                label='Workshops'
                                                checked={formik.values.isWorkshops}
                                                onChange={handleCheckboxChange}
                                            />
                                        </div>
                                          <div className="col-1">
                                           <Form.Check
                                                type="checkbox"
                                                name="isResources"
                                                label='Resources'
                                                checked={formik.values.isResources}
                                                onChange={handleCheckboxChange}
                                            />
                                        </div>
                                    </div>                                           
                                    <div className="row" >
                                        <div className="col">
                                            <div className="col">
                                                <Form.Group className="mb-3" controlId="searchKeyword">
                                                    <Form.Control
                                                        type="text"
                                                        name="searchKeyword"
                                                        placeholder="Type Mentor name, or Course name, or Resources"
                                                        isInvalid={Boolean(formik.errors.searchKeyword && formik.touched.searchKeyword)}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    <Button variant="primary" className="button" type="submit">
                                                        Submit
                                                    </Button>
                                                    <div className="error">{formik.errors.searchKeyword}</div>
                                                    

                                                    <div style={{minWidth:'750px'}} className={`${classes.dataGridContainer}`}>
                                                        <DataGrid
                                                            rows={searchresultsList}
                                                            columns={columns}
                                                            pageSize={5}
                                                            // checkboxSelection
                                                            initialState={{
                                                              pagination: {
                                                              paginationModel: { page: 0, pageSize: 5 },
                                                              },
                                                              }}
                                                            pageSizeOptions={[5, 10]}
                                                            getRowId={(row) => row.contentguid}
                                                            //onSelectionModelChange={handleRowSelection}
                                                            // components={{
                                                            //     Toolbar: () => (
                                                            //         <div style={{ textAlign: "right" }}>
                                                            //             <Button onClick={() => assignRows(selectedRows)}>Assign</Button>
                                                            //         </div>
                                                            //     ),
                                                            // }}
                                                            disableSelectionOnClick
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="col">
                                            </div>
                                        </div>
                                        {/*<div className="col">
                                             Search by
                                            <Form.Check
                                                type="checkbox"
                                                name="isMentors"
                                                label='Mentors'
                                                checked={formik.values.isMentors}
                                                onChange={handleCheckboxChange}
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                name="isSkills"
                                                label='Skills'
                                                checked={formik.values.isSkills}
                                                onChange={handleCheckboxChange}
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                name="isCourses"
                                                label='Courses'
                                                checked={formik.values.isCourses}
                                                onChange={handleCheckboxChange}
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                name="isWorkshops"
                                                label='Workshops'
                                                checked={formik.values.isWorkshops}
                                                onChange={handleCheckboxChange}
                                            />
                                            <Form.Check
                                                type="checkbox"
                                                name="isResources"
                                                label='Resources'
                                                checked={formik.values.isResources}
                                                onChange={handleCheckboxChange}
                                            /> 
                                        </div>*/}
                                    </div>
                                </Form>

                                {isModelDisplay && 
                                  <EmployeesListPageLND contentGUID={selectedContentguid} contentType={selectedContentType} contentTitle={selectedContentTitle} show={modalShow} onHide={() => {setModalShow(false); setIsModelDisplay(false);}}></EmployeesListPageLND>
                                }

                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LNDLibraryManagement;
