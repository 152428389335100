import React, {useEffect, useState } from "react";
// import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
// import Select from 'react-select';
// import { useFormik, Formik } from "formik"
// import * as Yup from "yup";
// import {useQuery, useQueryClient } from "react-query";
// import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
// import { Link } from "react-router-dom";
// import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import FilterFactoryProps, {textFilter} from "react-bootstrap-table2-filter";
//import filterFactory from "react-bootstrap-table2-filter";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { getPaymentsById,getSettlePercentageByGUID } from "../../../api/adminApi";
//import { getSettlePercentage } from "../../../api/mentorApi";

import { useParams } from "react-router-dom";

import SettlementUpdatePage from "./SettlementUpdatePage"; 
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function PaymentDetails(){
  const classes = useStyles();
    const { id } = useParams(); 
    const [paymentList, setPaymentList] = useState([]);
    const [settlementPerValue, setSettlementPerValue] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [isModelDisplay, setIsModelDisplay] = useState(false);
    const [guid, setGuid] = useState(null);

    // const dateFormatter = (cell) => { //, row
    //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
    //     return <span>{formattedDate}</span>;
    // }
     
    const columns =[
      {field: 'type', headerName: 'Type', minWidth: 150},
      {field: 'paymentfromuserlogon', headerName: 'From', minWidth: 150},
      {field: 'paymenttouserlogon', headerName: 'To', minWidth: 150},
      {field: 'finalamount', headerName: 'Amount', minWidth: 150},
      {field: 'paymentdate', headerName: 'Payment Date', minWidth: 150, 
      valueGetter: (params) => moment(params.row.paymentdate).format("YYYY-MM-DD HH:mm")
      },
      {field: 'action', headerName: 'action', minWidth: 150},

        // // {dataField: 'payment.id', text: '#'},
        // {dataField: 'type', text: 'Type'},        
        // {dataField: 'paymentfromuserlogon', text: 'From'},
        // {dataField: 'paymenttouserlogon', text: 'To'},
        // {dataField: 'finalamount', text: 'Amount'},
        // // {dataField: 'refNum', text: 'ref. Num'},
        // {dataField: 'paymentdate', text: 'Payment Date', formatter: dateFormatter},
        // // {dataField: 'status', text: 'Status'},
        // {
        //     dataField: 'action',
        //     text: 'Action',
        //     // formatter: (cell, row) => (
        //     //   <>
        //     //   {/* <Link to={`/mentor-details/${row.id}`}><i className="bi bi-activity" style={{paddingRight:"10px", fontSize:'20px'}}/></Link> */}
               
        //     //   </>
        //     // ),
        // },
    ];
    useEffect(()=>{
        fetchPaymentsData();
        fetchSettleentPercentageData();
      },[]);

      const fetchSettleentPercentageData =() => {
        getSettlePercentageByGUID(id).then(response=>{                    
            setSettlementPerValue(response);             
        });
      }
      const handleViewClick = (guid) => {
      
        setGuid(guid);
        setIsModelDisplay(true);
        setModalShow(true);
      };

      const fetchPaymentsData =() => {
        getPaymentsById(id).then(response=>{                    
            setPaymentList(response); 
        });
      }

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <ListGroup variant="flush">
                    <ListGroup.Item> 
                    
                    <div className="row">
                        <div className="col">
                        <Card.Subtitle>Payment Settlements</Card.Subtitle>
                          <div style={{float:"right"}}>Payment settlement - {settlementPerValue.settleper} %  <i className="bi bi-pencil" onClick={() => handleViewClick(id)}></i></div>  
                            {/* <BootstrapTable 
                            keyField="payment.id"
                            data={paymentList}
                            columns={columns}
                            // cellEdit={cellEditFactory(cellEditOptions)}
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                            /> */}
                             <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={paymentList}
                                columns={columns}
                                getRowId={(row) => row.payment.id}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
                        </div>
                    </div>
                    <Button variant="primary" type="submit" className="button">
                          Export to Excel
                        </Button> 
                    </ListGroup.Item>
                </ListGroup>
               
                {isModelDisplay && (
              <SettlementUpdatePage
                id={guid}
                show={modalShow}
                //onPaymentSettlementSubmit={handleReloadUpdateEvents}
                onHide={() => {
                  setModalShow(false);
                  setIsModelDisplay(false);
                }}
              ></SettlementUpdatePage>
            )}

                </div>
            </div>
        </div>
        </div>        

    )

}
export default PaymentDetails