import React, {useEffect, useState } from "react";
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import { getAssessmentInvites,addAssessmentInvites } from "../../../api/corporateApi";
import { Link,useParams } from "react-router-dom";
import moment from "moment/moment";
import SearchInputs from "../../common/shared/search/SearchInputs";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import useStyles from '../../common/shared/grid/useStyles';
import { DataGrid} from '@mui/x-data-grid';

function SendInvitesPage(){
  const classes = useStyles();
    const { guid } = useParams();  
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const isAnyCheckboxSelected = selectedEmployees.length > 0;
    const [headerData, setHeaderData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

  //   const dateFormatter = (cell) => {  
  //     if (!cell) {
  //       return <span>{'N/A'}</span>;  
  //     }

  //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
  //     return <span>{formattedDate}</span>;
  // }

    const columns =[
      {
        field: 'selectField',
        headerName: 'Select',
        minWidth: 50,
        renderCell: (params) => (
          <>
           <input
                type="checkbox"
                value={params.row.emailaddress}  
                onChange={handleCheckboxChange}
                disabled={!!params.row.sentdate}
              />
          </>
        ),
      },
      {field: 'firstname', headerName: 'First Name', minWidth: 200},
      {field: 'emailaddress', headerName: 'emailaddress', minWidth: 200},
      {field: 'sentdate', headerName: 'Sent Date', minWidth: 150, 
      valueGetter: (params) => moment(params.row.sentdate).format("YYYY-MM-DD HH:mm")
      },

        // {
        //     dataField: 'emailaddress',  
        //     text: 'Select',
        //     formatter: (cell, row) => (
        //       <input
        //         type="checkbox"
        //         value={row.emailaddress}  
        //         onChange={handleCheckboxChange}
        //         disabled={!!row.sentdate}
        //       />
        //     ),
        //   },
        // {dataField: 'firstname', text: 'First Name'},
        // // {dataField: 'lastname', text: 'Last Name'},
        // {dataField: 'emailaddress', text: 'Email Address'},
        // {dataField: 'sentdate', text: 'Sent Date', formatter: dateFormatter},
         
    ];

    const handleCheckboxChange = (event) => {
        const employeeId = event.target.value;
        const isChecked = event.target.checked;
      
        if (isChecked) {
          setSelectedEmployees((prevSelectedEmployees) => [...prevSelectedEmployees, employeeId]);
        } else {
          setSelectedEmployees((prevSelectedEmployees) =>
            prevSelectedEmployees.filter((id) => id !== employeeId)
          );
        }
      
       // setIsAnyCheckboxSelected(selectedEmployees.length > 0);
      };
      

      
    useEffect(()=>{
        fetchAssessentInvitesData();
          },[selectedEmployees]);
      
      const fetchAssessentInvitesData =() => {
        getAssessmentInvites(guid).then(response=>{                    
          setEmployeeList(response); 
          if (response.length > 0) {
            setHeaderData(response[0].title); 
          }
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }
    
    
    const handleSendInvite = () => {
      if (selectedEmployees.length === 0) {
          alert('No employees selected');
          return;
      }
  
      const invitePromises = selectedEmployees.map(email => {
          // Return a promise for each API call
          return sendInviteAPI(email);
      });
  
      Promise.all(invitePromises)
          .then(results => {
             
              //alert(JSON.stringify(results));
              const successCount = results.filter(result => result.success).length;
              const failureCount = results.filter(result => !result.success).length;
              const message = `Invitations sent: ${successCount}\nInvitations failed: ${failureCount}`;
              //alert(message);
              setSnackbarMessage(message);
              setShowSnackbar(true);

          })
          .catch(error => {
              // Handle any error that occurs during API calls
              console.error('Error sending invitations:', error);
          });
  
      // Clear the selected employees after sending invites
      setSelectedEmployees([]);
      setEmployeeList([]);
      fetchAssessentInvitesData();
  };
  
  const sendInviteAPI = (email) => {
    const payload = {
        emailaddress:email,
        assessmentguid: guid  
    };
     
    return  addAssessmentInvites(payload)
    .then(response => { 
      return response.json();
    })
    .then(data => {
        const success = data[0].responsemessage;  
        return { email, success };
    })
    .catch(error => {
        console.error(`Error sending invite to ${email}:`, error);
        return { email, success: false };
    });
       
  };
  
 

    useEffect(()=>{
      const filteredResults = employeeList.filter((item) => {
          const emailMatch = item.emailaddress.toLowerCase().includes(emailSearch.toLowerCase());
  
          return emailMatch;
      });
      setFilteredData(filteredResults);
      },[employeeList,emailSearch]);
      
      const handleEmailChange = (e) => {
      setEmailSearch(e.target.value);
      }
    
      const handleCloseSnackbar = () => {
        setShowSnackbar(false);
      }

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <Card style={{ width: '62rem', height: '53rem' }}>
                    <Card.Body>
                    <Link to="/assessments">
                    <Button variant="primary" type="submit" style={{float:"right"}} >
                        Back
                    </Button>
                    </Link>  
                    <Card.Title style={{ display: 'inline-block' }}>Invitation - {headerData} </Card.Title>
                    <br/> <br/>
                    <SearchInputs
                    showEmailSearch={true}
                    emailSearch={emailSearch}
                    onEmailChange={handleEmailChange}
                    ></SearchInputs>
                    {/* <BootstrapTable 
                    keyField="invitationid"
                    data={filteredData}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory()}
                    bootstrap4
                    striped
                    hover
                    condensed
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-bordered table-hover"
                    /> */}
                    <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.uguid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

               <Button variant="primary" type="submit" onClick={handleSendInvite} disabled={!isAnyCheckboxSelected}>
                Send Invite
                </Button>
                      
                    </Card.Body>
                </Card>
                </div>
            </div>
            <div>
                    <SnackbarProvider 
                    show={showSnackbar}
                    message={snackbarMessage}
                    onClose={handleCloseSnackbar}
                    />
                </div>
        </div>
        </div>        

    )

}
export default SendInvitesPage