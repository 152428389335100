// import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
// import * as ReactBootStrap from "react-bootstrap";
import { getSubscriptionPlan, deleteSubscriptionPlan } from "../../../api/adminApi";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import 'bootstrap/dist/css/bootstrap.min.css';
// import cellEditFactory from 'react-bootstrap-table2-editor';
// import OverlayFactory from "react-bootstrap-table2-overlay";
import { Link } from "react-router-dom";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { Card } from 'react-bootstrap';
import Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function SubscriptionSetupPage() {
  const classes = useStyles();
    const [items, setItems] = useState([]);
    //const [selectedImage, setSelectedImage] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    //const [modalShow, setModalShow] = useState(false);
    const [rowDataToDelete, setRowDataToDelete] = useState(null);

    const columns =[
      {field: 'rplanname', headerName: 'Plan', minWidth: 150},
      {field: 'rdescription', headerName: 'Description', minWidth: 150},
      {field: 'rinterval', headerName: 'Interval', minWidth: 150},
      {field: 'rintervaltype', headerName: 'Type', minWidth: 150},
      {field: 'rprice', headerName: 'Price', minWidth: 150},
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        renderCell: (params) => (
          <>
             <Link to={`/config-subscriptionplan/edit/${params.row.rguid}`}><i className="bi bi-pencil-fill" style={{paddingRight:"10px"}}/></Link>
           
           <i className="bi bi-trash" title={params.cell} onClick={() => handleDeleteClick(params.row)} />
          </>
        ),
      },

      // {dataField: 'rplanname', text: 'Plan'},
      // {dataField: 'rdescription', text: 'Description'},
      // {dataField: 'rinterval', text: 'Interval'},
      // {dataField: 'rintervaltype', text: 'Type'},
      // {dataField: 'rprice', text: 'Price'},  
      // {
      //   dataField: 'actions',
      //   text: 'Actions',
      //   formatter: (cell, row) => (
      //     <>
      //     <Link to={`/config-subscriptionplan/edit/${row.rguid}`}><i className="bi bi-pencil-fill" style={{paddingRight:"10px"}}/></Link>
           
      //     <i className="bi bi-trash" title={cell} onClick={() => handleDeleteClick(row)} />
      //     </>
      //   ),
      // },
    ];

    
   
    const handleDeleteClick = (row) => {
      setRowDataToDelete(row);
      setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
      const updatedDataToDelete = {
          ...rowDataToDelete,
        };
 
        deleteSubscriptionPlan(updatedDataToDelete).then((response)=>{    
          if(!response.ok){
              // const err = new Error("Error in response");
              // throw err;
              setSnackbarMessage('Error in response');
              setShowSnackbar(true);
          } else{
              //return response.json();
          }
          }).then(response=>{
            console.log(response);
             
              //return response;
              setSnackbarMessage('Successfully deleted Subscription plan data.');
              setShowSnackbar(true);
              fetchSubscriptionPlanData();
          });

      setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
      setShowConfirmation(false);
  }; 


    useEffect(()=>{
      fetchSubscriptionPlanData();

    },[]);
    
    const fetchSubscriptionPlanData =() => {
      getSubscriptionPlan().then(response=>{
        const data = response;
        setItems(data);
      });
    }
    
    const handleCloseSnackbar = () => {
      setShowSnackbar(false);
    }
    
    return (
      <div className="featured-section-wrap featured-section-wrap-row">
      <div className='mainPageContainer'>        
          <div className='dashboardcontainerflex'>
            <div className='dashboardcard'>
            <Card style={{ width: '62rem', height: '53rem' }}>
              <Card.Body>
                <Link to="/config-subscriptionplan/create">
                  <Button variant="primary" type="submit" className="button" style={{float:"right"}} >
                      + Add Subscription Plan
                  </Button>
                  </Link>
                  <Card.Title style={{ display: 'inline-block' }}>Subscription Plans</Card.Title>
                  <div className="row">
                  <div className="col">
&nbsp;
                    </div>
                    </div>
                  <div className="row">
                            <div className="col">
                    {/* <BootstrapTable 
                    keyField="rguid"
                    data={items}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory()}
                    /> */}
     <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={items}
                                columns={columns}
                                getRowId={(row) => row.rguid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

                    <div>
                      <SnackbarProvider 
                      show={showSnackbar}
                      message={snackbarMessage}
                      onClose={handleCloseSnackbar}
                      />
                    <Modal show={showConfirmation} onHide={handleCancelDelete}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="danger" className="button" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                    </Modal.Footer>
                    </Modal>

                    </div>
                    </div>
                    </div>
              </Card.Body>
            </Card>
          </div>
          </div>
          </div>
      </div>
    );

}

export default SubscriptionSetupPage;