import React, {useState, useRef, useEffect} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
// import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';

import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import listPlugin from "@fullcalendar/list";
import { DayPicker } from "react-day-picker";
import 'react-day-picker/dist/style.css';
// import parse from "date-fns/parse";

// import { Calendar } from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
// import ptBrLocale from "@fullcalendar/core/locales/pt-br";
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
import clockimg from '../../../Images/clock.svg';

import CreateEvent from "./CreateEvent";
import EditEvent from "./EditEvent";
import  {getEvents,addEvent,updateEventApi,cancelSession}  from "../../../api/mentorApi";

// import axios from "axios";
// import { useQuery } from "react-query";
import moment from 'moment';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";

function MentorCalendarPage(){
  const [clsName, setclsName] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
    const [date, setDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const [modalShow, setModalShow] = useState(false);
    const [editmodalShow, setEditModalShow] = useState(false);
    const[editevent,setEditevent] = useState({});
    //const[selectedSlot,setSelectedSlot]=useState(null);
    const[isCreateEventDisplay,setIsCreateEventDisplay]=useState(false);
    const[isEditEventDisplay,setIsEditEventDisplay]=useState(false);
    const calendarRef = useRef(null);
    const [events, setEvents] = useState([]);
   


   useEffect(()=>{
 
    //const {data,status} = useQuery("users", getEvents);
    // setEvents(getEvents());
    getEvents().then(response=>{       
       const formattedEvents = response.map(event => ({
        ...event,
        start: moment(event.estart).toISOString(),
        end: moment(event.eend).toISOString(),
      }));
    
      setEvents(formattedEvents);
       
       
       });
},[])

const handleCreateEvent = ()=>{
  setModalShow(true);
  setIsCreateEventDisplay(true);
}

const addNewEvent =(event)=>{ 
  addEvent(event).then(eventresponse=>{ 
    console.log(eventresponse);
    setclsName('alert success');
    setSnackbarMessage('Successfully added the session.');
    setShowSnackbar(true);
    setModalShow(false);
    handleDateChange(date);
    // getEvents().then(response=>{
    //   setSnackbarMessage('Successfully added the session.');
    //   setShowSnackbar(true);
    //   setEvents(response);
    //   setModalShow(false);
    //    });

    getEvents().then(response=>{       
      const formattedEvents = response.map(event => ({
       ...event,
       start: moment(event.estart).toISOString(),
       end: moment(event.eend).toISOString(),
     }));
   
     setEvents(formattedEvents);
      });

  });

}

const deleteEvent = (eventObj)=>{
  const formDataToSend = {
    id: eventObj.id,
    comments: "Cancelling the workshop."
  };
  // alert(JSON.stringify(formDataToSend));
  // cancelSession(formDataToSend).then(deleteres=>{
  //   console.log("res - " +JSON.stringify(deleteres));     
  //   getEvents().then(response=>{
  //     setSnackbarMessage('Successfully deleted the session.');
  //     setShowSnackbar(true);
  //     setEvents(response);
  //     setEditModalShow(false);
  //      });
  // });

  cancelSession(formDataToSend).then((response)=>{
    if(!response.ok){
      setclsName('alert error');
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        return response.json();
    }
    }).then(response=>{
      console.log(response);
        //return response;
        setclsName('alert success');
        //setSnackbarMessage('Successfully updated workshop data.');
        setSnackbarMessage(JSON.stringify(response));
        setShowSnackbar(true);
        // setEvents(response);
        // setEditModalShow(false);
    });

 
  // setEvents(events.filter(obj=>obj.title != eventObj.title))
  // setEditModalShow(false);
}

const updateEvent = (eventObj) =>{
  // updateEventApi(eventObj).then(updateres=>{
  //   console.log(updateres.responsemessage);     
  //   setSnackbarMessage('Successfully updated the session.');
  //   setShowSnackbar(true);

  //   setEditModalShow(false);
  //   handleDateChange(date);
  // });
  
  updateEventApi(eventObj).then((response)=>{
    if(!response.ok){
      setclsName('alert error');
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        return response.json();
    }
    }).then(response=>{
      console.log(response);
        //return response;
        setclsName('alert success');
        //setSnackbarMessage('Successfully updated workshop data.');
        setSnackbarMessage(JSON.stringify(response));
        setShowSnackbar(true);
        setEditModalShow(false);
        handleDateChange(date);
        //formik.resetForm();


       // props.onWorkshopSubmit();
       getEvents().then(response=>{       
        const formattedEvents = response.map(event => ({
         ...event,
         start: moment(event.estart).toISOString(),
         end: moment(event.eend).toISOString(),
       }));
       setEvents(formattedEvents);
        });

    });


}

const handleEventClick = (eventobj)=>{
 
  setEditevent(eventobj);
  setIsEditEventDisplay(true);
  setEditModalShow(true);
}

const handleCalendarEventClick =(clickInfo)=>{
  setSelectedEvent(clickInfo.event);
  setEditevent(clickInfo.event);
  setIsEditEventDisplay(true);
  setEditModalShow(true);
}

const eventRender =(info)=>{
  info.el.eventListener('click',()=>{
    setSelectedDate(info.event.start);
  });
}

const modifiers = {
  selected: date, // Define a custom CSS class for the selected date
};
const handleDateChange=(seldate)=>{

  setDate(seldate);
  getEvents().then(response=>{
    const formattedEvents = response.map(event => ({
      ...event,
      start: moment(event.estart).toISOString(),
      end: moment(event.eend).toISOString(),
    }));
  
    setEvents(formattedEvents.filter((event)=>{
      return ((new Date(event.start).getDate()+'/'+new Date(event.start).getMonth()+'/'+new Date(event.start).getFullYear()) == (seldate.getDate()+'/'+seldate.getMonth()+'/'+seldate.getFullYear()))
    }));
     
     
     });
      
  const api = calendarRef.current.getApi();
  api.gotoDate(seldate);
  api.changeView("timeGridDay");
}

// const eventFilterOnDateChange =(event,seldate)=>{
//   return ((new Date(event.start).getDate()+'/'+new Date(event.start).getMonth()+'/'+new Date(event.start).getFullYear()) == (seldate.getDate()+'/'+seldate.getMonth()+'/'+seldate.getFullYear()))
// }

const handleCalendarDateClick= (clickInfo)=>{
 
  setSelectedDate(clickInfo.date);
  console.log(selectedEvent);
  setModalShow(true);
}
function getTimeFromDate(datetime) {
  return datetime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false});
}
function getStingToDate(dateString) {
  const date = new Date(dateString);
  return date.toDateString();
}
 
const handleCloseSnackbar = () => {
  setShowSnackbar(false);
}   

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                
                  <Card>
                  <Card.Body>
                    <Card.Text> 
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5" style={{paddingTop:"7px"}}>
                            <Card.Title style={{ display: 'inline-block' }}>My Sessions</Card.Title>   
                                <Form.Group  controlId="formLeftSideSections">                                    
                                    <DayPicker 
                                    selectedDays={selectedDate}
                                    onDayClick={handleDateChange}
                                    modifiers={modifiers}
                                    className="custom-day-picker"/>
                                    <h4>Sessions</h4>
                                    <div className="eventsContainer">
                                    {events.map((item)=>
                                      (
                                      <div key={item.id}
                                      className="card mt-3 flex w-full cursor-pointer flex-col rounded-lg border border-slate-300 p-2 transition-all hover:animate-pulse"
                                      onClick={()=>{handleEventClick(item)}}
                                      >
                                        
                                      {/* <article 
                                      className="card mt-3 flex w-full cursor-pointer flex-col rounded-lg border border-slate-300 p-2 transition-all hover:animate-pulse"
                                      > */}
                                      <p className="text-sm" style={{margin:0}}>{item.title}</p>
                                      <p className="text-xs" style={{margin:0}}>{getStingToDate(item.start)}</p>
                                      <span className="mt-3 flex flex-row items-center gap-x-2 aLink" style={{color:"#44D5E7"}}>                                     
                                          <img src={clockimg}  /> {getTimeFromDate(new Date(item.start))} -  {getTimeFromDate(new Date(item.end))}                                
                                      </span>
                                      {/* </article> */}
                                      </div>)
                                      )
                                    }
                                    </div>
                                

                                </Form.Group>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-7">
                              <div className="row">
                                <div className="col">
                                <label>{(date != null && date != undefined) ? date.toDateString() : ''}</label>
                                </div>
                                <div className="col">
                                <Button variant="primary" type="submit" className="button" style={{float:"right"}} onClick={handleCreateEvent} >
                                  + Create sessions
                                </Button>
                                </div>
                              </div>
                              <div className="row">
                                <Form.Group className="mb-3" controlId="formRightSideSection">
                              <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      height: "100%"
                                    }}
                                  >
                                    <div
                                      style={{
                                        border: "1px",
                                        boxShadow: "0 1px 2px 0 rgba(0,0,0,.1)",
                                        borderTop: "4px solid #fac863",
                                        background: "#fff",
                                        height: "100%",
                                        width: "620px",
                                        marginTop: 36,
                                        paddingTop: 14
                                      }}
                                    >
                                    <FullCalendar                                      
                                    height="550px"
                                    plugins={[timeGridPlugin, interactionPlugin]}
                                    initialView="timeGridDay"
                                    duration={{days:1}}
                                    eventClick={handleCalendarEventClick}
                                    selectable={true}
                                    dateClick={handleCalendarDateClick}
                                    //select={(info)=>setSelectedSlot(info)}
                                    events={events}
                                    ref={calendarRef}
                                    //slotDuration="00:30:00"
                                    //weekends={false}
                                    selectOverlap={false}
                                    //selectConstraint={{
                                      //  start: '06:00',
                                        //end: '20:00'
                                    //}}
                                  // eventOverlap={false}
                                  // allDaySlot={false}
                                    // headerToolbar={{
                                    //     start: 'Title Mahesh',
                                    //     center: '',
                                    //     end: 'today prev, next'
                                    // }}
                                    headerToolbar={false}
                                    eventRender={eventRender}
                                  />

                                      
                                    </div>
                              </div>
                              <div>
                                {isCreateEventDisplay && 
                                <CreateEvent show={modalShow} onHide={() => {setModalShow(false); setIsCreateEventDisplay(false);}} addNewEvent={addNewEvent} ></CreateEvent>
                                }
                                
                                {isEditEventDisplay && 
                                <EditEvent editevent={editevent} deleteEvent={deleteEvent} updateEvent={updateEvent} show={editmodalShow} onHide={() => {setEditModalShow(false); setIsEditEventDisplay(false);}}></EditEvent>
                                }
                              </div>

                                </Form.Group>
                                </div>
                            </div>                           
                        </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <SnackbarProvider 
                    show={showSnackbar}
                    message={snackbarMessage}
                    onClose={handleCloseSnackbar}
                    message1={clsName}
                    />
        </div>
        </div>
              
            );

}
export default MentorCalendarPage;