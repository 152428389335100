import React,{useState} from "react";

// import Button  from "react-bootstrap/Button";
import Col  from "react-bootstrap/Col";
import Alert from 'react-bootstrap/Alert';
import { Card, Form, Button, ProgressBar } from 'react-bootstrap';
// import Select from 'react-select';
import { Link } from 'react-router-dom';
//import * as Yup from "yup";
// import {useQueryClient } from "react-query";
//import { registerWithUserDetails } from "../../../api/userAuthApi";
import Row  from "react-bootstrap/Row";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
//import  SnackbarProvider  from "../../authContext/SnackbarProvider";
//import topbarLogo from '../../../../src/Images/lifeskylls-logo.png';

const CRegisterPage1 = () => {
  //const [showSnackbar, setShowSnackbar] = useState(false);
 // const [snackbarMessage, setSnackbarMessage] = useState('');
 // const [isError, setIsError] = useState(null);
  //const [clsName, setclsName] = useState('');

  const [currentStep, setCurrentStep] = useState(1);

  const totalSteps = 4; // Total number of steps

  const stepColors = {
    1: 'primary',
    2: 'success',
    3: 'warning',
    4: 'info',
  };

  // const validationSchemas = {
  //   1: Yup.object({
  //     // Validation schema for step 1
  //   }),
  //   2: Yup.object({
  //     // Validation schema for step 2
  //   }),
  //   3: Yup.object({
  //     // Validation schema for step 3
  //   }),
  //   4: Yup.object({
  //     // Validation schema for step 4
  //   }),
  // };

  const formikConfig = {
    1: {
      initialValues: {
        // Initial values for step 1
        field1: '',
        field2: '',
      },
      onSubmit: () => setCurrentStep(2),
    },
    2: {
      initialValues: {
        // Initial values for step 2
        field3: '',
        field4: '',
      },
      onSubmit: () => setCurrentStep(3),
    },
    3: {
      initialValues: {
        // Initial values for step 3
        field5: '',
        field6: '',
      },
      onSubmit: () => setCurrentStep(4),
    },
    4: {
      initialValues: {
        // Initial values for step 4
        field7: '',
        field8: '',
      },
      onSubmit: () => {
        // Handle final submission
        console.log('Registration completed!');
      },
    },
  };

  const formik = useFormik(formikConfig[currentStep]);

  const moveToPreviousStep = () => {
    setCurrentStep((prevStep) => Math.max(1, prevStep - 1));
  };

  const moveToNextStep = () => {
    formik.handleSubmit(); // Run form submission logic for the current step
  };

  return (
    <Card>
      <Card.Body>
        <ProgressBar>
          {[...Array(totalSteps).keys()].map((step) => (
            <ProgressBar
              key={step + 1}
              variant={stepColors[step + 1]}
              now={step + 1 === currentStep ? 100 : 0}
            />
          ))}
        </ProgressBar>
        <Form onSubmit={formik.handleSubmit}>
          
          {currentStep === 1 && (
            <>
            Basic Informations
            <Form onSubmit={formik.handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col}  controlId="formcontrolCompName">
                                <Form.Label>Company Name</Form.Label>
                                <Form.Control
                                required
                                type='text'
                                placeholder="Company Name"
                                name="company_name" 
                                value={formik.values.company_name}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage
                                    fieldName="company_name"
                                    formik={formik}
                                ></FormErrorMessage>
                            </Form.Group>
                            
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col}  controlId="formcontrolFirstName">
                                <Form.Label>Your Name</Form.Label>
                                <Form.Control
                                required
                                type='text'
                                placeholder="First Name"
                                name="first_name" 
                                value={formik.values.first_name}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage
                                    fieldName="first_name"
                                    formik={formik}
                                ></FormErrorMessage>
                                <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please provide First Name</Form.Control.Feedback>
                            </Form.Group>
                            
                        </Row>
                                

                                <Row className="mb-3">
                                <Form.Group as={Col}  controlId="formcontrolEmail">
                                <Form.Label>Email</Form.Label>
                                    <Form.Control
                                required
                                type='email'
                                placeholder="Email"
                                name="email_address" 
                                value={formik.values.email_address}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage
                                    fieldName="email_address"
                                    formik={formik}
                                ></FormErrorMessage>
                                <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please provide valid email</Form.Control.Feedback>
                                </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                <Form.Group as={Col}  controlId="formcontrolMobileNumber">
                                <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                required
                                type='text'
                                placeholder="Mobile Number"
                                name="mobile_number" 
                                value={formik.values.mobile_number}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage
                                    fieldName="mobile_number"
                                    formik={formik}
                                ></FormErrorMessage>
                                <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please provide Last Name</Form.Control.Feedback>
                                </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                <Form.Group as={Col}  controlId="formcontrolProfileURL">
                                <Form.Label>LinkedIn Profile URL</Form.Label>
                                    <Form.Control
                                    required
                                    type='text'
                                    placeholder="Profile URL"
                                    name="profile_URL" 
                                    value={formik.values.profile_URL}
                                    onChange={formik.handleChange} 
                                    onBlur={formik.handleBlur}
                                    />
                                    <FormErrorMessage
                                        fieldName="profile_URL"
                                        formik={formik}
                                    ></FormErrorMessage>
                              
                                </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                <Form.Group as={Col}  controlId="formcontrolPassword">
                                <Form.Label>Password</Form.Label>
                                    <Form.Control
                                required
                                type='password'
                                placeholder="Paswword"
                                name="password" 
                                value={formik.values.password}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}
                                />
                                <FormErrorMessage
                                    fieldName="password"
                                    formik={formik}
                                ></FormErrorMessage>
                                <Form.Control.Feedback>Looks Good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please provide valid email</Form.Control.Feedback>
                                </Form.Group>
                                </Row>
                                {/* {isError !== null && (
                                <div className={isError ? 'alert error' : 'alert success'}>
                                    {snackbarMessage}
                                </div>
                                )} */}


                               
                                 
                                <Button 
                                //variant="primary" 
                                type="submit" 
                                className="button"
                                //disabled={mutation.isLoading}
                                //onClick={handleSubmit}
                                >
                                Submit{" "}
                                
                                </Button>
                                <Alert key='primary' className="alertbox" variant='primary'>
                                    <Link to="/Login" className="aLink">Already has an account? Login here</Link>
                                </Alert>
                    </Form>
            </>
          )}
          {currentStep === 2 && (
            <>
            Subscriptions
             <p></p>
              Subscription Options
              <p></p>3 Months Access
              <p></p>Get 3 months of access to all product features for just $25.

              <p></p>Lifetime Access
              <p></p>Get lifetime access to all product features for a special offer price of $100.

              <p></p>Regular price: $250

              <p></p>Offer valid until December 25th
<br></br>
            </>
          )}
          {currentStep === 3 && (
            <>
            Payments
              <Form.Group controlId="field5">
                <Form.Label>Field 5</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Field 5"
                  {...formik.getFieldProps('field5')}
                />
              </Form.Group>
              <Form.Group controlId="field6">
                <Form.Label>Field 6</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Field 6"
                  {...formik.getFieldProps('field6')}
                />
              </Form.Group>
            </>
          )}
          {currentStep === 4 && (
            <>
            Final Review
              <Form.Group controlId="field7">
                <Form.Label>Field 7</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Field 7"
                  {...formik.getFieldProps('field7')}
                />
              </Form.Group>
              <Form.Group controlId="field8">
                <Form.Label>Field 8</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Field 8"
                  {...formik.getFieldProps('field8')}
                />
              </Form.Group>
            </>
          )}
          <Button type="button" onClick={moveToPreviousStep} disabled={currentStep === 1}>
            Previous
          </Button>
          <Button type="button" onClick={moveToNextStep}>
            {currentStep === totalSteps ? 'Submit' : 'Next'}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CRegisterPage1;
