import React, { useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
//import BootstrapTable from "react-bootstrap-table-next";
import { getNotifications,getEnrollments,getWorkShops,getOrgsDashboard,getDashboardStatistics } from "../../../api/mentorApi";
import { DataGrid} from '@mui/x-data-grid'; //GridColDef, GridValueGetterParams 
//import { makeStyles } from '@mui/styles';
//import Button from 'react-bootstrap/Button';
import { getCurrentUser } from '../../../api/userAuthApi';
import useStyles from '../../common/shared/grid/useStyles';

const DashboardPage =()=>{
  const [enrollmentList, setEnrollmentList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [workshopList, setWorkshopList] = useState([]);
  const[orgsDataList,setorgsDataList] = useState([]); 
  //const[totalcompValue,settotalcompValue] = useState(0);
  //const[totalmentorValue,settotalmentorValue] = useState(0);
  const[totalusersValue,settotalusersValue] = useState(0);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isAlertVisible, setAlertVisible] = useState(true);

  const [userName, setUserName] = useState('');
 
  const classes = useStyles();

  const columnsWorkshops =[
    {field: 'title', headerName: 'Title', width: 150},
    {field: 'date', headerName: 'Date'},
    {
      field: 'estart',
      headerName: 'Time',
      description: '',
      //type: 'number',
      sortable: false,     
      valueGetter: (params) => `${params.row.estart || '-'} ${params.row.eend || ''}`,
    },
    
  ]; 
 
  const columnsEnrollments =[     
    // {field: 'id', headerName: '#', width: 50},
    {field: 'firstname', headerName: 'Name', width: 150},
    {field: 'type', headerName: 'Category', width: 250},
    {field: 'scheduledat', headerName: 'Scheduled At', width: 200},
    {field: 'status', headerName: 'Status', width: 100},
  ];
  
  const columnsNotifications = [
    // { field: 'id', headerName: '#'},
    { field: "receiverlogon", headerName: "Name" },
    { field: "notificationtype", headerName: "Type" },
    { field: "message", headerName: "Message" },
    { field: "status", headerName: "Status" },
    { field: "createddate", headerName: "Created Date" },
  ];
 
  useEffect(()=>{
    const isDismissed = localStorage.getItem('alertDismissed');     
    setAlertVisible(!isDismissed);
    setIsInitialRender(false);
  });

  useEffect(()=>{
    
    fetchEnrollmentsData();
    fetchNotificationsData();
    fetchWorkshopData();

    getOrgsDashboard().then(response => {
      setorgsDataList(response);
    });
    getDashboardStatistics().then(response => {
      //setdashboardStatisticsList(response);
      // settotalcompValue(response[0].totalcompanies);
      //settotalmentorValue(response[0].totalmentors);
      settotalusersValue(response[0].totalusers);
    });

  },[]);
    
  useEffect(() => {
    const user = getCurrentUser();         
    if (user) {         
        setUserName(user.firstname + ' ' + user.lastname);
    }
  },[]);

  
  const fetchWorkshopData =() => {
    getWorkShops().then(response=>{                    
      setWorkshopList(response); 
      
    });
  }   

  const fetchEnrollmentsData =() => {
    getEnrollments().then(response=>{                    
        setEnrollmentList(response); 
    });
  }

  const fetchNotificationsData =() => {
    getNotifications().then(response=>{
      const data = response;                  
      setNotificationList(data);        
    });
  }
 

  const handleCloseClick = () => {
    setAlertVisible(false);
    localStorage.setItem('alertDismissed', 'true');
  };

  
  

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>    
        {!isInitialRender && isAlertVisible && (
        <div className="dashboard-alert">
          <span className="dashboard-closebtn" onClick={handleCloseClick}>&times;</span>
          Welcome back, <span>{userName}!</span><br></br>
          You have logged in successfully, you can access, your upcoming mentorship sessions/workshops, review your calendar, messages, add courses and learning materials. Stay updated and make the most of your mentoring experience!<br></br><br></br>
                Yours sincerely,<br></br>
                Team at SOMOTUS
        </div>
      )}
            <Card>
            <Card.Body>
           
           
  <div className="row">
  <div className="col">
    <Card>
      <Card.Title>Enrollments</Card.Title>
      <Card.Body className="table-responsive" style={{ minWidth: "650px", minHeight:"250px", width: "100%", backgroundColor: "#E5E9ED", borderTop: "5px solid #654DA4", borderRadius: "5px 0 0 0" }}>
      <div style={{minHeight:"250px", width: '100%' }}>
      <div className={`${classes.dataGridContainer}`}>
        <DataGrid
          rows={enrollmentList}
          columns={columnsEnrollments}
          pageSize={5}
          disableSelectionOnClick
          hideFooterPagination
          autoHeight
          checkboxSelection={false}
          classes={{
            header: classes.header,
            toolbarContainer: classes.toolbarContainer,
            columnsContainer: classes.columnsContainer,
            columnHeaderTitle: classes.columnHeaderTitle,
            cell: classes.cell,
            paginationItem: classes.paginationItem,
            paginationItemSelected: classes.paginationItemSelected,
            paginationItemHover: classes.paginationItemHover,
          }}
        />
      </div>
    </div>
        
      </Card.Body>
    </Card>
  </div>
   
</div>


   
<div className="row">
  <div className="col">
    <Card>
      <Card.Title>Onboarded Customers</Card.Title>
      <Card.Body className="table-responsive" style={{ minWidth: "450px", minHeight:"300px", width: "50%", backgroundColor: "#E5E9ED", borderTop: "5px solid #654DA4", borderRadius: "5px 0 0 0",overflowX: "auto" }}>
        
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {orgsDataList.length > 0 ? (
        orgsDataList.map((item) => (
          <div key={item.id} style={{ flex: '0 0 33%', margin: '8px' }}>
            <img
              src={`data:image/png;base64,${item.logobase64String}`}
              className="img-fluid"
              alt={item.name}
              style={{ width: '80%', height: '80%' }}
            />
          </div>
        ))
      ) : (
        <p>No Corporates</p>
      )}
    </div>
        <p><b>Our total registered users: <br></br>
        {totalusersValue} & counting</b>
        </p>
      </Card.Body>
    </Card>
  </div>
  <div className="col">
    <Card>
      <Card.Title>Upcoming Workshops</Card.Title>
      <Card.Body className="table-responsive" style={{ minWidth: "460px", minHeight:"300px", width: "50%", backgroundColor: "#E5E9ED", borderTop: "5px solid #654DA4", borderRadius: "5px 0 0 0" }}>
      <div className={`${classes.dataGridContainer}`}>
    <DataGrid
      rows={workshopList}
      columns={columnsWorkshops}
      pageSize={5}
      disableSelectionOnClick
      hideFooterPagination
      autoHeight
      checkboxSelection={false}
      classes={{
        header: classes.header,
        toolbarContainer: classes.toolbarContainer,
        columnsContainer: classes.columnsContainer,
        columnHeaderTitle: classes.columnHeaderTitle,
        cell: classes.cell,
        paginationItem: classes.paginationItem,
        paginationItemSelected: classes.paginationItemSelected,
        paginationItemHover: classes.paginationItemHover,
      }}
    />
  </div>
  
      </Card.Body>
    </Card>
  </div>
</div>         

<div className="row">
  <div className="col">
    <Card>
      <Card.Title>Alerts and Notifications</Card.Title>
      <Card.Body className="table-responsive" style={{ minWidth: "650px", width: "75%", backgroundColor: "#E5E9ED", borderTop: "5px solid #654DA4", borderRadius: "5px 0 0 0",overflowX: "auto" }}>
      <div className={`${classes.dataGridContainer}`}>
    <DataGrid
      rows={notificationList.slice(0, 5)}
      columns={columnsNotifications}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
      }}
      pageSizeOptions={[5, 10]}
      //pageSize={5}
      disableSelectionOnClick
      // hideFooterPagination
      autoHeight
      checkboxSelection={false}
      classes={{
        header: classes.header,
        toolbarContainer: classes.toolbarContainer,
        columnsContainer: classes.columnsContainer,
        columnHeaderTitle: classes.columnHeaderTitle,
        cell: classes.cell,
        paginationItem: classes.paginationItem,
        paginationItemSelected: classes.paginationItemSelected,
        paginationItemHover: classes.paginationItemHover,
      }}
    />
  </div>
       
      </Card.Body>
    </Card>
  </div>
  <div className="col">
    <Card>
      <Card.Title>Latest Updates</Card.Title>
      <Card.Body className="table-responsive" style={{ minWidth: "300px", minHeight:"300px", width: "20%", backgroundColor: "#E5E9ED", borderTop: "5px solid #654DA4", borderRadius: "5px 0 0 0" }}>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
          <li>Nunc vel erat luctus ligula pretium hendrerit</li>
          <li>Morbi ornare justo eget massa interdum</li>
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
          <li>Nunc vel erat luctus ligula pretium hendrerit</li>
          <li>Morbi ornare justo eget massa interdum</li>
        </ul>
      </Card.Body>
    </Card>
  </div>
</div>
 
            
           
            </Card.Body>
            </Card>  
            
        </div>
          
        </div>
      
    )
}
export default DashboardPage;