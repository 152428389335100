import React, {useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
//import { Link } from "react-router-dom";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment/moment";
import { getCorporates } from "../../../api/adminApi";
import SearchInputs from "../../common/shared/search/SearchInputs";
import ConversationPage from "./ConversationPage";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

//getConversationsByLogon
function CorporateListPage(){
  const classes = useStyles();
    const [corporateList, setCorporateList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');

    const [modalShow, setModalShow] = useState(false);
    const [isModelDisplay,setIsModelDisplay]=useState(false);
    const [emailaddress, setEmailaddress] = useState(null);

    // const ActionColumnFormatter = (cell, row) => {
    //     if (cell) {
    //       return (
    //         <div>
    //         <i
    //           className="bi bi-chat"
    //           onClick={() => handleViewClick(row.user_logon)}
    //           style={{ paddingRight: "10px" }}
    //         />
    //        {/* <Link to={`/emp-details/${row.guid}`}><i className="bi bi-activity" style={{paddingRight:"10px", fontSize:'20px'}}/></Link> */}
    //         </div>
            
            
    //       );
    //     }
    //   }

    // const dateFormatter = (cell) => { //, row
    //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
    //     return <span>{formattedDate}</span>;
    // }
     
    const columns =[
      {field: 'companyname', headerName: 'Company Name', minWidth: 150},
      {field: 'user_logon', headerName: 'User Logon', minWidth: 150},
      {field: 'first_name', headerName: 'First Name', minWidth: 150},
      {field: 'last_name', headerName: 'Last Name', minWidth: 150},
      {field: 'mobile_number', headerName: 'Mobile Nuber', minWidth: 150},
      {field: 'created_date', headerName: 'Created date', minWidth: 150, 
      valueGetter: (params) => moment(params.row.created_date).format("YYYY-MM-DD HH:mm")
      },
      {
        field: 'user_logon',
        headerName: 'Actions',
        minWidth: 200,
        renderCell: (params) => (
          <>
          <div>
            <i
              className="bi bi-chat"
              onClick={() => handleViewClick(params.row.user_logon)}
              style={{ paddingRight: "10px" }}
            />
          
            </div>
            
          </>
        ),
      },

      //   {dataField: 'companyname', text: 'Company Name'},
      //   {
      //       dataField: 'user_logon', 
      //       text: 'User Logon', 
      //       // headerFormatter: () => <span>HHH</span>,
      //       // filter: textFilter({ 
      //       //     placeholder: 'Filter by logon', 
      //       //     getFilter: (filter)=> filter
                
      //       // })
      //   },
      //   {dataField: 'first_name', text: 'First Name'},
      //   {dataField: 'last_name', text: 'Last Name'},
      //   {dataField: 'mobile_number', text: 'Mobile Nuber'},
      //   // {dataField: 'planName', text: 'Plan Name'},
      //   // {dataField: 'description', text: 'Description'},
      //   // {dataField: 'duration', text: 'Duration'},
      //   // {dataField: 'subscriptionStartDate', text: 'Subs. Start Date'},
      //   // {dataField: 'subscriptionEndDate', text: 'Subs. End Date'},
      //   // {dataField: 'status', text: 'Status'},
      //   {dataField: 'created_date', text: 'Created date', formatter: dateFormatter},  
        
      //  {
      //       dataField: 'user_logon',
      //       text: 'Actions',
      //       formatter: ActionColumnFormatter,
      //     },
    ];
    const handleViewClick = (emailaddress)=>{   
        setEmailaddress(emailaddress); 
        setIsModelDisplay(true);
        setModalShow(true);
      }
    useEffect(()=>{
        fetchCorporateData();
  
      },[]);
      
      const fetchCorporateData =() => {
        getCorporates().then(response=>{                    
            setCorporateList(response); 
        });
      }
      useEffect(()=>{
        const filteredResults = corporateList.filter((item) => {
            const emailMatch = item.user_logon.toLowerCase().includes(emailSearch.toLowerCase());

            return emailMatch;
        });
        setFilteredData(filteredResults);
      },[corporateList,emailSearch]);

      const handleEmailChange = (e) => {
        setEmailSearch(e.target.value);
      }
    //columns[1].filter = undefined;
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
               


                    
                <Card style={{ width: '62rem', height: '53rem' }}>
                    <Card.Body>
                    <Card.Title style={{ display: 'inline-block' }}>Corporate List</Card.Title>
                    <SearchInputs
                    showEmailSearch={true}
                    emailSearch={emailSearch}
                    onEmailChange={handleEmailChange}
                    ></SearchInputs>

                    {/* <BootstrapTable 
                    keyField="user_id"
                    data={filteredData}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory()}
                    // filter={filterFactory()}
                    /> */}
<div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.user_id}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

                    {isModelDisplay && 
                      <ConversationPage id={emailaddress} show={modalShow} onHide={() => {setModalShow(false); setIsModelDisplay(false);}}></ConversationPage>
                    }
                      
                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default CorporateListPage