// import { makeStyles } from '@mui/styles';
// //const useStyles = makeStyles((theme) => ({
// const useStyles = makeStyles(() => ({
//     dataGridContainer: {
//       width: '100%',
//       border: '1px solid #ddd',
//       borderRadius: '8px',
//       overflow: 'hidden',
//       boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//       backgroundColor:'white'
//     },
//     header: {
//       backgroundColor: 'lightgray !important', // Add your desired background color
//     },
//     toolbarContainer: {
//       display: 'flex',
//       justifyContent: 'space-between',
//       padding: '16px',
//       borderBottom: '1px solid #ddd',
//       backgroundColor: '#f5f5f5',
//     },
//     columnsContainer: {
//       backgroundColor: '#f5f5f5 !important',
//       '& .MuiDataGrid-colCell, .MuiDataGrid-sortIcon, .MuiDataGrid-menuIcon': {
//         backgroundColor: 'lightgray !important',
//       },
//     },
//     columnHeaderTitle: {
//       fontWeight: 'bold !important',
//       color: 'black',
//       fontSize:'14px',
      
//     },
//     cell: {
//       color: '#555',
//     },
//     paginationItemSelected: {
//       backgroundColor: '#007bff',
//       color: '#fff',
//     },
//     paginationItemHover: {
//       '&:hover': {
//         backgroundColor: '#007bff',
//         color: '#fff',
//       },
//     },
//   }));

//   export default useStyles;

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const useStyles = () => ({
  dataGridContainer: css`
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
  `,
  header: css`
    background-color: lightgray !important;
  `,
  toolbarContainer: css`
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #ddd;
    background-color: #f5f5f5;
  `,
  columnsContainer: css`
    background-color: #f5f5f5 !important;
    & .MuiDataGrid-colCell,
    .MuiDataGrid-sortIcon,
    .MuiDataGrid-menuIcon {
      background-color: lightgray !important;
    }
  `,
  columnHeaderTitle: css`
    font-weight: bold !important;
    color: black;
    font-size: 14px;
  `,
  cell: css`
    color: #555;
  `,
  paginationItemSelected: css`
    background-color: #007bff;
    color: #fff;
  `,
  paginationItemHover: css`
    &:hover {
      background-color: #007bff;
      color: #fff;
    }
  `,
});

export default useStyles;
