import React, { useEffect, useState } from "react";
import { Card } from 'react-bootstrap';

// import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
import { getStudents } from "../../../api/instituteApi";

//import { getConversationsByLogon } from "../../../api/adminApi";
import { getNotifications } from "../../../api/mentorApi";
// import moment from "moment/moment";
import '../../../Stylesheet/dashboard.css';
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

const InstituteDashboardPage =()=>{
  const [employeeList, setEmployeeList] = useState([]);
  //const [conversationsList, setConversationsList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  
  const classes = useStyles();

 // const [showWelcomeContent, setShowWelcomeContent] = useState(true);

//   const [filterType, setFilterType] = useState("RECEIVED");
//   const [filterStatus, setFilterStatus] = useState("");
  
  // const dateFormatter = (cell) => { //, row
  //   const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
  //   if(formattedDate === 'Invalid date'){return <span></span>;}
  //   return <span>{formattedDate}</span>;
  //   }

  const columns =[
        // {field: 'id', headerName: '#'},
        {field: 'firstname', headerName: 'Name', width: 200},
        {field: 'emailaddress', headerName: 'Email Address' , width: 200},
        {field: 'mobilenumber', headerName: 'Mobile Number', width: 150},
        // {field: 'divisionname', headerName: 'Division'},
        // {field: 'createdby', headerName: 'Created By'},
        {field: 'lastlogon', headerName: 'Download Status', width: 150},
        // {field: 'lastlogon', headerName: 'Last Logon',formatter: dateFormatter},
         
    ];
    

    useEffect(()=>{
       
        fetchEmployeesData();
        //fetchConversationsData();
        fetchNotificationsData();
          },[]);
      
      const fetchEmployeesData =() => {
        getStudents().then(response=>{                          
          setEmployeeList(response); 
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }
 

      const fetchNotificationsData =() => {
        getNotifications().then(response=>{
          const data = response;                  
          setNotificationList(data);        
        });
      }
 

  const columnsSent = [
    { field: "receiverlogon", headerName: "Name", width: 150 },
    // { dataField: "senderlogon", text: "Sender" },
    { field: "notificationtype", headerName: "Type", width: 150 },
    { field: "message", headerName: "Message", width: 200 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "createddate", headerName: "Created Date" , width: 200},
    // { dataField: "ref_id", text: "Ref. Id" },
    // {
    //   dataField: "refid",
    //   text: "Actions",
    //   formatter: ActionColumnFormatter,
    // },
  ];

//   const filteredNotifications = notificationList.filter((notification) => {
//     if (filterType === "RECEIVED" && notification.receiverlogon !== "Somotus Admin") {
//       return false;
//     }
//     if (filterType === "SENT" && notification.senderlogon !== "Somotus Admin") {
//       return false;
//     }
//     if (filterStatus && notification.status !== filterStatus) {
//       return false;
//     }
//     return true;
//   });

const [isInitialRender, setIsInitialRender] = useState(true);
const [isAlertVisible, setAlertVisible] = useState(true);

useEffect(()=>{
  const isDismissed = localStorage.getItem('alertDismissed');     
  setAlertVisible(!isDismissed);
  setIsInitialRender(false);
});

  const handleCloseClick = () => {
    setAlertVisible(false);
    localStorage.setItem('alertDismissed', 'true');
  };

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>    
        {!isInitialRender && isAlertVisible && (
        <div className="dashboard-alert">
          <span className="dashboard-closebtn" onClick={handleCloseClick}>&times;</span>
          Welcome <br></br>
                You are the VIPs of our technological world! Without you, our products would be like a phone without a charger - useless! Your feedback and suggestions are like the secret sauce that makes our technology the best in the world. We rely on your brilliant minds to help us improve, innovate, and create the next big thing. So please, dont hold back! Share your thoughts, ideas, and even your wildest dreams with us. Together, we can conquer any technological challenge and build a future that is truly extraordinary. 
                <br></br><br></br>
                Ah, product development, the never-ending journey of adding features and making improvements. Our tech team are Superheroes, working tirelessly every day to add some awesome features to our product. And guess what? You wont have to wait long to see the magic happen! Updates will be rolling out every week, so get ready to be wowed. Stay tuned, my friend!
                Thank you for being a part of our incredible journey!<br></br><br></br>
                Yours sincerely,<br></br>
                Team at SOMOTUS
        </div>
      )}
            <Card>
            <Card.Body>
            
            <div className="row">
            <div className="col">
              <Card>
                <Card.Title>Students</Card.Title>
                <Card.Body className="table-responsive" style={{ minWidth: "650px", minHeight:"350px", width: "100%", backgroundColor: "#E5E9ED", borderTop: "5px solid #654DA4", borderRadius: "5px 0 0 0" }}>
                <div style={{ height: 400, width: '100%' }}>
                <div className={`${classes.dataGridContainer}`}>
                  <DataGrid
                    rows={employeeList.slice(0, 5)}
                    getRowId={(row) => row.guid}
                    columns={columns}
                    pageSize={5}
                    disableSelectionOnClick
                    hideFooterPagination
                    autoHeight
                    checkboxSelection={false}
                    classes={{
                      header: classes.header,
                      toolbarContainer: classes.toolbarContainer,
                      columnsContainer: classes.columnsContainer,
                      columnHeaderTitle: classes.columnHeaderTitle,
                      cell: classes.cell,
                      paginationItem: classes.paginationItem,
                      paginationItemSelected: classes.paginationItemSelected,
                      paginationItemHover: classes.paginationItemHover,
                    }}
                  />
                </div>
              </div>
                  
                </Card.Body>
              </Card>
            </div>
            </div>

            <div className="row">
              <div className="col">
                <Card>
                  <Card.Title>Alerts and Notifications</Card.Title>
                  <Card.Body className="table-responsive" style={{ minWidth: "650px", minHeight:"300px", width: "100%", backgroundColor: "#E5E9ED", borderTop: "5px solid #654DA4", borderRadius: "5px 0 0 0" }}>
                  <div style={{ height: 350, width: '100%' }}>
                  <div className={`${classes.dataGridContainer}`}>
                    <DataGrid
                      rows={notificationList.slice(0, 5)}
                      getRowId={(row) => row.guid}
                      columns={columnsSent}
                      pageSize={5}
                      disableSelectionOnClick
                      hideFooterPagination
                      autoHeight
                      checkboxSelection={false}
                      classes={{
                        header: classes.header,
                        toolbarContainer: classes.toolbarContainer,
                        columnsContainer: classes.columnsContainer,
                        columnHeaderTitle: classes.columnHeaderTitle,
                        cell: classes.cell,
                        paginationItem: classes.paginationItem,
                        paginationItemSelected: classes.paginationItemSelected,
                        paginationItemHover: classes.paginationItemHover,
                      }}
                    />
                  </div>
                </div>
                    
                  </Card.Body>
                </Card>
              </div>
              
            </div>

    
            
           
            </Card.Body>
            </Card>  
            
        </div>
          
        </div>
      
    )
}
export default InstituteDashboardPage;