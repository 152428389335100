import React, {useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
//  import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import { Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import Select from 'react-select';
import { addNotification } from "../../../api/corporateApi";
import { getConversationsByLogon } from "../../../api/adminApi";

import moment from "moment/moment";
import * as Yup from "yup";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { PropTypes } from "prop-types";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function ConversationPage(props){
  const classes = useStyles();
  const { id } = props;
  
  //const { guid } = useParams(); 
  const [conversationsList, setConversationsList] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
const  validationSchema = Yup.object({
      message: Yup.string()
      .required("Message is required."),
}); 

// const ActionColumnFormatter = (cell) => {
//   if (cell === 'mentor1@gmail.com') {
//     return (
//       <div  
//               style={{
//                 width: '30px',
//                 height: '10px',
//                 backgroundColor: 'red',
//                 marginRight: '10px',
//                 marginLeft:'10px'
                
//               }}
//             ></div>
//     );
//   }
//   else
//   {
//     return (
//       <div 
//       style={{
//         width: '30px',
//         height: '10px',
//         backgroundColor: 'blue',
//         marginRight: '10px',
//         marginLeft:'10px'
//       }}
//     ></div>
//     );
//   }
// }
// const dateFormatter = (cell) => { //, row
//   const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
//   return <span>{formattedDate}</span>;
// } 
const columns = [
  {field: 'message', headerName: 'Message', minWidth: 150},
  {field: 'sendat', headerName: 'Send At', minWidth: 150, 
  valueGetter: (params) => moment(params.row.sendat).format("YYYY-MM-DD HH:mm")
  },
  {
    field: 'sender',
    headerName: '',
    minWidth: 200,
    renderCell: (params) => {
      const sender = params.row.sender;
      const style = {
        width: '30px',
        height: '10px',
        marginRight: '10px',
        marginLeft: '10px',
      };

      if (sender === 'mentor1@gmail.com') {
        style.backgroundColor = 'red';
      } else {
        style.backgroundColor = 'blue';
      }

      return <div style={style}></div>;
    },
  },  
   
];

const handleSubmit = async (values) => {  
  if (values.refid === "") {
    values.refid = null;
  }
  else if (values.refid !== null && values.refid !== "") {
    values.refid = parseInt(values.refid);
  } 
   
  addNotification(values).then((response)=>{
    if(!response.ok){
        // const err = new Error("Error in response");
        // throw err;
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        //return response.json();
    }
    }).then(response=>{
      console.log(response);
       
        //return response;
        setSnackbarMessage('Successfully sent message');
        setShowSnackbar(true);
        formik.resetForm();
    });
    fetchConversationsData();
}

const formik = useFormik({
  initialValues: {
      receiver: id,
      type: "General",
      message: "",
      status: "Not Applicable",
      refid: null,
  },
  validationSchema ,
  onSubmit: handleSubmit,
}); 

   

  // const dateFormatter = (cell) => { //, row
  //   const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
  //   return <span>{formattedDate}</span>;
  // }   

 
  
  useEffect(()=>{
    fetchConversationsData();

  },[]);
  
  const fetchConversationsData =() => {
    getConversationsByLogon(id).then(response=>{                    
      setConversationsList(response); 
    })
    .catch(error => {
      // Handle error
      console.log(error);
    });
  }

  // const fetchedActionOptions = [ { value: "9", label: "Need Clarifications" },
  // { value: "10", label: "Clarified" },
  // ];

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    }
  // const handleActionChange = (selectedOption) => {
  //     formik.setFieldValue('action', selectedOption.label);
  // };
  // const options = {
  //   sizePerPageList: [
  //     { text: '5', value: 5 }, // Customize the available page size options
  //     { text: '10', value: 10 },
  //     { text: '20', value: 20 },
  //     { text: 'All', value: conversationsList.length },
  //   ],
  //   sizePerPage: 5, // Set the default page size to 5
  // };  

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              Messaging
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
            
              <div className="row">
                <div className="col">
                  <Form.Group className="mb-3" controlId="formShortDescription">
                    <Form.Label>Message</Form.Label>
                    <Form.Control required as="textarea" rows={4} placeholder="Enter message" 
                    name="message"  
                    value={formik.values.message}
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur} 
                    />
                      <FormErrorMessage
                          fieldName="message"
                          formik={formik}
                      ></FormErrorMessage>
                  </Form.Group>
                </div>                            
              </div>
              <Button variant="primary" className="button" type="submit">
              Submit
              </Button>  
            </Form> 
            <div className="row" style={{
              // position: "absolute",
            right: "31px",
            top: "10px",
            bottom: "0",
            display: "flex",
            alignItems: "center"}}>
              <div  
                style={{
                  width: '30px',
                  height: '10px',
                  backgroundColor: 'red',
                  marginRight: '10px',
                  marginLeft:'10px'
                  
                }}
              ></div>Self
              <div 
                style={{
                  width: '30px',
                  height: '10px',
                  backgroundColor: 'blue',
                  marginRight: '10px',
                  marginLeft:'10px'
                }}
              ></div>Admin
            </div>
             {/* <BootstrapTable 
                    keyField="id"
                    data={conversationsList}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory(options)}
                    
                    /> */}
            <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={conversationsList}
                                columns={columns}
                                getRowId={(row) => row.id}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

         <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
          </Modal.Body>
        </Modal>

        
    );
}

ConversationPage.propTypes ={
  id: PropTypes.string.isRequired,   
};
export default ConversationPage;