import React from "react";
import HeaderNew from '../../common/shared/header/HeaderNew';
//import SubHeader from '../../common/shared/header/SubHeader';
import AdminbarNew from "../../common/shared/leftbar/adminbarNew";
import PropTypes from "prop-types";
// import  {Link}  from 'react-router-dom'
import Footer from '../../common/shared/footer/Footer';
import {  useLocation } from "react-router-dom";
import './../../../css/style.min.css';

const AdminLayout = ({ children}) => {
    const location = useLocation();
    let formHeaderName = "";
    if(location.pathname === '/dashboard-corporate')
    {
        formHeaderName = 'Dashboard';
    }
    return(
        <div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
        data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
              <HeaderNew /> 
                <AdminbarNew ></AdminbarNew>

                <div className="page-wrapper page-wrapper-height">
                <div className="page-breadcrumb bg-white">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                        <h4 className="page-title">{formHeaderName}</h4>
                    </div>
                    {/*<div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                        <div class="d-md-flex">
                            <ol class="breadcrumb ms-auto">
                                <li><a href="#" class="fw-normal">{formHeaderName}</a></li>
                            </ol>
                             <a href="" target="_blank"
                                class="btn btn-danger  d-none d-md-block pull-right ms-3 hidden-xs hidden-sm waves-effect waves-light text-white">Upgrade
                                to Pro</a> 
                        </div>
                    </div>*/}
                </div>
                
                </div>

                <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="white-box">
                      {children}
                        </div>
                    </div>
                </div>
              
                </div>
               <Footer></Footer>
               
                 </div>
        </div>
        
    );
}
AdminLayout.propTypes ={
    children: PropTypes.array.isRequired,   
};  

export default AdminLayout;