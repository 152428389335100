import React, { useState } from "react";
// import { Link as RouterLink, useLocation } from "react-router-dom";
import { Card } from 'react-bootstrap';
// import { useState } from "react";
import { useEffect } from "react";

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Progress from '../../common/shared/progress/Progress';
import { getOrgsDashboard,getDashboardStatistics } from '../../../api/mentorApi';

const ProfileNotCompletePage = () => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);    
    // const location = useLocation();
    // const params = new URLSearchParams(location.search);
    const[personalDetailsScore,setPersonalDetailsScore]=useState('');
    const[mentorShipDetailsScore,setMentorShipDetailsScore]=useState('');
    const[profilePictureScore,setProfilePictureScore]=useState('');

    const[orgsDataList,setorgsDataList] = useState([]);
    //const[dashboardStatisticsList,setdashboardStatisticsList] = useState([]);
 
    const[totalcompValue,settotalcompValue] = useState(0);
    const[totalmentorValue,settotalmentorValue] = useState(0);
    const[totalusersValue,settotalusersValue] = useState(0);

//  const [icon, setIcon] = useState(null);
//  const [icon1, setIcon1] = useState(null);
//  const [icon2, setIcon2] = useState(null);
 useEffect(() => {
      getOrgsDashboard().then(response => {
        setorgsDataList(response);
      });
      getDashboardStatistics().then(response => {
        //setdashboardStatisticsList(response);
        settotalcompValue(response[0].totalcompanies);
        settotalmentorValue(response[0].totalmentors);
        settotalusersValue(response[0].totalusers);
      });
      
     
    setPersonalDetailsScore(queryParams.param1);
    setMentorShipDetailsScore(queryParams.param2);
    setProfilePictureScore(queryParams.param3);
//     if(personalDetailsScore !== '50'){
//         setIcon(<i className="bi bi-x" style={{fontSize: "20px", alignItems:"baseline", color: "red"}}></i>);
//      }
//      else{
//         setIcon(<i className="bi bi-check" style={{fontSize: "20px", alignItems:"baseline", color: "green"}}></i>);
//      }
    
//      if(mentorShipDetailsScore !== '25'){
//         setIcon1(<i className="bi bi-x" style={{fontSize: "20px", alignItems:"baseline", color: "red"}}></i>);
//      }
//      else{
//         setIcon1(<i className="bi bi-check" style={{fontSize: "20px", alignItems:"baseline", color: "green"}}></i>);
//      }
    
//      if(profilePictureScore !== '25'){
//         setIcon2(<i className="bi bi-x" style={{fontSize: "20px", alignItems:"baseline", color: "red"}}></i>);
//      }
//      else{
//         setIcon2(<i className="bi bi-check" style={{fontSize: "20px", alignItems:"baseline", color: "green"}}></i>);
//      }
 },[personalDetailsScore,mentorShipDetailsScore,profilePictureScore,totalcompValue,totalmentorValue,totalusersValue])
 


  return (
    <div className="featured-section-wrap featured-section-wrap-row">
    <div className='mainPageContainer'>        
        <div className='dashboardcontainerflex'>
        <div className='dashboardcard'>
            <Card style={{ width: '62rem', height: '53rem' }}>
            <Card.Body>
            
                <div className='row'>
                    <div className='col' style={{fontSize: "20px", alignItems:"baseline", color: "blue"}}>
                    {/* Please complete your profile before proceeding. */}
                    Please note; if your profile is not 100% complete, it will not be visible in user search results in the mobile app, kindly update the profile.
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                    SCORE
                    NEEDS IMPROVEMENT - SEE <span className="bi bi-x" style={{fontSize: "20px", alignItems:"baseline", color: "red"}}></span> BELOW FOR AREAS TO IMPROVE.
                    </div>
                </div>   

                    
                    <hr />
                    
                    <table className="table">                        
                        <tbody>
                            <tr>
                                <td>
                                    PERSONAL DETAILS
                                </td>
                                <td>
                                {personalDetailsScore !== '50' && (
                                        <i className="bi bi-x" style={{fontSize: "20px", alignItems:"baseline", color: "red"}}></i>
                                    )}
                                {personalDetailsScore === '50' && (
                                    <i className="bi bi-check" style={{fontSize: "20px", alignItems:"baseline", color: "green"}}></i>
                                )}    
                                     
                                </td>
                                <td>
                                    First name, Last name, Mobile number and Address fields are mandatory.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    MENTORSHIP DETAILS  
                                </td>
                                <td>
                                    {mentorShipDetailsScore !== '25' && (
                                            <i className="bi bi-x" style={{fontSize: "20px", alignItems:"baseline", color: "red"}}></i>
                                        )}
                                    {mentorShipDetailsScore === '25' && (
                                        <i className="bi bi-check" style={{fontSize: "20px", alignItems:"baseline", color: "green"}}></i>
                                    )} 
                                </td>
                                <td>
                                    Short Biography, Skills, Language, Experiance and Price fields are mandatory.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    PROFILE PICTURE
                                </td>
                                <td>
                                    {profilePictureScore !== '25' && (
                                        <i className="bi bi-x" style={{fontSize: "20px", alignItems:"baseline", color: "red"}}></i>
                                    )}
                                    {profilePictureScore === '25' && (
                                        <i className="bi bi-check" style={{fontSize: "20px", alignItems:"baseline", color: "green"}}></i>
                                    )} 
                                </td>
                                <td>
                                    is mandatory
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <i className="bi bi-bell fs-4 text-danger"></i>Complete your profile to start your impact activities. We have 10000+ users looking for mentors and career coaching.
                  (click the Blue button from your profile sction)
                  <br></br>
                  <span style={{fontWeight:"bold"}}>CURRENT STATISTICS @SOMOTUS  </span>
                  <Card>
                    <Card.Body>
                    <table className="table">
                        {orgsDataList.length > 0 ? (
                            
                        orgsDataList.map((item)=>
                            (
                            <div key={item.guid}>
                             <tr>
                            <td>
                                {item.createddate}
                            </td>
                            <td>
                                Hurry! we onboarded {item.organizationname}
                            </td>
                        </tr>
                           </div>
                            )
                            )
                        
                        ) : (
                        <p>No Corporates</p>
                        )}
                    </table>

                        {/* <table className="table" style={{border:"1px solid red;"}}>
                        <tr>
                            <td>
                                28.07.2023
                            </td>
                            <td>
                                Hurry! we onboarded harita techserv
                            </td>
                        </tr>
                        <tr>
                            <td>
                                28.07.2023
                            </td>
                            <td>
                                Hurry! we onboarded Surya 
                            </td>
                        </tr>
                        </table>                */}
                    </Card.Body>
                  </Card><br></br>
                  <Card>
                    <Card.Body>
                        <table className="table" style={{border:"1px solid red;"}}>
                        <tr>
                            <td>
                                <table className="table">
                                <tr>
                                    <td>
                                        <div className="row">
                                            <Progress percent='50' cName='progress-circle-fill-green' count={totalcompValue} >
                                            </Progress>                           
                                        </div>
                                        <div className="row"> 
                                            Total Companies <br/> Onboarded 
                                        </div>
                                                    
                                    </td>
                                    {/* <td>
                                        <div className="row">
                                                <Progress percent='25' cName='progress-circle-fill-lightblue' count='150' >
                                                </Progress>                           
                                            </div>
                                            <div className="row">
                                            Total reg. Mentors  
                                            </div> 
                                    </td> */}
                                    <td>
                                        <div className="row">
                                                <Progress percent='75' cName='progress-circle-fill-orange' count={totalmentorValue} >
                                                </Progress>                           
                                            </div>
                                            <div className="row">
                                               Total Mentors<br/> Registered 
                                            </div> 
                                    </td>
                                    <td>
                                        <div className="row">
                                                <Progress percent='50' cName='progress-circle-fill' count={totalusersValue} >
                                                </Progress>                           
                                            </div>
                                            <div className="row">
                                               Total Users
                                            </div> 
                                    </td>
                                </tr>
                                </table>
                            </td>
                            <td>
                                Recently Onboarded Companies <br></br>
                               {/* <img src="/files/harita.jpg"></img>
                                 <img src="/files/surya.png"></img> */}

                                {orgsDataList.length > 0 ? (
                            
                                orgsDataList.map((item)=>
                                (
                                <div key={item.guid}>
                                     
                                    <img src={`data:image/png;base64,${item.logobase64String}`}                                 
                                className="img-fluid" 
                                style={{ width: '50%', height: '50%'}}
                                />

                               </div>
                                )
                                )
                            
                            ) : (
                            <p>No Corporates</p>
                            )}

                            </td>
                        </tr>
                        </table>               
                    </Card.Body>
                  </Card>
                  
                    

                  
                                  
            </Card.Body>
            </Card>

           
        </div>
         
        </div>
        
    </div>
    </div>
  );
  };
  
  export default ProfileNotCompletePage;