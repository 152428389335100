import React, {useEffect, useState } from "react";
 
// import { retrieveAssessmentResultsbyguid } from "../../../api/corporateApi";
import { getWorkShopLeads } from "../../../api/corporateApi";
//import { useParams } from "react-router-dom";
import moment from "moment/moment";
// import SearchInputs from "../../common/shared/search/SearchInputs";
// import { DataGrid} from '@mui/x-data-grid';
// import useStyles from '../../common/shared/grid/useStyles';

function WorkshopsRequestViewPage(){  
    
    const [workShopLeadsList, setWorkShopLeadsList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);  
    const itemsPerPage = 2;  
 
    useEffect(()=>{
        fetchWorkshopLeadsData();
          },[]);
      

      
      

      const fetchWorkshopLeadsData =() => {
        getWorkShopLeads().then(response=>{                    
          setWorkShopLeadsList(response); 
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }

      // Calculate the items to display for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = workShopLeadsList.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handler
  const paginate = (pageNumber) => setCurrentPage(pageNumber);



    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'> 
            <div style={{ padding: "20px", backgroundColor: "#f4f4f4" }}>
            THE LEADS DASHBOARD PAGE DISPLAYS SUBMITTED INQUIRIES FROM THE LATEST TO THE OLDEST, AND THESE INQUIRIES WILL BE SHARED WITH ALL THE MENTORS WHO SHOW INTEREST.
      <div className="row">
        {currentItems.map((lead) => (
          <div className="lead-container" key={lead.guid} style={leadContainerStyle}>
            <div className="created-date" style={createdDateStyle}>
              {moment(lead.createddate).format('MMMM D, YYYY')}
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Name:</label>
              <p>{lead.createdby}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Email Address:</label>
              <p>{lead.emailaddress}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Mobile Number:</label>
              <p>{lead.mobilenumber}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Brief Summary of Requirements:</label>
              <p>{lead.summary}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Workshop Dates:</label>
              <p>{moment(lead.requesteddate).format('MMMM D, YYYY')}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Durations:</label>
              <p>{lead.requestedduration}</p>
            </div>
            <div className="lead-details" style={leadDetailsStyle}>
              <label style={labelStyle}>Specific Skills:</label>
              <ul className="skills-list" style={skillsListStyle}>
                {lead.skills?.map((skill, index) => (
                  <li key={index} style={skillItemStyle}>
                    {skill.name === "Any other Skills" && skill.others
                      ? skill.others
                      : skill.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div style={{ marginTop: '20px' }}>
        {Array.from({ length: Math.ceil(workShopLeadsList.length / itemsPerPage) }, (_, index) => (
          <button 
            key={index} 
            onClick={() => paginate(index + 1)}
            style={{ 
              margin: '0 5px', 
              padding: '5px 10px', 
              backgroundColor: currentPage === index + 1 ? '#007bff' : '#fff',
              color: currentPage === index + 1 ? '#fff' : '#000',
              border: '1px solid #007bff',
              borderRadius: '5px'
            }}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>

        </div>
        </div>        

    )

}

// Inline styles to replicate the CSS in the original static HTML
const leadContainerStyle = {
  position: "relative", // Make this container relative so that the created date can be positioned absolutely
  backgroundColor: "#fff",
  borderRadius: "5px",
  padding: "20px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  marginBottom: "20px",
};

const leadDetailsStyle = {
  marginBottom: "10px",
};

const labelStyle = {
  fontWeight: "bold",
};

const skillsListStyle = {
  listStyle: "none",
  paddingLeft: "0",
};

const skillItemStyle = {
  marginBottom: "5px",
};
const createdDateStyle = {
  position: "absolute",
  top: "10px",
  right: "20px",
  fontSize: "12px",
  color: "#666",
  fontWeight: "bold",
};

export default WorkshopsRequestViewPage