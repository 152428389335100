import React, {useEffect, useState } from "react";
// import Form from 'react-bootstrap/Form';
// import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
// import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
// import Select from 'react-select';
// import { useFormik, Formik } from "formik"
// import * as Yup from "yup";
// import {useQuery, useQueryClient } from "react-query";
// import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import { Link } from "react-router-dom";
// import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import { getEmployees } from "../../../api/corporateApi";
import SearchInputs from "../../common/shared/search/SearchInputs";
import ConversationPage from "./ConversationPage";
import moment from "moment/moment";
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function CorporateEmpPage(){
  const classes = useStyles();
    const [employeeList, setEmployeeList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [isModelDisplay,setIsModelDisplay]=useState(false);
    const [emailaddress, setEmailaddress] = useState(null);
    const ActionColumnFormatter = (cell, row) => {
        if (cell) {
          return (
            <div>
            <i
              className="bi bi-chat"
              onClick={() => handleViewClick(row.emailaddress)}
              style={{ paddingRight: "10px" }}
            />
           <Link to={`/emp-details/${row.guid}`}><i className="bi bi-activity" style={{paddingRight:"10px", fontSize:'20px'}}/></Link>
            </div>
            
            
          );
        }
      }
      const dateFormatter = (cell) => { //, row
        const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
        if(formattedDate === 'Invalid date'){return <span></span>;}
        return <span>{formattedDate}</span>;
    }

    const columns =[
      {field: 'firstname', headerName: 'Name', minWidth: 150},
      {field: 'emailaddress', headerName: 'Email Address', minWidth: 150},
      {field: 'mobilenumber', headerName: 'Mobile Number', minWidth: 150},
      {field: 'createdby', headerName: 'createdby', minWidth: 150},
      {field: 'lastlogon', headerName: 'Last Logon', minWidth: 150, 
      valueGetter: (params) => moment(params.row.lastlogon).format("YYYY-MM-DD HH:mm")
      },
      {
        field: 'selectAction',
        headerName: 'Actions',
        minWidth: 200,
        renderCell: (params) => (
          <>
           <div>
            <i
              className="bi bi-chat"
              onClick={() => handleViewClick(params.row.emailaddress)}
              style={{ paddingRight: "10px" }}
            />
           <Link to={`/emp-details/${params.row.guid}`}><i className="bi bi-activity" style={{paddingRight:"10px", fontSize:'20px'}}/></Link>
            </div>

             
          </>
        ),
      },

        // {dataField: 'id', text: '#'},
        {dataField: 'firstname', text: 'Name'},
        {dataField: 'emailaddress', text: 'Email Address'},
        {dataField: 'mobilenumber', text: 'Mobile Number'},
        // {dataField: 'divisionname', text: 'Division'},
        {dataField: 'createdby', text: 'Created By'},
        {dataField: 'lastlogon', text: 'Last Logon',formatter: dateFormatter},
        {
            dataField: 'emailaddress',
            text: 'Actions',
            formatter: ActionColumnFormatter,
          },
        //   {
        //     dataField: 'action',
        //     text: 'Action',
        //     formatter: (cell, row) => (
        //       <>
        //       <Link to={`/emp-details/${row.guid}`}><i className="bi bi-activity" style={{paddingRight:"10px", fontSize:'20px'}}/></Link>
               
        //       </>
        //     ),
        // },
    ];
    const handleViewClick = (emailaddress)=>{   
        setEmailaddress(emailaddress); 
        setIsModelDisplay(true);
        setModalShow(true);
      }
    useEffect(()=>{
        fetchEmployeesData();
          },[]);
      
      const fetchEmployeesData =() => {
        getEmployees().then(response=>{                    
          setEmployeeList(response); 
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }
    useEffect(()=>{
    const filteredResults = employeeList.filter((item) => {
        const emailMatch = item.emailaddress.toLowerCase().includes(emailSearch.toLowerCase());

        return emailMatch;
    });
    setFilteredData(filteredResults);
    },[employeeList,emailSearch]);
    
    const handleEmailChange = (e) => {
    setEmailSearch(e.target.value);
    }
      

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <Card>
                    <Card.Body>
                    <CustomTooltip text="Gain insights into employee performance, productivity, and engagement to make informed decisions for better management and team optimization" />
                    {/* <Card.Title style={{ display: 'inline-block' }}>Employee analytics</Card.Title> */}
                    <SearchInputs
                    showEmailSearch={true}
                    emailSearch={emailSearch}
                    onEmailChange={handleEmailChange}
                    ></SearchInputs>
                    {/* <BootstrapTable 
                    keyField="guid"
                    data={filteredData}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory()}
                    bootstrap4
                    striped
                    hover
                    condensed
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-bordered table-hover"
                    /> */}
<div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
 

                    {isModelDisplay && 
                      <ConversationPage id={emailaddress} show={modalShow} onHide={() => {setModalShow(false); setIsModelDisplay(false);}}></ConversationPage>
                    }
                        

                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default CorporateEmpPage