// import Button from "@material-ui/core/Button";
// import { useTranslation } from "react-i18next";
import React from "react";
// import { Link as RouterLink } from "react-router-dom";
import notFound from '../../Images/404.svg';

const NotFound = () => {
  return (
    <div className="featured-section-wrap featured-section-wrap-row">
    <div className='mainPageContainer'>        
        <div className='dashboardcontainerflex'>
          <img style={{paddingTop:"35px", paddingLeft:"55px"}} src={notFound} />
        </div>
        
    </div>
    </div>
  );
  };
  
  export default NotFound;
  