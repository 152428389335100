// import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
// import * as ReactBootStrap from "react-bootstrap";
import { getWorkShops, deleteWorkShop } from "../../../api/mentorApi";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import 'bootstrap/dist/css/bootstrap.min.css';
// import cellEditFactory from 'react-bootstrap-table2-editor';
// import OverlayFactory from "react-bootstrap-table2-overlay";
import { Link } from "react-router-dom";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { Card } from 'react-bootstrap';
import Button  from "react-bootstrap/Button";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function MentorWorkshopViewPage() { //{ onBackClick }
  const classes = useStyles();
  // const handleBack = () => {
  //   onBackClick();
  // }
    const [items, setItems] = useState([]);
    // const [selectedImage, setSelectedImage] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
     
    const columns =[
      {field: 'id', headerName: 'ID', minWidth: 150},
      {field: 'title', headerName: 'Title', minWidth: 150},
      {field: 'description', headerName: 'Description', minWidth: 150},
      {field: 'date', headerName: 'Date', minWidth: 150},
      {field: 'start', headerName: 'Start', minWidth: 150},
      {field: 'end', headerName: 'Start', minWidth: 150},
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        renderCell: (params) => (
          <>
          <Link to={`/workshopEdit/${params.row.id}`}><i className="bi bi-pencil-fill" style={{paddingRight:"10px"}}/></Link>
          <i className="bi bi-trash" onClick={() => handleDelete(params.row.id)} />
          </>
        ),
      },
    ];

    const handleDelete =(id) => {      
      
      const updatedData = items.filter((row) => row.id !== id);
      //const updatedData = items.filter((row) => row.id === id);
      setItems(updatedData);
      //console.log(JSON.stringify(items.filter((row) => row.id === id)));
      const deleteCourseData = items.filter((row) => row.id === id);
      console.log(JSON.stringify(deleteCourseData[0]));
      deleteWorkShop(deleteCourseData[0]).then((response)=>{
        if(!response.ok){
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response)
            setSnackbarMessage('Successfully deleted workshop data.');
            setShowSnackbar(true);
            //formik.resetForm();
        });

    }
    // const handleEdit =(row) => {
    //   console.log(row);    
    // }

    useEffect(()=>{
      fetchWorkShopsData();

    },[]);
    
    const fetchWorkShopsData =() => {
        getWorkShops().then(response=>{
        const data = response;
        setItems(data);
      });
    }
    // const cellEditOptions ={
    //   mode: 'click',
    //   blurToSave: true,
    //   autoSelectText: true,
    // }

    const handleCloseSnackbar = () => {
      setShowSnackbar(false);
    }
    
    return (
      <div className="featured-section-wrap featured-section-wrap-row">
      <div className='mainPageContainer'>        
          <div className='dashboardcontainerflex'>
            <div className='dashboardcard'>
            {/* style={{ width: '62rem', height: '53rem' }} */}
            <Card >
              <Card.Body>
                {/* <button onClick={handleBack}>Back</button> */}
                <Link to="/mentor-workshop">
                  <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                      + Add WorkShop
                  </Button>
                  </Link>
                  <Card.Title style={{ display: 'inline-block' }}>My WorkShop</Card.Title>
                  <div className="row">
                  <div className="col">
&nbsp;
                    </div>
                    </div>
                  <div className="row">
                            <div className="col">
                    {/* <BootstrapTable 
                    keyField="id"
                    data={items}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory()}
                    /> */}
     <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={items}
                                columns={columns}
                                getRowId={(row) => row.id}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
                    <div>
                      <SnackbarProvider 
                      show={showSnackbar}
                      message={snackbarMessage}
                      onClose={handleCloseSnackbar}
                      />
                    </div>
                    </div>
                    </div>
              </Card.Body>
            </Card>
          </div>
          </div>
          </div>
      </div>
    );

}

export default MentorWorkshopViewPage;