import React from 'react';
import PropTypes from "prop-types";

const SearchInputs = ( { showEmailSearch, emailSearch, onEmailChange}) => {

    return (
        
        <div style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            // height: "100%"
          }}
        >
          <div style={{
              boxShadow: "0 1px 2px 0 rgba(0,0,0,.1)",
              borderTop: "4px solid #654DA4",
              background: "#fff",
            //   height: "100%",
              width: "100%",
              marginTop: 10,
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 10,
            }}
          >
            <table>
                
                <tr>
                    <td>
                        {showEmailSearch && (
                            <div className='input-group'>
                            {/* form-control */}
                                <input  className=' custom-input'
                                    type='text'
                                    placeholder='Search by Email'
                                    value={emailSearch}
                                    onChange={onEmailChange}
                                    />
                                 <span className='input-group-addon'>
                                        <i className='bi bi-search'></i>
                                    </span>
                               
                            </div>
                        )}
                        
                       
                    </td>
                    {/* <td>
                        <input
                        type='text'
                        placeholder='Search by Name'
                        // value={nameSearch}
                        // onChange={onNameChange}
                        />
                    </td>
                    <td>
                        <input
                        type='text'
                        placeholder='Start Date'
                        // value={nameSearch}
                        // onChange={onNameChange}
                        />
                    </td>
                    <td>
                        <input
                        type='text'
                        placeholder='End Date'
                        // value={nameSearch}
                        // onChange={onNameChange}
                        />
                    </td> */}
                </tr>
            </table>
             
             
             
             
             
          </div>
    </div>

    );
};
SearchInputs.propTypes ={
    showEmailSearch: PropTypes.bool.isRequired, 
    emailSearch: PropTypes.string.isRequired,   
    onEmailChange: PropTypes.object.isRequired, 
  }; 
export default SearchInputs;