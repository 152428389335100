//import ProfilePic from '../../../../Images/avatar.png';
import React from "react";
import ProgressCircle from '../progress/ProgressCircle'
//import { Link } from "react-router-dom";
//import Button  from "react-bootstrap/Button";
import {useEffect, useState} from 'react';
import { getCurrentUser } from '../../../../api/userAuthApi';

const Profilecard = () =>{
    const [profileCompletionPercentage, setProfileCompletionPercentage] = useState(0);
    const [userName, setUserName] = useState('');
    
    const ProfilePicBase64 = localStorage.getItem("profilePic");

    useEffect(() => {
        const user = getCurrentUser();         
        if (user) {
            setProfileCompletionPercentage(user.profile_completion);
            setUserName(user.firstname + ' ' + user.lastname);
        }
      },[profileCompletionPercentage]);

    
    return(
        // className="profilepiccard"   marginTop: "17px"
        <div className="profilepiccard">
            <div style={{
                marginLeft: "75px",
               
            }}>
                <ProgressCircle percent={profileCompletionPercentage} avatarURL={ProfilePicBase64} >
                    {profileCompletionPercentage}%
                </ProgressCircle>
               
            </div>
            
            <p className="card-text" style={{marginLeft: "75px", marginTop: "5px"}}>{userName}</p>
                <hr/>
                
            
            {/* <div className="card-body" style={{alignContent:"center !important"}}>
                <p className="card-text">{userName}</p>
                <hr/>
                <div>
                    {profileCompletionPercentage}% <br></br>
                    Profile Compltion
                    
                    
                        <Button variant="primary" className="button" type="submit" style={{fontSize:"12px"}}  >
                            Complete your profile
                        </Button>
                    
                </div>
            </div> */}
            
            </div>
    )
}
export default Profilecard;