import { apiMthods } from "./apiUtils";
import { getToken } from "./userAuthApi";

//const baseUrl = "http://localhost/somutusAPI/v1/admin";  
//const baseUrl = "https://somotusapi.azurewebsites.net/v1/admin"; 
//const baseUrl = "http://localhost:3001/v1/admin"; 
//const baseUrl = process.env.NODE_ENV === 'production' ? "api/v1/admin" : "http://localhost:3001/v1/admin"; 

const baseUrl = process.env.NODE_ENV === 'production' ? "http://13.232.221.26/api/v1/admin" : "http://localhost:3001/v1/admin"; 

//const baseUrl = "http://13.232.221.26/api/v1/admin";

export async function getProfileDetails(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveProfileDetailsByLogon", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getNewSubscribers(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveNewSubscribers", {      
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}

export async function getNewSubscribersByRole(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveNewSubscribers/"+payload, {    
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}

export async function getNewSubscriberDetails(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveNewSubscriberDetails/"+payload, {
        method: apiMthods.get,
        headers: {
            //"content-type": "application/json",
            Authorization: token,
        },
    })
    .then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
             console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
        //console.log(response);
        return response;
        
    });
}

export async function approveSubscriber(payload){
    const token = await getToken();
    return fetch(baseUrl + "/approveNewSubscriber", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getMentors(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentors", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}

export async function getCorporates(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveCorporates", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}

export async function getMentorDetails(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveMentorProfileDetailsByLogon/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{       
        console.log(response);
        return response;
        
    });
}

export async function getSubscriptionsById(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSubscriptions/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}

export async function getPaymentsById(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrievePayments/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}

export async function getNotificationsById(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveNotifications/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}

export async function getSessionsByGuid(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSessions/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{

        return response;
        
    });
}

export async function getSessionsByUGuid(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSessionsByUguid/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{

        return response;
        
    });
}

export async function getWorkshopsByGuid(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveWorkshops/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{

        return response;
        
    });
}

export async function getWorkshopsByUGuid(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveWorkshopsByUguid/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{

        return response;
        
    });
}

export async function getResourcesByGuid(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveResources/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{

        return response;
        
    });
}

export async function getResourcesByUGuid(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveResourcesByUguid/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{

        return response;
        
    });
}

export async function getCoursesByGuid(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveCourses/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{

        return response;
        
    });
}
 
export async function getCoursesByUGuid(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveCoursesByUguid/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{

        return response;
        
    });
}



export async function addMasterdata(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addmasterdata", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateMasterdata(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateMasterdata", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateEmailTemplate(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateEmailTemplate", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}


export async function deleteMasterdata(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteMasterdata", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getConstants(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveconstants", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}

export async function getEmotionExpresso(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveEmotionExpresso", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}
 

export async function updateConstantdata(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateConstantData", {
        method: apiMthods.put,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getEmailTemplates(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveETemplates", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}

export async function getAdvertisements(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveAdvertisements", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}
export async function getAdvertisementsDetails(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveAdvertisements/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
        }
    }).then(response=>{
       
        //console.log(response);
        return response;
        
    });
}


export async function addAdvertisements(payload){
    const token = await getToken();
   
    return fetch(baseUrl + "/addAdvertisements", {
        method: apiMthods.post,
        headers: {
            //"content-type": "application/json",
            Authorization: token,
        },
        body: payload,
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updateAdvertisements(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateAdvertisements", {
        method: apiMthods.put,
        headers: {
            //"content-type": "application/json",
            Authorization: token,
        },
        body: payload,
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getPayments(){
    const token = await getToken();
    return fetch(baseUrl + "/retrievePaymentsByLogon", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function paymentsettlement(payload){
    
    const token = await getToken();
    return fetch(baseUrl + "/paymentsettlement", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}


export async function updateSettlePercentage(payload){    
    const token = await getToken();
    return fetch(baseUrl + "/updateSettlePercentage", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function accountActivate(payload){
    
    const token = await getToken();
    return fetch(baseUrl + "/accountActivate", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function accountDeActivate(payload){
    
    const token = await getToken();
    return fetch(baseUrl + "/accountDeActivate", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}



export async function deleteAdvertisements(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteAdvertisements", {
        method: apiMthods.delete,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}


export async function getSubscriptionPlan(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSubscriptionPlan", {      
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getSubscriptionPlanFormatted(){    
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSubscriptionPlanFormatted", {      
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}


export async function getSubscriptionPlanDetails(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSubscriptionPlan/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function getSettlePercentageByGUID(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveSettlePercentage/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            // console.log(JSON.stringify(response) );
            // console.log(response.json() );

            return response.json();
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function addSubscriptionPlan(payload){
    const token = await getToken();
    return fetch(baseUrl + "/addSubscriptionPlan",{
        method:apiMthods.post,
        headers:{
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    })
}

export async function updateSubscriptionPlan(payload){
    const token = await getToken();
    return fetch(baseUrl + "/updateSubscriptionPlan",{
        method:apiMthods.put,
        headers:{
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    })

}

export async function deleteSubscriptionPlan(payload){
    const token = await getToken();
    return fetch(baseUrl + "/deleteSubscriptionPlan",{
        method:apiMthods.delete,
        headers:{
            "content-type": "application/json",
            Authorization:  token,
        },
         body: JSON.stringify(payload),
    })
    
}

export async function getConversationsByLogon(payload){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveConversationsByLogon/"+payload, {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}

export async function sendFireBaseNotifications(payload){
    const token = await getToken();
    return fetch(baseUrl + "/SendFireBaseNotifications", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function updatePassword(payload){
    const token = await getToken();
    return fetch(baseUrl + "/resetpassword", {
        method: apiMthods.post,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
        body: JSON.stringify(payload),
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response;
        }
    });
}

export async function getWorkShopLeads(){
    const token = await getToken();
    return fetch(baseUrl + "/retrieveWorkshopLeads", {
        method: apiMthods.get,
        headers: {
            "content-type": "application/json",
            Authorization: token,
        },
    }).then((response) => {
        if(!response.ok){
            const err = new Error("Error in response");
            throw err;
        } else{
            return response.json();
            //return response;
        }
    }).then(response=>{
       
        console.log(response);
        return response;
        
    });
}