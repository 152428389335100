import React,{useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import "react-datepicker/dist/react-datepicker.css";
import  Button  from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
//import  {Row} from "react-bootstrap";
//import {Col} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import PropTypes from "prop-types";
//import { zonedTimeToUtc } from "date-fns-tz";
//import parse  from "date-fns/parse";
import { format } from 'date-fns';
import { getcustomers  } from "../../../api/mentorApi";

function EditEvent(props){
    const [mode, setMode] = useState("PUBLIC");  
    const [inputValue, setInputValue] = useState("");
    const [inputId, setInputId] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(true);

    const editeventobj = props.editevent;
    const [date,setDate] = useState(new Date());
    const[title,setTitle] =useState(props.editevent.title);
    const[id,setId] =useState(props.editevent.id);
    let desc;
   
    // const hourlyTimeOptions = [];
    // for (let i = 0; i < 24; i++) {
    //     const hour = i.toString().padStart(2, '0');
    //     hourlyTimeOptions.push(`${hour}:00`);
    // }

    // const calculateNearestHour = () => {
    //     const currentDate = new Date();
    //     const currentHour = currentDate.getHours();
    //     const currentMinute = currentDate.getMinutes();
    
    //     // Calculate the nearest hour
    //     let nearestHour = currentHour;
    
    //     if (currentMinute >= 30) {
    //       nearestHour++;
    //     }
    
    //     if (nearestHour >= 24) {
    //       nearestHour = 0;
    //     }
    
    //     return `${nearestHour.toString().padStart(2, "0")}:00`;
    //   };

    const handleEndTimeChange = (time) => {
        const [, minutes] = time.split(':');
        if (minutes === '00' ) { //|| minutes === '30'
          onEndTimeChange(time);
        } else {
          console.log('Invalid end time');
          //formik.setFieldValue('end', '00:00');
        }
      };
      
      const handleStartTimeChange = (time) => {
        const [, minutes] = time.split(':');
        if (minutes === '00') { //|| minutes === '30'
          onStartTimeChange(time);

          // Calculate and set the default end time (1 hour later)
          const [hours, minutes] = time.split(':').map(Number);
          console.log(minutes);
          const newEndTime = `${(hours + 1).toString().padStart(2, '0')}:00`;
          onEndTimeChange(newEndTime);

        } else {
          console.log('Invalid start time');
          //formik.setFieldValue('start', '00:00');
        }
      };
      
    //   const isEndTimeValid = (startTime, endTime) => {
    //     const [startHours, startMinutes] = startTime.split(':');
    //     const [endHours, endMinutes] = endTime.split(':');
      
    //     const startTimestamp = parseInt(startHours) * 60 + parseInt(startMinutes);
    //     const endTimestamp = parseInt(endHours) * 60 + parseInt(endMinutes);
      
    //     return endTimestamp - startTimestamp >= 60;
    //   };

    if(props.editevent.description != undefined){
       
        desc = props.editevent.description;
    }
    else{
        desc =  props.editevent.extendedProps.description;
    }
    editeventobj.description = desc;
    const[description,setDescription] =useState(desc);
    const [startTime, onStartTimeChange] =useState();
    const [endTime,onEndTimeChange] = useState();

    useEffect(()=>{
        setId(props.editevent.id);
        setTitle(props.editevent.title);
        setDescription(desc);
        onStartTimeChange((props.editevent.start != null && props.editevent.start !=undefined ) ? (new Date(props.editevent.start).getHours().toString().length == 1 ? ('0' + new Date(props.editevent.start).getHours().toString()) : new Date(props.editevent.start).getHours().toString()) + ':'+ (new Date(props.editevent.start).getMinutes().toString().length == 1 ? (new Date(props.editevent.start).getMinutes().toString() + '0') : new Date(props.editevent.start).getMinutes().toString()) : '10:00');
        onEndTimeChange((props.editevent.end != null && props.editevent.end !=undefined ) ? (new Date(props.editevent.end).getHours().toString().length == 1 ? ('0'+new Date(props.editevent.end).getHours().toString()) : new Date(props.editevent.end).getHours().toString())  + ':'+ (new Date(props.editevent.end).getMinutes().length == 1 ? (new Date(props.editevent.end).getMinutes().toString() +'0') : new Date(props.editevent.end).getMinutes().toString()) : '10:00');
        setDate(new Date(props.editevent.start));

        const fetchSuggestions = async () => {
            try {
                const customersData = await getcustomers();
                // Filter data based on input value
                const filteredSuggestions = customersData.filter(
                    (customer) =>
                    customer.name.toLowerCase().includes(inputValue.toLowerCase())
                );

                setSuggestions(filteredSuggestions);
                // getcustomers().then(response=>{                    
                //     setSuggestions(response);
                // });
              
            } catch (error) {
              // Handle errors
            }
          };      
          if (inputValue.trim() !== "") {
            fetchSuggestions();
          } else {
            setSuggestions([]);
          }

        // // NEW CODE START
        // const startDate = new Date(props.editevent.start);
        // const endDate = new Date(props.editevent.end);
      
        // // Format the start time as HH:mm
        // const formattedStartTime =
        //   startDate.getHours().toString().padStart(2, "0") +
        //   ":" +
        //   startDate.getMinutes().toString().padStart(2, "0");
      
        // // Format the end time as HH:mm
        // const formattedEndTime =
        //   endDate.getHours().toString().padStart(2, "0") +
        //   ":" +
        //   endDate.getMinutes().toString().padStart(2, "0");
      
         

        // // Set the state variables for startTime and endTime
        // onStartTimeChange(formattedStartTime);
        // onEndTimeChange(formattedEndTime);
      
        // setDate(startDate);

        // // NEW CODE END
        
        //onStartTimeChange(calculateNearestHour());

    },[editeventobj,inputValue,inputId])
    
    const handleCalendarClose =()=>{

    }

    const handleCalendarOpen=()=>{

    }

    // const handleTypeOfChange =() =>{

    // }
    const handleDeleteEvent = () =>{
        props.deleteEvent(props.editevent);
    }

    

    const handleUpdateEvent = (event) => {
        event.preventDefault();
        const selectedDate = new Date(date);
        const eventObj = {};
        eventObj.id = id;
        eventObj.title = title;
        eventObj.description = description;
        eventObj.color = '#378006';
       // const localTimeZone ='UTC';

        const startDateTime = new Date(selectedDate);
        startDateTime.setHours(startTime.split(':')[0]);
        startDateTime.setMinutes(startTime.split(':')[1]);
    
        const endDateTime = new Date(selectedDate);
        endDateTime.setHours(endTime.split(':')[0]);
        endDateTime.setMinutes(endTime.split(':')[1]);
    
        // Use format from date-fns to format the date into the desired format
        const dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"; // Use the format that matches your server's expectations
        eventObj.start = format(startDateTime, dateFormat);
        eventObj.end = format(endDateTime, dateFormat);

        // alert(JSON.stringify(eventObj));

        // let start1= zonedTimeToUtc(parse(startDateTime, "MM/dd/yyyy HH:mm",new Date()),localTimeZone);
        // let end1=zonedTimeToUtc(parse(endDateTime, "MM/dd/yyyy HH:mm",new Date()),localTimeZone);
        // alert(start1);
        // alert(end1);
        props.updateEvent(eventObj);
    }

    
    const handleInputTextChange=(event)=>{       
        if(event.target.name == 'title'){
            //props.editevent.title=event.target.value;
            setTitle(event.target.value);
        }
        else{
            //props.editevent.description=event.target.value;
            setDescription(event.target.value);
        }
    }

    const handleModeChange = (e) => {
        setMode(e.target.value);
        setInputValue('');
        setInputId('');
      }; 
     
    
      const handleInputChange = (e) => {
        setInputValue(e.target.value);       
        setShowSuggestions(true);
      };
    
      const handleSelectSuggestion = (selectedItem) => {
        setInputValue(selectedItem.name);
        setInputId(selectedItem.id)
        setSuggestions([]); // Clear suggestions when a suggestion is selected
        setShowSuggestions(false);
      };


    return(

        <div>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Update/Cancel Session
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <div className="row">
                <div className="col">
                *Every 1-1 session will be conducted/blocked for an hour. If you select more than one hour, it will be considered and displayed as multiple sessions.
                    <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" required placeholder="title" value={title} onChange={handleInputTextChange} name='title'></Form.Control>
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="formDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={3}   required placeholder="Description" value={description} onChange={handleInputTextChange} name='description'></Form.Control>
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="formDate">
                    <Form.Label>Date</Form.Label>
                    <DatePicker required
                    selected={date}
                    onChange={(date)=>{setDate(date)}}
                    onCalendarClose={handleCalendarClose}
                    onCalendarOpen={handleCalendarOpen}
                    minDate={new Date()}
                    ></DatePicker>
                    </Form.Group>
                </div>
                <div className="col">
                    <Form.Group className="mb-3" controlId="formStartTime">
                    <Form.Label>Start At</Form.Label><br></br>
                
                    <TimePicker required
                    //onChange={onStartTimeChange}
                    onChange={handleStartTimeChange}
                    disableClock={true}
                    value={startTime}></TimePicker>
                    </Form.Group>
                </div>
                <div className="col">
                    <Form.Group className="mb-3" controlId="formEndTime">
                    <Form.Label>End At</Form.Label><br></br>
                    <TimePicker required disabled
                    //onChange={onEndTimeChange}
                    onChange={handleEndTimeChange}
                    disableClock={true}
                    value={endTime}></TimePicker>
                    </Form.Group>
                    <Form.Text className="text-muted">
                    You can select only hourly time slots 
                    </Form.Text>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="formEndTime">
                    <Form.Check
                    inline
                    label="PUBLIC"
                    name="mode"
                    type="radio"
                    id="radio-public"
                    value="PUBLIC"
                    checked={mode === "PUBLIC"}
                    onChange={handleModeChange}
                  />
                  <Form.Check
                    inline
                    label="PRIVATE"
                    name="mode"
                    type="radio"
                    id="radio-private"
                    value="PRIVATE"
                    checked={mode === "PRIVATE"}
                    onChange={handleModeChange}
                  />
                  {mode === "PRIVATE" && (
                    // <Form.Control
                    //   required
                    //   type="text"
                    //   placeholder="Enter User Name"
                    //   name="username"
                    // />
                    <div className="autocomplete-container">
      <input
       required={mode === 'PRIVATE'}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type a name"
      />
      {inputValue && showSuggestions && (
        <ul className="suggestions-list">
          {suggestions.length > 0 ? (
            suggestions.map((item) => (
              <li
                key={item.id}
                onClick={() => handleSelectSuggestion(item)}
                className="suggestion-item"
              >
                {item.name}
              </li>
            ))
          ) : (
            <li className="no-data">No data found</li>
          )}
        </ul>
      )}
    </div>
                  )} 
                                
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Form.Group controlId="btnUpdate">
                    <Button variant="primary" className="button"  onClick={handleUpdateEvent}> Update </Button>
                    <Button variant="primary" className="button" style={{marginLeft:"5px"}}  onClick={handleDeleteEvent}>  Cancel Session </Button>
                    </Form.Group>
                    
                </div>
                 
            </div>

            
  
            
        </Form>
      </Modal.Body>       
    </Modal>

        
        </div>
    )
}
EditEvent.propTypes ={
    editevent: PropTypes.object.isRequired,
    deleteEvent: PropTypes.object.isRequired,
    updateEvent: PropTypes.object.isRequired,
};

export default EditEvent;