import React from "react";
import topbarLogo from '../../../Images/lifeskylls-logo.png';
import banner from '../../../Images/landing-banner.PNG';
import  {Link}  from 'react-router-dom' 

function ContactUsPage(){
  return (
    <div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
    data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
        <div className="App App-height" style={{minHeight:'600px'}}>

            <nav className="navbar navbar-expand-lg navbar-light header-color">
                <div className="container-fluid">                  
                    <img src={topbarLogo} className="topBarLogo" />                   
                    <a className="navbar-brand" href="#"></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="navbar-nav me-auto mb-2 mb-lg-0">

                        </div>

                        <form className="d-flex">
                            [A product of SOMOTUS]
                        </form>
                    </div>
                </div>
            </nav>

            <div className='landingcontainerflex'>
                <div className='landingflexitem1'>
                    <span className='landing-h1'>
                        Contact Us
                    </span>
                  
                    <span className='landing-h3'>
                    <br/>
                    <br/>
                    We&apos;re here to help you unlock the potential of your team or students! Reach out to us today to discuss your specific needs and learn how LifeSkylls product can empower your young minds to achieve their full potential. 

<p>You can contact us to learn more about our product or Demo call.</p>

<p>Address: 18th Main, Jayanagar 9th Block, Marenahalli Main Road, Bangalore 560041 <br></br>
Email - hello@somotus.com <br></br>
Phone - 91 9886951007  /  91 9945132972 <br></br>
</p>
                    </span>
                   
                  
                   
                 

                </div>
                <div className='landingflexitem2'>
                    <img src={banner} className="banner" />
                </div>
            </div>
           
        </div>
    <div className="footer-New text-center">
    <div className='row'>
      <div className='col'>
      Copyright © 2024 by Somotus
      </div>
      <div className='col'>
      <Link className='aLink' to={"/"}>
      Home        
      </Link>  | &nbsp;
      <Link className='aLink' to={"/contactus"}>
      Contact Us         
      </Link> 
          
      </div>
    </div>
         
  </div>
    </div>

);
    

}
export default ContactUsPage;