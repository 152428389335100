import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from "prop-types";

function CustomTooltip({ text }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <OverlayTrigger
      placement="auto"
      show={showTooltip}
      overlay={
        <Tooltip id="help-tooltip" className="custom-tooltip" style={{ width: '350px', whiteSpace: 'normal', maxWidth: '600px' }}>
          <div>
            <span className="tooltip-close" onClick={toggleTooltip}>×</span>
            <br />
            {/* {text} */}
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </Tooltip>
      }
    >
      <span
        className="help-icon"
        style={{
          position: 'absolute',
          top: '10px',
          right: '5px',
          cursor: 'pointer',
          fontSize: '20px',
        }}
        onClick={toggleTooltip}
      >
        <i className="bi bi-question-circle-fill" style={{ color: '#707cd2' }}></i>
      </span>
    </OverlayTrigger>
  );
}
CustomTooltip.propTypes ={
    text: PropTypes.string.isRequired, 
  }; 
export default CustomTooltip;
