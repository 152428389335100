// import { Outlet, Navigate } from "react-router-dom";
// // import { useAuth } from "./authContext";
// import React, {useEffect, useState} from "react";
// import { getCurrentUser } from '../../api/userAuthApi';
// import MentorLayout from "../pages/layouts/mentor-layout";
// import AdminLayout from "../pages/layouts/admin-layout";
// import CorporateLayout from "../pages/layouts/corporate-layout";

// const PrivateRoutes = () => {
//   const [loading, setLoading] = useState(true);
//   const [state, setState] = useState({
//     showMentorBoard: false,
//     showAdminBoard: false,
//     showCorporateBoard: false,
//     //currentUser: undefined,
//   });
  
//   const accessToken = localStorage.getItem("accessToken");
//   let auth = {'token': true}
//   if(accessToken === null){
//     auth = {'token': false}
//   }
    

//     useEffect(() => {
//       const user = getCurrentUser();
    
//       if (user) {
//         // console.log(JSON.stringify(user));
//         // console.log(user.role);
//         setState(prevState => ({
//           ...prevState,
//           //currentUser: user,
//           showMentorBoard: user.role.includes("Mentor"), //ROLE_MODERATOR
//           showAdminBoard:  user.role.includes("Super Admin"), //ROLE_ADMIN
//           showCorporateBoard: user.role.includes("Corporate"), //ROLE_MODERATOR
//         }));
//         setLoading(false);
//       }
//     },[state.showMentorBoard,state.showAdminBoard,state.showCorporateBoard]);

//      if(loading){
//         return<div>Loading...</div>;
//      } 
//   return (
//      auth.token && state.showMentorBoard ? <MentorLayout> <Outlet/> </MentorLayout> : 
//      auth.token && state.showAdminBoard ? <AdminLayout> <Outlet/> </AdminLayout> : 
//      auth.token && state.showCorporateBoard ? <CorporateLayout> <Outlet/> </CorporateLayout> : 
//      <Navigate to="/login"/>
     
//   ) 

// };

// export default PrivateRoutes;

import { Outlet, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getCurrentUser } from '../../api/userAuthApi';
import MentorLayout from "../pages/layouts/mentor-layout";
import AdminLayout from "../pages/layouts/admin-layout";
import CorporateLayout from "../pages/layouts/corporate-layout";
import InstituteLayout from "../pages/layouts/institute-layout";

const PrivateRoutes = () => {
  const [state, setState] = useState({
    showMentorBoard: false,
    showAdminBoard: false,
    showCorporateBoard: false,
    showInstituteBoard: false,
  });

  // Check for the access token when the component initially renders
  const accessToken = localStorage.getItem("accessToken");

  // If there's no access token, immediately navigate to the login page
  if (accessToken === null) {
    return <Navigate to="/login" />;
  }

  useEffect(() => {
    const user = getCurrentUser();

    if (user) {
      setState(prevState => ({
        ...prevState,
        showMentorBoard: user.role.includes("Mentor"),
        showAdminBoard:  user.role.includes("Super Admin"),
        showCorporateBoard: user.role.includes("Corporate"),
        showInstituteBoard: user.role.includes("Educational Institute"),
      }));
    }
  }, []);

  return (
    state.showMentorBoard ? <MentorLayout> <Outlet/> </MentorLayout> :
    state.showAdminBoard ? <AdminLayout> <Outlet/> </AdminLayout> :
    state.showCorporateBoard ? <CorporateLayout> <Outlet/> </CorporateLayout> :
    state.showInstituteBoard ? <InstituteLayout> <Outlet/> </InstituteLayout> :
    null // Return null if none of the conditions match
  );
};

export default PrivateRoutes;

