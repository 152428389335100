import React, {useEffect, useState } from "react";
// import Form from 'react-bootstrap/Form';
// import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
//import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
// import Select from 'react-select';
// import { useFormik, Formik } from "formik"
// import * as Yup from "yup";
// import {useQuery, useQueryClient } from "react-query";
// import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
// import { Link } from "react-router-dom";
// import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import FilterFactoryProps, {textFilter} from "react-bootstrap-table2-filter";
// import filterFactory from "react-bootstrap-table2-filter";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { getSubscriptions } from "../../../api/mentorApi";
//import { useParams } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function SubscriptionDetails(){
    const classes = useStyles();

   // const { id } = useParams(); 
    const [subscriptionList, setSubscriptionList] = useState([]);

    // const dateFormatter = (cell) => { //, row
    //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
    //     return <span>{formattedDate}</span>;
    // }

    

     
    const columns =[
        {field: 'planname', headerName: 'Plan Name', minWidth: 150},
        {field: 'description', headerName: 'Description', minWidth: 150},
        {field: 'duration', headerName: 'Duration', minWidth: 150},
        {field: 'finalamount', headerName: 'Price', minWidth: 150},
        {field: 'subscriptionstartdate', headerName: 'Start Date', minWidth: 150, 
        valueGetter: (params) => moment(params.row.subscriptionstartdate).format("YYYY-MM-DD HH:mm")
        },
        {field: 'subscriptionenddate', headerName: 'End Date', minWidth: 150, 
        valueGetter: (params) => moment(params.row.subscriptionenddate).format("YYYY-MM-DD HH:mm")
        },
    ];
    
    useEffect(()=>{
        fetchSubscriptionData();
  
      },[]);
      
      const fetchSubscriptionData =() => {
        getSubscriptions().then(response=>{                    
            setSubscriptionList(response); 
        });
      }

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <ListGroup variant="flush">
                    <ListGroup.Item> 
                    <p>We&apos;re thrilled to have you as a valued customer. Thank you for choosing us and we look forward to serving you for many more years to come!</p>
<p>We&apos;ll send you a renewal notification 10 days prior to the expiry. You can easily renew your subscription with just a few clicks to continue enjoying all the benefits of the product. </p>
<p>If you wish to change the plan, write to us <a className="aLink" href ="mailto: hello@somotus.com">hello@somotus.com</a> and contact us through Support Page.</p>

                    <div className="row">
                        <div className="col">
                         
                        {/* <div style={{float:"right"}}> &nbsp;</div>  
                            <BootstrapTable 
                            keyField="id"
                            data={subscriptionList}
                            columns={columns}
                            // cellEdit={cellEditFactory(cellEditOptions)}
                            pagination={paginationFactory()}
                            // filter={filterFactory()}
                            /> */}
                            <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={subscriptionList}
                                columns={columns}
                                getRowId={(row) => row.id}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col">
                            <Card.Subtitle>Renew Subscription</Card.Subtitle>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formBannerImage">
                            <Form.Label>Subscription</Form.Label>
                            <Select required
                            options={fetchedSubscriptionOptions}
                            //value={fetchedSubscriptionOptions.find((option) => option.label === formik.values.categoryName)}
                            formatOptionLabel={customOptionLabel}
                        //    onChange={handleSubscriptionChange}
                            />                                  
                            
                        </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formBannerImage" style={{width:'150px'}}>
                            <Form.Label>Start From </Form.Label>
                            <DatePicker required  
                            // selected={date}
                            // onChange={(date)=>{setDate(date)}}
                            // onCalendarClose={handleCalendarClose}
                            // onCalendarOpen={handleCalendarOpen}
                            name="date"
                            ></DatePicker>
                        </Form.Group>
                        </div>
                    </div>        
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>    */}
                    </ListGroup.Item>
                </ListGroup>
               
                </div>
                <div className="row">
                <div className="col">
                    <Card>
                    <Card.Body className="table-responsive" style={{ minWidth: "160px", minHeight:"350px", width: "30%", backgroundColor: "#E5E9ED", borderTop: "5px solid #654DA4", borderRadius: "5px 0 0 0" }}>
  <h4 style={{ color: "#333", marginBottom: "20px", textAlign: "left" }}>Subscription Plan</h4>
  <hr style={{ border: "1px solid #654DA4", margin: "15px 0" }} />
  <div className="row">
  <div className="col"><strong>Free Tier</strong></div>
  <div className="col"><strong>3 MONTHS FREE</strong></div>
  </div>
  {/* <p><strong>Free Tier</strong></p>
  <p><strong>3 MONTHS FREE</strong></p> */}
  
  <h5>SOMOTUS S1</h5>
  <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#654DA4', margin: '0' }}>1 GB </p>
  <span style={{ marginTop: "-10px" }}>of standard storage</span>

  <hr style={{ border: "1px solid #654DA4", margin: "15px 0" }} />

  <p><strong>Features:</strong></p>
  <ul style={{ listStyleType: "none", paddingLeft: "0", marginLeft: "10px" }}>
    <li>1 GB of Standard Storage</li>
    <li>100 Course video uploads</li>
    <li>500 Resources uploads</li>
    <li>50 Shots uploads</li>
    <li>100 Mentoring & Workshops</li>
  </ul>
</Card.Body>

                    </Card>
                </div>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default SubscriptionDetails