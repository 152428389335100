// import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
// import * as ReactBootStrap from "react-bootstrap";
import { getResources, deleteResource } from "../../../api/mentorApi";
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
import 'bootstrap/dist/css/bootstrap.min.css';
// import cellEditFactory from 'react-bootstrap-table2-editor';
// import OverlayFactory from "react-bootstrap-table2-overlay";
import { Link } from "react-router-dom";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { Card } from 'react-bootstrap';
import Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';

import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function MentorResourcesViewPage() {
  const [clsName, setclsName] = useState('');
    const [items, setItems] = useState([]);
    //const [selectedImage, setSelectedImage] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [rowDataToDelete, setRowDataToDelete] = useState(null);

    const classes = useStyles();

    const columns =[
      // {dataField: 'id', text: 'ID'},
     
      {field: 'title', headerName: 'Title', minWidth: 200},     
      {field: 'categoryname', headerName: 'Category Name', minWidth: 250},
      {field: 'status', headerName: 'Status', minWidth: 200},
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        renderCell: (params) => (
          <>
          <Link to={`/resourceEdit/${params.row.id}`}><i className="bi bi-pencil-fill" style={{paddingRight:"10px"}}/></Link>
          <i className="bi bi-trash" title={params.row.cell} onClick={() => handleDeleteClick(params.row)} />
          </>
        ),
      
      },
    ];
    const handleDeleteClick = (row) => {
      setRowDataToDelete(row);
      setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
      const updatedDataToDelete = {
          ...rowDataToDelete,
        };
      
        deleteResource(updatedDataToDelete.id).then((response)=>{
        
          if(!response.ok){
              // const err = new Error("Error in response");
              // throw err;
              setclsName('alert error');
              setSnackbarMessage('Error in response');
              setShowSnackbar(true);
          } else{
              //return response.json();
          }
          }).then(response=>{
            console.log(response);
            setclsName('alert success');
              setSnackbarMessage('Successfully deleted Resources data.');
              setShowSnackbar(true);
              fetchResourcesData();
          });

      setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
      setShowConfirmation(false);
  }; 
    

    useEffect(()=>{
      fetchResourcesData();

    },[]);
    
    const fetchResourcesData =() => {
      getResources().then(response=>{
        const data = response;
        setItems(data);
      });
    }
    const handleCloseSnackbar = () => {
      setShowSnackbar(false);
    }
    
    return (
      <div className="featured-section-wrap featured-section-wrap-row">
      <div className='mainPageContainer'>        
          <div className='dashboardcontainerflex'>
            <div className='dashboardcard'>
            <Card >
              <Card.Body>
              <CustomTooltip text="You can easily upload documents, images, podcasts, videos, and activities for users." />
                  {/* <Card.Title style={{ display: 'inline-block' }}>My Resource</Card.Title> */}
                  <div className="row">
                  <div className="col" style={{float:"right"}}>
                    <Link to="/resourceAdd">
                    <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                        + Add Resource
                    </Button>
                    </Link>
                    </div>
                    </div>
                  <div className="row">
                    <div className="col">
                      <div className={`${classes.dataGridContainer}`}>
                      <DataGrid
                        rows={items}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        //hideFooterPagination
                        autoHeight
                        checkboxSelection={false}
                        classes={{
                          header: classes.header,
                          toolbarContainer: classes.toolbarContainer,
                          columnsContainer: classes.columnsContainer,
                          columnHeaderTitle: classes.columnHeaderTitle,
                          cell: classes.cell,
                          paginationItem: classes.paginationItem,
                          paginationItemSelected: classes.paginationItemSelected,
                          paginationItemHover: classes.paginationItemHover,
                        }}
                      />
              
                      </div>
                    <div>
                      <SnackbarProvider 
                      show={showSnackbar}
                      message={snackbarMessage}
                      onClose={handleCloseSnackbar}
                      message1={clsName}
                      />

                    <Modal show={showConfirmation} onHide={handleCancelDelete}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="danger" className="button" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                    </Modal.Footer>
                    </Modal>
                    </div>
                    </div>
                    </div>
              </Card.Body>
            </Card>
          </div>
          </div>
          </div>
      </div>
    );

}

export default MentorResourcesViewPage;