import React from 'react';
import { razorpay } from "../../../api/userAuthApi";

const RazorpayPayment = () => {
  

  const loadScripts = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
     // document.body.append(script);
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
    const orderDetails = {
      amount: 50000, // Replace with your actual amount
      currency: 'INR', // Replace with your actual currency
      // Add other required parameters
    };
    const response = await razorpay(orderDetails);
    console.log(JSON.stringify(response));
    
    const res = await loadScripts('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      alert('Rezorpay failed to load');
      return;
    }
    const options = {
      key: 'rzp_test_AdBNODeedxkMve',
      amount: response.amount,
      currency: response.currency,
      name: 'SOMOTUS',
      description: 'Test Transaction',
      image: 'https://example.com/your_logo',
      order_id: response.id,
     //callback_url: 'https://eneqd3r9zrjok.x.pipedream.net/',
     handler: function (response){
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
     },
      prefill: {
        name: 'Gaurav Kumar',
        // email: 'gaurav.kumar@example.com',
        // contact: '9000090000',
      },
      
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    // Rest of your code to display Razorpay checkout
  };

  return (
    <div>
      <a onClick={displayRazorpay} target="_blank"
      rel="noopener noreferrer">
        Donate $5
      </a>
    </div>
  );
};

export default RazorpayPayment;
