import React, { useState, useEffect  } from 'react';
import { Card, Form, Button, ProgressBar } from 'react-bootstrap';
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { getSearchDetails,addRecommendations,sendFireBaseNotifications } from '../../../api/corporateApi';
import EmployeesListPage from "./EmployeesListPage";
import Modal from 'react-bootstrap/Modal';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";

const AssignmentManagement = () => {
    const [clsName, setclsName] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [rowDataToDelete, setRowDataToDelete] = useState([]);

    const [errorMessage1,setErrorMessage1] = useState('');
    const [searchresultsList, setSearchresultsList] = useState([
      // { id: 1, name: 'Emotional Intelligence',type:'Course', isChecked: false },
      // { id: 2, name: 'Empathy Skills',type:'Resources', isChecked: false },
      // { id: 3, name: 'Test Assessements',type:'Assessments', isChecked: false },
    ]);
  
    // const [employeesList, setEmployeesList] = useState([
    //   { id: 101, name: 'Employee A', isChecked: false },
    //   { id: 102, name: 'Employee B', isChecked: false },
    //   // Add more employees as needed
    // ]);
  
    const [selectedItemsStep1, setSelectedItemsStep1] = useState([]);
    const [selectedItemsStep2, setSelectedItemsStep2] = useState([]);
    const [selectedItemsStep2Stored, setSelectedItemsStep2Stored] = useState([]);

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 3; // Total number of steps
  
    const stepColors = {
      1: 'primary',
      2: 'success',
      3: 'warning',
      4: 'info',
    };
    const  validationSchema = Yup.object({
      empEmail: Yup.string()
      .required("Keyword is required.")
    });

    const formikConfig = {
        1: {
          initialValues: {
            // Initial values for step 1
            empEmail: '',          
          },
          validationSchema,
          onSubmit: () => {
            //setSelectedItemsStep1(searchresultsList);
            setCurrentStep(2);
          },
        },
        2: {
          initialValues: {
            // Initial values for step 2
            field3: '',
            field4: '',
          },
          onSubmit: () => {
            // Get selected items from the EmployeesListPage (update this based on your logic)
            //const selectedEmployees = []; // Replace with your logic to get selected employees
            //setSelectedItemsStep2(selectedEmployees);
            setCurrentStep(3);
          },
        },
        3: {
          initialValues: {
            // Initial values for step 3
            field5: '',
            field6: '',
          },
          onSubmit: () => {
            // Handle final submission
            let data = [];
            selectedItemsStep2.forEach((receiver) => {
              // Loop through selectedItemsStep1 for each receiver
              selectedItemsStep1.forEach((item) => {
                const postData = {
                  receiverguid: receiver.guid,
                  contenttype: item.contenttype,
                  contentguid: item.contentguid,
                  title: item.title,
                  description: '',                   
                };
                data.push(postData);
               
              });

            });
           
            handleDeleteClick(data);
            console.log('Assignment completed!');
          },
        },
        // 4: {
        //   initialValues: {
        //     // Initial values for step 4
        //     field7: '',
        //     field8: '',
        //   },
        //   onSubmit: () => {
        //     // Handle final submission
        //     console.log('Registration completed!');
        //   },
        // },
      };
  
    const handleDeleteClick = (row) => {
      setRowDataToDelete(row);
      setShowConfirmation(true);
    };
    const handleConfirmDelete = () => {
      // const updatedDataToDelete = {
      //     ...rowDataToDelete,
      //   };
      alert('START rowDataToDelete');
        rowDataToDelete.forEach((data) => {
          alert('START addRecommendations');
            addRecommendations(data).then((response)=>{    
            if(!response.ok){
                // const err = new Error("Error in response");
                // throw err;
                setclsName('alert error');
                setSnackbarMessage('Error in response');
                setShowSnackbar(true);
            } else{
                //return response.json();
            }
            }).then(response=>{
              console.log(response);
              alert('START SUCCESS');
              let contentType = data.contenttype;
              let title ='';
              let body ='';
              let postData = null;

              switch (contentType) {
                case "MENTOR":
                  postData = {
                    type: 'MENTOR',
                    guid: data.guid,                                                   
                  };
                  title = "Personalized Mentor Recommendations";
                  body = "Explore personalized mentor recommendations tailored just for you. Connect with mentors who can help you achieve your goals.";
                  break;  
                case "RESOURCE":
                  postData = {
                    type: 'RESOURCE',
                    guid: data.guid,                                                   
                  };
                  title = "Recommendation about Resource";
                  body = "Explore curated recommendations for resource. Take your skills to the next level with personalized learning suggestions.";
                  break;  
                case "COURSE":
                  postData = {
                    type: 'COURSE',
                    guid: data.guid,                                                   
                  };
                  title = "Recommendation about Courses";
                  body = "Explore curated recommendations for courses. Take your skills to the next level with personalized learning suggestions.";
                  break;  
                case "WORKSHOPS":
                  postData = {
                    type: 'WORKSHOPS',
                    guid: data.guid,                                                   
                  };
                  title = "Recommendation about Workshops";
                  body = "Explore curated recommendations for workshops. Take your skills to the next level with personalized learning suggestions.";
                  break; 
                case "SESSIONS":
                  postData = {
                    type: 'SESSIONS',
                    guid: data.guid,                                                   
                  };
                  title = "Recommendation about Mentor Sessions";
                  body = "Explore curated recommendations for one-on-one sessions. Take your skills to the next level with personalized learning suggestions.";
                  break;
                default:
                  title = "New Notification";
                  body = "You have received a new notification. Check it out!";
                  break;
              }
              alert('START postNotifications');
              const postNotifications = {
                guid: data.guid,
                title: title,
                body: body,
                postData: postData,  
              };
              alert(JSON.stringify(postNotifications));
              sendFireBaseNotifications(postNotifications).then((response)=>{    
                if(!response.ok){
                    setclsName('alert error');
                    setSnackbarMessage('Error in response');
                    setShowSnackbar(true);
                } else{
                    //return response.json();
                }
                }).then(response=>{
                  console.log(response);
                  alert('DONE');
                }); 
                setclsName('alert success');
                setSnackbarMessage('Successfully assigned1.');
                setShowSnackbar(true);
                 
            });
        });
        
         

          const emptydata = [];
          setSelectedItemsStep1(emptydata);
          setSelectedItemsStep2(emptydata);
          setSearchresultsList(emptydata);
          setCurrentStep(1);

      setShowConfirmation(false);
    };

    const handleCancelDelete = () => {
      setShowConfirmation(false);
    }; 

    const handleCloseSnackbar = () => {
      setShowSnackbar(false);
    }

    const formik = useFormik(formikConfig[currentStep]);
  
    const moveToPreviousStep = () => {
      setCurrentStep((prevStep) => Math.max(1, prevStep - 1));

      const selectedEmployees = []; // Replace with your logic to get selected employees
      setSelectedItemsStep2(selectedEmployees);
    };
  
    const moveToNextStep = () => {
      formik.handleSubmit(); // Run form submission logic for the current step
      if (currentStep === 2) {
        // Save selected items for Step 2
        setSelectedItemsStep2Stored(selectedItemsStep2);
      }
    };
  
    useEffect(() => {
        console.log('Selected items step 1:', JSON.stringify(selectedItemsStep1));
      }, [selectedItemsStep1]);
    
      useEffect(() => {
        console.log('Selected items step 2:', JSON.stringify(selectedItemsStep2));
      }, [selectedItemsStep2]);

      // const fetchEmployeesData = () => {
      //   getSearchDetails()
      //     .then((response) => {
      //       setSearchresultsList(response);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // };

      // const handleCheckboxChangeStep1 = (item) => {
      //   setSearchresultsList((prevList) =>
      //     prevList.map((search) =>
      //     search.contentguid === item.contentguid ? { ...search, isChecked: !search.ischecked } : search
      //     )
      //   );
      // alert(JSON.stringify(searchresultsList));
      //   setSelectedItemsStep1((prevList) =>
      //     item.ischecked
      //       ? prevList.filter((selectedItem) => selectedItem.contentguid !== item.contentguid)
      //       : [...prevList, { ...item, isChecked: !item.ischecked }]
      //   );
      //   alert(JSON.stringify(selectedItemsStep1));
      // };

      // const handleCheckboxChangeStep1_old = (item) => {
      //   setSearchresultsList((prevList) =>
      //     prevList.map((search) =>
      //       search.contentguid === item.contentguid ? { ...search, isChecked: !search.ischecked } : search
      //     )
      //   );
      
      //   setSelectedItemsStep1((prevList) => {
      //     const updatedList = item.ischecked
      //       ? prevList.filter((selectedItem) => selectedItem.contentguid !== item.contentguid)
      //       : [...prevList, { ...item, isChecked: !item.ischecked }];
      //     return updatedList;
      //   });
      // };
      const handleCheckboxChangeStep1 = (item) => {
        setSearchresultsList((prevList) =>
        prevList.map((search) =>
          search.contentguid === item.contentguid &&
          search.contenttype === item.contenttype &&
          search.title === item.title
            ? { ...search, isChecked: !search.isChecked }
            : search
        )
      );
    
      setSelectedItemsStep1((prevList) => {
        const isItemInList = prevList.some(
          (selectedItem) =>
            selectedItem.contentguid === item.contentguid &&
            selectedItem.contenttype === item.contenttype &&
            selectedItem.title === item.title
        );
    
        const updatedList = isItemInList
          ? prevList.filter(
              (selectedItem) =>
                selectedItem.contentguid !== item.contentguid ||
                selectedItem.contenttype !== item.contenttype ||
                selectedItem.title !== item.title
            )
          : [...prevList, { ...item, isChecked: !item.isChecked }];
    
        return updatedList;
      });
      };
      
      
      // const handleCheckboxChangeStep2 = (item) => {
      //   // setEmployeesList((prevList) =>
      //   //   prevList.map((employee) =>
      //   //     employee.id === item.id ? { ...employee, isChecked: !employee.isChecked } : employee
      //   //   )
      //   // );
      
      //   // setSelectedItemsStep2((prevList) =>
      //   //   item.isChecked
      //   //     ? prevList.filter((selectedItem) => selectedItem.id !== item.id)
      //   //     : [...prevList, { ...item, isChecked: !item.isChecked }]
      //   // );
      // };

      const handleSelectedEmployeesStep2 = (selectedEmployees) => {
        setSelectedItemsStep2(selectedEmployees);
       // setEmployeesList(selectedEmployees);
         
      };
      const shouldDisableButton = (buttonType) => {
        
        switch (buttonType) {
          case 'previous':
            return currentStep === 1;
          case 'next':
            return  !hasSelectedItemsForStep(currentStep);
          default:
            return false;
        }
      };

      const hasSelectedItemsForStep = (step) => {
        switch (step) {
          case 1:
            return selectedItemsStep1.length > 0;
          case 2:
            return selectedItemsStep2.length > 0;
          case 3:
            return selectedItemsStep1.length > 0 && selectedItemsStep2Stored.length > 0;
          default:
            return false;
        }
      };
      
    return (
      <div className="featured-section-wrap featured-section-wrap-row">
        <div className="mainPageContainer">
          <div className="dashboardcontainerflex">
            <div className="dashboardcard">
              <Card>
                <Card.Body>
                  <p style={{ fontSize: '18px' }}>Assign your employees</p>
                  <ProgressBar>
                    {[...Array(totalSteps).keys()].map((step) => (
                      <ProgressBar
                        key={step + 1}
                        variant={stepColors[step + 1]}
                        now={step + 1 === currentStep ? 100 : 0}
                      />
                    ))}
                  </ProgressBar>
                  <Form onSubmit={formik.handleSubmit}>
                 
                    {currentStep === 1 && (
                    <>
                        <p>You can search Mentors, Courses, Resources and select your employees to assign or recommend</p> 
                            <div className="row">
                                            <div className="col">
                                            
                                            <Form.Group className="mb-3" controlId="empEmail">
                                            <Form.Control
                                              type="text"
                                              name="empEmail"
                                              placeholder="Type Mentor name, or Course name, or Resources"
                                              isInvalid={Boolean(formik.errors.empEmail && formik.touched.empEmail)}
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                            />
                                            <div className="error"
                                            
                                            >{errorMessage1}</div>
                                                    <FormErrorMessage
                                                        fieldName="empEmail"
                                                        formik={formik}
                                                    ></FormErrorMessage>

                                                <Form.Text className="text-muted">
                                                You can search Mentors, Courses, Resources and select your employees to assign or
                                                recommend.
                                                </Form.Text>
                                          <p>
                                          <Button
                                            variant="primary"
                                            onClick={() => {
                                              if (formik.values.empEmail !== '') {
                                                setErrorMessage1('');
                                                getSearchDetails(formik.values.empEmail)
                                                  .then((response) => {
                                                    setSearchresultsList(response);
                                                    //setCurrentStep(2); // Move to the next step after fetching the search results
                                                  })
                                                  .catch((error) => {
                                                    console.log(error);
                                                  });
                                              } else {
                                                setErrorMessage1('Please enter keyword.');
                                                // if (!formik.values.empEmail) {
                                                //   formik.setFieldError('empEmail', 'Email is required');
                                                // }

                                                // formik.validateForm().then((errors) => {
                                                 
                                                //   if (errors.empEmail) {
                                                    
                                                //     formik.setFieldError('empEmail', errors.empEmail);
                                                //   }
                                                // });
                                              }
                                            }}
                                          >
                                            Search
                                          </Button>
                                           

                                                </p>
                                                <p>Search results...</p>
                            
                            {searchresultsList.map((item) => (
                            <div className='row' key={item.contentguid}>
                                <div className='col-1'>
                                  <Form.Check
                                    type="checkbox"
                                    id={`checkbox-${item.contentGuid}`}
                                    checked={item.isChecked}
                                    onChange={() => handleCheckboxChangeStep1(item)}
                                  />
                                </div>
                                <div className='col-2' style={{marginBottom:'10px'}}>
                                    <span className='tag-box'>{item.contenttype} </span>
                                </div>
                                <div className='col-3'>
                                   <a className='aLink'>{item.title} </a> 
                                </div>
                                <div className='col'>
                                   | {item.description}
                                </div>
                            </div>
                              ))}
                                                {/* <div className='row header-row'>
                                                <div className='col-1'>
                                                  <strong>Select</strong>
                                                </div>
                                                <div className='col'>
                                                  <strong>Content Type</strong>
                                                </div>
                                                <div className='col'>
                                                  <strong>Title</strong>
                                                </div>
                                              </div> */}

                                              {/* {searchresultsList.length === 0 ? (
                                                <div className='row'>
                                                  <div className='col'>
                                                    No data
                                                  </div>
                                                </div>
                                              ) : (
                                                searchresultsList.map((search) => (
                                                  <div key={search.contentGuid} className='row'>
                                                    <div className='col-1'>
                                                      <Form.Check
                                                        type="checkbox"
                                                        id={`checkbox-${search.contentGuid}`}
                                                        checked={search.isChecked}
                                                        onChange={() => handleCheckboxChangeStep1(search)}
                                                      />
                                                    </div>
                                                    
                                                    <div className='col'>
                                                      {search.contenttype}
                                                    </div>
                                                    <div className='col'>
                                                      {search.title}
                                                    </div>
                                                  </div>
                                                ))
                                              )} */}

                                            </Form.Group>
                                            </div>
                                        </div>

                        
                    </>
                    )}

                    {currentStep === 2 && (
                    <>
                        <p>Select employees and assign</p>
                        
                        <EmployeesListPage onSelectEmployees={handleSelectedEmployeesStep2}
                        initialSelectedEmployees={selectedItemsStep2}
                        ></EmployeesListPage>
                    </>
                    )}

                    {currentStep === 3 && (
                      <>
                       
                        <p>Final review</p>
                        <div>
                            <p>Recommend Mentors, or Courses, or Resources</p>
                            {selectedItemsStep1.map((item) => (
                            <div className='row' key={item.contentguid}>                               
                                <div className='col-2' style={{marginBottom:'10px'}}>
                                    <span className='tag-box'>{item.contenttype} </span>
                                </div>
                                <div className='col-3'>
                                   <a className='aLink'>{item.title} </a> 
                                </div>
                                <div className='col'>
                                   | {item.description}
                                </div>
                            </div>
                              ))}
                            {/* <div className='row header-row'>
                           
                            <div className='col'>
                              <strong>Content Type</strong>
                            </div>
                            <div className='col'>
                              <strong>Title</strong>
                            </div>
                          </div> */}

                          {/* {selectedItemsStep1.length === 0 ? (
                            <div className='row'>
                              <div className='col'>
                                No data
                              </div>
                            </div>
                          ) : (
                            selectedItemsStep1.map((search) => (
                              <div key={search.contentGuid} className='row'>
                                <div className='col'>
                                  {search.contenttype}
                                </div>
                                <div className='col'>
                                  {search.title}
                                </div>
                              </div>
                            ))
                          )} */}
                          <hr></hr>
                            <p>Selected employees are</p>
                            {selectedItemsStep2.map((item) => (
                            <div className='row' key={item.receiverguid}>                               
                                
                                <div className='col-3'>
                                   <a className='aLink'>{item.firstname} - {item.lastname} </a> 
                                </div>
                                <div className='col'>
                                   | {item.emailaddress}
                                </div>
                            </div>
                              ))}
                            {/* <div className='row header-row'>
                           
                            <div className='col'>
                              <strong>Employee Name</strong>
                            </div>
                            <div className='col'>
                              <strong>Email Address</strong>
                            </div>
                          </div> */}
{/* 
                          {selectedItemsStep2.length === 0 ? (
                            <div className='row'>
                              <div className='col'>
                                No data
                              </div>
                            </div>
                          ) : (
                            selectedItemsStep2.map((search) => (
                              <div key={search.receiverguid} className='row'>
                                <div className='col'>
                                  {search.firstname} - {search.lastname}
                                </div>
                                <div className='col'>
                                  {search.emailaddress}  
                                </div>
                              </div>
                            ))
                          )} */}
                          <br></br>
                        </div>
                      </>
                    )}
                    {/* {currentStep === 4 && (
                      <>
                        <p>Completed</p>
                      </>
                    )} */}
                 <Button type="button" onClick={moveToPreviousStep} disabled={shouldDisableButton('previous')}>
  Previous
</Button>
<Button type="button" onClick={moveToNextStep} disabled={shouldDisableButton('next')}>
  {currentStep === totalSteps ? 'Submit' : 'Next'}
</Button>
                  </Form>
                </Card.Body>
              </Card>
  
              <SnackbarProvider 
                      show={showSnackbar}
                      message={snackbarMessage}
                      onClose={handleCloseSnackbar}
                      message1={clsName}
                      />

                    <Modal show={showConfirmation} onHide={handleCancelDelete}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to assign this item?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className='button' onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="secondary" className='button' onClick={handleConfirmDelete}>
                        Assign
                    </Button>
                    </Modal.Footer>
                    </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default AssignmentManagement;
