import React from "react";
import HeaderNew from '../../common/shared/header/HeaderNew';
//import SubHeader from '../../common/shared/header/SubHeader';
import Institutebar from '../../common/shared/leftbar/institutebar';

import Footer from '../../common/shared/footer/Footer';
import PropTypes from "prop-types";
// import  {Link}  from 'react-router-dom'
import {  useLocation } from "react-router-dom";
import './../../../css/style.min.css';

const InstituteLayout = ({ children}) => {
    const location = useLocation();
    let formHeaderName = "";
    if(location.pathname === '/dashboard-institute')
    {
        formHeaderName = 'Dashboard';
    }
    else if(location.pathname === '/profile-institute')
    {
        formHeaderName = 'My Profile';
    }
    else if(location.pathname === '/institute-student-setup')
    {
        formHeaderName = 'Student Setup';
    }
    else if(location.pathname === '/institute-student-list')
    {
        formHeaderName = 'Student Analytics';
    }
     
    else if(location.pathname === '/institute-notifications')
    {
        formHeaderName = 'My Alerts & Messages';
    }
    else if(location.pathname === '/config-divisions')
    {
        formHeaderName = 'Sub Admin Setup';
    }
    else if(location.pathname === '/config-roles')
    {
        formHeaderName = 'Sub Admin Setup';
    }
    else if(location.pathname === '/config-users')
    {
        formHeaderName = 'Sub Admin Setup';
    }
    else if(location.pathname === '/under-construction')
    {
        formHeaderName = 'Under Construction';
    }
    
    else if(location.pathname === '/assessments')
    {
        formHeaderName = 'My Assessments';
    }
    else if(location.pathname === '/assessment/create')
    {
        formHeaderName = 'My Assessments';
    }
    else if(location.pathname.split('/')[1] === 'assessment')
    {
        formHeaderName = 'My Assessments';
    }
    else if(location.pathname.split('/')[1] === 'employee-invites')
    {
        formHeaderName = 'My Assessments';
    }
    else if(location.pathname.split('/')[1] === 'employee-results')
    {
        formHeaderName = 'My Assessments';
    }
    
    else if(location.pathname === '/survey/create')
    {
        formHeaderName = 'My Survey';
    }
    else if(location.pathname.split('/')[1] === 'survey')
    {
        formHeaderName = 'My Survey';
    }
    else if(location.pathname === '/subscriptions')
    {
        formHeaderName = 'My Subscriptions';
    }
    else if(location.pathname === '/support')
    {
        formHeaderName = 'Supportings';
    }
    else if(location.pathname === '/institute-assign')
    {
        formHeaderName = 'Recommendations';
    }
    else if(location.pathname === '/students-library')
    {
        formHeaderName = 'L & D Library';
    }
    else if(location.pathname === '/workshops-request')
    {
        formHeaderName = 'Workshop Request';
    }
    return(
        <div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
        data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
              <HeaderNew /> 
                <Institutebar ></Institutebar>

                <div className="page-wrapper page-wrapper-height">
                <div className="page-breadcrumb bg-white">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                        <h4 className="page-title">{formHeaderName}</h4>
                    </div>
                    {/*<div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                        <div class="d-md-flex">
                            <ol class="breadcrumb ms-auto">
                                <li><a href="#" class="fw-normal">{formHeaderName}</a></li>
                            </ol>
                             <a href="" target="_blank"
                                class="btn btn-danger  d-none d-md-block pull-right ms-3 hidden-xs hidden-sm waves-effect waves-light text-white">Upgrade
                                to Pro</a> 
                        </div>
                    </div>*/}
                </div>
                
                </div>

               
                <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="white-box" style={{ minHeight: '45.4rem', height: 'auto' }}>
                      {children}
                        </div>
                    </div>
                </div>
              
                </div>
               <Footer></Footer>
               
                 </div>
        </div>
        
    );
}

InstituteLayout.propTypes ={
    children: PropTypes.object.isRequired,   
}; 

export default InstituteLayout;