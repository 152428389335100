import React, {useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
// import { Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
//import Select from 'react-select';
import { paymentsettlement } from "../../../api/adminApi";
//import moment from "moment/moment";
import * as Yup from "yup";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { PropTypes } from "prop-types";

function SettlementPage(props){
 // const restrictedWordsRegex = /\b(call|phone|whatsapp|number|mobile)\b/i;

  const { id } = props;
  
  //const { guid } = useParams(); 
  //const [conversationsList, setConversationsList] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
  const validationSchema = Yup.object({
    paymentrefno: Yup.string().required('Payment Ref No. is required.'),
    comments: Yup.string()
      .test('restricted-words', 'Restricted words found.', function (value) {
        const restrictedWordsRegex = /\b(call|phone|whatsapp|whats|number|num|landline|mobile|email|emailaddress|me|cell|cellphone)\b/i;
        return !restrictedWordsRegex.test(value);
      })
      .test('no-numbers', 'Numbers are not allowed.', function (value) {
        const numbersRegex = /\d/;
        return !numbersRegex.test(value);
      })
      .test('no-special-characters', 'Special characters are not allowed.', function (value) {
        const specialCharactersRegex = /[@!#$%^&*()_+[\]{}|\\:;"'<>,?/~`]/;
        return !specialCharactersRegex.test(value);
      }),
  });

const handleSubmit = async (values) => {  
  paymentsettlement(values).then((response)=>{
    
    if(!response.ok){
        // const err = new Error("Error in response");
        // throw err;
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        //return response.json();
    }
    }).then(response=>{
      console.log(response);
       
       // fetchConversationsData();
        setSnackbarMessage('Payment settled.');
        setShowSnackbar(true);
        formik.resetForm();
        props.onPaymentSettlementSubmit();
    });
}

const formik = useFormik({
  initialValues: {
      guid: id,
      paymentrefno: "",
      comments: "",
  },
  validationSchema ,
  onSubmit: handleSubmit,
}); 

   
  
  useEffect(()=>{
    //fetchConversationsData();

  },[id]);
  

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    }
   

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              Payment Settlement
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
            <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBasicCategory">
                                <Form.Label>Payment Ref No.</Form.Label>
                                <Form.Control type="text" placeholder="Enter payment ref no." 
                                name="paymentrefno"  
                                value={formik.values.paymentrefno}
                                onChange={formik.handleChange} 
                                //onChange={handleOnChange}
                                onBlur={formik.handleBlur} 
                                />
                                 <FormErrorMessage
                                      fieldName="paymentrefno"
                                      formik={formik}
                                  ></FormErrorMessage>
                              
                              </Form.Group>
                            </div>                           
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formShortDescription">
                                <Form.Label>Comments</Form.Label>
                                <Form.Control required as="textarea" rows={4} placeholder="Enter comments" 
                                name="comments"  
                                value={formik.values.comments}
                                onChange={formik.handleChange} 
                                //onChange={handleOnChange}
                                onBlur={formik.handleBlur} 
                                />
                                 <FormErrorMessage
                                      fieldName="comments"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>                            
                          </div>
                          <Button variant="primary" type="submit">
                          Submit
                          </Button>  
            </Form> 
           
         <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
          </Modal.Body>
        </Modal>

        
    );
}

SettlementPage.propTypes ={
  id: PropTypes.string.isRequired,  
  onPaymentSettlementSubmit: PropTypes.object.isRequired, 
};
export default SettlementPage;