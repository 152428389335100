import React, { useEffect, useState } from "react";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import Select from 'react-select';
// import { Card } from 'react-bootstrap';
import LibraryList from './LibraryList';
import { Trash  } from "react-bootstrap-icons";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
//addQuestion
import { getQuestions,getQuestionTags, deleteQuestion, updateQuestion } from "../../../api/corporateApi";
import { PropTypes } from "prop-types";
import Modal from 'react-bootstrap/Modal';

const CreateQuestionPage = ({  questionCount, onSave, assessmentguid }) => {
  const [clsName, setclsName] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [questionText, setQuestionText] = useState('');
const userRole = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).role : '';


   const [questionType, setQuestionType] = useState('single_choice');
//   const [questionMarks, setQuestionMarks] = useState('');
  const [options, setOptions] = useState([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);
//   const [questionTags, setQuestionTags] = useState([]);
//   const [newOption, setNewOption] = useState('');
  const [questionsList, setQuestionsList] = useState([]);
  const [isAddQuestion, setIsAddQuestion] = useState(false);
  const [isAddQuestionFromLibrary, setIsAddQuestionFromLibrary] = useState(false);
  const [editingQuestionIndex, setEditingQuestionIndex] = useState(-1);
  const [questionTagsList, setQuestionTagsList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
    const [rowDataToDelete, setRowDataToDelete] = useState(null);

  const initialValues = {
    guid:'',
    questionType: 'single_choice',
    questionText: '',
    questionMarks: '1',
    options: [{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }],
    questionTags: []
  };
  

  const validationSchema = Yup.object({
    //questionType: Yup.string().required("Question type is required."),
    questionText: Yup.string().required("Question text is required."),
    questionMarks: Yup.string().required("Marks is required."),
    // options: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       optionText: Yup.string().required("Option text is required."),
    //       correctAns: Yup.boolean().required("Correct answer is required.")
    //     })
    //   )
    //   .min(2, "At least two options are required."),
     
    //questionTags: Yup.array()
    //.min(1, "At least one tag is required.")
   // .required("Tags are required.")
    
  });
  

  const handleSubmit = (values) => {
  
    let guid = values.guid;
    let addeditStatus = 'EDIT';
    if(editingQuestionIndex === -1){
        guid = assessmentguid;
        addeditStatus = 'ADD';
    }
    
    const question = {
      guid,
      questionText: values.questionText,
      questionType: values.questionType,
      questionMarks: values.questionMarks,
      options: options.map((option) => ({
        optionText: option.optionText,
        correctAns: option.correctAns
      })),
      questionTags: values.questionTags,
      addeditStatus: addeditStatus,
      parentid:null,
    };
     
    onSave(question);
    formik.resetForm();
    setIsAddQuestion(false);
    fetchQuestionsData();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    fetchQuestionsData();
    fetchQuestionsTagsData();
  }, [onSave, assessmentguid, questionsList.length]);
  
  useEffect(() => {
    questionCount(questionsList.length);
  }, [questionsList]);
  
  const fetchQuestionsData =() => {
   
    if (assessmentguid !== null && assessmentguid !== undefined) {
        getQuestions(assessmentguid).then(response=>{                    
            setQuestionsList(response); 
            questionCount(questionsList.length);
 
           
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }
    
  }
  const fetchQuestionsTagsData =() => {
    getQuestionTags().then(response=>{   
      
      let updatedQuestionTagsList = [...response];

      // Check the user's role
      if (userRole === 'Corporate') {
        // Add a dummy item for Mentor
        const dummyTag = {
          value: 'NA',
          label: 'NA',
        };
        updatedQuestionTagsList = [dummyTag, ...updatedQuestionTagsList];
      }
      setQuestionTagsList(updatedQuestionTagsList); 
        //setQuestionTagsList(response); 
        //alert(updatedQuestionTagsList);
        //setIsEdit(true);
         
    })
    .catch(error => {
      // Handle error
      console.log(error);
    });
  }
  

  const categoryOptions = [
    // { value: "text", label: "Text" },
    { value: "single_choice", label: "Single-choice" },
    { value: "multiple_choice", label: "Multiple-choice" },
  ];

//   const handleQuestionTextChange = (e) => {
//     setQuestionText(e.target.value);
//   };

  const handleQuestionTypeChange = (selectedOption) => {
    console.log(selectedOption.value);
    setQuestionType(selectedOption.value);
    formik.setFieldValue('questionType',selectedOption.value);
    setOptions([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);
  };

  const handleOptionTextChange = (e, index) => {
    const updatedOptions = [...options];
    updatedOptions[index].optionText = e.target.value;
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, { optionText: '', correctAns: false }]);
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const handleCheckboxChange = (index) => {
    const updatedOptions = [...options];

    if (questionType === 'single_choice') {
      updatedOptions.forEach((option, idx) => {
        updatedOptions[idx].correctAns = idx === index;
      });
    } else if (questionType === 'multiple_choice') {
      updatedOptions[index].correctAns = !updatedOptions[index].correctAns;
    }

    setOptions(updatedOptions);
  };

  const handleAddQuestionLink = () => {
    setIsAddQuestion(true);
    setIsAddQuestionFromLibrary(false);
    formik.resetForm();
    setOptions([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);
    setEditingQuestionIndex(-1);
    setIsEdit(false);
    
    setQuestionType('single_choice');
    formik.setFieldValue('questionType','single_choice');
    setOptions([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);
  };

  const handleAddQuestionFromLibraryLink = () => {
    setIsAddQuestionFromLibrary(true);
    setIsAddQuestion(false);
    formik.setFieldValue('questionType','single_choice');
  };

  
  const handleEditQuestion = (index) => {
    const question = questionsList[index];    
    setEditingQuestionIndex(index);
    formik.setValues({
      guid:question.guid,
      questionType: question.type,
      questionText: question.questiontext,
      questionMarks: question.marks,
      options: question.options || [],  
      questionTags: question.tags
    });
    setQuestionType(question.type);
    setOptions(question.options || []);  
    setIsAddQuestion(true);
   
    if(userRole === 'Corporate' && question.isparent)
    {
      setIsEdit(true);
      //alert(isEdit);
    }
    else{
      setIsEdit(false);
    }
    
    //setIsAddQuestion(true);
    setIsAddQuestionFromLibrary(false);

  };

  const handleCancelQuestion = () => {
    setIsAddQuestion(false);
    setEditingQuestionIndex(-1);
    formik.resetForm();
    setOptions([{ optionText: '', correctAns: false }, { optionText: '', correctAns: false }]);
    setIsEdit(false);
  };
  

//   const handleUpdateQuestion = () => {
//     const updatedQuestion = {
//       ...questionsList[editingQuestionIndex],
//       questionType: formik.values.questionType,
//       questionText: formik.values.questionText,
//       questionMarks: formik.values.questionMarks,
//       options,
//       questionTags: formik.values.questionTags
//     };
//     onSave(updatedQuestion);
//     handleCancelQuestion();
//   };

const handleReloadUpdateData = (handleReloadUpdateData) => {  
   
  updateQuestion(handleReloadUpdateData).then((response)=>{
    if(!response.ok){
      setclsName('alert error');
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        return response.json();
    }
    }).then(response=>{
      console.log(response);      
      if(response.message === 'ALREADYEXISTS')
      { 
            
        setclsName('alert error');
        setSnackbarMessage('Question already exists.');
        setShowSnackbar(true);
      }else{
      
        setclsName('alert success');
        setSnackbarMessage('Successfully updated data.');
        setShowSnackbar(true);
        fetchQuestionsData();
      }
        
       
    });

  
  // setIsAddQuestionFromLibrary(false);
  // setIsAddQuestion(false);
};

const handleReloadData = () => {
    setIsAddQuestionFromLibrary(false);
    setIsAddQuestion(false);
};

 

const handleDeleteClick = (row) => {
  setRowDataToDelete(row);
  setShowConfirmation(true);
};

const handleConfirmDelete = () => {
  const updatedDataToDelete = {
      ...rowDataToDelete,
    };

    const deletedata = {
      aguid:assessmentguid,
      qguid: updatedDataToDelete.guid,
    };

    deleteQuestion(deletedata).then((response)=>{
      if(!response.ok){
        setclsName('alert error');
          setSnackbarMessage('Error in response');
          setShowSnackbar(true);
      } else{
          return response.json();
      }
      }).then(response=>{
        console.log(response);
        setclsName('alert success');
          setSnackbarMessage('Successfully deleted data.');
          setShowSnackbar(true);
          fetchQuestionsData();
          // alert(questionsList.length);
          // questionCount(questionsList.length);
          //handleReloadUpdateData();
          // props.onWorkshopSubmit();
      });


  setShowConfirmation(false);
};

const handleCancelDelete = () => {
  setShowConfirmation(false);
}; 
const handleCloseSnackbar = () => {
  setShowSnackbar(false);
}
  return (
    <div>
      <div className="row">
        <div className="col" style={{ borderRight: '1px solid black' }}>
          <label>Selected Questions - {questionsList.length} (min 5 questions are required to publish)</label><br />
          <span onClick={handleAddQuestionLink} className="mt-3 flex flex-row items-center gap-x-2 aLink" style={{ color: "#44D5E7" }}>
            + Add Questions
          </span>
          &nbsp; or 
          <span onClick={handleAddQuestionFromLibraryLink} className="mt-3 flex flex-row items-center gap-x-2 aLink" style={{ color: "#44D5E7" }}>
            + Choose from Library
          </span>

          
         
          <div className="scrollable-list-big">
            {questionsList.length > 0 ? (
              questionsList.map((item, index) => (
                <div key={item.guid}>
                  <article
                    className={`card mt-3 flex w-full cursor-pointer flex-col rounded-lg border border-slate-300 p-2 transition-all hover:animate-pulse ${
                      userRole === 'Corporate' && item.isparent ? 'bg-blue-200' : 'bg-yellow-200'
                    }`}
                  >
                    <p className="text-sm" style={{ margin: 0 }}>
                      {index + 1}. {item.questiontext}  
                    </p>
                    <p className="text-xs" style={{ margin: 0 }}></p>
                    <span
                      className="mt-3 flex flex-row items-center gap-x-2 aLink"
                      style={{ color: "#44D5E7" }}
                      onClick={() => handleEditQuestion(index)}
                    >
                      View Options
                    </span>
                    <Trash
                      className="aLink primary"
                      size={24}
                      color="#4774D1"
                      style={{ position: "absolute", top: "5px", right: "5px" }}
                      onClick={() => handleDeleteClick(item)}
                    />
                  </article>
                </div>
              ))
            ) : (
              <p>No Questions</p>
            )}
          </div>

        </div>
        <div className="col">
          <Form onSubmit={formik.handleSubmit}>
            {isAddQuestion && (
              <>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3" controlId="formBasicCategory">
                      <Form.Label>Type of questions</Form.Label>
                      <Select
                        required
                        styles={{ width: "150px" }}
                        options={categoryOptions}
                        value={categoryOptions.find((option) => option.value === formik.values.questionType)}
                        onChange={handleQuestionTypeChange}
                        defaultValue={categoryOptions[0]}  
                        />
                      <FormErrorMessage fieldName="questionType" formik={formik} />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3" controlId="formTitle">
                      <Form.Label>Question Text</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Question Text"
                        name="questionText"
                        value={formik.values.questionText}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur} 
                />
                 <FormErrorMessage
                    fieldName="questionText"
                    formik={formik}
                ></FormErrorMessage>
              </Form.Group>
            </div>
                </div>
                {questionType !== 'text' && (
                  <div className="row">
                    <div className="col">
                      Enter the choice and mark the checkbox corresponding to the correct choice.
                      {options.map((option, index) => (
                        <div className="row" key={index}>
                          <div className="col" style={{ marginBottom: '5px',marginTop: '5px' }}>
                            <Form.Check
                              type={questionType === 'multiple_choice' ? 'checkbox' : 'radio'}
                              label=""
                              checked={option.correctAns}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          </div>
                          <div className="col"  style={{ marginBottom: '5px', marginTop: '5px', marginLeft: '-200px' }}>
                            <Form.Control
                              required
                              type="text"
                              placeholder={`Option ${index + 1}`}
                              value={option.optionText}
                              onChange={(e) => handleOptionTextChange(e, index)}
                            />
                          </div>
                          <div className="col">
                          {index !== 0 && (   
                            <Button variant="primary" onClick={() => handleRemoveOption(index)}>Delete</Button>
                          )}
                            </div>
                        </div>
                      ))}
                      <div className="row">
                        <div className="col">
                          <Button variant="primary" className="button" type="submit" onClick={handleAddOption}>
                            Add Option
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col">
                    <Form.Group className="mb-3" controlId="formTitle">
                      <Form.Label>Marks</Form.Label>
                      <Form.Control
                        required
                        disabled
                        type="text"
                        placeholder="Marks"
                        name="questionMarks"
                        value={formik.values.questionMarks}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur} 
                      />
                      <FormErrorMessage
                          fieldName="questionMarks"
                          formik={formik}
                      ></FormErrorMessage>
                    </Form.Group>
                  </div>
                </div>
                <div className="row" style={{ visibility: userRole === 'Corporate' ? 'hidden' : 'visible' }}>
                  <div className="col">
                    <Form.Group className="mb-3" controlId="formTitle">
                      <Form.Label>Tags</Form.Label>
                      <Select 
                      required={userRole === 'Mentor'}                      
                      isMulti 
                      options={questionTagsList} 
                      //value={formik.values.questionTags}
                      value={userRole === 'Corporate' ? [{ value: 'NA', label: 'NA' }] : formik.values.questionTags}
                      onChange={(selectedOptions) =>
                          formik.setFieldValue('questionTags',selectedOptions)
                      }
                      isDisabled={userRole === 'Corporate'}
                      //defaultValue={userRole === 'Corporate' ? { value: 'NA', label: 'NA' } : null}
                      />
                          <FormErrorMessage
                              fieldName="questionTags"
                              formik={formik}
                          ></FormErrorMessage>
                                              
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Button variant="primary" type="submit" className="button"
                    disabled={isEdit}
                    >  
                      {editingQuestionIndex !== -1 ? "Update Question" : "Add Question"}
                    </Button>
                    <Button variant="primary" className="button" type="submit" onClick={handleCancelQuestion}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </>
          )}      
          {isAddQuestionFromLibrary && (
              <>
              <LibraryList onMasterSubmit={handleReloadUpdateData} onReloadData={handleReloadData} assessmentguid={assessmentguid}></LibraryList>
              
              </>
          )}    
         </Form>
         <SnackbarProvider 
                      show={showSnackbar}
                      message={snackbarMessage}
                      onClose={handleCloseSnackbar}
                      message1={clsName}
                      />
         <Modal show={showConfirmation} onHide={handleCancelDelete}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="secondary" className="button" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                    </Modal.Footer>
                    </Modal>
        </div>
      </div>
    </div>
  );
};

CreateQuestionPage.propTypes ={
    onSave: PropTypes.object.isRequired, 
    questionCount: PropTypes.number.isRequired, 
    assessmentguid: PropTypes.string.isRequired, 
    
  };
export default CreateQuestionPage;
