import React, { useState, useEffect } from 'react';
// import './../../../../Stylesheet/leftbar.css'
// import '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min'
// import '../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css'
import  {Link}  from 'react-router-dom'
//import ProfilePic from '../../../../Images/avatar.png';
import ProfileCard from '../profileCards/Profilecard';
//import ProfileCard from '../../shared/profileCards/ProfileCard';
import { getPermissions } from "../../../../api/corporateApi";
//import { useLocation } from "react-router-dom";
import './../../../../css/style.min.css';

const CorporatebarNew = ()=>{
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isSubAdminmenuOpen, setIsSubAdminmenuOpen] = useState(false);
  const [isSubWorkshopmenuOpen, setIsSubWorkshopmenuOpen] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);    
  
  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  }
  const toggleSubAdminmenu = () => {
    setIsSubAdminmenuOpen(!isSubAdminmenuOpen);
  }
  const toggleSubWorkshopmenu = () => {
    setIsSubWorkshopmenuOpen(!isSubWorkshopmenuOpen);
  }
  useEffect(() => {   
    fetchPermissionData(); 
  }, []);

  const fetchPermissionData =() => {
    getPermissions().then(response => {
      setUserPermissions(response);      
      });
  } 
  const handleLogout =()=>{    
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }
    return(
      
        <aside className="left-sidebar" data-sidebarbg="skin6">
           
        <div className="scroll-sidebar">
        <ProfileCard></ProfileCard>
            <nav className="sidebar-nav">
                <ul id="sidebarnav">
                   
                    <li className="sidebar-item pt-2">
                        <Link to={"/dashboard-corporate"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/dashboard-corporate' ? 'active' : ''}`}>
                            <i className="far fa-clock"></i> <span className="hide-menu left-menu-text">Dashboard</span>
                        </Link>
                        
                    </li>
                    <li className="sidebar-item">                             
                        <Link to={"/profile-corporate"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/profile-corporate' ? 'active' : ''}`}>
                            <i className="fa fa-user"></i> <span className="hide-menu left-menu-text">My Profile</span>
                        </Link>
                    </li>
                    
                    <li className='sidebar-item '>
                        <a className='sidebar-item sidebar-link waves-effect waves-dark' style={{cursor:'pointer'}} onClick={toggleSubmenu}>
                        <i className="bi bi-person-plus"></i> <span className="hide-menu left-menu-text"
                        >Employee Management</span>
                        </a>
                            {isSubmenuOpen && (
                                <ul className='submenu' style={{ marginLeft: '45px' }}>
                                      <li className='nav-link'>
                                        <Link to={"/corporate-emp-setup"} className="aLink">
                                        <i className="bi bi-caret-right me-1" ></i> Add Employees
                                        </Link>
                                    </li>
                                    <li className='nav-link'> 
                                    <Link to={"/corporate-emp-list"} className="aLink">
                                        <i className="bi bi-caret-right me-1" ></i> Employee Analytics
                                        </Link>                                            
                                    </li>
                                </ul>
                            )}
                               
                                
                            </li>

                    <li className="sidebar-item">                             
                        <Link to={"/corporate-library"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/corporate-library' ? 'active' : ''}`}>
                            <i className="fa fa-book"></i> <span className="hide-menu left-menu-text">Learning Library</span>
                        </Link>
                    </li>  
                    <li className='sidebar-item '>
                        <a className='sidebar-item sidebar-link waves-effect waves-dark' style={{cursor:'pointer'}} onClick={toggleSubWorkshopmenu}>
                        <i className="bi bi-person-plus"></i> <span className="hide-menu left-menu-text"
                        >Employee Engagement</span>
                        </a>
                            {isSubWorkshopmenuOpen && (
                                <ul className='submenu' style={{ marginLeft: '45px' }}>
                                     <li className='nav-link'> 
                                        <Link to={"/workshops-request"} className="aLink">
                                            <i className="bi bi-caret-right me-1"></i> Submit Inquiries
                                        </Link>  
                                    </li>   
                                    <li className='nav-link'> 
                                    <Link to={"/workshops-request-view"} className="aLink">
                                        <i className="bi bi-caret-right me-1" ></i> View Inquiries
                                        </Link>                                            
                                    </li>
                                </ul>
                            )}
                               
                                
                            </li>

                    
                     
                    
                    <li className="sidebar-item">
                        <Link to={"/assessments"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/assessments' ? 'active' : ''}`}>
                            <i className="fa fa-database"></i> <span className="hide-menu left-menu-text">Assessments</span>
                            </Link>
                    </li>                    
                    <li className="sidebar-item">                            
                        <Link to={"/corporate-notifications"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/corporate-notifications' ? 'active' : ''}`}>
                            <i className="fa fa-bell"></i> <span className="hide-menu left-menu-text">Alerts & Messages</span>
                        </Link>
                    </li>
                    {userPermissions.includes('Account Setup') && (
                      <li className='sidebar-item '>
                      <a className='sidebar-item sidebar-link waves-effect waves-dark' style={{cursor:'pointer'}} onClick={toggleSubAdminmenu}>
                      <i className="fs-4 bi-building"></i> <span className="hide-menu left-menu-text"
                      >Sub Admin Setup</span>
                      </a>
                          {isSubAdminmenuOpen && (
                              <ul className="submenu" style={{ marginLeft: '45px' }}>
                                   <li className="nav-link">
                                    <Link className="aLink" to={'/config-divisions'}>
                                       <i className="bi bi-caret-right me-1"></i> Add Division
                                     </Link>
                                  </li>
                                   <li className="nav-link">
                                     <Link className="aLink" to={'/config-roles'}>
                                       <i className="bi bi-caret-right me-1"></i> Add Role
                                     </Link>
                                   </li>
                                   <li className="nav-link">
                                     <Link className="aLink" to={'/config-users'}>
                                       <i className="bi bi-caret-right me-1"></i> Add Sub Admin
                                     </Link>
                                   </li>
                                 </ul>
                          )}
                             
                              
                          </li>

                    //   <li className="sidebar-item">
                    //   <a className="sidebar-link waves-effect waves-dark sidebar-link" style={{ cursor: 'pointer' }}>
                    //     <i className="fs-4 bi-building"></i> <span className="hide-menu left-menu-text">Sub Admin Setup</span>
                    //   </a>
                    //   <ul className="submenu" style={{ marginLeft: '45px' }}>
                    //     <li className="sidebar-item">
                    //       <Link className="aLink" to={'/config-divisions'}>
                    //         <i className="bi bi-caret-right me-1"></i> Add Division
                    //       </Link>
                    //     </li>
                    //     <li className="sidebar-item">
                    //       <Link className="aLink" to={'/config-roles'}>
                    //         <i className="bi bi-caret-right me-1"></i> Add Role
                    //       </Link>
                    //     </li>
                    //     <li className="sidebar-item">
                    //       <Link className="aLink" to={'/config-users'}>
                    //         <i className="bi bi-caret-right me-1"></i> Add Sub Admin
                    //       </Link>
                    //     </li>
                    //   </ul>
                    // </li>                            
                    )}
                    {userPermissions.includes('Subscriptions') && (
                    <li className="sidebar-item"> 
                            <Link to={"/subscriptions"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/subscriptions' ? 'active' : ''}`}>
                                <i className="bi bi-credit-card"></i> <span className="hide-menu left-menu-text">Subscriptions</span>
                            </Link>  
                    </li>
                    )}
                      <li className="sidebar-item"> 
                            <Link to={"/survey"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/survey' ? 'active' : ''}`}>
                                <i className="fs-4 bi-clipboard-data"></i> <span className="hide-menu left-menu-text">Survey</span>
                            </Link>  
                        </li>
                       
                        <li className="sidebar-item"> 
                            <Link to={"/support"} className={`sidebar-link waves-effect waves-dark sidebar-link ${location.pathname === '/support' ? 'active' : ''}`}>
                                <i className="bi bi-question-circle"></i> <span className="hide-menu left-menu-text">Support</span>
                            </Link>  
                        </li>
                    <li className="text-center p-20 upgrade-btn">
                            <a href="/login" onClick={handleLogout}
                                className="btn d-grid btn-menu text-white">
                                SignOut</a>

                                
                        </li>
                </ul>

            </nav>
           
        </div>
       
    </aside>
    );
   
}
export default CorporatebarNew;