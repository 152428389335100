import React, {useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
import { getSurvey } from "../../../api/corporateApi";
import { Link, useNavigate } from 'react-router-dom';
import Button  from "react-bootstrap/Button";
//import Select from 'react-select';
// import { useHistory } from 'react-router-dom';

import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function ViewSurveyPage(){
    const navigate = useNavigate();
    const [surveyList, setSurveyList] = useState([]);

    const classes = useStyles();

    const columnsNew = [
      { field: 'title', headerName: 'TEST NAME', width: 200, },
      { field: 'questioncount', headerName: 'QUESTIONS' },
      { field: 'duration', headerName: 'DURATION(MINUTES)', width: 150, },
      {
       
        headerName: 'INVITES',
        width: 200,  
        renderCell: (params) => (
          <>
            <Link className="aLink" style={{ paddingRight: '10px' }}>
              <i /> {params.row.invitescount} Sent
            </Link>
            <br />
            <Link className="aLink" style={{ paddingRight: '10px' }}>
              <i /> {params.row.responsecount} Completed
            </Link>
          </>
        ),
      },
      {
        field: 'ispublished',
        headerName: 'STATUS',
        width: 150, // Set the width as needed
        renderCell: (params) => (
          <>
            {params.row.ispublished ? 'Published' : 'Draft'}
          </>
        ),
      },
      {
        field: 'action',
        headerName: 'ACTION',
        width: 75,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <>
            {params.row.iseditable && (
              <>
               <i
                className="bi bi-pencil-fill aLink"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/survey/${params.row.guid}/edit`);
                }}
                style={{ paddingRight: "10px" }}
              />
              
              </>
            )}
            {params.row.ispublished && (
              <>
               <i
                className="bi bi-eye-fill aLink"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/employee-survey-results/${params.row.guid}`);
                }}
                style={{ paddingRight: "10px" }}
              />
              <i
                className="bi bi-person-plus-fill aLink"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/employee-survey-invites/${params.row.guid}`);
                }}
                style={{ paddingRight: "10px" }}
              />
                 
              
              </>
            )}
          </>
        ),
      },
    ];

    // const columns =[
    //     // {dataField: 'guid', text: '#'},
    //     {dataField: 'title', text: 'TEST NAME'},
    //     {dataField: 'questioncount', text: 'QUESTIONS'},
    //     {dataField: 'duration', text: 'DURATION(MINUTES)'},
    //     {          
    //          dataField: 'action',
    //          text: 'INVITES',
    //          formatter: () => ( //cell, row
    //          <>
    //          <Link><i style={{paddingRight:"10px"}}/>0 Sent</Link><br/>
    //          <Link><i style={{paddingRight:"10px"}}/>0 Completed</Link>
    //          </>
    //          ),
    //      },
    //      {          
    //         dataField: 'ispublished',
    //         text: '',
    //         formatter: (cell, row) => (
    //             <>
    //               {row.ispublished ? 'Published' : 'Draft'}
    //             </>
    //           ),
    //     },
        
    //     {
    //         dataField: 'ispublished',
    //         text: 'ACTION',
    //         formatter: (cell, row) => {
    //           const options = [
    //             { value: 'edit', label: 'Edit' },
    //             // { value: 'preview', label: 'Preview Test' },
    //           ];
        
    //           if (row.ispublished) {
    //             options.push({ value: 'view', label: 'View Results' });
    //             options.push({ value: 'invites', label: 'Send Invites' });
    //           }
        
    //           return (
    //             <Select
    //               options={options}
    //               placeholder="Select an action"
    //               onChange={(selectedOption) => {
    //                 if (selectedOption.value === 'edit') {                      
    //                   //navigate(`/assessment/${row.guid}/edit`);
    //                   navigate(`/survey/${row.guid}/edit`);
    //                 } else if (selectedOption.value === 'preview') {
    //                   // Handle Preview Test action
    //                 } 
    //                 else if (selectedOption.value === 'view') {
    //                   // Handle View Results action
    //                   navigate(`/employee-survey-results/${row.guid}`);
    //                 }
    //                 else if (selectedOption.value === 'invites') {
    //                     // Handle View Results action
    //                     navigate(`/employee-survey-invites/${row.guid}`);
    //                   }
    //               }}
                  
    //             />
    //           );
    //         },
    //       },
        
    // ];

    
    useEffect(()=>{
        fetchSurveyData();
          },[]);
      
      const fetchSurveyData =() => {
        getSurvey().then(response=>{                    
            setSurveyList(response); 
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }

      

    return(
      <div className="featured-section-wrap featured-section-wrap-row">
      <div className='mainPageContainer'>        
          <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
              {/* style={{ height: '39rem' }} width: '62rem', */}
              <Card>
                    <Card.Body>
                    <CustomTooltip text="With this feature, you can set up and schedule surveys to gather feedback and insights from participants or users." />
                    <div className="row">
              <div className="col" style={{float:"right"}}>
              <Link to="/survey/create">
              <Button variant="primary" className="button"  type="submit" style={{float:"right"}} >
              Create Survey
              </Button>
              </Link>

              </div>
              </div>
              {/* <div className="row">
              <div className="col">
              <BootstrapTable 
              keyField="guid"
              data={surveyList}
              columns={columns}
              // cellEdit={cellEditFactory(cellEditOptions)}
              pagination={paginationFactory()}
              bootstrap4
              striped
              hover
              condensed
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-bordered table-hover"
              />

              </div>
              </div> */}

                  <div className="row">
                    <div className="col">
                    
                    <div className={`${classes.dataGridContainer}`}>
                      <DataGrid
                      rows={surveyList}
                      columns={columnsNew}
                      getRowId={(row) => row.guid}
                      initialState={{
                      pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                      },
                      }}
                      pageSizeOptions={[5, 10]}
                      disableSelectionOnClick
                      //hideFooterPagination
                      autoHeight
                      checkboxSelection={false}
                      classes={{
                      header: classes.header,
                      toolbarContainer: classes.toolbarContainer,
                      columnsContainer: classes.columnsContainer,
                      columnHeaderTitle: classes.columnHeaderTitle,
                      cell: classes.cell,
                      paginationItem: classes.paginationItem,
                      paginationItemSelected: classes.paginationItemSelected,
                      paginationItemHover: classes.paginationItemHover,
                      }}
                      />

                    </div>

                    </div>
                    </div>  
                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default ViewSurveyPage

 