import React, {useEffect, useState} from "react";
//import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import  Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { updatePassword } from "../../../api/corporateApi";
import { useFormik} from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";


function ResetPasswordPage(){
  const [clsName, setclsName] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
     

    const initialValues = { 
        password:'',
      }
    
      const  validationSchema = Yup.object({  
        password: Yup.string()
        .required("Password is required."),
      
      }); 
      const handleSubmit = (values) => { 
        updatePassword(values).then((response)=>{    
          if(!response.ok){
            setclsName('alert error');
              setSnackbarMessage('Error in response');
              setShowSnackbar(true);
          } else{
              //return response.json();
          }
          }).then(response=>{
            console.log(response);
             
              //return response;
              setclsName('alert success');
              setSnackbarMessage('Successfully updated user password.');
              setShowSnackbar(true);
              formik.resetForm();
          });
      }
      const handleCloseSnackbar = () => {
        setShowSnackbar(false);
      }
      const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
      });
    
      useEffect(()=>{
        
      },[]);

       

    return (
      <div className="featured-section-wrap featured-section-wrap-row">
      <div className='mainPageContainer'>        
          <div className='dashboardcontainerflex'>
            <div className='dashboardcard'>
                <Card>
                <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
               
                    
                    <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formLabel">
                        <Form.Label>Password</Form.Label>
                        <Form.Control required type="password" placeholder="Enter password" 
                        name="password"  
                        value={formik.values.password}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}                                
                        />
                            <FormErrorMessage
                                fieldName="password"
                                formik={formik}
                            ></FormErrorMessage>
                        </Form.Group>
                        
                        </div>
                    </div>
                    <Button variant="primary" className="button" type="submit"> Submit </Button>
            </Form>
            <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
                  </Card.Body>
                  </Card>
            </div>
          </div>
      </div>
      </div>

       
    );
}

 
export default ResetPasswordPage;