import React, { useEffect, useState } from 'react';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import { getStudents } from '../../../api/instituteApi';
import SearchInputs from '../../common/shared/search/SearchInputs';
import { PropTypes } from "prop-types";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function EmployeesListPage({ onSelectEmployees, initialSelectedEmployees  }) {
  const classes = useStyles();
  const [employeeList, setEmployeeList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [emailSearch, setEmailSearch] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState(initialSelectedEmployees);

  const columns = [
    {
      field: 'emailaddress',
      headerName: 'Select',
      minWidth: 200,
      renderCell: (params) => (
        <>
         <input
          type="checkbox"
          value={params.row.emailaddress}
         // checked={selectedEmployees.some((e) => e.emailaddress === row.emailaddress)} // Check if the row's id is in selectedEmployees
          onChange={(event) => handleCheckboxChange(event, params.row)}
        />
        </>
      ),
    },
    {field: 'firstname', headerName: 'First Name', minWidth: 150},
    {field: 'emailaddress', headerName: 'Email Address', minWidth: 150},

    // {
    //   dataField: 'emailaddress',
    //   text: 'Select',
    //   formatter: (cell, row) => (
    //     <input
    //       type="checkbox"
    //       value={row.emailaddress}
    //      // checked={selectedEmployees.some((e) => e.emailaddress === row.emailaddress)} // Check if the row's id is in selectedEmployees
    //       onChange={(event) => handleCheckboxChange(event, row)}
    //     />
    //   ),
    // },
    // {dataField: 'firstname', text: 'First Name'},
    // // {dataField: 'lastname', text: 'Last Name'},
    // {dataField: 'emailaddress', text: 'Email Address'},
    // // Other columns...
  ];

  useEffect(() => {
    fetchEmployeesData();
  }, []);

  useEffect(() => {
    const filteredResults = employeeList.filter((item) => {
      const emailMatch = item.emailaddress.toLowerCase().includes(emailSearch.toLowerCase());
      return emailMatch;
    });
    setFilteredData(filteredResults);
  }, [employeeList, emailSearch]);

  

  const fetchEmployeesData = () => {
    getStudents()
      .then((response) => {
        setEmployeeList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCheckboxChange = (event, employee) => {
    const isChecked = event.target.checked;
  
    console.log('Checkbox Change Event:', isChecked, employee);
  
    if (isChecked) {
      setSelectedEmployees((prevSelectedEmployees) => [...prevSelectedEmployees, employee]);
    } else {
      setSelectedEmployees((prevSelectedEmployees) =>
        prevSelectedEmployees.filter(
          (selectedEmployee) => selectedEmployee.emailaddress !== employee.emailaddress
        )
      );
    }
  };
  // const handleCheckboxChange = (event, employee) => {
  //   event.persist(); // Persist the event if needed
  
  //   setSelectedEmployees((prevSelectedEmployees) => {
  //     const employeeIndex = prevSelectedEmployees.findIndex(
  //       (selectedEmployee) => selectedEmployee.emailaddress === employee.emailaddress
  //     );
  
  //     if (event.target.checked && employeeIndex === -1) {
  //       return [...prevSelectedEmployees, employee];
  //     } else if (!event.target.checked && employeeIndex !== -1) {
  //       return [
  //         ...prevSelectedEmployees.slice(0, employeeIndex),
  //         ...prevSelectedEmployees.slice(employeeIndex + 1),
  //       ];
  //     }
  
  //     return prevSelectedEmployees;
  //   });
  // };
  
  useEffect(() => {
    console.log('Selected Employees:', selectedEmployees);
  }, [selectedEmployees]);
  
  useEffect(() => {
    console.log('Initial Selected Employees:', initialSelectedEmployees);
    setSelectedEmployees(initialSelectedEmployees);
  }, [initialSelectedEmployees]);

  useEffect(() => {
    onSelectEmployees(selectedEmployees);
   // alert(JSON.stringify(selectedEmployees));
  }, [selectedEmployees, onSelectEmployees]);

  const handleEmailChange = (e) => {
    setEmailSearch(e.target.value);
  };

  return (
    <div>
      <SearchInputs showEmailSearch={true} emailSearch={emailSearch} onEmailChange={handleEmailChange} />
      {/* <BootstrapTable
        keyField="guid"
        data={filteredData}
        columns={columns}
        pagination={paginationFactory()}
        bootstrap4
        striped
        hover
        condensed
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-bordered table-hover"
      /> */}
       <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
    </div>
  );
}

EmployeesListPage.propTypes ={
  onSelectEmployees: PropTypes.object.isRequired,  
  initialSelectedEmployees: PropTypes.object.isRequired,  
};
export default EmployeesListPage;
