import React,{useEffect,useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { addWorkShop } from "../../../api/mentorApi";
import { useFormik} from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
// import { Link } from "react-router-dom";

import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import "react-datepicker/dist/react-datepicker.css";
import { PropTypes } from "prop-types";
// import { parse } from "date-fns";

// import MentorCoursesViewPage from "./MentorCoursesViewPage";

function WorkshopCreatePage({onWorkshopSubmit}){
  const bannerfileInputRef = useRef(null);
  const [clsName, setclsName] = useState('');
  let endDate= new Date();
  endDate.setMinutes(endDate.getMinutes()+30);
  const [date,setDate] = useState(new Date());
  // const [startTime, onStartTimeChange] =useState( (new Date().getHours().toString().length == 1 ? ('0' + new Date().getHours().toString()) : new Date().getHours().toString()) + ':'+ (new Date().getMinutes().toString().length == 1 ? (new Date().getMinutes().toString() + '0') : new Date().getMinutes().toString()) );
  // const [endTime,onEndTimeChange] = useState(endDate.getHours().toString()+':'+endDate.getMinutes().toString());

  
 
 
  // const [fileSelected,setFileSelected] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showViewPageModal, setShowViewPageModal] = useState(false);

  // const [isStreamMode, setIsStreamMode] = useState('LIVE');

  // const getCurrentTime = () => {
  //   const currentDate = new Date();
  //   const minutes = currentDate.getMinutes();
  //   const roundedMinutes = Math.ceil(minutes / 30) * 30;
  //   currentDate.setMinutes(roundedMinutes);
    
  //   return currentDate.toTimeString().slice(0,5);
  // }
  const getCurrentTime = () => {
    const currentDate = new Date();
    const minutes = currentDate.getMinutes();
    // Always round up to the next hour
    const roundedMinutes = minutes >= 30 ? 0 : 30;

    currentDate.setMinutes(roundedMinutes);
    currentDate.setMinutes(roundedMinutes === 0 ? 0 : 30);
    if (minutes > 0) {
      currentDate.setHours(currentDate.getHours() + 1);
    }
    return currentDate.toTimeString().slice(0, 5);

    // const currentDate = new Date();
    // const roundedHour = currentDate.getHours() + 1;
    // currentDate.setHours(roundedHour, 0, 0, 0);
    // return currentDate.toTimeString().slice(0, 5);

  };
  

  // const getEndTime = () => {
  //   const selctedTime = getCurrentTime(); //formik.values.startTime;
  //   const [hours, minutes1] = selctedTime.split(':');
  //   const endTime = new Date();
  //   endTime.setHours(parseInt(hours) + 1);
  //   endTime.setMinutes(parseInt(minutes1));   
  //   return endTime.toTimeString().slice(0,5);
  // }
  const getEndTime = () => {
    const selectedTime = getCurrentTime(); //formik.values.startTime;
    const [hours, minutes1] = selectedTime.split(':');
    const endTime = new Date();
    endTime.setHours(parseInt(hours) + 1);
    endTime.setMinutes(parseInt(minutes1));
    return endTime.toTimeString().slice(0, 5);
  };
  
  // const handleStartTimeChange = (time) => {
  //   // Parse the selected time
  //   const [hours, minutes] = time.split(':');
    
  //   // Check if the selected time has valid minutes (0 or 30)
  //   if (minutes === '00' || minutes === '30') {
  //     // Set the start time
  //     formik.setFieldValue('start', time);
  //   }
  // };
  
  // const handleEndTimeChange = (time) => {
  //   // Parse the selected time
  //   const [hours, minutes] = time.split(':');
    
  //   // Check if the selected time has valid minutes (0 or 30)
  //   if (minutes === '00' || minutes === '30') {
  //     // Set the end time
  //     formik.setFieldValue('end', time);
  //   }
  // };

   
  
  const handleEndTimeChange = (time) => {
    const [, minutes] = time.split(':');
  
    // Check if the selected time has valid minutes (00 or 30)
    if (minutes === '00' || minutes === '30' ) { //|| minutes === '30'
      formik.setFieldValue('end', time);
  
      // // Ensure that the end time is at least an hour ahead of the start time
      // const startHours = formik.values.start.split(':')[0];
      // const startMinutes = formik.values.start.split(':')[1];
  
      // const startTimestamp = parseInt(startHours) * 60 + parseInt(startMinutes);
      // const endTimestamp = parseInt(hours) * 60 + parseInt(minutes);
  
      // if (endTimestamp - startTimestamp >= 60) {
      //   formik.setFieldValue('end', time);
      // } else {
      //   // Handle invalid end time, for example, by showing an error message
      //   console.log('Invalid end time');
      //   //formik.setFieldValue('end', '00:00');
      // }
    } else {
      // Handle invalid end time (minutes other than 00 or 30), for example, by showing an error message
      console.log('Invalid end time');
      //formik.setFieldValue('end', '00:00');
    }
  };
  
  const handleStartTimeChange = (time) => {
    const [, minutes] = time.split(':');
  
    // Check if the selected time has valid minutes (00 or 30)
    if (minutes === '00' || minutes === '30') { //|| minutes === '30'
      formik.setFieldValue('start', time);
  
      // // Ensure that the end time is at least an hour ahead of the start time
      // const endHours = parseInt(hours) + 1;
      // const endTime = endHours.toString().padStart(2, '0') + ':' + minutes;
      
      // // If the calculated end time is valid, set it
      // if (isEndTimeValid(time, endTime)) {
      //   formik.setFieldValue('start', endTime);
      // } else {
      //   // Handle invalid end time, for example, by showing an error message
      //   console.log('Invalid end time');
      //   formik.setFieldValue('start', '00:00');
      // }
    } else {
      // Handle invalid start time (minutes other than 00 or 30), for example, by showing an error message
      console.log('Invalid start time');
      //formik.setFieldValue('start', '00:00');
    }
  };
  
  // const isEndTimeValid = (startTime, endTime) => {
  //   const [startHours, startMinutes] = startTime.split(':');
  //   const [endHours, endMinutes] = endTime.split(':');
  
  //   const startTimestamp = parseInt(startHours) * 60 + parseInt(startMinutes);
  //   const endTimestamp = parseInt(endHours) * 60 + parseInt(endMinutes);
  
  //   return endTimestamp - startTimestamp >= 60;
  // };
  



  const initialValues = {   
    title: '',
    description: '',
    date:date,
    start: getCurrentTime(),//'12:00',
    end: getEndTime(),   
    stream_mode: 'LIVE',
    stream_details: '',
    address_details: '',
    google_map_url: '',
    file_upload: null,
    price_mode:'FREE', 
    price: '0',
    bannerImage: null,
  }

  const  validationSchema = Yup.object({   
    title: Yup.string()
    .required("Title is required."),
    // description: Yup.string()
    // .required("Description is required."), 
    
    description: Yup.string()
    .required('Description is required.')
    .matches(/^[^0-9]*([0-9][^0-9]*){0,6}$/, 'Numbers are not allowed(Max 6).')    
    .test('no-restricted-words', 'Restricted words found.', (value) => {
      const restrictedWords = ['call', 'phone', 'whatsapp', 'whats', 'number', 'num', 'landline', 'mobile', 'email', 'emailaddress', 'cellphone'];
      for (const word of restrictedWords) {
        if (value.toLowerCase().includes(word)) {
          return false;  
        }
      }
      return true; 
    })
    .test('no-special-characters', 'Special characters are not allowed.', (value) => {
      const specialCharactersRegex = /[@!#$%^&*()_[\]{}|\\:;"'<>?/~`]/;
      return !specialCharactersRegex.test(value);
    })
    .test('no-number-wording', 'Number wordings are not allowed.', (value) => {
      // Use a regular expression to check for number wordings
      const numberWordingsPattern = /\bone\b|\btwo\b|\bthree\b|\bfour\b|\bfive\b|\bsix\b|\bseven\b|\beight\b|\bnine\b|\bten\b|\beleven\b|\btwelve\b|\bthirteen\b|\bfourteen\b|\bfifteen\b|\bsixteen\b|\bseventeen\b|\beighteen\b|\bnineteen\b|\btwenty\b|\bthirty\b|\bforty\b|\bfifty\b|\bsixty\b|\bseventy\b|\beighty\b|\bninety\b|\bhundred\b|\bthousand\b|\bmillion\b|\bbillion\b|\btrillion\b/;
      return !numberWordingsPattern.test(value.toLowerCase());
    }),

    start: Yup.string()
    .required("Start Time is required."),
    end: Yup.string()
    .required("End Time is required."),
    date: Yup.date()
    .required("Date is required."),

    // stream_details: Yup.string()
    // .required("Stream details required."),

    price: Yup.number()
    .typeError("Price must be a valid number")
    //.positive("Price must be a positive nuber")
    .required("Price is required."),
    bannerImage: Yup.mixed()
    .required('Banner image is required.')
    .test('fileSize', 'File size must be less than 3MB', (value) => {
      if (!value) return true; // No file uploaded, so validation passes
      const fileSizeLimit = 3 * 1024 * 1024; // 3MB in bytes
      return value.size <= fileSizeLimit;
    })
    .test('fileType', 'Only JPEG, PNG, and GIF images are allowed.', (value) => {
      if (!value) return true;  

      const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      return allowedImageTypes.includes(value.type);
    }),
  }); 

 
  const handleSubmit = (values) => {
     
    // const validationSchemaStream = null;
    // if(values.stream_mode === 'LIVE')
    // {
    //   validationSchemaOnline= Yup.object({    
    //     stream_details: Yup.string()
    //     .required("Stream details required."),
         
    //   }); 
    // }
    // else{

    // }
  
    // formik.validateForm(validationSchemaStream)
    // .then(() => {
    //   if(formik.isValid){
        
    //   }
     
    // })
    // .catch((errors) => {
    //   console.log("Error" + errors);
    // })
 
    try {
      const utcDate = new Date(Date.UTC(
        values.date.getFullYear(),
        values.date.getMonth(),
        values.date.getDate(),
        values.date.getHours(),
        values.date.getMinutes(),
        values.date.getSeconds(),
      ));

      const formDataToSend = new FormData();
      formDataToSend.append("title",values.title);
      formDataToSend.append("date",utcDate.toISOString().split("T")[0]);
      formDataToSend.append("start",values.start); 
      formDataToSend.append("end",values.end); 
      formDataToSend.append("description",values.description);     
      formDataToSend.append("streamMode",values.stream_mode); 
      formDataToSend.append("streamDetails",values.stream_details); 
      formDataToSend.append("priceMode",values.price_mode); 
      formDataToSend.append("price",values.price); 
      formDataToSend.append("addressDetails",values.address_details); 
      formDataToSend.append("googleMapurl",values.google_map_url); 
      formDataToSend.append("file",values.file_upload); 
     
      formDataToSend.append("bannerfile",values.bannerImage);
      
      
      addWorkShop(formDataToSend).then((response)=>{

        if(!response.ok){
            // const err = new Error("Error in response");
            // throw err;
            setclsName('alert error');
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
            //return response;
            setclsName('alert success');
            setSnackbarMessage('Successfully added workshop data');
            setShowSnackbar(true);
            bannerfileInputRef.current.value = null;
            formik.resetForm();
              // Invoke the onEventSubmit callback after successful submission
          onWorkshopSubmit();
        });

    }
    catch (error){
      console.log(error);
    }
  }
   const fileInputRef = useRef(null);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(()=>{
    // onStartTimeChange((new Date().getHours().toString().length == 1 ? ('0' + new Date().getHours().toString()) : new Date().getHours().toString()) + ':'+ (new Date().getMinutes().toString().length == 1 ? (new Date().getMinutes().toString() + '0') : new Date().getMinutes().toString()) );
    // onEndTimeChange(endDate.getHours().toString()+':'+endDate.getMinutes().toString());

    
  },[]);

  const handleFileUploadChange = event => {
    console.log(event);
      const file = fileInputRef.current.files[0];
     formik.setFieldValue('file_upload', file);
     
  }
  
  const handleBannerImageChange = event => {
    console.log(event);
      const file = bannerfileInputRef.current.files[0];
     formik.setFieldValue('bannerImage', file);     
  }

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
  // const openViewPageModal = () => {
  //   setShowViewPageModal(true);
  // }
  const closeViewPageModal = () => {
    setShowViewPageModal(false);
  }

 
    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
              {/* style={{ width: '33rem', height: '62rem' }} */}
                  <Card style={{ width: '33rem'  }}>
                  <Card.Body>
                    <Card.Title style={{ display: 'inline-block' }}>Add Workshop</Card.Title>
                    <Form onSubmit={formik.handleSubmit}>
                     
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter title" 
                                name="title"  
                                value={formik.values.title}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="title"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>                  
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formShortDescription">
                                <Form.Label>Short Description</Form.Label>
                                <Form.Control required as="textarea" rows={4} placeholder="Enter Short description" 
                                name="description"  
                                value={formik.values.description}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                />
                                 <FormErrorMessage
                                      fieldName="description"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>                            
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBannerImage">
                                <Form.Label>Workshop Date</Form.Label>
                                <DatePicker
                                    id='date'
                                    selected={formik.values.date}
                                    onChange={(date)=>{
                                      setDate(date);
                                      formik.setFieldValue('date', date)
                                    }}
                                    minDate={new Date()}
                                    // showTimeSelect
                                    // dateFormat="yyyy-MM-dd HH:mm:ss"
                                    dateFormat="yyyy-MM-dd"
                                    // onCalendarClose={handleCalendarClose}
                                    // onCalendarOpen={handleCalendarOpen}
                                   
                                    ></DatePicker>
                              </Form.Group>
                            </div>
                             
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDuration">
                                <Form.Label>Start At</Form.Label>
                                <br></br>
                                {/* <Field type="time" id="ds" name="stime"/> */}
                                <TimePicker
                                    //onChange={onStartTimeChange}
                                    //value={startTime}
                                    value={formik.values.start}
                                    name="start"
                                    //onChange={(time) => formik.setFieldValue('start', time)}
                                    onChange={handleStartTimeChange}
                                    //onBlur={formik.handleBlur} 
                                    ></TimePicker>
                                      <FormErrorMessage
                                      fieldName="start"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formEndTime">
                                  <Form.Label>End At</Form.Label> <br></br>
                                  <TimePicker
                                      value={formik.values.end}
                                      name="end"
                                      //onChange={(time) => formik.setFieldValue('end', time)}
                                      onChange={handleEndTimeChange}
                                      >
                                      </TimePicker> <br></br>
                                      <Form.Text className="text-muted">
                                      You can select only hourly time slots 
                                    </Form.Text>
                                      <FormErrorMessage
                                        fieldName="end"
                                        formik={formik}
                                    ></FormErrorMessage>
                                </Form.Group>
                            </div>
                            
                          </div>  
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBannerImage">
                                <Form.Label>Banner Image</Form.Label>
                                <Form.Control type="file"  
                                 accept=".jpeg, .png, .gif, .jpg"
                                onChange={handleBannerImageChange} 
                                name="BannerImage" 
                                ref={bannerfileInputRef}
                                // value={bannerImage} 
                                />
                                  <FormErrorMessage
                                      fieldName="bannerImage"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                             
                          </div>
                         
                      <hr/>
                          <div className="row">                           
                           <div className="col">
                             <Form.Group className="mb-3" controlId="formEndTime">
                               <Form.Label>Streaming </Form.Label>
                                    <Form.Check
                                      inline
                                      label="LIVE"
                                      name="stream_mode"
                                      type="radio"
                                      id="radio-has-user"
                                      onChange={formik.handleChange}
                                      checked={formik.values.stream_mode === "LIVE"}
                                      value="LIVE"
                                      >
                                    </Form.Check>
                                    <Form.Check
                                    inline
                                    label="OFFLINE"
                                    name="stream_mode"
                                    type="radio"
                                    id="radio-has-user"
                                    onChange={formik.handleChange}
                                    checked={formik.values.stream_mode === "OFFLINE"}
                                    value="OFFLINE"
                                    >
                                    </Form.Check>

                                    {formik.values.stream_mode === 'LIVE' && (
                                      <div>
                                       <Form.Control required type="text" placeholder="Enter streaming details" 
                                       name="stream_details"  
                                       value={formik.values.stream_details}
                                       onChange={formik.handleChange} 
                                       onBlur={formik.handleBlur}                                
                                       />
                                       <FormErrorMessage
                                       fieldName="stream_details"
                                       formik={formik}
                                      ></FormErrorMessage>
                                      </div>
                                    )}
                                   
                                   {formik.values.stream_mode === 'OFFLINE' && (
                                    <div>
                                         <Form.Control required as="textarea" rows={4} placeholder="Enter address details" 
                                        name="address_details"  
                                        value={formik.values.address_details}
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}                                
                                        />
                                        <FormErrorMessage
                                        fieldName="address_details"
                                        formik={formik}
                                      ></FormErrorMessage>
                                    </div>
                                   )}
                                 
                                </Form.Group>
                            </div>
                          </div>
                          {formik.values.stream_mode === 'OFFLINE' && (
                            <div>
                                  <div className="row">                           
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formEndTime">
                                    <Form.Label>Google Map URL </Form.Label>
                                      <Form.Control required type="text" placeholder="Enter google map URL" 
                                            name="google_map_url"  
                                            value={formik.values.google_map_url}
                                            onChange={formik.handleChange} 
                                            onBlur={formik.handleBlur}                                
                                            />
                                        <FormErrorMessage
                                        fieldName="google_map_url"
                                        formik={formik}
                                      ></FormErrorMessage>
                                    </Form.Group>
                                  </div>
                                </div>
                                <div className="row">                           
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formEndTime">
                                    <Form.Label>File Uploads </Form.Label>
                                      <Form.Control type="file"  
                                       accept=".pdf, .xls, .xlsx, .docx, .doc"
                                        onChange={handleFileUploadChange} 
                                        name="file_upload" 
                                        ref={fileInputRef}
                                        />
                                    </Form.Group>
                                  </div>
                                </div>
                              
                            </div>
                          
                        
                          )}
                            <div className="row">                           
                                <div className="col">
                                  <Form.Group className="mb-3" controlId="formEndTime">
                                  <Form.Check
                                      inline
                                      label="FREE"
                                      name="price_mode"
                                      type="radio"
                                      id="radio-has-user"
                                      onChange={formik.handleChange}
                                      checked={formik.values.price_mode === "FREE"}
                                      value="FREE"
                                      >
                                      </Form.Check>
                                      <Form.Check
                                        inline
                                        label="PAID"
                                        name="price_mode"
                                        type="radio"
                                        id="radio-has-user"
                                        onChange={formik.handleChange}
                                        checked={formik.values.price_mode === "PAID"}
                                        value="PAID"
                                      >
                                      </Form.Check>
                                          <Form.Control required disabled={formik.values.price_mode === "FREE"} type="text" placeholder="Enter Price" 
                                          name="price"  
                                          value={formik.values.price_mode === "FREE" ? formik.values.price ='0' : formik.values.price}
                                          onChange={formik.handleChange} 
                                          onBlur={formik.handleBlur}                                
                                          />
                                          <FormErrorMessage
                                          fieldName="price"
                                          formik={formik}
                                        ></FormErrorMessage>
                                  </Form.Group>
                                  </div>
                             </div>
                          <Button variant="primary" className="button" type="submit">
                          Submit
                          </Button>                 
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
            </div>

            {showViewPageModal && (
            <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Courses List</h5>
                    <button type="button" className="close" onClick={closeViewPageModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {/* <MentorCoursesViewPage/> */}
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        </div>
          );

}


WorkshopCreatePage.propTypes ={
  //onWorkshopSubmit: PropTypes.object.isRequired,  
  onWorkshopSubmit: PropTypes.func, 
};
export default WorkshopCreatePage;