import React,{useEffect , useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import { Card} from 'react-bootstrap';
 
import { addSupportings } from "../../../api/mentorApi";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
 
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";

import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';
 

function SupportPage(){
  const [clsName, setclsName] = useState(''); 
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showViewPageModal, setShowViewPageModal] = useState(false);

 
  const initialValues = {
    downloadFile: null,
    description: '',   
  }

  const  validationSchema = Yup.object({
    
    description: Yup.string()
    .required("Description is required."), 
    downloadFile: Yup.mixed()
    .required("File is required."), 
    
  }); 

 
  const handleSubmit = (values) => {

    try {
      
      const formDataToSend = new FormData();
      formDataToSend.append("file",values.downloadFile);
      formDataToSend.append("description",values.description); 

      addSupportings(formDataToSend).then((response)=>{
        console.log(response);
        if(!response.ok){
          setclsName('alert error');
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
            //return response;
            setclsName('alert success');
            setSnackbarMessage('Suppotings upload Successfull, your request will be reviewed by our team and confirm after varification.');
            setShowSnackbar(true);
            formik.resetForm();            
            fileInputRef.current.value = null;
        });

    }
    catch (error){
      console.log(error);
    }
  }
  const fileInputRef = useRef(null);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(()=>{

    
  },[]);

  const handleDownloadFileChange = event => {
    console.log(event);
      const file = fileInputRef.current.files[0];
     formik.setFieldValue('downloadFile', file);
     
  }

   
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }

  const closeViewPageModal = () => {
    setShowViewPageModal(false);
  }
    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card>
                  <Card.Body>
                  <CustomTooltip text="If you need assistance or have any questions, our support team is available to help you with any issues or concerns you may have while using the platform." />


                  <p>Hey there!</p>

                  <p> Having trouble with our product? Don&apos;t you worry, we&apos;ve got your back!
Our support team is here to assist you with any questions or issues you might be facing. We know that sometimes technology can be a bit mischievous, but fear not, because we&apos;re here to save the day!
</p>
                  <p>To make things even easier, we&apos;ve set up an easy error tracking feature just for you. Simply attach any questions or error screenshots you have, and we&apos;ll dive right into the depths of the issue.
                  </p>
                    {/* <Card.Title style={{ display: 'inline-block' }}>Add Resources</Card.Title> */}
                    <Form onSubmit={formik.handleSubmit}>
                   
                            <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDownloadFile">
                                <Form.Label>Upload document</Form.Label>
                                <Form.Control type="file"  
                                accept=".png,.jpg,.jpeg"
                                onChange={handleDownloadFileChange} 
                                name="downloadFile" 
                                ref={fileInputRef}
                                />
                                  <FormErrorMessage
                                      fieldName="downloadFile"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                             
                          </div>
                        
                          
                         
                             <div className="row">
                             <div className="col">
                               <Form.Group className="mb-3" controlId="formdescription">
                                 <Form.Label>Description</Form.Label>
                                 <Form.Control required as="textarea" rows={4} placeholder="Enter description"
                                  name="description"
                                  value={formik.values.description }
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur}   
                                 />
                                  <FormErrorMessage
                                       fieldName="description"
                                       formik={formik}
                                   ></FormErrorMessage>
                               </Form.Group>
                             </div>
                             
                           </div> 
                         
                           
                          <Button variant="primary" className="button" type="submit">
                          Submit
                          </Button>                 
                    </Form>

                 <p>   Click that upload button and let the magic begin!</p>
                <p>Rest assured, our support team won&apos;t leave you hanging. We&apos;ll review your submissions and get back to you with a solution within 48 hours. That&apos;s right, we&apos;re lightning fast!
                </p>
                <p>So don&apos;t hesitate, share your questions and suggestions via email <a className="aLink" href ="mailto: support@somotus.com">support@somotus.com</a>. We can&apos;t wait to help you out and get you back on track!
                </p>
                <p>Cheers,<br></br>
                Support Team @Somotus
                </p>

                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
            </div>

            {showViewPageModal && (
            <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Courses List</h5>
                    <button type="button" className="close" onClick={closeViewPageModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {/* <MentorCoursesViewPage/> */}
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        </div>
          );

}
export default SupportPage;