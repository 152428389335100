import React,{useEffect , useState } from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
// import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
//import Select from 'react-select';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
// import MentorCoursesViewPage from "./MentorCoursesViewPage";
import { getSubscriptionPlanDetails,updateSubscriptionPlan } from "../../../api/adminApi";
//import DateTimePicker from 'react-datetime-picker';
//import moment from "moment/moment";

function EditSubscriptionPage(){
  const { guid } = useParams();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  //const [showViewPageModal, setShowViewPageModal] = useState(false);
  // const [curriculum, SetCurriculum] = useState('');
  const [selectedOption, setSelectedOption] = useState('day');
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  // const fetchedPageOptions = [ { value: "1", label: "HOME PAGE" },
  // { value: "2", label: "SUB PAGE" }]
 

  const initialValues = {
    guid:'',
    planname: '', 
    description: '',
    interval: '',
    intervaltype:'',
    price:0,
  }

  const  validationSchema = Yup.object({
    planname: Yup.string()
    .required("Plan name is required."),
    description: Yup.string()
    .required("Description is required."),
    interval: Yup.string()
    .required("Interval is required."),
    // intervaltype: Yup.string()
    // .required("Interval type is required."),
    price: Yup.string()
    .required("Price is required."),
  }); 

 
  const handleSubmit = (values) => {
    //values.preventDefault();
    values.intervaltype = selectedOption;

    try {
      
      updateSubscriptionPlan(values).then((response)=>{
        if(!response.ok){
            
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
            //return response;
            setSnackbarMessage('Successfully updated Subscription data.');
            setShowSnackbar(true);
            //formik.resetForm();
        });

    }
    catch (error){
      console.log(error);
    }
  }
  

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(()=>{
    
    getSubscriptionPlanDetails(guid).then(response=>{    
     
      formik.setValues({
        guid:guid,
        title: response.title,
        pagename: response.pagename,
        startdate: response.startdate,
        enddate: response.enddate,
       
        planname: response.rplanname, 
        description:  response.rdescription, 
        interval:  response.rinterval, 
        intervaltype: response.rintervaltype, 
        price: response.rprice, 

         
      });
      setSelectedOption(response.rintervaltype);

      
    })

  },[guid]);

   
   
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
 
  // const closeViewPageModal = () => {
  //   setShowViewPageModal(false);
  // }
   
 
    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card style={{ width: '62rem', height: '53rem' }}>
                  <Card.Body>
                  <Link to="/config-subscriptionplan/view">
                  <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                       Back
                  </Button>
                  </Link>  
                    <Card.Title style={{ display: 'inline-block' }}>Update Subscription</Card.Title>
                    <Form onSubmit={formik.handleSubmit}>
                    <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Plan Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter Plan Name" 
                                name="planname"  
                                value={formik.values.planname}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="planname"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formdesc">
                                <Form.Label>Description</Form.Label>
                                <Form.Control required type="text" placeholder="Enter description" 
                                name="description"  
                                value={formik.values.description}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="description"
                                      formik={formik}
                                  ></FormErrorMessage>

                         
                                </Form.Group>
                            </div>                       
                          </div>
                          
                           
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="forminterval">
                                <Form.Label>Interval</Form.Label>
                                <Form.Control required type="text" placeholder="Enter interval" 
                                name="interval"  
                                value={formik.values.interval}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                <div className="option-container">
                                <div
                                  className={`option-box ${selectedOption === 'day' ? 'selected' : ''}`}
                                  onClick={() => handleOptionClick('day')}
                                >
                                  Day
                                </div>
                                <div
                                  className={`option-box ${selectedOption === 'month' ? 'selected' : ''}`}
                                  onClick={() => handleOptionClick('month')}
                                >
                                  Month
                                </div>
                                <div
                                  className={`option-box ${selectedOption === 'year' ? 'selected' : ''}`}
                                  onClick={() => handleOptionClick('year')}
                                >
                                  Year
                                </div>
                              </div>
                                 <FormErrorMessage
                                      fieldName="interval"
                                      formik={formik}
                                  ></FormErrorMessage>

                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formPrice">
                                <Form.Label>Price</Form.Label>
                                <Form.Control required type="text" placeholder="Enter price" 
                                name="price"  
                                value={formik.values.price}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="price"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                          
                          </div>  
                           
                          <Button variant="primary" className="button" type="submit">
                          Update
                          </Button>                 
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
            </div>

        </div>
        </div>
          );

}
export default EditSubscriptionPage;