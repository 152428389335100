import React,{useEffect , useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import { addShorts } from "../../../api/mentorApi";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { Link } from "react-router-dom";


function MentorShortsPage(){
  const [clsName, setclsName] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const documentfileInputRef = useRef(null);

  const initialValues = {
    title: '',
    description: '',
    downloadFile: null,
  }

  const  validationSchema = Yup.object({
    title: Yup.string()
    .required("Title is required."),  
    description: Yup.string()
    .required("Description is required."),   
    downloadFile: Yup.mixed()
    .required('Shorts image is required.')
    .test('fileSize', 'File size must be less than 10MB', (value) => {
      if (!value) return true; // No file uploaded, so validation passes
      const fileSizeLimit = 10 * 1024 * 1024; // 10MB in bytes
      return value.size <= fileSizeLimit;
    })
    .test('fileType', 'Only JPEG, PNG, and GIF images are allowed.', (value) => {
      if (!value) return true;  

      const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/mpeg', 'video/quicktime', 'video/webm', 'video/x-msvideo', 'video/x-flv'];
      return allowedImageTypes.includes(value.type);
    }),
  }); 

 
  const handleSubmit = (values) => {
    try {
     
      const formDataToSend = new FormData();     
      formDataToSend.append("title",values.title);
      formDataToSend.append("description",values.description);
      //formDataToSend.append("file",values.downloadFile);
 
      const files = documentfileInputRef.current.files;
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formDataToSend.append("documentfile",file);
      }

      addShorts(formDataToSend).then((response)=>{
        console.log(response);
        if(!response.ok){
          setclsName('alert error');
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
            //return response;
            setclsName('alert success');
            setSnackbarMessage('Successfully added shorts data');
            setShowSnackbar(true);
            formik.resetForm();
            documentfileInputRef.current.value = null;
        });

    }
    catch (error){
      console.log(error);
    }
  }
 

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(()=>{
    
  },[]);

  const handleDocumentUploadChange  = event => {
     console.log(event);
    const file = documentfileInputRef.current.files[0];  
    formik.setFieldValue('downloadFile', file);
     
  }
 
 
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
 

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card>
                  <Card.Body>
                  
                    {/* <Card.Title style={{ display: 'inline-block' }}>Add Shorts</Card.Title> */}
                    <Form onSubmit={formik.handleSubmit}>
                    <div className="row">
                                <div className="col" style={{float:"right"}}>
                                  <Link to="/shortsView">
                                  <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                                      Back
                                  </Button>
                                  </Link>  
                                </div>
                        </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control required type="text" placeholder="Enter title" 
                                name="title"  
                                value={formik.values.title}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="title"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control required type="text" placeholder="Enter description" 
                                name="description"  
                                value={formik.values.description}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="description"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>                         
                          </div>
                        
                            <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formDownloadFile">
                                <Form.Label>Upload document</Form.Label>
                                <Form.Control type="file" multiple 
                                onChange={handleDocumentUploadChange} 
                                name="downloadFile" 
                                ref={documentfileInputRef} />
                                  <FormErrorMessage
                                      fieldName="downloadFile"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                          </div>                           
                          <Button variant="primary" type="submit" className="button">
                          Submit
                          </Button>                 
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
            </div>
 

        </div>
        </div>
          );

}
export default MentorShortsPage;