import '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import '../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css'
// import topbarLogo from '../../../../Images/Somotus-Logo1-Lighter.png';
import topbarLogo from '../../../../Images/lifeskylls-logo.png';

import avatar from '../../../../Images/avatar.png';

import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
// import { getCurrentUser,logout } from '../../../../api/userAuthApi';
import React, { useState, useRef } from 'react';

import './../../../../css/custom.css';

const HeaderNew =()=>{

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    // const handleProfileClick = (event) => {
    //     event.stopPropagation(); // Stop the event propagation
    //     setIsMenuOpen(!isMenuOpen);
    //   };
    const handleLogout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      setIsMenuOpen(false);
    };

    // const handleWindowClick = (event) => {
    //     console.log('Window click event');
    //     // Close the menu if the click is outside the menu
    //     if (menuRef.current && !menuRef.current.contains(event.target)) {
    //       setIsMenuOpen(false);
    //     }
    //   };


    // useEffect(() => {
    //     console.log('Adding global click event listener');
    //     // Add a global click event listener
    //     window.addEventListener('click', handleWindowClick);
      
    //     // Clean up the event listener on component unmount
    //     return () => {
    //       console.log('Removing global click event listener');
    //       window.removeEventListener('click', handleWindowClick);
    //     };
    //   }, []); // Ensure that the effect only runs once (empty dependency array)
      
       

      // const [state, setState] = useState({
  //   showMentorBoard: false,
  //   showAdminBoard: false,
  //   showCorporateBoard: false,
  //   currentUser: undefined,
  // });


  // const user = null;//getCurrentUser();
     
  //   if (user) {
  //     setState({
  //       currentUser: user,
  //       showMentorBoard: user.role.includes("Mentor"), //ROLE_MODERATOR
  //       showAdminBoard: user.role.includes("Admin"), //ROLE_ADMIN
  //       showCorporateBoard: user.role.includes("Corporate"), //ROLE_MODERATOR
  //     });
  //   }

  //const navigate = useNavigate();

  // const handleLogout =()=>{    
  //   localStorage.removeItem("user");
  //   localStorage.removeItem("accessToken");
  //   localStorage.removeItem("refreshToken");
  //   //navigate("/login");
  //   //window.location.reload();
  //   // props.pageData({
  //   //   showMentorBoard: false,
  //   //   showAdminBoard: false,
  //   //   showCorporateBoard: false,
  //   //   currentUser: undefined,
  //   // });
  // }
// /showMentorBoard, showAdminBoard,showCorporateBoard
  //const { currentUser } = state;

    return(
      <header className="topbar header-color" data-navbarbg="skin5">
            <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                <div className="navbar-header" data-logobg="skin6">
                    
                    <a className="navbar-brand" >
                     
                        <b className="logo-icon">
                           
                            <img src={topbarLogo} className='logo-width' alt="homepage" />
                        </b>
                       
                        {/* <span class="logo-text">
                          
                            <img src="plugins/images/logo-text.png" alt="homepage" />
                        </span> */}
                    </a>
                   
                    <a className="nav-toggler waves-effect waves-light text-dark d-block d-md-none"
                        href="javascript:void(0)"><i className="ti-menu ti-close"></i></a>
                </div>
              
                <div className="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin5">
                   
                   
                    <ul className="navbar-nav ms-auto d-flex align-items-center">

                       
                        <li className=" in">
                           
                                {/* <input type="text" placeholder="Search..." class="form-control mt-0"></input>
                                <a href="" class="active">
                                    <i class="fa fa-search"></i>
                                </a> */}
                            <Button className="custom-button" type="submit"   >
                           No Active Subscription
                          </Button>     
                                             
                          <Link to={"/login"} onClick={handleLogout} className="custom-button" style={{width:'100px',marginTop:'5px'}}>
                                <i className="fs-4 bi-box-arrow-right" style={{marginRight:'5px'}}></i> <span > SignOut</span>
                            </Link> 
                        </li>
                       
                        <li>
                        {/* onClick={handleProfileClick} */}
                        <div className="profile-pic" >
                        <img src={avatar} alt="user-img" width="36" className="img-circle" />
                        </div>
                        
                        {isMenuOpen && (
                        <div ref={menuRef} className="slide-menu">
                            <ul>
                            <li >
                             
                                <Link to={"/login"} onClick={handleLogout} className='aLink'>
                                <i className="fs-4 bi-box-arrow-right" style={{marginRight:'5px'}}></i> <span className="hide-menu left-menu-text"> SignOut</span>
                            </Link> 
                            </li>
                            {/* Add more menu items as needed */}
                            </ul>
                        </div>
                        )}
                           
                        </li>
                       
                     
                    </ul>
                    
                </div>
            </nav>
           
        </header>
  )
}
export default HeaderNew;


// import React, { useState } from 'react';
// import topbarLogo from '../../../../Images/lifeskylls-logo.jpeg';
// import { Link } from 'react-router-dom';

// const Header = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('user');
//     localStorage.removeItem('accessToken');
//     localStorage.removeItem('refreshToken');
//   };

//   return (
//     <nav className="navbar navbar-expand-lg navbar-light topbar">
//       <div className="container-fluid">
//         <img
//           src={topbarLogo}
//           className="topBarLogo"
//           onClick={toggleMenu} // Toggle the menu when the logo is clicked
//         />
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarSupportedContent"
//           aria-controls="navbarSupportedContent"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
//           <div className="navbar-nav me-auto mb-2 mb-lg-0">
//             {/* Add your menu items here */}
//             <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//               <li className="nav-item">
//                 <a className="nav-link active" aria-current="page" href="#">
//                   Courses
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a className="nav-link" href="#">
//                   Resources
//                 </a>
//               </li>
//             </ul>
//           </div>

//           <form className="d-flex">
//             <Link to={'/login'} onClick={handleLogout} className="nav-link">
//               Logout
//             </Link>
//             {localStorage.getItem('user') ? (
//               <a href="/login" className="nav-link" onClick={handleLogout}>
//                 LogOut
//               </a>
//             ) : (
//               <></>
//             )}
//           </form>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Header;
