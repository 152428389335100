import React, {useEffect, useState } from "react";
// import Form from 'react-bootstrap/Form';
// import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
// import Select from 'react-select';
// import { useFormik, Formik } from "formik"
// import * as Yup from "yup";
// import {useQuery, useQueryClient } from "react-query";
// import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
// import { Link } from "react-router-dom";
// import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import FilterFactoryProps, {textFilter} from "react-bootstrap-table2-filter";
//import filterFactory from "react-bootstrap-table2-filter";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment/moment";
import { getCoursesByUGuid } from "../../../api/adminApi";
import { useParams } from "react-router-dom";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function CoursesListPage(){
  const classes = useStyles();
  const { id } = useParams(); 
  const [coursesList, setCoursesList] = useState([]);

      
  // const dateFormatter = (cell) => {  
  //   const formattedDate = moment(cell).format('YYYY-MM-DD');
  //   return <span>{formattedDate}</span>;
  // }   

  const columns = [
    {field: 'title', headerName: 'Title', minWidth: 150},
    {field: 'overview', headerName: 'Overview', minWidth: 150},
    {field: 'duration', headerName: 'Duration', minWidth: 150},
    {field: 'displayprice', headerName: 'Display price', minWidth: 150},
    {field: 'statustxt', headerName: 'Status', minWidth: 150},
    {field: 'createddate', headerName: 'Created date', minWidth: 150, 
    valueGetter: (params) => moment(params.row.createddate).format("YYYY-MM-DD HH:mm")
    },

    // {
    //   dataField: 'title',
    //   text: 'Title',
    // },
    // {
    //   dataField: 'overview',
    //   text: 'Overview',
    // },
     
    // {
    //   dataField: 'duration',
    //   text: 'Deration',
    // },
    // {
    //   dataField: 'displayprice',
    //   text: 'Display price',
    // },
    // {
    //   dataField: 'statustxt',
    //   text: 'Status',
    // },
    // {
    //   dataField: 'createddate',
    //   text: 'Created date',
    //   formatter: dateFormatter
    // },
  ];
  
  useEffect(()=>{
    fetchCoursesData();

  },[]);
  
  const fetchCoursesData =() => {
    getCoursesByUGuid(id).then(response=>{                         
      setCoursesList(response); 
    });
  }
    
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <ListGroup variant="flush">
                    <ListGroup.Item> 
                    
                    <div className="row">
                        <div className="col">
                        <Card.Subtitle>Courses Details</Card.Subtitle>
                        <div style={{float:"right"}}> &nbsp;</div>  
                            {/* <BootstrapTable 
                            keyField="id"
                            data={coursesList}
                            columns={columns}
                            // cellEdit={cellEditFactory(cellEditOptions)}
                            pagination={paginationFactory()}
                            filter={filterFactory()}
                            /> */}
                             <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={coursesList}
                                columns={columns}
                                getRowId={(row) => row.id}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
                        </div>
                    </div>
                    </ListGroup.Item>
                </ListGroup>
               
                </div>
            </div>
        </div>
        </div>        

    )

}
export default CoursesListPage