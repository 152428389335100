import React, {useEffect, useState } from "react";
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import { getAssessmentResults } from "../../../api/corporateApi";
import { Link,useParams } from "react-router-dom";
import moment from "moment/moment";
import SearchInputs from "../../common/shared/search/SearchInputs";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function ViewResultsPage(){
  const classes = useStyles();
    const { guid } = useParams();  
    const [employeeList, setEmployeeList] = useState([]);
    // const [selectedEmployees, setSelectedEmployees] = useState([]);
    // const isAnyCheckboxSelected = selectedEmployees.length > 0;
    const [filteredData, setFilteredData] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');

  //   const dateFormatter = (cell) => {  
  //     if (!cell) {
  //       return <span>{''}</span>;  
  //     }

  //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
  //     return <span>{formattedDate}</span>;
  // }

    const columns =[
      {
        field: 'selectField',
        headerName: 'Status',
        minWidth: 200,
        renderCell: (params) => (
          <span>
            {!params.row.createddate ? 'Not Initiated' : 'Completed'}
          </span>
        ),
      },
      {field: 'firstname', headerName: 'First Name', minWidth: 150},
      {field: 'emailaddress', headerName: 'Email Address', minWidth: 150},
      {field: 'score', headerName: 'Score', minWidth: 150},
      {field: 'percentage', headerName: 'Percentage', minWidth: 150},
      {field: 'timetaken', headerName: 'Timetaken', minWidth: 150},
      {field: 'result', headerName: 'Result', minWidth: 150},
      {field: 'createddate', headerName: 'Completion Date', minWidth: 150, 
      valueGetter: (params) => moment(params.row.createddate).format("YYYY-MM-DD HH:mm")
      },
     

        // {
        //     dataField: 'emailaddress',  
        //     text: 'Status',
        //     formatter: (cell, row) => {
        //         if (!row.createddate) {
        //           return <span>Not Initiated</span>;
        //         } else {
        //             return <span>Completed</span>;
        //         }
        //       },
        //     },
        // {dataField: 'firstname', text: 'First Name'},
        // // {dataField: 'lastname', text: 'Last Name'},
        // {dataField: 'emailaddress', text: 'Email Address'},
        // {dataField: 'score', text: 'Score'},
        // {dataField: 'percentage', text: 'Percentage'},
        // {dataField: 'timetaken', text: 'Timetaken'},
        // {dataField: 'result', text: 'Result'},
        // {dataField: 'createddate', text: 'Completion Date', formatter: dateFormatter},
         
    ];

    
      
    useEffect(()=>{
        fetchAssessentResultsData();
          },[]);
      
      const fetchAssessentResultsData =() => {
        getAssessmentResults(guid).then(response=>{                    
          setEmployeeList(response); 
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }
 useEffect(()=>{
      const filteredResults = employeeList.filter((item) => {
          const emailMatch = item.emailaddress.toLowerCase().includes(emailSearch.toLowerCase());
  
          return emailMatch;
      });
      setFilteredData(filteredResults);
      },[employeeList,emailSearch]);
      
      const handleEmailChange = (e) => {
      setEmailSearch(e.target.value);
      }

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <Card style={{ width: '62rem', height: '53rem' }}>
                    <Card.Body>
                    <Link to="/assessments">
                    <Button variant="primary" type="submit" style={{float:"right"}} >
                        Back
                    </Button>
                    </Link>  
                    <Card.Title style={{ display: 'inline-block' }}>Employees Results </Card.Title>
                    <br/> <br/>
                    <SearchInputs
                    showEmailSearch={true}
                    emailSearch={emailSearch}
                    onEmailChange={handleEmailChange}
                    ></SearchInputs>
                    {/* <BootstrapTable 
                    keyField="invitationid"
                    data={filteredData}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory()}
                    bootstrap4
                    striped
                    hover
                    condensed
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-bordered table-hover"
                    /> */}
                <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.invitationid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
                      
                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default ViewResultsPage