import React from "react";
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
 
import ViewResultsPage from './ViewResultsPage';
import ViewRewardsPage from './ViewRewardsPage';

function StudentDetailsPage(){

     
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>                    
                <Card >
                    <Card.Body>
                    <Link to="/institute-student-list">
                  <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                       Back
                  </Button>
                  </Link>  
                    <Card.Title style={{ display: 'inline-block' }}>Student Details</Card.Title>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true">Assessments</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="subscription-tab" data-bs-toggle="tab" data-bs-target="#subscription" type="button" role="tab" aria-controls="subscription" aria-selected="false">Rewards</button>
                    </li>
                     
                   
                    </ul>
                <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <ViewResultsPage />
                </div>
                <div className="tab-pane fade" id="subscription" role="tabpanel" aria-labelledby="subscription-tab">
                    <ViewRewardsPage />
                </div>
                </div>

                   
                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default StudentDetailsPage