import React  from 'react';
// import ProfilePic from '../../../../Images/avatar.png';
// import { uploadProfileImage } from "../../../../api/mentorApi";
import PropTypes from "prop-types";

const Progress = ({ percent,cName,count }) => {
    //const [selectedProfilePic, setSelectedProfilePic] = useState('');    
    const strokeWidth = 15;
    const radius = 50;
    const normalizedRadius = radius - strokeWidth;
    const circumfrence = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumfrence - (percent / 100) * circumfrence;

    const textX = radius + 17;
    const textY = radius + -5;

   
    
    return (
        <div className='progress-circle'>
            <svg height={radius * 3} width={radius * 2}>
                <circle 
                    className='progress-circle-background'
                    strokeWidth={strokeWidth}
                    fill='transparent'
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
                 <circle  
                    className={cName}  //progress-circle-fill
                    strokeWidth={strokeWidth}
                    fill='transparent'
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                    transform={`rotate(-190 ${radius} ${radius})`}
                    style={{
                        strokeDasharray: circumfrence,
                        strokeDashoffset : strokeDashoffset,
                    }}
                />
                <foreignObject x={strokeWidth} y={strokeWidth} width={normalizedRadius * 2} height={normalizedRadius * 2}>
                    {/* <img src={avatarURL} className='card-img-top' alt="Card image cap"
                    style={{ width: '100%', height: '100%', borderRadius: '50%'}} /> */}

                  
                </foreignObject>
                
                <text
                className='progress-circle-text'
                x={textX}
                y={textY}
                textAnchor='end'
                alignmentBaseline='baseline'
                fill='#007bff'
                style={{dominantBaseline:'central'}}
                dy='.3em'
                >
                    {count}
                </text>
              
            </svg>
            
               
            {/* <img src={ProfilePic} className='card-img-top' alt="Card image cap" /> */}
        </div>
    )
}

Progress.propTypes ={
    percent: PropTypes.number.isRequired, 
    cName:PropTypes.string.isRequired, 
    count:PropTypes.number.isRequired, 
   // avatarURL: PropTypes.string.isRequired,   
  }; 

export default Progress;