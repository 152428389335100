import '../../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import '../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css'

import React from 'react';
import  {Link}  from 'react-router-dom'
const Footer =()=>{
    
    return(
    //   <footer className="footer-New text-center"> Copyright © 2023 by Somotus    Privacy Policy  Terms and Conditions   Refund Policy
    // </footer>
      <div className="footer-New text-center">
        <div className='row'>
          <div className='col'>
          Copyright © 2024 by Somotus
          </div>
          <div className='col'>
          <Link className='aLink' to={"/privacy"}>
            Privacy Policy              
          </Link> | &nbsp;
          <Link className='aLink' to={"/terms"}>
          Terms and Conditions             
          </Link> | &nbsp;
          <Link className='aLink' to={"/refund"}>
          Refund Policy          
          </Link> 
              
          </div>
        </div>
             
      </div>
     
  )
}
export default Footer;
