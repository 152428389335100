import React, { useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import Button  from "react-bootstrap/Button";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import DateTimePicker from 'react-datetime-picker';
import moment from "moment/moment";
import Select from 'react-select';
import { getAssessmentDetails } from "../../../api/corporateApi";
// import { parseISO } from 'date-fns';
import { PropTypes } from "prop-types";
const CreateAssessmentPage = ({ onSave, setActiveTab, assessmentguid   }) => {
    // const [title, setTitle] = useState('');
    // const [description, setDescription] = useState('');
    // const [expiry, setExpiry] = useState('');
    // const [duration, setDuration] = useState('');
   
    //const [activeTab, setActiveTab] = useState('assessments');
    // const [isContinueEnabled, setIsContinueEnabled] = useState(false);
    // const [value, onChange] = useState(new Date());
   
  

    const durationOptions = [
        { value: "15", label: "15 minutes" },
        { value: "30", label: "30 minutes" },
        { value: "45", label: "45 minutes" },
      ];
    const initialValues = {       
        title: '',
        description: '',
        expiry: new Date(),
        duration: '',   
    }

    const  validationSchema = Yup.object({
        title: Yup.string()
        .required("Title is required.")
        .test('no-restricted-words', 'Restricted words found.', (value) => {
            const restrictedWords = ['call', 'phone', 'whatsapp', 'whats', 'number', 'num', 'landline', 'mobile', 'email', 'emailaddress','cellphone'];
            for (const word of restrictedWords) {
            if (value.toLowerCase().includes(word)) {
                return false;  
            }
            }
            return true;  
        })
        .test('no-more-than-two-digits', 'Numbers are not allowed.', (value) => {
            // Use a regular expression to check for more than two consecutive digits
            const consecutiveDigitsPattern = /\d{3,}/;
            return !consecutiveDigitsPattern.test(value);
        })
        .test('no-special-characters', 'Special characters are not allowed.', (value) => {
            const specialCharactersRegex = /[@!#$%^&*()_[\]{}|\\:;"'<>?/~`]/;
            return !specialCharactersRegex.test(value);
        }),


        description: Yup.string()
        .required("Instructions is required.")
        .test('no-restricted-words', 'Restricted words found.', (value) => {
            const restrictedWords = ['call', 'phone', 'whatsapp', 'whats', 'number', 'num', 'landline', 'mobile', 'email', 'emailaddress','cellphone'];
            for (const word of restrictedWords) {
            if (value.toLowerCase().includes(word)) {
                return false;  
            }
            }
            return true;  
        })
        .test('no-more-than-two-digits', 'Numbers are not allowed.', (value) => {
            // Use a regular expression to check for more than two consecutive digits
            const consecutiveDigitsPattern = /\d{3,}/;
            return !consecutiveDigitsPattern.test(value);
        })
        .test('no-special-characters', 'Special characters are not allowed.', (value) => {
            const specialCharactersRegex = /[@!#$%^&*()_[\]{}|\\:;"'<>?/~`]/;
            return !specialCharactersRegex.test(value);
        }),
        expiry: Yup.string()
        .required("Expiry is required."),
        // duration: Yup.string()
        // .required("Duration is required."),  
       duration: Yup.string()
       .required("Duration is required.")  
  }); 

    const handleSubmit = (values) => {
        let addeditStatus = 'ADD';
        if(assessmentguid !== null && assessmentguid !== undefined){
            addeditStatus = 'EDIT';
        }
        //const expiryDate = moment(values.expiry).toDate();
        const expiryDate = moment(values.expiry).format('YYYY-MM-DD HH:mm:ss');
        const assessment = {
            assessmentguid,
            title: values.title,
            description: values.description,
            expiry: expiryDate.toLocaleString(),
            duration: values.duration,
            addeditStatus: addeditStatus,
        };
        onSave(assessment);
        setActiveTab('questionConfigurations');
    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
    });

    useEffect(()=>{
        if(assessmentguid !== undefined && assessmentguid !== null ){
            getAssessmentDetails(assessmentguid).then(response=>{
                const expiryDate = moment(response.expiry).toDate(); // Convert the string to a Date object
              
                formik.setValues({
                  title: response.title,
                  description: response.description,
                  expiry: expiryDate,
                  duration: response.duration,
                });
              });
            }
          }, [assessmentguid]);

       
    // const handleTitleChange =(e) => {
    //     setTitle(e.target.value);
    // }
    // const handleDescriptionChange =(e) => {
    //     setDescription(e.target.value);
    // }
    // const handleExpiryChange =(e) => {
    //     setExpiry(e.target.value);
    // }
    // const handleDurationChange =(e) => {
    //     setDuration(e.target.value);
    // }
    
    // const handleSave = () => {
    //     onSave({title,description,expiry,duration});
    //     // setTitle('');
    //     // setDescription('');
    //     // setExpiry('');
    //     // setDuration('');
    //     formik.setFieldValue('title', title);
    //     formik.setFieldValue('description', description);
    //     formik.setFieldValue('expiry', expiry);
    //     formik.setFieldValue('duration', duration);
    // }
    // const handleContinue = () => {
    //     onSave({ title, description, expiry, duration });
    //     setActiveTab('questionConfigurations');
    //     //setIsContinueEnabled(false);
    //   };

    // const handleExpiryChange = (value) => {
    //     const formattedDate = moment(value).format("YYYY-MM-DD HH:mm:ss.SSSSSS");
    //     formik.setFieldValue("expiry", moment(formattedDate).toDate());
    //   };
      
    //   const formatDate = (date) => {
    //     const formattedDate = moment(date).format('YYYY-MM-DD HH:mm');
    //     return formattedDate;
    //   };

      const handleDurationChange = (selectedOption) => {
        formik.setFieldValue('duration', selectedOption.value);
    };

    return (
        <div>
              <Form onSubmit={formik.handleSubmit}>
            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control required type="text" placeholder="Enter title" 
                    name="title"  
                    // value={title}
                    // onChange={handleTitleChange} 
                       
                    value={formik.values.title}
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}                                
                    />
                        <FormErrorMessage
                            fieldName="title"
                            formik={formik}
                        ></FormErrorMessage>
                    </Form.Group>
                </div>                  
            </div>
            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="formShortDescription">
                        <Form.Label>Test Instructions</Form.Label>
                        <Form.Control required as="textarea" rows={2} placeholder="Enter instructions" 
                        name="description" 
                        value={formik.values.description}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur} 
                        />
                            <FormErrorMessage
                                fieldName="description"
                                formik={formik}
                            ></FormErrorMessage>
                            <Form.Text className="text-muted">
                            Candidates will be shown these before the test starts
                    </Form.Text>
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Test Expiry</Form.Label><br/>
                    <DateTimePicker
                        id="expiry"
                        name="expiry"
                        value={formik.values.expiry}
                        onChange={(date) => formik.setFieldValue('expiry', date)}
                        minDate={new Date()}
                        //format="yyyy-MM-dd HH:mm:ss"
                        />
                    <br/>

                    {/* <Form.Control required type="text" placeholder="Enter Expiry" 
                    name="expiry"  
                    // value={expiry}
                    // onChange={handleExpiryChange} 
                    value={formik.values.expiry}
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}                                
                    /> */}
                        <FormErrorMessage
                            fieldName="expiry"
                            formik={formik}
                        ></FormErrorMessage>
                           <Form.Text className="text-muted">
                           Candidates will not be able to access the test after this date and time
                    </Form.Text>
                    </Form.Group>
                </div>                  
            </div>
            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Test Duration</Form.Label>
                    <Select required isSearchable={false}
                    options={durationOptions} 
                    value={durationOptions.find((option) => option.value === formik.values.duration)}
                    onChange={handleDurationChange}
                    />
                    {/* <Form.Control required type="text" placeholder="Enter duration" 
                    name="duration"  
                    // value={duration}
                    // onChange={handleDurationChange} 
                    value={formik.values.duration}
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur}                                
                    /> */}
                        <FormErrorMessage
                            fieldName="duration"
                            formik={formik}
                        ></FormErrorMessage>
                          <Form.Text className="text-muted">
                          The test will be automatically submitted when the duration ends.
                    </Form.Text>
                    </Form.Group>
                </div>                  
            </div>    
            {/* onClick={handleContinue} */}
            <Button variant="primary" type="submit" className="button"> 
                Continue
            </Button>       
            {/* <h2>Create Assessments</h2>
            <label>Title</label>
            <input type="text" value={title} onChange={handleTitleChange} />
            <label>Description</label>
            <input type="text" value={description} onChange={handleDescriptionChange} />
            <button onClick={handleSave} >Save</button>

            <Button variant="primary" type="submit">
                Continue
            </Button>    */}
            </Form>
        </div>
    )

}
CreateAssessmentPage.propTypes ={
    onSave: PropTypes.object.isRequired, 
    setActiveTab: PropTypes.string.isRequired, 
    assessmentguid: PropTypes.string.isRequired, 
    
  };
export default CreateAssessmentPage;