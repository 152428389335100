import React, {useState, useEffect, useRef } from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import { Card } from 'react-bootstrap';
import 'react-day-picker/dist/style.css';
import 'react-calendar/dist/Calendar.css';
import  {getWorkShopsDetails,updateWorkShop,cancelWorkshop}  from "../../../api/mentorApi";
import { useParams } from "react-router-dom";

import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { useFormik } from "formik"
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import Modal from 'react-bootstrap/Modal';
import PropTypes from "prop-types";

function MentorWorkshopEditPage(props){
  const [clsName, setclsName] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { id } = useParams();
  let endDate= new Date();
  endDate.setMinutes(endDate.getMinutes()+30);
  const [date,setDate] = useState(new Date());
  const bannerfileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  // const getCurrentTime = () => {
  //   const currentDate = new Date();
  //   const minutes = currentDate.getMinutes();
  //   const roundedMinutes = Math.ceil(minutes / 30) * 30;
  //   currentDate.setMinutes(roundedMinutes);
    
  //   return currentDate.toTimeString().slice(0,5);
  // }
  // const getEndTime = () => {
  //   const selctedTime = getCurrentTime(); //formik.values.startTime;
  //   const [hours, minutes1] = selctedTime.split(':');
  //   const endTime = new Date();
  //   endTime.setHours(parseInt(hours) + 1);
  //   endTime.setMinutes(parseInt(minutes1));   
  //   return endTime.toTimeString().slice(0,5);
  // }
  
  const getCurrentTime = () => {
    const currentDate = new Date();
    const roundedHour = currentDate.getHours() + 1;
    currentDate.setHours(roundedHour, 0, 0, 0);
    return currentDate.toTimeString().slice(0, 5);
  };

  const getEndTime = () => {
    const selectedTime = getCurrentTime(); //formik.values.startTime;
    const [hours, minutes1] = selectedTime.split(':');
    const endTime = new Date();
    endTime.setHours(parseInt(hours) + 1);
    endTime.setMinutes(parseInt(minutes1));
    return endTime.toTimeString().slice(0, 5);
  };
  
  const handleEndTimeChange = (time) => {
    const [, minutes] = time.split(':');
    if (minutes === '00' ) { //|| minutes === '30'
      formik.setFieldValue('end', time);
    } else {
      console.log('Invalid end time');
      //formik.setFieldValue('end', '00:00');
    }
  };  
  const handleStartTimeChange = (time) => {
    const [, minutes] = time.split(':');
    if (minutes === '00') { //|| minutes === '30'
      formik.setFieldValue('start', time);
    } else {
      console.log('Invalid start time');
    }
  };
  
  // const isEndTimeValid = (startTime, endTime) => {
  //   const [startHours, startMinutes] = startTime.split(':');
  //   const [endHours, endMinutes] = endTime.split(':');
  
  //   const startTimestamp = parseInt(startHours) * 60 + parseInt(startMinutes);
  //   const endTimestamp = parseInt(endHours) * 60 + parseInt(endMinutes);
  
  //   return endTimestamp - startTimestamp >= 60;
  // };


  const initialValues = {   
    id:'',
    title: '',
    description: '',
    date:date,
    start: getCurrentTime(),//'12:00',
    end: getEndTime(),  
    stream_mode: 'LIVE',
    stream_details: '',
    address_details: '',
    google_map_url: '',
    file_upload: null,
    price_mode:'FREE',    
    price: '0',
    bannerImage: null,
    bannerImagePath: '',
    filePath: '',
  }

  const  validationSchema = Yup.object({   
    title: Yup.string()
    .required("Title is required."),
    description: Yup.string()
    .required('Description is required.')
    .matches(/^[^0-9]*([0-9][^0-9]*){0,6}$/, 'Numbers are not allowed(Max 6).')    
    .test('no-restricted-words', 'Restricted words found.', (value) => {
      const restrictedWords = ['call', 'phone', 'whatsapp', 'whats', 'number', 'num', 'landline', 'mobile', 'email', 'emailaddress', 'cellphone'];
      for (const word of restrictedWords) {
        if (value.toLowerCase().includes(word)) {
          return false;  
        }
      }
      return true; 
    })
    .test('no-special-characters', 'Special characters are not allowed.', (value) => {
      const specialCharactersRegex = /[@!#$%^&*()_[\]{}|\\:;"'<>?/~`]/;
      return !specialCharactersRegex.test(value);
    })
    .test('no-number-wording', 'Number wordings are not allowed.', (value) => {
      // Use a regular expression to check for number wordings
      const numberWordingsPattern = /\bone\b|\btwo\b|\bthree\b|\bfour\b|\bfive\b|\bsix\b|\bseven\b|\beight\b|\bnine\b|\bten\b|\beleven\b|\btwelve\b|\bthirteen\b|\bfourteen\b|\bfifteen\b|\bsixteen\b|\bseventeen\b|\beighteen\b|\bnineteen\b|\btwenty\b|\bthirty\b|\bforty\b|\bfifty\b|\bsixty\b|\bseventy\b|\beighty\b|\bninety\b|\bhundred\b|\bthousand\b|\bmillion\b|\bbillion\b|\btrillion\b/;
      return !numberWordingsPattern.test(value.toLowerCase());
    }),  
    start: Yup.string()
    .required("Start Time is required."),
    end: Yup.string()
    .required("End Time is required."),
    date: Yup.string()
    .required("Date is required."),
    // stream_details: Yup.string()
    // .required("Stream details required."),
    price: Yup.number()
    .typeError("Price must be a valid number")
    //.positive("Price must be a positive nuber")
    .required("Price is required."),

    bannerImage: Yup.mixed()
    //.required('Banner image is required.')
    .test('fileSize', 'File size must be less than 3MB', (value) => {
      if (!value) return true; // No file uploaded, so validation passes
      const fileSizeLimit = 3 * 1024 * 1024; // 3MB in bytes
      return value.size <= fileSizeLimit;
    })
    .test('fileType', 'Only JPEG, PNG, and GIF images are allowed.', (value) => {
      if (!value) return true;  

      const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      return allowedImageTypes.includes(value.type);
    }),

  }); 

  const handleSubmit = (values) => {
    try {
      const utcDate = new Date(Date.UTC(
        values.date.getFullYear(),
        values.date.getMonth(),
        values.date.getDate(),
        values.date.getHours(),
        values.date.getMinutes(),
        values.date.getSeconds(),
      ));

      const formDataToSend = new FormData();
      formDataToSend.append("id",values.id);
      formDataToSend.append("title",values.title);
      formDataToSend.append("date",utcDate.toISOString().split("T")[0]);
      formDataToSend.append("start",values.start); 
      formDataToSend.append("end",values.end); 
      formDataToSend.append("description",values.description);     
      formDataToSend.append("streamMode",values.stream_mode); 
      formDataToSend.append("streamDetails",values.stream_details); 
      formDataToSend.append("priceMode",values.price_mode); 
      formDataToSend.append("price",values.price); 
      formDataToSend.append("addressDetails",values.address_details); 
      formDataToSend.append("googleMapUrl",values.google_map_url); 
      formDataToSend.append("file",values.file_upload); 

      formDataToSend.append("bannerfile",values.bannerImage);
 
      updateWorkShop(formDataToSend).then((response)=>{
        if(!response.ok){
          setclsName('alert error');
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            return response.json();
        }
        }).then(response=>{
          console.log(response);
            //return response;
            
            //setSnackbarMessage('Successfully updated workshop data.');
            setclsName('alert success');
            setSnackbarMessage(JSON.stringify(response));
            setShowSnackbar(true);
            //formik.resetForm();
            bannerfileInputRef.current.value = null;

            props.onWorkshopSubmit(response);
        });

    }
    catch (error){
      console.log(error);
    }
  }
  
  const fileInputRef = useRef(null);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });
  
  useEffect(()=>{
    
    getWorkShopsDetails(props.editWorkShop.id).then(response=>{    
       
      // const formattedDate = format(date,'yyyy-MM-dd');
      const date1 = parseISO(response.date.toString());       
      
      formik.setValues({
        id:props.editWorkShop.id,
        title: response.title,
        description: response.description,
        date: date1,//new Date(),//'2023-05-24 00:00', //response.date,
        start: response.estart,
        end: response.eend,
        stream_mode: response.stream_mode,
        stream_details: response.stream_details,
        price_mode: response.price_mode,
        price: response.price,

        address_details: response.addressdetails,
        google_map_url: response.googlemap,
        //file_upload: null,
        bannerImagePath: response.bannerpath,
        filePath: response.filepath,
      });

      //formik.setFieldValue('date',date);

    })

  },[id]);

  const handleFileUploadChange = event => {
    console.log(event);
    const file = fileInputRef.current.files[0];
   formik.setFieldValue('file_upload', file);
   
}
const handleBannerImageChange = event => {
  console.log(event);
    const file = bannerfileInputRef.current.files[0];
   formik.setFieldValue('bannerImage', file);     
}
 
const handleCloseSnackbar = () => {
  setShowSnackbar(false);
}   

const handleCancel = () => {
  const values = formik.values;
 
  try {
    // const formDataToSend = new FormData();
    // formDataToSend.append("id",values.id);
    // formDataToSend.append("comments","Cacelling the workshop.");
    
    const formDataToSend = {
      id: values.id,
      comments: "Cancelling the workshop."
    };

    cancelWorkshop(formDataToSend).then((response)=>{
      if(!response.ok){
        setclsName('alert error');
          setSnackbarMessage('Error in response');
          setShowSnackbar(true);
      } else{
          return response.json();
      }
      }).then(response=>{
        console.log(response);
          //return response;
          setclsName('alert success');
          //setSnackbarMessage('Successfully updated workshop data.');
          setSnackbarMessage(JSON.stringify(response));
          setShowSnackbar(true);
          //formik.resetForm();


         // props.onWorkshopSubmit();
      });

  }
  catch (error){
    console.log(error);
  }


};

const openBannerModal = () => {
  setSelectedImage(formik.values.bannerImagePath);
};

// const openFileModal = () => {
//   setSelectedImage(formik.values.filePath);
// };

    return(

      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Update WorkShops
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
                     
                     <div className="row">
                       <div className="col">
                         <Form.Group className="mb-3" controlId="formTitle">
                           <Form.Label>Name</Form.Label>
                           <Form.Control required type="text" placeholder="Enter title" 
                           name="title"  
                           value={formik.values.title}
                           onChange={formik.handleChange} 
                           onBlur={formik.handleBlur}                                
                           />
                            <FormErrorMessage
                                 fieldName="title"
                                 formik={formik}
                             ></FormErrorMessage>
                         </Form.Group>
                       </div>                  
                     </div>
                     <div className="row">
                       <div className="col">
                         <Form.Group className="mb-3" controlId="formShortDescription">
                           <Form.Label>Short Description</Form.Label>
                           <Form.Control required as="textarea" rows={4} placeholder="Enter Short description" 
                           name="description"  
                           value={formik.values.description}
                           onChange={formik.handleChange} 
                           onBlur={formik.handleBlur} 
                           />
                            <FormErrorMessage
                                 fieldName="description"
                                 formik={formik}
                             ></FormErrorMessage>
                         </Form.Group>
                       </div>                            
                     </div>
                     <div className="row">
                       <div className="col">
                         <Form.Group className="mb-3" controlId="formBannerImage">
                           <Form.Label>Workshop Date</Form.Label>
                           <DatePicker
                               selected={formik.values.date}
                               onChange={(date)=>{
                                 setDate(date);
                                 formik.setFieldValue('date', date)
                               }}
                               minDate={new Date()}
                               // onCalendarClose={handleCalendarClose}
                               // onCalendarOpen={handleCalendarOpen}
                              
                               ></DatePicker>
                         </Form.Group>
                       </div>
                       <div className="col">
                        <Form.Group className="mb-3" controlId="formDuration">
                            <Form.Label>Start At</Form.Label><br></br>

                            {/* <Field type="time" id="ds" name="stime"/> */}
                            <TimePicker
                                //onChange={onStartTimeChange}
                                //value={startTime}
                                value={formik.values.start}
                                name="start"
                                //onChange={(time) => formik.setFieldValue('start', time)}
                                onChange={handleStartTimeChange}
                                //onBlur={formik.handleBlur} 
                                ></TimePicker>
                                  <FormErrorMessage
                                  fieldName="start"
                                  formik={formik}
                              ></FormErrorMessage>
                          </Form.Group>
                        </div>
                        <div className="col">
                          <Form.Group className="mb-3" controlId="formEndTime">
                            <Form.Label>End At</Form.Label><br></br>
                            <TimePicker
                                value={formik.values.end}
                                name="end"
                                //onChange={(time) => formik.setFieldValue('end', time)}
                                onChange={handleEndTimeChange}
                                >
                                </TimePicker><br></br>
                                <Form.Text className="text-muted">
                                  You can select only hourly time slots 
                                  </Form.Text>
                                <FormErrorMessage
                                  fieldName="end"
                                  formik={formik}
                              ></FormErrorMessage>
                          </Form.Group>
                        </div>
                     </div>
                      
                     <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBannerImage">
                                <Form.Label>Banner Image</Form.Label>
                                <Form.Control type="file"  
                                 accept=".jpeg, .png, .gif, .jpg"
                                onChange={handleBannerImageChange} 
                                name="BannerImage" 
                                ref={bannerfileInputRef}
                                // value={bannerImage} 
                                />
                                  <FormErrorMessage
                                      fieldName="bannerImage"
                                      formik={formik}
                                  ></FormErrorMessage>
                                  {formik.values.bannerImagePath && (
                                <div>
                                  <i className="bi bi-eye-fill" title='' onClick={() => openBannerModal()} />
                                  {/* <i className="bi bi-trash-fill" title="Delete" onClick={deleteBannerImage} /> */}
                                </div>
                              )}
                              </Form.Group>
                            </div>
                             
                          </div>
                         
                      <hr/>
                     <div className="row">                           
                           <div className="col">
                             <Form.Group className="mb-3" controlId="formEndTime">
                               <Form.Label>Streaming </Form.Label>
                                    <Form.Check
                                      inline
                                      label="LIVE"
                                      name="stream_mode"
                                      type="radio"
                                      id="radio-has-user"
                                      onChange={formik.handleChange}
                                      checked={formik.values.stream_mode === "LIVE"}
                                      value="LIVE"
                                      >
                                    </Form.Check>
                                    <Form.Check
                                    inline
                                    label="OFFLINE"
                                    name="stream_mode"
                                    type="radio"
                                    id="radio-has-user"
                                    onChange={formik.handleChange}
                                    checked={formik.values.stream_mode === "OFFLINE"}
                                    value="OFFLINE"
                                    >
                                    </Form.Check>

                                    {formik.values.stream_mode === 'LIVE' && (
                                      <div>
                                       <Form.Control required type="text" placeholder="Enter streaming details" 
                                       name="stream_details"  
                                       value={formik.values.stream_details}
                                       onChange={formik.handleChange} 
                                       onBlur={formik.handleBlur}                                
                                       />
                                       <FormErrorMessage
                                       fieldName="stream_details"
                                       formik={formik}
                                      ></FormErrorMessage>
                                      </div>
                                    )}
                                   
                                   {formik.values.stream_mode === 'OFFLINE' && (
                                    <div>
                                         <Form.Control required as="textarea" rows={4} placeholder="Enter address details" 
                                        name="address_details"  
                                        value={formik.values.address_details}
                                        onChange={formik.handleChange} 
                                        onBlur={formik.handleBlur}                                
                                        />
                                        <FormErrorMessage
                                        fieldName="address_details"
                                        formik={formik}
                                      ></FormErrorMessage>
                                    </div>
                                   )}
                                 
                                </Form.Group>
                            </div>
                          </div>
                          {formik.values.stream_mode === 'OFFLINE' && (
                            <div>
                                  <div className="row">                           
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formEndTime">
                                    <Form.Label>Google Map URL </Form.Label>
                                      <Form.Control required type="text" placeholder="Enter google map URL" 
                                            name="google_map_url"  
                                            value={formik.values.google_map_url}
                                            onChange={formik.handleChange} 
                                            onBlur={formik.handleBlur}                                
                                            />
                                        <FormErrorMessage
                                        fieldName="google_map_url"
                                        formik={formik}
                                      ></FormErrorMessage>
                                    </Form.Group>
                                  </div>
                                </div>
                                <div className="row">                           
                                  <div className="col">
                                    <Form.Group className="mb-3" controlId="formEndTime">
                                    <Form.Label>File Uploads </Form.Label>
                                      <Form.Control type="file"  
                                       accept=".pdf, .xls, .xlsx, .docx, .doc"
                                        onChange={handleFileUploadChange} 
                                        name="file_upload" 
                                        ref={fileInputRef}
                                        />
                                        {formik.values.filePath && (
                                          <div>
                                            {/* <i className="bi bi-eye-fill" title='' onClick={() => openFileModal()} /> */}
                                            <a href={formik.values.filePath} target="_blank" rel="noopener noreferrer">
                                            <i className="bi bi-eye-fill" title='' />
                                            </a>
                                          </div>
                                        )}

                                    </Form.Group>
                                  </div>
                                </div>
                              
                            </div>
                          
                        
                          )}
                            <div className="row">                           
                                <div className="col">
                                  <Form.Group className="mb-3" controlId="formEndTime">
                                  <Form.Check
                                      inline
                                      label="FREE"
                                      name="price_mode"
                                      type="radio"
                                      id="radio-has-user"
                                      onChange={formik.handleChange}
                                      checked={formik.values.price_mode === "FREE"}
                                      value="FREE"
                                      >
                                      </Form.Check>
                                      <Form.Check
                                        inline
                                        label="PAID"
                                        name="price_mode"
                                        type="radio"
                                        id="radio-has-user"
                                        onChange={formik.handleChange}
                                        checked={formik.values.price_mode === "PAID"}
                                        value="PAID"
                                      >
                                      </Form.Check>
                                          <Form.Control required disabled={formik.values.price_mode === "FREE"} type="text" placeholder="Enter Price" 
                                          name="price"  
                                          value={formik.values.price_mode === "FREE" ? formik.values.price ='0' : formik.values.price}
                                          onChange={formik.handleChange} 
                                          onBlur={formik.handleBlur}                                
                                          />
                                          <FormErrorMessage
                                          fieldName="price"
                                          formik={formik}
                                        ></FormErrorMessage>
                                  </Form.Group>
                                  </div>
                             </div>
                      <Button variant="primary" type="submit" className="button" onClick={formik.handleSubmit}>
                        Update
                      </Button> &nbsp;
                      <Button variant="primary" className="button" type="button" onClick={handleCancel}>
                        Cancel Workshop
                      </Button>  

                      {selectedImage && (
                        <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
                          <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Image Preview</h5>
                                <button type="button" className="close" onClick={() => setSelectedImage(null)}>
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                {/* <img src={`data:image/png;base64, ${selectedImage}`} 
                                alt="Selected" 
                                className="img-fluid" 
                                /> */}
                                <img src={selectedImage} className='card-img-top' alt="Card image cap"
                              style={{ width: '100%', height: '100%', borderRadius: '50%'}} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}  

               </Form>
            
            <SnackbarProvider 
                    show={showSnackbar}
                    message={snackbarMessage}
                    onClose={handleCloseSnackbar}
                    message1={clsName}
                    />
       
        </Modal.Body>
      </Modal>
       
              
            );

}

 
MentorWorkshopEditPage.propTypes ={
  editWorkShop: PropTypes.object,
  onWorkshopSubmit: PropTypes.func,
}; 

export default MentorWorkshopEditPage;