import React, {useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
import { getAssessments } from "../../../api/corporateApi";
import { Link, useNavigate } from 'react-router-dom';
import Button  from "react-bootstrap/Button";
//import Select from 'react-select';
// import { useHistory } from 'react-router-dom';

import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function ViewAssessmentsPage(){
    const navigate = useNavigate();
    const [assessmentList, setAssessmentList] = useState([]);
    const classes = useStyles();

    const columns = [
      { field: 'title', headerName: 'TEST NAME', width: 200, },
      { field: 'questioncount', headerName: 'QUESTIONS' },
      { field: 'duration', headerName: 'DURATION(MINUTES)', width: 150, },
      {
       
        headerName: 'INVITES',
        width: 200,  
        renderCell: (params) => (
          <>
            <Link className="aLink" style={{ paddingRight: '10px' }}>
              <i /> {params.row.invitescount} Sent
            </Link>
            <br />
            <Link className="aLink" style={{ paddingRight: '10px' }}>
              <i /> {params.row.responsecount} Completed
            </Link>
          </>
        ),
      },
      {
        field: 'ispublished',
        headerName: 'STATUS',
        width: 150, // Set the width as needed
        renderCell: (params) => (
          <>
            {params.row.ispublished ? 'Published' : 'Draft'}
          </>
        ),
      },
      {
        field: 'action',
        headerName: 'ACTION',
        width: 75,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <>
            {params.row.iseditable && (
              <>
               <i
                className="bi bi-pencil-fill aLink"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/assessment/${params.row.guid}/edit`);
                }}
                style={{ paddingRight: "10px" }}
              />
              {/* <Link
                className="aLink"
                style={{ paddingRight: '10px' }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/assessment/${params.row.guid}/edit`);
                }}
              >
                <i /> Edit
              </Link> */}
              {/* <div
            onClick={(e) => {
              e.preventDefault();
              
            }}
          >
            <Select
              options={getOptions(params.row)}
              placeholder="Select an action"
              onChange={(selectedOption) => handleActionChange(selectedOption, params.row)}
            />
          </div> */}
              </>
            )}
            {params.row.ispublished && (
              <>
               <i
                className="bi bi-eye-fill aLink"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/employee-results/${params.row.guid}`);
                }}
                style={{ paddingRight: "10px" }}
              />
              <i
                className="bi bi-person-plus-fill aLink"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/employee-invites/${params.row.guid}`);
                }}
                style={{ paddingRight: "10px" }}
              />
                {/* <Link
                  className="aLink"
                  style={{ paddingRight: '10px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/employee-results/${params.row.guid}`);
                  }}
                >
                  <i /> View Results
                </Link> */}
                {/* <br />
                <Link
                  className="aLink"
                  style={{ paddingRight: '10px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/employee-invites/${params.row.guid}`);
                  }}
                >
                   
                  <i />Send Invites
                </Link> */}
              
              </>
            )}
          </>
        ),
      },
    ];

    // const getOptions = (row) => {
    //   const options = [];
    //   if (row.iseditable) {
    //     options.push({ value: 'edit', label: 'Edit' });
    //   }
    //   if (row.ispublished) {
    //     options.push({ value: 'view', label: 'View Results' });
    //     options.push({ value: 'invites', label: 'Send Invites' });
    //   }
    //   return options;
    // };
    
    // Handle the action change based on selected option
    // const handleActionChange = (selectedOption, row) => {
    //   if (selectedOption.value === 'edit') {
    //     navigate(`/assessment/${row.guid}/edit`);
    //   } else if (selectedOption.value === 'view') {
    //     navigate(`/employee-results/${row.guid}`);
    //   } else if (selectedOption.value === 'invites') {
    //     navigate(`/employee-invites/${row.guid}`);
    //   }
    // };

    useEffect(()=>{
        fetchAssessmentData();
          },[]);
      
      const fetchAssessmentData =() => {
        getAssessments().then(response=>{                    
            setAssessmentList(response); 
        })
        .catch(error => {
          // Handle error
          console.log(error);
        });
      }

      

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <Card >
                    <Card.Body>
                    <CustomTooltip text="Create various types of assessments, including quizzes, tests, and assignments, and upload them to the platform for your students to complete and submit for evaluation." />
                    {/* <Card.Title style={{ display: 'inline-block' }}>Assessments</Card.Title> */}
                    <div className="row">
                    <div className="col" style={{float:"right"}}>
                    <Link to="/assessment/create">
                    <Button variant="primary" className="button"  type="submit" style={{float:"right"}} >
                        Create Assessment
                    </Button>
                    </Link>

                    </div>
                    </div>
                    <div className="row">
                    <div className="col">
                    
                    <div className={`${classes.dataGridContainer}`}>
                      <DataGrid
                      rows={assessmentList}
                      columns={columns}
                      getRowId={(row) => row.guid}
                      initialState={{
                      pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                      },
                      }}
                      pageSizeOptions={[5, 10]}
                      disableSelectionOnClick
                      //hideFooterPagination
                      autoHeight
                      checkboxSelection={false}
                      classes={{
                      header: classes.header,
                      toolbarContainer: classes.toolbarContainer,
                      columnsContainer: classes.columnsContainer,
                      columnHeaderTitle: classes.columnHeaderTitle,
                      cell: classes.cell,
                      paginationItem: classes.paginationItem,
                      paginationItemSelected: classes.paginationItemSelected,
                      paginationItemHover: classes.paginationItemHover,
                      }}
                      />

                    </div>

                    </div>
                    </div>
                   

                        

                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default ViewAssessmentsPage