import React, {useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import { Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import Select from 'react-select';
import { addNotification } from "../../../api/corporateApi";
// import moment from "moment/moment";
import * as Yup from "yup";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { PropTypes } from "prop-types";

function ConversationPage(props){

  const { id } = props;
  
  //const { guid } = useParams(); 
  // const [conversationsList, setConversationsList] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  
const  validationSchema = Yup.object({
      message: Yup.string()
      .required("Message is required."),
}); 

const handleSubmit = async (values) => {  
  if (values.refid === "") {
    values.refid = null;
  }
  else if (values.refid !== null && values.refid !== "") {
    values.refid = parseInt(values.refid);
  } 
  addNotification(values).then((response)=>{
    if(!response.ok){
        // const err = new Error("Error in response");
        // throw err;
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        //return response.json();
    }
    }).then(response=>{
      console.log(response);
       
        //return response;
        setSnackbarMessage('Successfully sent message');
        setShowSnackbar(true);
        formik.resetForm();
    });
}

const formik = useFormik({
  initialValues: {
      receiver: id,
      type: "General",
      message: "",
      status: "Not Applicable",
      refid: null,
  },
  validationSchema ,
  onSubmit: handleSubmit,
}); 

   

  // const dateFormatter = (cell) => { //, row
  //   const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
  //   return <span>{formattedDate}</span>;
  // }   

 
  
  useEffect(()=>{
     

  },[]);
  
  

  // const fetchedActionOptions = [ { value: "9", label: "Need Clarifications" },
  // { value: "10", label: "Clarified" },
  // ];

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    }
  // const handleActionChange = (selectedOption) => {
  //     formik.setFieldValue('action', selectedOption.label);
  // };
  

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              Messaging
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
            
              <div className="row">
                <div className="col">
                  <Form.Group className="mb-3" controlId="formShortDescription">
                    <Form.Label>Message</Form.Label>
                    <Form.Control required as="textarea" rows={4} placeholder="Enter message" 
                    name="message"  
                    value={formik.values.message}
                    onChange={formik.handleChange} 
                    onBlur={formik.handleBlur} 
                    />
                      <FormErrorMessage
                          fieldName="message"
                          formik={formik}
                      ></FormErrorMessage>
                  </Form.Group>
                </div>                            
              </div>
              <Button variant="primary" className="button" type="submit">
              Submit
              </Button>  
            </Form> 
         <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
          </Modal.Body>
        </Modal>

        
    );
}

ConversationPage.propTypes ={
  id: PropTypes.string.isRequired,   
};
export default ConversationPage;