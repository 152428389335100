import React,{useEffect , useRef, useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import Select from 'react-select';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import { getMasterData,updateResource,getResourceDetails,deleteFilesPhysically } from "../../../api/mentorApi";
import Modal from 'react-bootstrap/Modal';

function MentorResourcesEditPage(){
  const [clsName, setclsName] = useState('');
  const { id } = useParams();
  const[categoryOptions,setCategoryOptions] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rowDataToDelete, setRowDataToDelete] = useState(null);

  const initialValues = {
    id:'',
    categoryId: '',
    title: '',
    downloadFile: null,
    resourceLink: '', 
    fileitems:[],  
  }

  const  validationSchema = Yup.object({
    categoryName: Yup.string()
    .required("Category is required."),
    title: Yup.string()
    .required("Title is required."),
    downloadFile: Yup.mixed()
    .test('fileType', 'Only PDF, spreadsheet, and DOC files are allowed.', (value) => {
      if (!value) return true; // No file selected, so validation passes

      const allowedFileTypes = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      return allowedFileTypes.includes(value.type);
    })
    .test('fileSize', 'File size must be less than 10MB', (value) => {
      if (!value) return true; // No file uploaded, so validation passes
      const fileSizeLimit = 10 * 1024 * 1024; // 10MB in bytes
      return value.size <= fileSizeLimit;
    })
  }); 

 
  const handleSubmit = (values) => {
    console.log(JSON.stringify(values));
    try {
     
      const formDataToSend = new FormData();
      formDataToSend.append("Id",values.id);
      formDataToSend.append("CategoryId",values.categoryId);
      formDataToSend.append("Title",values.title);
      formDataToSend.append("file",values.downloadFile);
      formDataToSend.append("ResourceLink",values.resourceLink); 
     
       
      updateResource(formDataToSend).then((response)=>{
        if(!response.ok){
          setclsName('alert error');
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
          setclsName('alert success');
            setSnackbarMessage('Successfully updated resource data.');
            setShowSnackbar(true);
            //formik.resetForm();
        });

    }
    catch (error){
      console.log(error);
    }
  }
  const fileInputRef = useRef(null);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(()=>{

    getMasterData("RCAT").then(response => {
      const transformedOptions = response.map(option => ({
        label: option.label,
        value: option.value
      }));
      setCategoryOptions(transformedOptions);
    });
    
    getResourceDetails(id).then(response=>{    
      //setResourcesDetails(response);
      //formik.setValues(response);
      console.log(response);
      formik.setValues({
        id:id,
        categoryId:response.categoryid,
        categoryName: response.categoryname,
        title: response.title,
        // downloadFile: null,
        // downloadFileBase64:response.downloadFile_base64,
        resourceLink: response.resourcelink,    
        fileitems: response.fileitems,
      });
      // if(response.categoryid !== 1) // Video
      // {
      //   setShowDownloadFile(true);
      //   setShowResourceLink(false);
      // }
      // else{
      //   setShowDownloadFile(false);
      //   setShowResourceLink(true);
      // }

    })

  },[id]);

  const handleDownloadFileChange = event => {
    console.log(event);
      const file = fileInputRef.current.files[0];
     formik.setFieldValue('downloadFile', file);
     
  }


  const handleCategoryChange = (selectedOption) => {
    formik.setFieldValue('categoryName', selectedOption.label);
    formik.setFieldValue('categoryId', selectedOption.value);

    // if(selectedOption.value !== 1) // Video
    // {
    //   setShowDownloadFile(true);
    //   setShowResourceLink(false);
    // }
    // else{
    //   setShowDownloadFile(false);
    //   setShowResourceLink(true);
    // }

  };

  // const handleFileChange =(e)=>{
  //   setFileSelected(e.target.files[0]);
  // }
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
  // const openViewPageModal = () => {
  //   setShowViewPageModal(true);
  // }
  // const closeViewPageModal = () => {
  //   setShowViewPageModal(false);
  // }

  const handleDeleteFile = (index) => {
    setRowDataToDelete(index);
    handleDeleteClick();
  };

  const handleDeleteClick = () => {
    //setRowDataToDelete(row);
    setShowConfirmation(true);
};

const handleConfirmDelete = () => {
    
  const updatedFiles = [...formik.values.fileitems];
  //const deletedFile = updatedFiles[rowDataToDelete];
  updatedFiles.splice(rowDataToDelete, 1); // Remove the file at the specified index
  formik.setFieldValue('fileitems', updatedFiles);

  const fileId = formik.values.fileitems[rowDataToDelete].fileid;
 
  const formDataToSend = {
    id: id,
    guid:null,
    fileid: fileId,
    type: 'RESOURCES',
    };
  deleteFilesPhysically(formDataToSend).then((response)=>{
    if(!response.ok){
        setclsName('alert error');
        setSnackbarMessage('Error in response');
        setShowSnackbar(true);
    } else{
        //return response.json();
    }
    }).then(response=>{
      console.log(response);
        setclsName('alert success');
        setSnackbarMessage('Successfully deleted file.');
        setShowSnackbar(true);
        
    });

    setShowConfirmation(false);
    setRowDataToDelete(null);
};

// const handledeleteFileItem = (filepath) => {
//   const formDataToSend = {
//     filepath:filepath
//     };
//    deleteFile(formDataToSend).then((response)=>{
     
//     }).then(response=>{
//       console.log(response);
//     });
// }

const handleCancelDelete = () => {
   setRowDataToDelete(null);
    setShowConfirmation(false);
}; 

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card>
                  <Card.Body>
                 
                    {/* <Card.Title style={{ display: 'inline-block' }}>Update Resource</Card.Title> */}
                    <Form onSubmit={formik.handleSubmit}>
                    <div className="row">
                    <div className="col" style={{float:"right"}}>
                      <Link to="/resourcesView">
                      <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                          Back
                      </Button>
                      </Link>  
                      </div>
                    </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Title</Form.Label>
                                <Form.Control required type="text" placeholder="Enter title" 
                                name="title"  
                                value={formik.values.title}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="title"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formCategory">
                                <Form.Label>Category</Form.Label>
                                <Select required
                                  options={categoryOptions}
                                  value={categoryOptions.find((option) => option.label === formik.values.categoryName)}
                                  onChange={handleCategoryChange}
                                  />
                                 <div
                                    className="invalid-feedback"
                                    style={{
                                        display:
                                        formik.errors.categoryName
                                        ? "block"
                                        : "none",
                                    }}
                                >
                                    {formik.errors.categoryName ? formik.errors.categoryName : null}
                                </div>
                              
                              </Form.Group>
                            </div>                           
                          </div>
                         {/* {showDownloadFile && ( */}
                             <div className="row">
                             <div className="col">
                               <Form.Group className="mb-3" controlId="formDownloadFile">
                                 <Form.Label>Download File</Form.Label>
                                 <Form.Control type="file"  
                                 accept=".pdf, .xls, .xlsx, .docx, .doc"
                                 onChange={handleDownloadFileChange} 
                                 name="DownloadFile" 
                                 ref={fileInputRef}
                                 />
                               </Form.Group>

                               {formik.values.fileitems && formik.values.fileitems.map((fileItem, index) => (
                                  <div key={index}>
                                    <a href={fileItem.path} target="_blank" rel="noopener noreferrer">
                                      {fileItem.title}
                                    </a>
                                    <Button variant="primary" style={{marginBottom:'5px',marginTop:'5px',marginLeft:'5px'}} size="sm" onClick={() => handleDeleteFile(index)}>
                                    <span className="bi bi-trash"></span>  
                                  </Button>
                                  </div>
                                ))}

                             </div>
                              
                           </div>
                         {/* )} */}
                         
                        {/* {showResourceLink && ( */}
                             <div className="row">
                             <div className="col">
                               <Form.Group className="mb-3" controlId="formResourceLink">
                                 <Form.Label>Resource Link</Form.Label>
                                 <Form.Control required type="text" placeholder="Enter resource link" 
                                  name="resourceLink"  
                                  value={formik.values.resourceLink}
                                  onChange={formik.handleChange} 
                                  onBlur={formik.handleBlur} 
                                 />
                                  <FormErrorMessage
                                       fieldName="resourceLink"
                                       formik={formik}
                                   ></FormErrorMessage>
                               </Form.Group>
                             </div>
                           </div> 
                        {/* )} */}
                           
                          <Button variant="primary" className="button" type="submit">
                          Update
                          </Button>                 
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />

            <Modal show={showConfirmation} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                    Cancel
                </Button>
                <Button variant="danger" className="button" onClick={handleConfirmDelete}>
                    Delete
                </Button>
                </Modal.Footer>
            </Modal>

            </div>

        </div>
        </div>
          );

}
export default MentorResourcesEditPage;