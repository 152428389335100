import React, {useState,useEffect  } from "react";
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
import CreateUserPage from './CreateUserPage';
import EditUserPage from './EditUserPage';
import Modal from 'react-bootstrap/Modal';
import { getUsers,deleteUser } from "../../../api/corporateApi";
//import moment from "moment/moment";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function UserSetupPage(){
  const classes = useStyles();
  const [clsName, setclsName] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [dataList, setDataList] = useState([]);   
    const [modalShow, setModalShow] = useState(false);
    const [isEditModelDisplay,setIsEditModelDisplay]=useState(false);
    const [isCreateModelDisplay,setIsCreateModelDisplay]=useState(false);
    const [guid, setGuid] = useState(null);
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [rowDataToDelete, setRowDataToDelete] = useState(null);

    const [editdata, setEditdata] = useState({});  

    // const ActionColumnFormatter = (cell, row) => {
        
    //     if (cell) {
    //       return (
    //         <>
    //         <i
    //           className="bi bi-pencil-fill"
    //           onClick={() => handleEditClick(row)}
    //           style={{ paddingRight: "10px" }}
    //         />
    //         <i className="bi bi-trash" 
    //         onClick={() => handleDeleteClick(row)}
    //           style={{ paddingRight: "10px" }}
    //           />
    //         </>
            
    //       );
    //     }
    //   }

    const handleDeleteClick = (row) => {
        setRowDataToDelete(row);
        setShowConfirmation(true);
    };

    const handleConfirmDelete = () => {
        const updatedDataToDelete = {
            ...rowDataToDelete,
            type: 'CAT',
          };

        deleteUser(updatedDataToDelete).then((response)=>{    
            if(!response.ok){
                // const err = new Error("Error in response");
                // throw err;
                setclsName('alert error');
                setSnackbarMessage('Error in response');
                setShowSnackbar(true);
            } else{
                //return response.json();
            }
            }).then(response=>{
              console.log(response);
               
                //return response;
                setclsName('alert success');
                setSnackbarMessage('Successfully deleted user data.');
                setShowSnackbar(true);
                fetchUserData();
            });

        setShowConfirmation(false);
    };

    const handleCancelDelete = () => {
        setShowConfirmation(false);
    }; 

    const handleEditClick = (row)=>{   
        setEditdata(row);
        setGuid(row.guid); 
        setName(row.rolename); 
        setDescription(row.roledescription); 
        setIsEditModelDisplay(true);
        setModalShow(true);
      }

    const handleCreateUserdata = ()=>{
    setModalShow(true);
    setIsCreateModelDisplay(true);
    }
     
    const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    }  
    // const dateFormatter = (cell) => { //, row
    //   const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
    //   if(formattedDate === 'Invalid date'){return <span></span>;}
    //   return <span>{formattedDate}</span>;
    // }
    const columns =[
      {field: 'firstname', headerName: 'First Name', minWidth: 150},
      {field: 'lastname', headerName: 'Last Name', minWidth: 150},
      {field: 'emailaddress', headerName: 'Email Address', minWidth: 150},
      {field: 'divisionname', headerName: 'Division', minWidth: 150},
      {
        field: 'actions',
        headerName: 'Actions',
        minWidth: 200,
        renderCell: (params) => (
          <>
           <i
              className="bi bi-pencil-fill"
              onClick={() => handleEditClick(params.row)}
              style={{ paddingRight: "10px" }}
            />
            <i className="bi bi-trash" 
            onClick={() => handleDeleteClick(params.row)}
              style={{ paddingRight: "10px" }}
              />
          
          </>
        ),
      },

        // {dataField: 'firstname', text: 'First Name'},
        // {dataField: 'lastname', text: 'Last Name'},
        // {dataField: 'emailaddress', text: 'Email Address'},
        // {dataField: 'divisionname', text: 'Division'},
        // {
        //     dataField: 'guid',
        //     text: 'Actions',
        //     formatter: ActionColumnFormatter,
        //   },
    ];
    
    useEffect(()=>{
      fetchUserData();
      },[]);
      
    const fetchUserData =() => {
      getUsers().then(response => {
        setDataList(response);
        });
    }

      const handleReloadUpdateEvents = () => {       

        // setIsEditModelDisplay(false);
        // setModalShow(false);
        // setSnackbarMessage('Successfully updated Skills data.');
        // setShowSnackbar(true);
        fetchUserData();
      };

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
              <Card>
              <Card.Body>
               
                              
                  <div className="row">
                        <div className="col">
                          <Button variant="primary" className="button" type="submit" style={{float:"right"}}  onClick={handleCreateUserdata}>
                            Add Sub Admin
                        </Button>  
                        </div>
                   </div>
                    <div className="row">
                        <div className="col">
                        <Card.Subtitle>Sub Admin Setup</Card.Subtitle>
                        <div style={{float:"right"}}> &nbsp;</div>  
                            {/* <BootstrapTable 
                            keyField="guid"
                            data={dataList}
                            columns={columns}
                            // cellEdit={cellEditFactory(cellEditOptions)}
                            pagination={paginationFactory()}
                            // filter={filterFactory()}
                            bootstrap4
                            striped
                            hover
                            condensed
                            wrapperClasses="table-responsive"
                            bordered={false}
                            classes="table table-bordered table-hover"
                            /> */}
                             <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={dataList}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

                        </div>
                    </div>
                    
                     

                    {isCreateModelDisplay && 
                        <CreateUserPage onMasterSubmit={handleReloadUpdateEvents} show={modalShow} onHide={() => {setModalShow(false); setIsCreateModelDisplay(false);}}  ></CreateUserPage>
                    }
                    {isEditModelDisplay && 
                        <EditUserPage onMasterSubmit={handleReloadUpdateEvents} editdata={editdata} guid={guid} name={name} description={description} show={modalShow} onHide={() => {setModalShow(false); setIsEditModelDisplay(false);}}  ></EditUserPage>
                    }
 

                 </Card.Body>
                  </Card>
                </div>
            </div>
            <Modal show={showConfirmation} onHide={handleCancelDelete}>
            <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                Cancel
            </Button>
            <Button variant="danger" className="button" onClick={handleConfirmDelete}>
                Delete
            </Button>
            </Modal.Footer>
            </Modal>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
            </div>
        </div>
        </div>        

    )

}

export default UserSetupPage