import React, {useEffect, useState } from "react";
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
// import FilterFactoryProps, {textFilter} from "react-bootstrap-table2-filter";
//import filterFactory from "react-bootstrap-table2-filter";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { getPayments  } from "../../../api/adminApi";
// import { useParams } from "react-router-dom";
import SearchInputs from "../../common/shared/search/SearchInputs";
import SettlementPage from "./SettlementPage";

import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function AdminPaymentPage(){
    //const { id } = useParams(); 
    const [modalShow, setModalShow] = useState(false);
    const [isModelDisplay, setIsModelDisplay] = useState(false);
    const [guid, setGuid] = useState(null);
    const [paymentList, setPaymentList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');
    const classes = useStyles();

    // const dateFormatter = (cell) => { //, row
    //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
    //     return <span>{formattedDate}</span>;
    // }
     
    // const ActionColumnFormatter = (cell, row) => {
    //     if (cell) {
    //       if(row.settlementstatus !== 'Payment Pending'){
    //         return (
    //           <i
    //           className="bi bi-activity"
    //             onClick={() => handleViewClick(row.guid)}
    //             style={{ paddingRight: "10px" }}
    //           />
    //         );
    //       }
         
    //     }
    //   };

    const columns =[
        {field: 'mentorname', headerName: 'Mentor', minWidth: 150},
        {field: 'enrollmenttype', headerName: 'Type', minWidth: 150},
        {field: 'sender', headerName: 'Sender', minWidth: 150},
       
        {field: 'amount', headerName: 'Amount', minWidth: 150, 
        renderCell: (params) => (
          <span>
            ₹ {parseFloat(params.value).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        ),
        //formatter: (cell) => `₹ ${parseFloat(cell).toFixed(2)}`
        },  
        {field: 'settlementper', headerName: 'Settle %', minWidth: 150},
        {field: 'settledamount', headerName: 'Settled Amount', minWidth: 150, 
        //formatter: (cell) => `₹ ${parseFloat(cell).toFixed(2)}`
        renderCell: (params) => (
          <span>
            ₹ {parseFloat(params.value).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        ),
        },
        {field: 'transactiondate', headerName: 'Transaction Date', minWidth: 150, 
        valueGetter: (params) => moment(params.row.transactiondate).format("YYYY-MM-DD HH:mm")
        },
        {field: 'settlementstatus', headerName: 'Settlement Status', minWidth: 150},
        // {
        //   field: "guid",
        //   headerName: "Actions",
        //    // formatter: ActionColumnFormatter,
        // },
        {
          field: 'actions',
          headerName: 'Actions',
          minWidth: 200,
          renderCell: (params) => (
            <>
            
            <i className="bi bi-activity" title={params.row.cell} onClick={() => handleViewClick(params.row.guid)} />
            </>
          ),
        },
    ];

    const handleViewClick = (guid) => {
        setGuid(guid);
        setIsModelDisplay(true);
        setModalShow(true);
      };

    useEffect(()=>{
        fetchPaymentsData();
  
      },[]);
      
      const fetchPaymentsData =() => {
        getPayments().then(response=>{                    
            setPaymentList(response); 
        });
      }
    useEffect(()=>{
    const filteredResults = paymentList.filter((item) => {
        const emailMatch = item.sender.toLowerCase().includes(emailSearch.toLowerCase());

        return emailMatch;
    });
    setFilteredData(filteredResults);
    },[paymentList,emailSearch]);
    
    const handleEmailChange = (e) => {
    setEmailSearch(e.target.value);
    }
    const handleReloadUpdateEvents = () => {       
      fetchPaymentsData();
    };

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <Card style={{ width: '62rem', height: '53rem' }}>
                    <Card.Body>
                    <CustomTooltip text="Coming soon." />
                    <Card.Title style={{ display: 'inline-block' }}>Payment Details</Card.Title>
                    <div className="row">
                        <div className="col">                         
                          
                           <br/>
                           <SearchInputs
                            showEmailSearch={true}
                            emailSearch={emailSearch}
                            onEmailChange={handleEmailChange}
                            ></SearchInputs>
                           
                            <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>

                        </div>
                    </div>
                    <Button variant="primary" type="submit">
                          Export to Excel
                        </Button> 
                   </Card.Body>
                   </Card>
                {isModelDisplay && (
              <SettlementPage
                id={guid}
                show={modalShow}
                onPaymentSettlementSubmit={handleReloadUpdateEvents}
                onHide={() => {
                  setModalShow(false);
                  setIsModelDisplay(false);
                }}
              ></SettlementPage>
            )}
            {/* This button will be removed and enable onclick from grid iteselt */}
            <Button style={{visibility:'hidden'}} onClick={() => handleViewClick(null)}></Button>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default AdminPaymentPage