import React,{useEffect , useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import Select from 'react-select';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { Link, useParams,useNavigate } from "react-router-dom";
import { getNewSubscriberDetails, approveSubscriber, getSubscriptionPlanFormatted } from "../../../api/adminApi";

function CorporateActivityPage(){
  const { guid } = useParams();  
  
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress]= useState('');
  const [mobileNumber, setMobileNumber]= useState('');
  const [shortDescription, setShortDescription]= useState('');
  const [profileURL, setProfileURL]= useState('');
  const [name, setName]= useState('');

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
 
  const [isApproved, setIsApproved] = useState(false);
  const [isFree, setIsFree] = useState(true);
  // const [isCouponApplied, setIsCouponApplied] = useState(false);
  // const [finalPrice, setFinalPrice] = useState(0);
  // const [couponDiscount, setCouponDiscount] = useState(0);

  // const [fetchedActionOptions, setFetchedActionOptions] = useState([ { value: "1", label: "Approved" },
  // { value: "2", label: "Rejected" },
  // ]);
  const [fetchedSubscriptionOptions, setFetchedSubscriptionOptions] = useState([]);
  const fetchedActionOptions = [ 
    { value: "1", label: "Approved" },
    { value: "2", label: "Rejected" },
    { value: "3", label: "Need Clarifications" },
  ];
   
  // const fetchedSubscriptionOptions = [ 
  //   { value: "1", label: "Free Plan", details: "Free Subscription plan | Duration: 3 months | Price: 0" },
  //   { value: "2", label: "Premium Plan" , details: "Crargeable Subscription plan | Duration: 12 months | Price: 2000"},
  //   { value: "3", label: "Basic Plan", details: "Chargeable Subscription plan | Duration: 6 months | Price: 1000" }
  // ];
  //const defaultValue =  { value: "1", label: "Free Plan", details: "Free Subscription plan | Duration: 3 months | Price: 0" };
  
  ////getSubscriptionPlanFormatted
  const customOptionLabel = ({
    label, details
  }) => (
    <div>
      <div>{label}</div>
      <div style={{color:"green"}}>{details}</div>
    </div>
  );

  const initialValues = {
    user_id:guid,
    action: '',
    comments: '',
    subscription_plan_id: '0',
    //coupon: '',    
  }

  const  validationSchema = Yup.object({    
    action: Yup.string()
    .required("Please select the action."),     
    comments: Yup.string()
    .required("Comments is required."),    
  }); 
 
  const handleSubmit = (values) => {
    
    approveSubscriber(values).then((response)=>{
      if(!response.ok){
          setSnackbarMessage('Error in response');
          setShowSnackbar(true);
      } else{
          //return response.json();
      }
      }).then(response=>{
        console.log(response);
        setSnackbarMessage('Successfully reviewed.');
        setShowSnackbar(true);
          //formik.resetForm();
      });
    navigate('/new-leads/corporate');
    //navigate(-1);
  }
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(()=>{

    getNewSubscriberDetails(guid).then(response=>{
      
      setEmailAddress(response.email_address);
      setMobileNumber(response.mobile_number);
      setShortDescription(response.short_description);
      setProfileURL(response.profile_url);
      setName(response.first_name + ' ' + response.last_name );
    });

    getSubscriptionPlanFormatted().then(response => {
      setFetchedSubscriptionOptions(response);
      
    });

  },[]);
  
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
  const handleActionChange = (selectedOption) => {
    if(selectedOption.value === '1'){
      setIsApproved(true);
    }
    else{
      setIsApproved(false);
    }
    formik.setFieldValue('action', selectedOption.label);
  };
  const handleSubscriptionChange = (selectedOption) => {
    formik.setFieldValue('subscription_plan_id', selectedOption.value);
    
    setIsFree(true);
    // if(selectedOption.value === '1'){
    //   setIsFree(true);
    // }
    // else{
    //   setIsFree(false);
    // }
    
  };
  
    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card style={{ width: '62rem', height: '53rem' }}>
                  <Card.Body>
                  <Link to="/new-leads/corporate">
                  <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                       Back
                  </Button>
                  </Link>  
                    <Card.Title style={{ display: 'inline-block' }}>Final review - {name} </Card.Title>
                    <Form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col">
                           &nbsp;
                        </div>
                        </div>
                        <div className="row">
                        <div className="col">
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control required type="text" disabled placeholder="Email Address" 
                                name="email"  
                                value={emailAddress}
                                />  
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control required type="text" disabled placeholder="Mobile Number" 
                                name="mobilNuber"  
                                value={mobileNumber}
                                />  
                            </Form.Group>
                        </div>
                        <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formShortDescription">
                                <Form.Label>Short Description</Form.Label>
                                <Form.Control required as="textarea" disabled rows={4} placeholder="Description" 
                                name="shortDescription"  
                                value={shortDescription}
                                />                               
                              </Form.Group>
                            </div>                            
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formLinkedInURL">
                                <Form.Label>LinkedIn Profile</Form.Label>
                                <Form.Control required type="text" disabled placeholder="Profile URL" 
                                name="linkedInURL"  
                                value={profileURL}
                                />                               
                              </Form.Group>
                            </div>                            
                          </div>
                        </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formBasicCategory">
                                <Form.Label>Action</Form.Label>
                                <Select required
                                  options={fetchedActionOptions}
                                  value={fetchedActionOptions.find((option) => option.label === formik.values.action)}
                                   onChange={handleActionChange}
                                  />
                                 <div
                                    className="invalid-feedback"
                                    style={{
                                        display:
                                        formik.errors.action
                                        ? "block"
                                        : "none",
                                    }}
                                >
                                    {formik.errors.action ? formik.errors.action : null}
                                </div>
                              
                              </Form.Group>
                            </div>                           
                          </div>
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formShortDescription">
                                <Form.Label>Comments</Form.Label>
                                <Form.Control required as="textarea" rows={4} placeholder="Enter comments" 
                                name="comments"  
                                value={formik.values.comments}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                />
                                 <FormErrorMessage
                                      fieldName="comments"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>                            
                          </div>
                          {isApproved && (
                            <div className="row">
                             <div className="col">
                               <Form.Group className="mb-3" controlId="formBannerImage">
                                 <Form.Label>Subscription</Form.Label>
                                 <Select required 
                                   options={fetchedSubscriptionOptions}
                                   value={fetchedSubscriptionOptions.find((option) => option.label === formik.values.subscription_plan_id)}
                                   formatOptionLabel={customOptionLabel}
                                   onChange={handleSubscriptionChange}
                                   // defaultValue={defaultValue}
                                   />
                               </Form.Group>
                             </div>
                              
                           </div>
                          )}
                            {isApproved && !isFree && (
                          <div className="row">
                             <div className="col" >
                             <table>
                             <tr>
                                 <td>
                                     <Form.Group className="mb-3" controlId="formCurriculum">
                                         <Form.Label>Coupon</Form.Label>
                                         <Form.Control type="text" placeholder="Enter coupon" 
                                         name="curriculum"  
                                         style={{width:'150px'}}
                                         >
                                         </Form.Control>
                                         <Form.Text className="text-muted">
                                   Final Price : 
                                 </Form.Text>
                                     </Form.Group>
                                 </td>
                                 <td style={{alignContent:'center', alignItems:'center'}}> 
                                 <i className="bi bi-gift me-2"></i> Apply
                                     {/* <Button variant="primary" type="submit" >
                                         <i class="bi bi-gift me-2"></i> Apply
                                     </Button>   */}
                                 </td>
                             </tr>
                             </table>
                             
                             </div>
                             
                           </div>
                          )}
                          <Button variant="primary" type="submit" className="button">
                          Submit
                          </Button>                 
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
            </div>

        </div>
        </div>
          );

}
export default CorporateActivityPage;