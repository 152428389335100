import React,{useEffect, useState} from "react";
import { Card } from 'react-bootstrap';
import { Link } from "react-router-dom";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
// import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment/moment";
import { getNewSubscribersByRole } from "../../../api/adminApi";
 
import SearchInputs from "../../common/shared/search/SearchInputs";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function MentorLeadsPage(){
    const classes = useStyles();
    //const navigate = useNavigate();
    const [newSubscriberList, setNewSubscriberList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');

    // const newSubscriberList = [
    //     {user_id: 1, user_Logon:'email@gmail.com',first_Name:'Adrian', last_Name:'M',mobile_number:'24242424',role_name:'Mentor',action:'Action' },
    // ];

    // const dateFormatter = (cell) => { //, row
    //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
    //     return <span>{formattedDate}</span>;
    // }
    // const handleActionClick = (id,role,email) => {       
    //     navigate(`/new-subscriberActivity/${id}/${role}/${email}`);
    // }
    const columns =[
        {field: 'first_name', headerName: 'Name', minWidth: 150},
        {field: 'email_address', headerName: 'Email Address', minWidth: 150},
        {field: 'status', headerName: 'Status', minWidth: 150},
        {field: 'created_date', headerName: 'Created Date', minWidth: 150, 
        valueGetter: (params) => moment(params.row.created_date).format("YYYY-MM-DD HH:mm")
        },
        {
          field: 'action',
          headerName: 'Actions',
          minWidth: 200,
          renderCell: (params) => (
            <>
            <Link to={`/new-lead/mdetails/${params.row.guid}`}><i className="bi bi-activity" style={{paddingRight:"10px", fontSize:'20px'}}/></Link>
            </>
          ),
        },

        // {dataField: 'first_name', text: 'Name'},
        // {dataField: 'email_address', text: 'Email Address'},
        // {dataField: 'status', text: 'Status'},    
        // {dataField: 'created_date', text: 'Created date', formatter: dateFormatter},     
        // {
        //     dataField: 'action',
        //     text: 'Action',
        //     formatter: (cell, row) => (
        //       <>
        //       <Link to={`/new-lead/mdetails/${row.guid}`}><i className="bi bi-activity" style={{paddingRight:"10px", fontSize:'20px'}}/></Link>
        //       </>
        //     ),
        // },
    ];
 

    useEffect(()=>{
        fetchNewSubscribersData();  
       
      },[]);
      
      const fetchNewSubscribersData =() => {
        getNewSubscribersByRole('Mentor').then(response=>{   
           //console.log(JSON.stringify(response));                 
            setNewSubscriberList(response); 
            
        });
      }

    useEffect(()=>{
        const filteredResults = newSubscriberList.filter((item) => {
            const emailMatch = item.user_logon.toLowerCase().includes(emailSearch.toLowerCase());

            return emailMatch;
        });
        setFilteredData(filteredResults);
      },[newSubscriberList,emailSearch]);

      const handleEmailChange = (e) => {
        setEmailSearch(e.target.value);
      }

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <Card style={{ width: '62rem', height: '53rem' }}>
                    <Card.Body>
                    <Card.Title style={{ display: 'inline-block' }}>New Mentor Leads</Card.Title>
                    <SearchInputs
                    showEmailSearch={true}
                    emailSearch={emailSearch}
                    onEmailChange={handleEmailChange}
                    ></SearchInputs>
                    {/* <BootstrapTable 
                    keyField="id"
                    data={filteredData}
                    columns={columns}
                    // cellEdit={cellEditFactory(cellEditOptions)}
                    pagination={paginationFactory()}
                    filter={filterFactory()}
                    bootstrap4
                    striped
                    hover
                    condensed
                    wrapperClasses="table-responsive"
                    bordered={false}
                    classes="table table-bordered table-hover"
                    /> */}
                    <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={filteredData}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default MentorLeadsPage