import React, {useState,useEffect  } from "react";
// import Button  from "react-bootstrap/Button";
import { Card } from 'react-bootstrap';
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import "react-datepicker/dist/react-datepicker.css";
//import { deleteMasterdata } from "../../../api/adminApi";
import CreateLanguagePage from './CreateLanguagePage';
import EditConstantsPage from './EditConstantsPage';
// import Modal from 'react-bootstrap/Modal';
import { getConstants } from "../../../api/adminApi";
import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';

function ConstantsSetupPage(){
  const classes = useStyles();
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    //const [showConfirmation, setShowConfirmation] = useState(false);
    const [dataList, setDataList] = useState([]);   
    const [modalShow, setModalShow] = useState(false);
    const [isEditModelDisplay,setIsEditModelDisplay]=useState(false);
    const [isCreateModelDisplay,setIsCreateModelDisplay]=useState(false);
    const [id, setId] = useState(null);
    const [description, setDescription] = useState(null);
    const [value, setValue] = useState(null);
    //const [rowDataToDelete, setRowDataToDelete] = useState(null);

    // const ActionColumnFormatter = (cell, row) => {
        
    //     if (cell) {
    //       return (
    //         <>
    //         <i
    //           className="bi bi-pencil-fill"
    //           onClick={() => handleEditClick(row)}
    //           style={{ paddingRight: "10px" }}
    //         />
    //         {/* <i className="bi bi-trash" 
    //         onClick={() => handleDeleteClick(row)}
    //           style={{ paddingRight: "10px" }}
    //           /> */}
    //         </>
            
    //       );
    //     }
    //   }

    // const handleDeleteClick = (row) => {
    //     setRowDataToDelete(row);
    //     setShowConfirmation(true);
    // };

    // const handleConfirmDelete = () => {
    //     const updatedDataToDelete = {
    //         ...rowDataToDelete,
    //         type: 'LAN',
    //       };

    //     deleteMasterdata(updatedDataToDelete).then((response)=>{    
    //         if(!response.ok){
    //             // const err = new Error("Error in response");
    //             // throw err;
    //             setSnackbarMessage('Error in response');
    //             setShowSnackbar(true);
    //         } else{
    //             //return response.json();
    //         }
    //         }).then(response=>{
    //           console.log(response);
               
    //             //return response;
    //             setSnackbarMessage('Successfully deleted language data.');
    //             setShowSnackbar(true);
    //             fetchConstantsData();
    //         });

    //     setShowConfirmation(false);
    // };

    // const handleCancelDelete = () => {
    //     setShowConfirmation(false);
    // }; 

    const handleEditClick = (row)=>{   
        setId(row.cid); 
        setDescription(row.description); 
        setValue(row.value); 
        setIsEditModelDisplay(true);
        setModalShow(true);
      }

    // const handleCreateMasterdata = ()=>{
    // setModalShow(true);
    // setIsCreateModelDisplay(true);
    // }
     
    const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    }  

    const columns =[
      {field: 'cid', headerName: 'Id', minWidth: 150},
      {field: 'description', headerName: 'Description', minWidth: 150},
      {field: 'value', headerName: 'Value', minWidth: 150},
      {field: 'format', headerName: 'Format', minWidth: 150},
      {
        field: 'value',
        headerName: 'Actions',
        minWidth: 200,
        renderCell: (params) => (
          <>
         <i
              className="bi bi-pencil-fill"
              onClick={() => handleEditClick(params.row)}
              style={{ paddingRight: "10px" }}
            />
          </>
        ),
      },

        // {dataField: 'cid', text: 'Id'},
        // {dataField: 'description', text: 'Description'},
        // {dataField: 'value', text: 'Value'},
        // {dataField: 'format', text: 'Format'},
        // {
        //     dataField: 'value',
        //     text: 'Actions',
        //     formatter: ActionColumnFormatter,
        //   },
    ];
    
    useEffect(()=>{
        fetchConstantsData();
  
      },[]);
      
    const fetchConstantsData =() => {
    
    getConstants().then(response => {
        setDataList(response);
        });
    }

      const handleReloadUpdateEvents = () => {       

        // setIsEditModelDisplay(false);
        // setModalShow(false);
         setSnackbarMessage('Successfully updated data.');
        // setShowSnackbar(true);
        fetchConstantsData();
      };

    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
              <Card style={{ width: '62rem', height: '53rem' }}>
              <Card.Body>
               
                  {/* <Button variant="primary" type="submit" style={{float:"right"}}  onClick={handleCreateMasterdata}>
                       Add Constants
                  </Button>                */}
                  <div className="row">
                        <div className="col">
                            &nbsp; 
                        </div>
                   </div>
                    <div className="row">
                        <div className="col">
                        <Card.Subtitle>Constants Setup</Card.Subtitle>
                        <div style={{float:"right"}}> &nbsp;</div>  
                            {/* <BootstrapTable 
                            keyField="id"
                            data={dataList}
                            columns={columns}
                            // cellEdit={cellEditFactory(cellEditOptions)}
                            pagination={paginationFactory()}
                            // filter={filterFactory()}
                            /> */}
                            <div className={`${classes.dataGridContainer}`}>
                                <DataGrid
                                rows={dataList}
                                columns={columns}
                                getRowId={(row) => row.guid}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                disableSelectionOnClick
                                //hideFooterPagination
                                autoHeight
                                checkboxSelection={false}
                                classes={{
                                    header: classes.header,
                                    toolbarContainer: classes.toolbarContainer,
                                    columnsContainer: classes.columnsContainer,
                                    columnHeaderTitle: classes.columnHeaderTitle,
                                    cell: classes.cell,
                                    paginationItem: classes.paginationItem,
                                    paginationItemSelected: classes.paginationItemSelected,
                                    paginationItemHover: classes.paginationItemHover,
                                }}
                                />

                        </div>
                        </div>
                    </div>
                    
                     

                    {isCreateModelDisplay && 
                        <CreateLanguagePage onMasterSubmit={handleReloadUpdateEvents} show={modalShow} onHide={() => {setModalShow(false); setIsCreateModelDisplay(false);}}  ></CreateLanguagePage>
                    }
                    {isEditModelDisplay && 
                        <EditConstantsPage onMasterSubmit={handleReloadUpdateEvents} id={id} description={description} value={value} show={modalShow} onHide={() => {setModalShow(false); setIsEditModelDisplay(false);}}  ></EditConstantsPage>
                    }
 

                 </Card.Body>
                  </Card>
                </div>
            </div>
            {/* <Modal show={showConfirmation} onHide={handleCancelDelete}>
            <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelDelete}>
                Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
                Delete
            </Button>
            </Modal.Footer>
            </Modal> */}
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
            </div>
        </div>
        </div>        

    )

}

export default ConstantsSetupPage