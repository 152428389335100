import React from "react";
import { PropTypes } from "prop-types";

const FormErrorMessage = ({ formik, fieldName}) => {
    return (
        <div
            className="invalid-feedback"
            style={{
                display:
                formik.touched[fieldName] && formik.errors[fieldName]
                ? "block"
                : "none",
            }}
        >
            {formik.errors[fieldName] ? formik.errors[fieldName] : null}
        </div>
    );
};

FormErrorMessage.propTypes = {
    fieldName: PropTypes.string.isRequired,
    formik: PropTypes.object.isRequired,
};

export default FormErrorMessage;