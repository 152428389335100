import React,{useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import "react-datepicker/dist/react-datepicker.css";
import  Button  from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
//import  {Row,Col} from "react-bootstrap";
// import Col from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import parse  from "date-fns/parse";
import { zonedTimeToUtc } from "date-fns-tz";
import PropTypes from "prop-types";
import { getcustomers  } from "../../../api/mentorApi";

function CreateEvent(props){
    const [mode, setMode] = useState("PUBLIC");  
    const [inputValue, setInputValue] = useState("");
    const [inputId, setInputId] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(true);
    
    let endDate= new Date();
    endDate.setMinutes(endDate.getMinutes()+30);
    const [date,setDate] = useState(new Date());
    // const [startTime, onStartTimeChange] =useState( (new Date().getHours().toString().length == 1 ? ('0' + new Date().getHours().toString()) : new Date().getHours().toString()) + ':'+ (new Date().getMinutes().toString().length == 1 ? (new Date().getMinutes().toString() + '0') : new Date().getMinutes().toString()) );
    // const [endTime,onEndTimeChange] = useState(endDate.getHours().toString()+':'+endDate.getMinutes().toString());
   
    const [startTime, onStartTimeChange] =useState('');
    const [endTime,onEndTimeChange] = useState('');

    
      


    const getCurrentTime = () => {
        const currentDate = new Date();
        const minutes = currentDate.getMinutes();
        const roundedMinutes = Math.ceil(minutes / 30) * 30;
        currentDate.setMinutes(roundedMinutes);
        return currentDate.toTimeString().slice(0,5);

        // const currentDate = new Date();
        // const roundedHour = currentDate.getHours() + 1;
        // currentDate.setHours(roundedHour, 0, 0, 0);    
        // return currentDate.toTimeString().slice(0, 5);

      }
      const getEndTime = () => {
        const selctedTime = getCurrentTime(); //formik.values.startTime;
        const [hours, minutes1] = selctedTime.split(':');
        const endTime = new Date();
        endTime.setHours(parseInt(hours) + 1);
        endTime.setMinutes(parseInt(minutes1));   
        return endTime.toTimeString().slice(0,5);

        // const selectedTime = getCurrentTime(); //formik.values.startTime;
        // const [hours, minutes1] = selectedTime.split(':');
        // const endTime = new Date();
        // endTime.setHours(parseInt(hours) + 1);
        // endTime.setMinutes(parseInt(minutes1));
        // return endTime.toTimeString().slice(0, 5);

      }

    //   const hourlyTimeOptions = [];
    //     for (let i = 0; i < 24; i++) {
    //         const hour = i.toString().padStart(2, '0');
    //         hourlyTimeOptions.push(`${hour}:00`);
    //     }

        // const calculateNearestHour = () => {
        //     const currentDate = new Date();
        //     const currentHour = currentDate.getHours();
        //     const currentMinute = currentDate.getMinutes();
        
        //     // Calculate the nearest hour
        //     let nearestHour = currentHour;
        
        //     if (currentMinute >= 30) {
        //     nearestHour++;
        //     }
        
        //     if (nearestHour >= 24) {
        //     nearestHour = 0;
        //     }
        
        //     return `${nearestHour.toString().padStart(2, "0")}:00`;
        // };

         

        const handleEndTimeChange = (time) => {
            //const [hours, minutes] = time.split(':');
            const [, minutes] = time.split(':');
            if (minutes === '00' || minutes === '30') { //|| minutes === '30'
              onEndTimeChange(time);
            } else {
              console.log('Invalid end time');
              //formik.setFieldValue('end', '00:00');
            }
          };
          
          const handleStartTimeChange = (time) => {
            const now = new Date();
            const selectedTime = new Date(date);
            selectedTime.setHours(time.split(':')[0]);
            selectedTime.setMinutes(time.split(':')[1]);

            if (selectedTime < now) {
              // alert();
            }
            else{
              const [, minutes] = time.split(':');
              if (minutes === '00' || minutes === '30') { //|| minutes === '30'
                // onStartTimeChange(time);

                // const [hours, minutes] = time.split(':').map(Number);
                // const newEndTime = `${(hours + 1).toString().padStart(2, '0')}:00`;
                // onEndTimeChange(newEndTime);

                const newEndTime = new Date(selectedTime);
                newEndTime.setHours(newEndTime.getHours() + 1);
            
                // Format the new end time as HH:mm
                const formattedEndTime =
                  newEndTime.getHours().toString().padStart(2, '0') +
                  ':' +
                  newEndTime.getMinutes().toString().padStart(2, '0');
            
                onStartTimeChange(time);
                onEndTimeChange(formattedEndTime);


              } else {
                console.log('Invalid start time');
                //formik.setFieldValue('start', '00:00');
              }
            }

           
          };
          
        //   const isEndTimeValid = (startTime, endTime) => {
        //     const [startHours, startMinutes] = startTime.split(':');
        //     const [endHours, endMinutes] = endTime.split(':');
          
        //     const startTimestamp = parseInt(startHours) * 60 + parseInt(startMinutes);
        //     const endTimestamp = parseInt(endHours) * 60 + parseInt(endMinutes);
          
        //     return endTimestamp - startTimestamp >= 60;
        //   };

    useEffect(()=>{
        const fetchSuggestions = async () => {
            try {
                const customersData = await getcustomers();
                // Filter data based on input value
                const filteredSuggestions = customersData.filter(
                    (customer) =>
                    customer.name.toLowerCase().includes(inputValue.toLowerCase())
                );

                setSuggestions(filteredSuggestions);
                // getcustomers().then(response=>{                    
                //     setSuggestions(response);
                // });
              
            } catch (error) {
              // Handle errors
            }
          };
      
          if (inputValue.trim() !== "") {
            fetchSuggestions();
          } else {
            setSuggestions([]);
          }

       
        onStartTimeChange(getCurrentTime());
        onEndTimeChange(getEndTime());
        // onStartTimeChange((new Date().getHours().toString().length == 1 ? ('0' + new Date().getHours().toString()) : new Date().getHours().toString()) + ':'+ (new Date().getMinutes().toString().length == 1 ? (new Date().getMinutes().toString() + '0') : new Date().getMinutes().toString()) );
        // onEndTimeChange(endDate.getHours().toString()+':'+endDate.getMinutes().toString());

        //onStartTimeChange(calculateNearestHour());

    },[inputValue,inputId])
   
    
    const handleCalendarClose =()=>{

    }

    const handleCalendarOpen=()=>{

    }

    // const handleTypeOfChange =() =>{

    // }

    const handleCreateNewEvent =(event)=>{
     
    
     let eventObj={};
     event.preventDefault();

     const dateValue = event.target.elements.date.value;
     const startTimeValue = event.target.elements.startTime.value;
     const endTimeValue = event.target.elements.endTime.value;

     const startDateTimeString = `${dateValue} ${startTimeValue}`;
     const endDateTimeString = `${dateValue} ${endTimeValue}`;
     const localTimeZone ='UTC';

     eventObj.title=event.target.title.value;
     eventObj.start= zonedTimeToUtc(parse(startDateTimeString, "MM/dd/yyyy HH:mm",new Date()),localTimeZone);
     eventObj.end=zonedTimeToUtc(parse(endDateTimeString, "MM/dd/yyyy HH:mm",new Date()),localTimeZone);
     eventObj.description=event.target.elements.description.value;
     eventObj.color='#378006';
     //eventObj.Datestring = new Date(event.target.elements.date.value + ' '+ event.target.elements.endTime.value).toLocaleDateString([],{ year: 'numeric', month: 'long', day: 'numeric' });

     eventObj.mode = mode;
     eventObj.inputId = inputId;
     eventObj.inputValue = inputValue;

     props.addNewEvent(eventObj);
       

     //props.onHide();
 
    }

    const handleModeChange = (e) => {
        setMode(e.target.value);
        setInputValue('');
        setInputId('');
      }; 
     
    
      const handleInputChange = (e) => {
        setInputValue(e.target.value);       
        setShowSuggestions(true);
      };
    
      const handleSelectSuggestion = (selectedItem) => {
        setInputValue(selectedItem.name);
        setInputId(selectedItem.id)
        setSuggestions([]); // Clear suggestions when a suggestion is selected
        setShowSuggestions(false);
      };

    return(
        <div>
      

        <Modal  
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Create Session
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={handleCreateNewEvent}>
            <div className="row">
                <div className="col">
                  *Every 1-1 session will be conducted/blocked for an hour. If you select more than one hour, it will be considered and displayed as multiple sessions.
                    <Form.Group className="mb-3" controlId="formTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" required placeholder="title" name="title"></Form.Control>
                    </Form.Group>  
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="formDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={3} required placeholder="Description" name="description"></Form.Control>
                    </Form.Group>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="formDate">
                    <Form.Label>Date</Form.Label>
                    <DatePicker required
                    selected={date}
                    onChange={(date)=>{setDate(date)}}
                    onCalendarClose={handleCalendarClose}
                    onCalendarOpen={handleCalendarOpen}
                    name="date"
                    minDate={new Date()}
                    ></DatePicker>
                    </Form.Group>
                </div>
                <div className="col">
                    <Form.Group className="mb-3" controlId="formStartTime">
                    <Form.Label>Start At</Form.Label><br></br>
                    <TimePicker required
                    //  onChange={onStartTimeChange}
                    onChange={handleStartTimeChange}
                    disableClock={true}
                    value={startTime}
                    name="startTime"></TimePicker>
                    </Form.Group>
                </div>
                <div className="col">
                    <Form.Group className="mb-3" controlId="formEndTime">
                    <Form.Label>End At</Form.Label><br></br>
                    <TimePicker required
                    //onChange={onEndTimeChange}
                    onChange={handleEndTimeChange}
                    disableClock={true}
                    value={endTime}
                    name="endTime"></TimePicker>
                    </Form.Group>
                    <Form.Text className="text-muted">
                    You can select only hourly time slots 
                    </Form.Text>
                </div>
            </div>  
            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="formEndTime">
                    <Form.Check
                    inline
                    label="PUBLIC"
                    name="mode"
                    type="radio"
                    id="radio-public"
                    value="PUBLIC"
                    checked={mode === "PUBLIC"}
                    onChange={handleModeChange}
                  />
                  <Form.Check
                    inline
                    disabled
                    label="PRIVATE"
                    name="mode"
                    type="radio"
                    id="radio-private"
                    value="PRIVATE"
                    checked={mode === "PRIVATE"}
                    onChange={handleModeChange}
                  />
                  {mode === "PRIVATE" && (
                    // <Form.Control
                    //   required
                    //   type="text"
                    //   placeholder="Enter User Name"
                    //   name="username"
                    // />
                    <div className="autocomplete-container">
      <input
       required={mode === 'PRIVATE'}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type a name"
      />
      {inputValue && showSuggestions && (
        <ul className="suggestions-list">
          {suggestions.length > 0 ? (
            suggestions.map((item) => (
              <li
                key={item.id}
                onClick={() => handleSelectSuggestion(item)}
                className="suggestion-item"
              >
                {item.name}
              </li>
            ))
          ) : (
            <li className="no-data">No data found</li>
          )}
        </ul>
      )}
    </div>
                  )} 
                                
                    </Form.Group>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Form.Group className="mb-3" controlId="btnCreate">
                    <Button variant="primary" type="submit" className="button"> Create Session </Button>
                    </Form.Group>
                </div>
            </div>
            </Form>
        </Modal.Body>
 
    </Modal>
    

        </div>
    )
}

CreateEvent.propTypes ={
    addNewEvent: PropTypes.object.isRequired,   
};
export default CreateEvent;