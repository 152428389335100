// import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
// import * as ReactBootStrap from "react-bootstrap";
import { getCourses, deleteCourse } from "../../../api/mentorApi";
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
import 'bootstrap/dist/css/bootstrap.min.css';
// import cellEditFactory from 'react-bootstrap-table2-editor';
// import OverlayFactory from "react-bootstrap-table2-overlay";
import { Link } from "react-router-dom";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { Card } from 'react-bootstrap';
import Button  from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';

import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';
function MentorCoursesViewPage() {
  const [clsName, setclsName] = useState('');
  const [items, setItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [rowDataToDelete, setRowDataToDelete] = useState(null);

  const classes = useStyles();

   
  const columns =[
    {field: 'title', headerName: 'Title', minWidth: 100},
    {
      field: 'overview', 
      headerName: 'Overview',
      minWidth: 150
    },
    {
      field: 'categoryitems',
      headerName: 'Category',
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.categoryitems && params.row.categoryitems.length > 0) {        
          const formattedCategories = params.row.categoryitems.map((item) => item.label).join(', ');  
          return <div>{formattedCategories}</div>;
        } else {
          return <div>No categories</div>;
        }
      },
    },
    {
      field: 'langitems',
      headerName: 'Language',
      minWidth: 200,
      renderCell: (params) => {
        if (params.row.langitems && params.row.langitems.length > 0) {        
          const formattedLang = params.row.langitems.map((item) => item.label).join(', ');  
          return <div>{formattedLang}</div>;
        } else {
          return <div>No language</div>;
        }
      },
    },
    // {
    //   field: 'objectives', 
    //   headerName: 'Objectives',
    //   minWidth: 200
    // },
    {
      field: 'statustxt',
      headerName: 'Status',
      minWidth: 150,
      renderCell: (params) => {
        let displayStatus = params.row.statustxt;
  
        // Change displayStatus based on conditions
        if (params.row.statustxt === 'Pending') {
          displayStatus = 'Under review';
        } else if (params.row.statustxt === 'Approved') {
          displayStatus = 'Published';
        }
  
        return <span>{displayStatus}</span>;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      minWidth: 150,
      renderCell: (params) => (
        <>
        <Link to={`/courseEdit/${params.row.id}`}><i className="bi bi-pencil-fill" style={{paddingRight:"10px"}}/></Link>
      
        <i className="bi bi-trash" title={params.cell} onClick={() => handleDeleteClick(params.row)} />
        </>
      ),
    
    },
    
  ];

  const handleDeleteClick = (row) => {
    setRowDataToDelete(row);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
      const updatedDataToDelete = {
          ...rowDataToDelete,
        };
        deleteCourse(updatedDataToDelete.id).then((response)=>{
        
          if(!response.ok){
              // const err = new Error("Error in response");
              // throw err;
              setclsName('alert error');
              setSnackbarMessage('Error in response');
              setShowSnackbar(true);
          } else{
              //return response.json();
          }
          }).then(response=>{
            console.log(response);
            setclsName('alert success');
              setSnackbarMessage('Successfully deleted Course data.');
              setShowSnackbar(true);
              fetchCourseData();
          });

      setShowConfirmation(false);
  };

  const handleCancelDelete = () => {
      setShowConfirmation(false);
  }; 

    

    useEffect(()=>{
      fetchCourseData();

    },[]);
    
    const fetchCourseData =() => {
      getCourses().then(response=>{
        const data = response;
        //const total = 10;//response.headers.get("x-total-count");
        //setpageCount(Math.ceil(total / limit));              
        setItems(data);
       
        // console.log(response);
         //console.log(JSON.stringify(response));
      });
    }

    const handleCloseSnackbar = () => {
      setShowSnackbar(false);
    }
    
    return (
      <div className="featured-section-wrap featured-section-wrap-row">
      <div className='mainPageContainer'>        
          <div className='dashboardcontainerflex'>
            <div className='dashboardcard'>
            <Card>
              <Card.Body>
              <CustomTooltip text="You can easily upload your course (using XYZ website), or any other resources such as documents, images, and activities for users by uploading them to the platform for easy access." />
                   
                  <div className="row">
                  <div className="col" style={{float:"right"}}>
                  <Link to="/courseAdd">
                  <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                      + Add Courses
                  </Button>
                  </Link>
                    </div>
                    </div>
                  <div className="row">
                    <div className="col">
                      
                  <div className={`${classes.dataGridContainer}`}>
                      <DataGrid
                      rows={items}
                      columns={columns}
                      initialState={{
                      pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                      },
                      }}
                      pageSizeOptions={[5, 10]}
                      disableSelectionOnClick
                      //hideFooterPagination
                      autoHeight
                      checkboxSelection={false}
                      classes={{
                      header: classes.header,
                      toolbarContainer: classes.toolbarContainer,
                      columnsContainer: classes.columnsContainer,
                      columnHeaderTitle: classes.columnHeaderTitle,
                      cell: classes.cell,
                      paginationItem: classes.paginationItem,
                      paginationItemSelected: classes.paginationItemSelected,
                      paginationItemHover: classes.paginationItemHover,
                      }}
                      />

                  </div>
     
                    {selectedImage && (
                        <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
                          <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Image Preview</h5>
                                <button type="button" className="close" onClick={() => setSelectedImage(null)}>
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                {/* <img src={`data:image/png;base64, ${selectedImage}`} 
                                alt="Selected" 
                                className="img-fluid" 
                                /> */}
                                 <img src={selectedImage} className='card-img-top' alt="Card image cap"
                              style={{ width: '100%', height: '100%', borderRadius: '50%'}} />

                              </div>
                            </div>
                          </div>
                        </div>
                      )}
        

                    <div>
                      <SnackbarProvider 
                      show={showSnackbar}
                      message={snackbarMessage}
                      onClose={handleCloseSnackbar}
                      message1={clsName}
                      />

<Modal show={showConfirmation} onHide={handleCancelDelete}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="button" onClick={handleCancelDelete}>
                        Cancel
                    </Button>
                    <Button variant="danger" className="button" onClick={handleConfirmDelete}>
                        Delete
                    </Button>
                    </Modal.Footer>
                    </Modal>
                    </div>
                    </div>
                    </div>
              </Card.Body>
            </Card>
          </div>
          </div>
          </div>
      </div>
    );

}

export default MentorCoursesViewPage;