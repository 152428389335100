import React, {useEffect, useState } from "react";
// import Button  from "react-bootstrap/Button";
// import { Card } from 'react-bootstrap';
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import { getQuestions } from "../../../api/corporateApi";
//import { useParams } from "react-router-dom";
// import moment from "moment/moment";
// import SearchInputs from "../../common/shared/search/SearchInputs";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { PropTypes } from "prop-types";
function PreviewPage(props){
     
    //const { guid } = useParams();  
    //const [employeeList, setEmployeeList] = useState([]);
    // const [selectedEmployees, setSelectedEmployees] = useState([]);
    // const isAnyCheckboxSelected = selectedEmployees.length > 0;
    // const [filteredData, setFilteredData] = useState([]);
    //const [emailSearch, setEmailSearch] = useState('');
    const [questionsList, setQuestionsList] = useState([]);
    // const [questionType, setQuestionType] = useState('single_choice');
  //   const dateFormatter = (cell) => {  
  //     if (!cell) {
  //       return <span>{''}</span>;  
  //     }

  //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
  //     return <span>{formattedDate}</span>;
  // }

    // const columns =[
    //     {
    //         dataField: 'emailaddress',  
    //         text: 'Status',
    //         formatter: (cell, row) => {
    //             if (!row.createddate) {
    //               return <span>Not Initiated</span>;
    //             } else {
    //                 return <span>Completed</span>;
    //             }
    //           },
    //         },
    //     {dataField: 'firstname', text: 'First Name'},
    //     // {dataField: 'lastname', text: 'Last Name'},
    //     {dataField: 'emailaddress', text: 'Email Address'},
    //     {dataField: 'score', text: 'Score'},
    //     {dataField: 'percentage', text: 'Percentage'},
    //     {dataField: 'timetaken', text: 'Timetaken'},
    //     {dataField: 'createddate', text: 'Completion Date', formatter: dateFormatter},
         
    // ];

    
      
    useEffect(()=>{
       // fetchAssessentResultsData();
        fetchQuestionsData();
         
          },[]);
      
      // const fetchAssessentResultsData =() => {
      //   getAssessmentResults(guid).then(response=>{                    
      //     setEmployeeList(response); 
      //   })
      //   .catch(error => {
      //     // Handle error
      //     console.log(error);
      //   });
      // }
      const fetchQuestionsData =() => {
   
        if (props.assessmentguid !== null && props.assessmentguid !== undefined) {
            getQuestions(props.assessmentguid).then(response=>{                    
                setQuestionsList(response); 
            })
            .catch(error => {
              // Handle error
              console.log(error);
            });
          }
        
      }

//  useEffect(()=>{
//       const filteredResults = employeeList.filter((item) => {
//           const emailMatch = item.emailaddress.toLowerCase().includes(emailSearch.toLowerCase());
  
//           return emailMatch;
//       });
//       setFilteredData(filteredResults);
//       },[employeeList,emailSearch]);
      
      // const handleEmailChange = (e) => {
      // setEmailSearch(e.target.value);
      // }

    return(
      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            Assessment Preview
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className=''>
                <div className=''>
                {questionsList.length > 0 ? (
                questionsList.map((item, index) => (
                <div key={item.guid} style={{width:"650px;"}}>
                  <article className="card mt-3 flex w-full cursor-pointer flex-col rounded-lg border border-slate-300 p-2 transition-all hover:animate-pulse">
                    <p className="text-sm" style={{ margin: 0 }}>
                      {index + 1}. {item.questiontext}
                    </p>
                    <p className="text-xs" style={{ margin: 0 }}></p>
                    <span className="mt-3 flex flex-row items-center gap-x-2" style={{ color: "#44D5E7" }} >
                    {item.options.map((itemoptions, index) => (
                      <>
                      
                    <div className="row" key={index}>
                    <div className="col" style={{ marginBottom: '5px',marginTop: '5px' }}>
                      <Form.Check
                        type={item.type === 'multiple_choice' ? 'checkbox' : 'radio'}
                        label=""
                        checked={itemoptions.correctAns}
                        //onChange={() => handleCheckboxChange(index)}
                      />
                    </div>
                    <div className="col"  style={{ marginBottom: '5px', marginTop: '5px', marginLeft: '-700px' }}>
                      {/* <Form.Control
                        required
                        type="text"
                        placeholder={`Option ${index + 1}`}
                        value={itemoptions.optionText}
                        //onChange={(e) => handleOptionTextChange(e, index)}
                      /> */}
                      <i className="aLink">{itemoptions.optionText}</i>
                    </div>
                   
                  </div>

                      </>
                    ))}
                    </span>
                  </article>
                </div>
            ))
          ) : (
            <p>No Questions</p>
          )}
                </div>
            </div>
        </div>
        </div>        
        </Modal.Body>
        </Modal>
    )

}

PreviewPage.propTypes ={
  props: PropTypes.object.isRequired, 
  assessmentguid: PropTypes.string.isRequired, 
  
};
export default PreviewPage