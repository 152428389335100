import React,{ useState} from "react";
import Form from 'react-bootstrap/Form';
import Button  from "react-bootstrap/Button";
// import Col  from "react-bootstrap/Col";
// import { InputGroup } from "react-bootstrap/InputGroup";
// import Row  from "react-bootstrap/Row";
import { Card } from 'react-bootstrap';
// import ListGroup from 'react-bootstrap/ListGroup';
// import Alert from 'react-bootstrap/Alert';
//import Select from 'react-select';
import { addSubscriptionPlan } from "../../../api/adminApi";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
// import { getMasterData } from "../../../api/mentorApi";
import { useFormik } from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import { Link } from "react-router-dom";
import SubscriptionSetupPage from "./SubscriptionSetupPage";
//import DateTimePicker from 'react-datetime-picker';
//import moment from "moment/moment";

function CreateSubscriptionPage(){
  // const [categoryOptions,setCategoryOptions] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showViewPageModal, setShowViewPageModal] = useState(false);
  // const [curriculum, SetCurriculum] = useState('');
  //const fileInputRef = useRef(null);
  // const fetchedPageOptions = [ { value: "1", label: "HOME PAGE" },
  // { value: "2", label: "SUB PAGE" }]

  const [selectedOption, setSelectedOption] = useState('day');
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const initialValues = {
    planname: '', 
    description: '',
    interval: '',
    intervaltype: '',
    price: 0,   
  }

  const  validationSchema = Yup.object({
    planname: Yup.string()
    .required("Plan name is required."),
    description: Yup.string()
    .required("Description is required."),
    interval: Yup.string()
    .required("Interval is required."),
    // intervaltype: Yup.string()
    // .required("Interval type is required."),
    price: Yup.string()
    .required("Price is required."),
  }); 

 
  const handleSubmit = (values) => {
    
    values.intervaltype = selectedOption;
    
    //values.preventDefault();
    try {
       
      addSubscriptionPlan(values).then((response)=>{
        if(!response.ok){
            // const err = new Error("Error in response");
            // throw err;
            setSnackbarMessage('Error in response');
            setShowSnackbar(true);
        } else{
            //return response.json();
        }
        }).then(response=>{
          console.log(response);
            //return response;
            setSnackbarMessage('Successfully added Subscription data');
            setShowSnackbar(true);
            formik.resetForm();
        });

    }
    catch (error){
      console.log(error);
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  
 
 
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  }
 
  const closeViewPageModal = () => {
    setShowViewPageModal(false);
  }
   

    return(

        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
              <div className='dashboardcard'>
                  <Card style={{ width: '62rem', height: '53rem' }}>
                  <Card.Body>
                    
                  
                   <Link to="/config-subscriptionplan/view">
                  <Button variant="primary" className="button" type="submit" style={{float:"right"}} >
                       Back
                  </Button>
                  </Link>
                    <Card.Title style={{ display: 'inline-block' }}>Add Subscription Plan</Card.Title>
                    <Form onSubmit={formik.handleSubmit}>
                      
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>Plan Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter Plan Name" 
                                name="planname"  
                                value={formik.values.planname}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="planname"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formdesc">
                                <Form.Label>Description</Form.Label>
                                <Form.Control required type="text" placeholder="Enter description" 
                                name="description"  
                                value={formik.values.description}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="description"
                                      formik={formik}
                                  ></FormErrorMessage>

                         
                                </Form.Group>
                            </div>                       
                          </div>
                          
                           
                          <div className="row">
                            <div className="col">
                              <Form.Group className="mb-3" controlId="forminterval">
                                <Form.Label>Interval</Form.Label>
                                <Form.Control required type="text" placeholder="Enter interval" 
                                name="interval"  
                                value={formik.values.interval}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                <div className="option-container">
                                <div
                                  className={`option-box ${selectedOption === 'day' ? 'selected' : ''}`}
                                  onClick={() => handleOptionClick('day')}
                                >
                                  Day
                                </div>
                                <div
                                  className={`option-box ${selectedOption === 'month' ? 'selected' : ''}`}
                                  onClick={() => handleOptionClick('month')}
                                >
                                  Month
                                </div>
                                <div
                                  className={`option-box ${selectedOption === 'year' ? 'selected' : ''}`}
                                  onClick={() => handleOptionClick('year')}
                                >
                                  Year
                                </div>
                              </div>
                                 <FormErrorMessage
                                      fieldName="interval"
                                      formik={formik}
                                  ></FormErrorMessage>

                              </Form.Group>
                            </div>
                            <div className="col">
                              <Form.Group className="mb-3" controlId="formPrice">
                                <Form.Label>Price</Form.Label>
                                <Form.Control required type="text" placeholder="Enter price" 
                                name="price"  
                                value={formik.values.price}
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur}                                
                                />
                                 <FormErrorMessage
                                      fieldName="price"
                                      formik={formik}
                                  ></FormErrorMessage>
                              </Form.Group>
                            </div>
                          
                          </div>   
                          
                          <Button variant="primary" className="button" type="submit">
                          Submit
                          </Button>                 
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              />
            </div>

            {showViewPageModal && (
            <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Subscription Plan List</h5>
                    <button type="button" className="close" onClick={closeViewPageModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <SubscriptionSetupPage/>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        </div>
          );

}
export default CreateSubscriptionPage;