import React,{useEffect, useState} from "react";
import { Card } from 'react-bootstrap';
// import { Link } from "react-router-dom";
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
// import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment/moment";
import { getEnrollments  } from "../../../api/mentorApi";
import SearchInputs from "../../common/shared/search/SearchInputs";

import { DataGrid} from '@mui/x-data-grid';
import useStyles from '../../common/shared/grid/useStyles';
import CustomTooltip from '../../common/shared/tooltip/CustomTooltip';

function EnrollmentsPage(){
    const [enrollmentList, setEnrollmentList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [emailSearch, setEmailSearch] = useState('');
    const classes = useStyles();

    // const dateFormatter = (cell) => { //, row
    //     const formattedDate = moment(cell).format('YYYY-MM-DD HH:mm');
    //     return <span>{formattedDate}</span>;
    // }
    const columns =[
        {field: 'id', headerName: '#'},
        {field: 'type', headerName: 'Type', minWidth: 150},
        {field: 'title', headerName: 'Session / Workshop Title', minWidth: 200},
        {field: 'scheduledat', headerName: 'Scheduled At', minWidth: 150},
        {field: 'firstname', headerName: 'First Name', minWidth: 100},
        // {field: 'mobilenumber', headerName: 'Mobile Number'},
        // {field: 'emailaddress', headerName: 'Email'},
        {field: 'status', headerName: 'Status', minWidth: 100},
        {field: 'createddate', headerName: 'Created date', minWidth: 200,
        valueGetter: (params) => moment(params.row.createddate).format("YYYY-MM-DD HH:mm"), 
         //formatter: dateFormatter
        },
    ];
    useEffect(()=>{
        fetchEnrollmentsData();
  
      },[]);
      
      const fetchEnrollmentsData =() => {
        getEnrollments().then(response=>{                    
            setEnrollmentList(response); 
        });
      }
    useEffect(()=>{
    const filteredResults = enrollmentList.filter((item) => {
        const emailMatch = item.emailaddress.toLowerCase().includes(emailSearch.toLowerCase());

        return emailMatch;
    });
    setFilteredData(filteredResults);
    },[enrollmentList,emailSearch]);
    
    const handleEmailChange = (e) => {
    setEmailSearch(e.target.value);
    }
    return(
        <div className="featured-section-wrap featured-section-wrap-row">
        <div className='mainPageContainer'>        
            <div className='dashboardcontainerflex'>
                <div className='dashboardcard'>
                <Card>
                    <Card.Body>
                    <CustomTooltip text="Coming soon." />

                    {/* <Card.Title style={{ display: 'inline-block' }}>Enrollment Details</Card.Title> */}
                    <SearchInputs
                    showEmailSearch={true}
                    emailSearch={emailSearch}
                    onEmailChange={handleEmailChange}
                    ></SearchInputs>
                    
                    <div className={`${classes.dataGridContainer}`}>
                      <DataGrid
                      rows={filteredData}
                      columns={columns}
                      initialState={{
                      pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                      },
                      }}
                      pageSizeOptions={[5, 10]}
                      disableSelectionOnClick
                      //hideFooterPagination
                      autoHeight
                      checkboxSelection={false}
                      classes={{
                      header: classes.header,
                      toolbarContainer: classes.toolbarContainer,
                      columnsContainer: classes.columnsContainer,
                      columnHeaderTitle: classes.columnHeaderTitle,
                      cell: classes.cell,
                      paginationItem: classes.paginationItem,
                      paginationItemSelected: classes.paginationItemSelected,
                      paginationItemHover: classes.paginationItemHover,
                      }}
                      />

                  </div>

                    </Card.Body>
                </Card>
                </div>
            </div>
        </div>
        </div>        

    )

}
export default EnrollmentsPage