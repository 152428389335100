import React, {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import Form from "react-bootstrap/Form";
import  Button  from "react-bootstrap/Button";
import  SnackbarProvider  from "../../authContext/SnackbarProvider";
import { updateRole } from "../../../api/corporateApi";
import { useFormik} from "formik"
import FormErrorMessage from '../../common/shared/form-error-message/FormErrorMessage';
import * as Yup from "yup";
import PropTypes from "prop-types";

function EditRolePage(props){
  const [clsName, setclsName] = useState('');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    //const { id } = useParams();
    const { guid,name,description } = props;
    const initialValues = {  
        guid: guid,
        name: name,
        description: description,
      }
    
      const  validationSchema = Yup.object({  
        name: Yup.string()
        .required("Role name is required."), 
      }); 

      const handleSubmit = (values) => {
        updateRole(values).then((response)=>{    
          if(!response.ok){
              // const err = new Error("Error in response");
              // throw err;
              setclsName('alert error');
              setSnackbarMessage('Error in response');
              setShowSnackbar(true);
          } else{
              //return response.json();
          }
          }).then(response=>{
            console.log(response);
             
              //return response;
              setclsName('alert success');
              setSnackbarMessage('Successfully updated role data.');
              setShowSnackbar(true);
              //formik.resetForm();
              props.onMasterSubmit();
          });
      }

      const handleCloseSnackbar = () => {
        setShowSnackbar(false);
      }

      const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
      });
    

    return (
        <>
         <Modal  
             {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Role
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
                <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formLabel">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" placeholder="Enter Name" 
                        name="name"  
                        value={formik.values.name}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}                                
                        />
                            <FormErrorMessage
                                fieldName="name"
                                formik={formik}
                            ></FormErrorMessage>
                        </Form.Group>
                        
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <Form.Group className="mb-3" controlId="formLabel">
                        <Form.Label>Description</Form.Label>
                        <Form.Control required type="text" placeholder="Enter Name" 
                        name="description"  
                        value={formik.values.description}
                        onChange={formik.handleChange} 
                        onBlur={formik.handleBlur}                                
                        />
                            <FormErrorMessage
                                fieldName="description"
                                formik={formik}
                            ></FormErrorMessage>
                        </Form.Group>
                        
                        </div>
                    </div>
                    
                    <Button variant="primary" className="button" type="submit"> Submit </Button>
            </Form>
            </Modal.Body>
        </Modal>
        <div>
              <SnackbarProvider 
              show={showSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar}
              message1={clsName}
              />
            </div>
                   
        </>
    );
}

EditRolePage.propTypes ={
  guid: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onMasterSubmit: PropTypes.object.isRequired,
}; 
export default EditRolePage;